
// Import Custom Modules
import {
    SMALL_MAX_WIDTH,
    SMALL_MAX_HEIGHT,
    MEDIUM_MAX_WIDTH,
    MEDIUM_MAX_HEIGHT
} from './UIBreakpoints';

//
// A file containing various UI Static Media Queries for different viewing formats
// This file is created to maintain a centeral name for MDVT cell types around the program.
// 
//

// 'mobile'
export const NO_HOVER_QUERY = `(hover: none)`;

// Micro

// Small
export const MOBILE_PORTRAIT_MEDIA_QUERY = `(max-width:${SMALL_MAX_WIDTH}px)`;
export const MOBILE_LANDSCAPE_MEDIA_QUERY = `(max-height:${SMALL_MAX_HEIGHT}px)`;
// Medium
export const TABLET_PORTRAIT_MEDIA_QUERY = `(min-width:${SMALL_MAX_WIDTH + 1}px) and (max-width:${MEDIUM_MAX_WIDTH}px) and (min-height: ${SMALL_MAX_HEIGHT + 1}px) and (max-height: ${MEDIUM_MAX_HEIGHT}px) and (orientation: portrait)`;
export const TABLET_LANDSCAPE_MEDIA_QUERY = `(min-width:${SMALL_MAX_HEIGHT + 1}px) and (max-width:${MEDIUM_MAX_HEIGHT}px) and (min-height: ${SMALL_MAX_WIDTH + 1}px) and (max-height: ${MEDIUM_MAX_WIDTH}px) and (orientation: landscape)`;
// Large
export const DESKTOP_PORTRAIT_MEDIA_QUERY = `(min-width: ${MEDIUM_MAX_WIDTH + 1}px) and (orientation: portrait)`;
export const DESKTOP_LANDSCAPE_MEDIA_QUERY = `(min-width: ${MEDIUM_MAX_HEIGHT + 1}px) and (orientation: landscape)`;
// X-Large

// Dark Mode Preference
export const COLOR_THEME_PREFERENCE_DARK = "(prefers-color-scheme: dark)";