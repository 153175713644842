import * as ROUTES from '../navigation/routes';

const SettingsCategoryList = [
    {
        title: 'Customization',
        identifier: 'settings_customization',
        children: [
            {
                title: 'Objects',
                identifier: 'objects',
                description: 'View and modify the information related to all objects on the platform. (Includes customizing object types, fields, and actions.)',
                route: ROUTES.SETTINGS_CUSTOMIZE_OBJECTS,
                permission_requirements: [
                    {
                        type: 'admin_permissions',
                        value: 'customize_objects'
                    }
                ],
            },
            {
                title: 'Customizations Manager',
                identifier: 'customizations_manager',
                description: 'View or update all Object and Global Customizations. Object Details, Actions, Object Fields, Object Types, List Views, Page Layouts, Dropdown Sets, and more.',
                route: ROUTES.SETTINGS_CUSTOMIZATIONS_MANAGER,
                permission_requirements: [
                    {
                        type: 'admin_permissions',
                        value: 'customizations_manager'
                    }
                ],
            }
        ],
    },
    {
        title: 'Company',
        identifier: 'settings_company',
        children: [
            {
                title: 'Company Information',
                identifier: 'company_information',
                description: "Change you company's information and tax details.",
                route: ROUTES.SETTINGS_COMPANY,
                permission_requirements: [
                    {
                        type: 'admin_permissions',
                        value: 'edit_company_information'
                    }
                ],
            },
            {
                title: 'Company Branding',
                identifier: 'company_branding',
                description: 'Customize how your branding appears in emails, invoices, and in the customer community area.',
                route: ROUTES.SETTINGS_COMPANY_BRANDING,
                permission_requirements: [
                    {
                        type: 'admin_permissions',
                        value: 'edit_company_branding'
                    }
                ],
            },
            {
                title: 'Company Financials',
                identifier: 'company_financials',
                description: "View your company's financially sensentive information like Tax ID.",
                route: ROUTES.SETTINGS_COMPANY_FINANCIAL_INFORMATION,
                permission_requirements: [
                    {
                        type: 'admin_permissions',
                        value: 'edit_financial_information'
                    }
                ],
            }
        ],
    },
    {
        title: 'ProHelper Account',
        identifier: 'settings_prohelper',
        children: [
            {
                title: 'Overview',
                identifier: 'overview',
                description: 'View your ProHelper account status, balance, subscription, and ProHelper information.',
                route: ROUTES.SETTINGS_PROHELPER_OVERVIEW,
                permission_requirements: [
                    {
                        type: 'admin_permissions',
                        value: 'manage_prohelper_subscription'
                    }
                ],
            },
            {
                title: 'Subscription',
                identifier: 'subscription',
                description: 'View and manage your ProHelper subscription plan, billing cycle, defauly payment method, and more.',
                route: ROUTES.SETTINGS_PROHELPER_SUBSCRIPTION,
                permission_requirements: [
                    {
                        type: 'admin_permissions',
                        value: 'manage_prohelper_subscription'
                    }
                ],
            },
            {
                title: 'Payments',
                identifier: 'payments',
                description: 'Add or update payment methods and view all past transactions.',
                route: ROUTES.SETTINGS_PROHELPER_PAYMENTS,
                permission_requirements: [
                    {
                        type: 'admin_permissions',
                        value: 'manage_prohelper_subscription'
                    }
                ],
            },
            {
                title: 'Plans',
                identifier: 'plans',
                description: 'Compare different ProHelper plans and features to fit your business.',
                route: ROUTES.SETTINGS_PROHELPER_PLANS,
                permission_requirements: [
                    {
                        type: 'admin_permissions',
                        value: 'manage_prohelper_subscription'
                    }
                ],
            },

        ],
    },
    {
        title: 'Data Settings',
        identifier: 'settings_data',
        children: [
            {
                title: 'Manage Integrations',
                identifier: 'manage_integration',
                description: 'Manage your connection with QuickBooks Online and/or Stripe to sync customers, products, transactions, and more.',
                route: ROUTES.SETTINGS_DATA_MANAGE_INTEGRATIONS,
                permission_requirements: [
                    {
                        type: 'admin_permissions',
                        value: 'manage_integrations'
                    },
                    {
                        type: 'admin_permissions',
                        value: 'stripe_integration'
                    }
                ],
            },
            {
                title: 'Data Import',
                identifier: 'data_import',
                description: "Upload an Excel or CSV file to import data, sanitize & hydrate data, and merge into your ProHelper data.",
                route: ROUTES.SETTINGS_DATA_IMPORT,
                permission_requirements: [
                    {
                        type: 'admin_permissions',
                        value: 'data_importing_and_exporting'
                    }
                ],
            },
            {
                title: 'Data Export',
                identifier: 'data_export',
                description: "Export your company's ProHelper data to an Excel or CSV file to download.",
                route: ROUTES.SETTINGS_DATA_EXPORT,
                permission_requirements: [
                    {
                        type: 'admin_permissions',
                        value: 'data_importing_and_exporting'
                    }
                ],
            },
            {
                title: 'Archive',
                identifier: 'archived_data',
                description: 'View and manage all archived object records. Able to permentantly delete records or restore them.',
                route: ROUTES.SETTINGS_DATA_ARCHIVE,
                permission_requirements: [
                    {
                        type: 'admin_permissions',
                        value: 'manage_archived_data'
                    }
                ],
            }
            
        ],
    }
];

export default SettingsCategoryList;