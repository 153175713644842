// Import NPM Modules
import React, { Suspense, Fragment, useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import _ from 'lodash';


// Import Custom Modules
import * as ROUTES from '../../../constants/navigation/routes';
import { 
    SidebarBackdrop,
    SidebarBackdropButton,
    SidebarDrawer,
    SidebarDrawerHeader,
    SectionDivider,
    SidebarDrawerList,
    SectionContainer,
    SectionChildrenContainer,
    SectionTopItemContainer,
    SectionDropdownButton,
    SectionTopItem,
    SectionTopItemIcon,
    SectionTopItemText,
    SidebarDrawerListItem,
    SidebarDrawerListItemIcon,
    SidebarDrawerListItemText,
    Spacer
} from './Styles';
import {
    AuthHeaderTitle
} from '../app_header/auth/mobile/Styles';
import * as NavigationActionTypes from '../../../redux/action_types/program/NavigationActionTypes';

// UI constants that can be lazy loaded when they are needed.



const AuthNavigator = (props) => {

    // Create Variables
    const auth = useSelector(state => state.program.authentication.auth);
    const platform_permissions = useSelector(state => state.program.permissions.all_permissions.platform_permissions);
    const authNavigatorCategories = useSelector(state => state.program.navigation.authNavigatorCategories);
    const display_type = useSelector(state => state.ui.layout.display_type);
    const is_mobile = useSelector(state => state.ui.layout.is_mobile);

    const dispatch = useDispatch();

    // For Tablet and Mobile Display Types to hold background still
    useEffect(() => {
        switch (display_type) {
            case ('desktop'):
                // Desktop
                break;
            default:
                // Override body css style to stop scrolling if open
                document.body.style.overflow = props.authNavigatorIsOpen ? 'hidden': 'unset';
                document.body.style.position = props.authNavigatorIsOpen ? 'fixed': 'unset';
                document.body.style.left = props.authNavigatorIsOpen ? '0px': 'unset';
                document.body.style.right = props.authNavigatorIsOpen ? '0px': 'unset';
                break;
        }

        // Cleanup body css overrides on dismount
        return function cleanup() {
            document.body.style.overflow = 'unset';
            document.body.style.position = 'unset';
            document.body.style.left = 'unset';
            document.body.style.right = 'unset';
        };

    }, [display_type, props.authNavigatorIsOpen]);


    // Functions
    const checkIsAuthValid = () => {
        let result = false;
        // Check platform permissions (basic check)
        if (!_.isEmpty(auth) && !_.isEmpty(platform_permissions)) {
            if (!_.isEmpty(platform_permissions.company_access)) {
                if (platform_permissions.company_access.privileges) {
                    if (platform_permissions.company_access.privileges.use) {
                        result = true;
                    }
                }
            }
        }

        return result;
    }

    const onSectionToggleButtonClicked = (selected_section) => {
        
        let newAuthNavigatorCategories = authNavigatorCategories.map((section) => {
            if (selected_section.top_item.identifier === section.top_item.identifier) {
                return {...section, top_item: {...section.top_item, open: !section.top_item.open}};
            }else {
                return section;
            }
        });

        dispatch({
            type: NavigationActionTypes.SET_AUTH_NAVIGATOR_CATEGORIES,
            payload: {
                authNavigatorCategories: newAuthNavigatorCategories
            }
        });
    }

    // Initalize runtime variables
    const location = useLocation();
    const pathName = location.pathname;
    
    // Create drawer content components once
    const drawerListContent = checkIsAuthValid() ? (
        <SidebarDrawerList>
            {/* List of autheniticated navigation items by category group */}
            {authNavigatorCategories.map((section, section_index) => (
                <SectionContainer key={section_index}>

                    <SectionTopItemContainer
                        $active={(pathName.startsWith(section.top_item.route))}
                        $display_type={display_type}
                        $open={props.authNavigatorIsOpen}
                        $section_open={section.top_item.open}
                        $is_mobile={is_mobile}
                    >
                        {!_.isEmpty(section.children) ?
                            <SectionDropdownButton
                                onClick={() => onSectionToggleButtonClicked(section)}
                                $active={(pathName.startsWith(section.top_item.route))}
                                $display_type={display_type}
                                $is_mobile={is_mobile}
                            >
                                <FontAwesomeIcon
                                    icon={section.top_item.open ? faChevronDown : ((display_type === 'desktop' && !props.authNavigatorIsOpen) ? faChevronUp : faChevronRight)}
                                    style={{
                                        width: '15px',
                                        height: '15px',
                                        padding: '0px 5px'
                                    }}
                                />
                            </SectionDropdownButton>
                            : null
                        }

                        {!_.isEmpty(section.children) ? <Spacer $width={5} /> : null }

                        <LinkContainer
                            key={section.top_item.identifier}
                            to={section.top_item.route}
                            exact
                        >
                            <SectionTopItem
                                onClick={() => display_type === 'mobile' ? props.onMenuToggle() : null}
                                $active={(pathName.startsWith(section.top_item.route))}
                                $open={props.authNavigatorIsOpen}
                                $display_type={display_type}
                            >

                                {/* Navigation List Item Icon */}
                                <SectionTopItemIcon
                                    $active={(pathName.startsWith(section.top_item.route))}
                                    $display_type={display_type}
                                    $open={props.authNavigatorIsOpen}
                                >
                                    <FontAwesomeIcon
                                        icon={[(pathName.startsWith(section.top_item.route)) ? "fas" : "far", section.top_item.icon]}
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            margin: !_.isEmpty(section.children) ? 'auto' : 'auto 0px auto 4px'
                                        }}
                                    />
                                </SectionTopItemIcon>

                                {/* Navigation List Item Text */}
                                <SectionTopItemText
                                    $active={(pathName.startsWith(section.top_item.route))}
                                    $display_type={display_type}
                                    $open={props.authNavigatorIsOpen}
                                >
                                    {section.top_item.label}
                                </SectionTopItemText>
                            </SectionTopItem>
                        </LinkContainer>
                    </SectionTopItemContainer>


                    {/* Auth Navigation Category Child Element Array */}
                    <SectionChildrenContainer>
                        {section.top_item.open ? section.children.map(({ identifier: childIdentifier, label: childLabel, icon, route }) => (
                            <LinkContainer
                                key={childIdentifier}
                                to={route}
                                exact
                            >
                                <SidebarDrawerListItem
                                    button="true"
                                    $active={(pathName.startsWith(route) && route !== '/')}
                                    $display_type={display_type}
                                    $open={props.authNavigatorIsOpen}
                                    $is_mobile={is_mobile}
                                    onClick={() => display_type === 'mobile' ? props.onMenuToggle() : null}
                                >
                                    {/* Navigation List Item Icon */}
                                    <SidebarDrawerListItemIcon
                                        $active={(pathName.startsWith(route) && route !== '/')}
                                        $display_type={display_type}
                                        $open={props.authNavigatorIsOpen}
                                    >
                                        <FontAwesomeIcon
                                            icon={[(pathName.startsWith(route) && route !== '/') ? "fas" : "far", icon]}
                                            style={{
                                                width: '20px',
                                                height: '20px'
                                            }}
                                        />
                                    </SidebarDrawerListItemIcon>

                                    {/* Navigation List Item Text */}
                                    <SidebarDrawerListItemText
                                        $active={(pathName.startsWith(route) && route !== '/')}
                                        $display_type={display_type}
                                        $open={props.authNavigatorIsOpen}
                                    >
                                        {childLabel}
                                    </SidebarDrawerListItemText>
                                </SidebarDrawerListItem>
                            </LinkContainer>
                        ))
                            : null
                        }
                    </SectionChildrenContainer>
                    

                    {section_index + 1 < authNavigatorCategories.length ?
                        <SectionDivider
                            $open={props.authNavigatorIsOpen}
                        />
                        : null
                    }

                </SectionContainer>
            ))}
        </SidebarDrawerList>
    ) : null;

    // Show the correct auth navigator based on display type (mobile, tablet, desktop)
    switch (display_type) {
        case ('mobile'):
            return (
                <Suspense fallback={<div />} >
                    <SidebarBackdrop
                        $isShown={props.authNavigatorIsOpen}
                        $isLeftHanded={props.$isLeftHanded}
                    >
                        <SidebarDrawer
                            $isShown={props.authNavigatorIsOpen}
                            $isLeftHanded={props.$isLeftHanded}
                            $display_type={display_type}
                            $open={props.authNavigatorIsOpen}
                        >
                            {/* Replicate ProHelper Header (Menu + Logo) */}
                            <SidebarDrawerHeader>
                                {/* The ProHelper Branding, Not shown if authenticated sidebar is not fully open. */}
                                <AuthHeaderTitle>
                                    ProHelper
                                </AuthHeaderTitle>
                            </SidebarDrawerHeader>

                            {/* Auth Navigatory Categories List */}
                            {drawerListContent}
                        </SidebarDrawer>

                        {/* Clickable area to hide the navigation popover */}
                        <SidebarBackdropButton
                            onClick={() => props.onMenuToggle()}
                            $isShown={props.authNavigatorIsOpen}
                            $isLeftHanded={props.$isLeftHanded}
                        />

                    </SidebarBackdrop>
                </Suspense>
            );
        case ('tablet'):
            return (
                <Suspense fallback={<div />} >
                    { props.authNavigatorIsOpen ?
                        <SidebarBackdrop
                            $isShown={props.authNavigatorIsOpen}
                            $isLeftHanded={props.$isLeftHanded}
                        >
                            <SidebarDrawer
                                $isShown={props.authNavigatorIsOpen}
                                $isLeftHanded={props.$isLeftHanded}
                                $display_type={display_type}
                                $open={props.authNavigatorIsOpen}
                            >
                                {/* Replicate ProHelper Header (Menu + Logo) */}
                                <SidebarDrawerHeader>

                                    {/* The ProHelper Branding, Not shown if authenticated sidebar is not fully open. */}
                                    <AuthHeaderTitle>
                                        ProHelper
                                    </AuthHeaderTitle>

                                </SidebarDrawerHeader>

                                {/* Sidebar drawer list content */}
                                {drawerListContent}
                            </SidebarDrawer>

                            {/* Clickable area to hide the navigation popover */}
                            <SidebarBackdropButton
                                onClick={() => props.onMenuToggle()}
                                $isShown={props.authNavigatorIsOpen}
                                $isLeftHanded={props.$isLeftHanded}
                            />
                        </SidebarBackdrop>
                        :
                        null
                    }
                </Suspense>
            );
        case ('desktop'): 
            return (
                <Suspense fallback={<div />} >
                    <SidebarDrawer
                        $isShown={props.authNavigatorIsOpen}
                        $isLeftHanded={props.$isLeftHanded}
                        $display_type={display_type}
                        $open={props.authNavigatorIsOpen}
                    >
                        {drawerListContent}
                    </SidebarDrawer>
                </Suspense>
            );
        default:
            return null;
    }

}

export default AuthNavigator;