
// This constants file is a representation of how data for object types will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';


const AccountObjectTypesData = [

    // ----------------------------------------
    // Account Object Types
    // ----------------------------------------

    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "accounts",
        sync_token: 1,
        label: "Customer",
        identifier: "customer",
        description: "A person or company that purchases services or goods from you.",
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "accounts",
        sync_token: 1,
        label: "Vendor",
        identifier: "vendor",
        description: "A person or company that your business purchases goods or services from.",
        sort_order: 2,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "accounts",
        sync_token: 1,
        label: "Lead",
        identifier: "lead",
        description: "A prospect who has expressed interest in your business, but has not converted to buying yet.",
        sort_order: 3,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "accounts",
        sync_token: 1,
        label: "Competitor",
        identifier: "competitor",
        description: "A person or company that directly competes for customers with your business.",
        sort_order: 4,
        is_active: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "accounts",
        sync_token: 1,
        label: "Partner",
        identifier: "partner",
        description: "A person or company that helps you running your business in some way.",
        sort_order: 5,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },

];

export default AccountObjectTypesData;