
// Import NPM Modules
import _ from 'lodash';

// Dropdown Sets Reducer
import * as actionTypes from '../../action_types/customizations/DropdownSetsActionTypes';

// Empty initial state sample
// const initialState = {
//     'accounts': {},
//     'global': {}
// };

const dropdownSetsReducer = (state = { }, action) => {

    switch (action.type) {

        // Set All the Dropdown Sets at once
        case actionTypes.SET_ALL_DROPDOWN_SETS:
            // Create a copy of the current redux state
            let newDropdownSetsState = { ...state };
            // Convert the action.payload.dropdown_sets from an array to a Dropdown Sets map.
            if (_.isArray(action.payload.dropdown_sets)) {
                // Go through all the dropdown sets received from the customization query and order them in this redux reducers state (state -> object -> dropdown_set)
                for (let i = 0; i < action.payload.dropdown_sets.length; i++) {
                    // Get the current dropdown set using the index position
                    const currentDropdownSet = action.payload.dropdown_sets[i];
                    // If the current dropdown set belongs to an object, then store it inside the parent object container
                    if (currentDropdownSet.object_identifier) {
                        // This dropdown set belongs to an object and should be placed in the appropriate object container
                        // If no JSON has been created for this object, then create a new JSON structure to hold this new object's dropdown sets.
                        if (!_.isObject(newDropdownSetsState[currentDropdownSet.object_identifier])) {
                            newDropdownSetsState[currentDropdownSet.object_identifier] = {};
                        }
                        // Check if this dropdown set has a valid identifier
                        if (!_.isEmpty(currentDropdownSet.identifier)) {
                            // Store this dropdown set in the parent object container 
                            newDropdownSetsState[currentDropdownSet.object_identifier][currentDropdownSet.identifier.toLowerCase()] = currentDropdownSet;
                        }
                    }
                }
            }
            return newDropdownSetsState;

        // Removes all the dropdown sets in this reducer
        case actionTypes.UPDATE_DROPDOWN_SET:
            // Create a copy of the current redux state
            let updatedDropdownSetsState = { ...state };
            // Validation check for inputs
            if (!_.isEmpty(action.payload.dropdown_set)) {
                const updatedDropdownSet = action.payload.dropdown_set;
                if (updatedDropdownSetsState[updatedDropdownSet.object_identifier])  {
                    updatedDropdownSetsState[updatedDropdownSet.object_identifier][updatedDropdownSet.identifier.toLowerCase()] = updatedDropdownSet;
                }
            }
            // Return the new action state
            return updatedDropdownSetsState;


        // Removes all the dropdown sets in this reducer
        case actionTypes.REMOVE_ALL_DROPDOWN_SETS:

            // Return the new action state
            return {};
        

        // Default, do nothing and return state
        default:
            return state;

    }
};

export default dropdownSetsReducer;