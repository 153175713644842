import { combineReducers } from 'redux';
import ProgramReducer from './program';
import UIReducer from './ui';
import CustomizationsReducer from './customizations';
import DataReducer from './data';
import ScreensReducer from './screens';

const Reducer = combineReducers({
    program: ProgramReducer,
    ui: UIReducer,
    screens: ScreensReducer,
    customizations: CustomizationsReducer,
    data: DataReducer
});

export default Reducer;