
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';

// Import Custom Modules
import * as FilterOperatorTypeIdenifiers from '../../../constants/filtering/operators/FilterOperatorTypeIdentifiers';
import * as SortingMethods from '../../sorting/SortingMethods';
import * as SortingDirectionIdentifiers from '../../sorting/SortDataTypeIdentifiers';
import * as GraphQLQueryTypes from '../../types/GraphQLQueryTypes';
import * as GraphQLTypes from '../../types/GraphQLTypes';
import * as FieldTypes from '../../types/FieldTypes';
import * as DatabaseTypes from '../../types/DatabaseTypes';



const ContactListViewsData = [
    // Active Contacts
    {
        object: null,
        object_identifier: 'contacts',
        public_id: uniqid('view_'),
        sync_token: 1,
        type: "list_view",
        identifier: identifier('Active Contacts').toLowerCase(),
        name: 'Active Contacts',
        description: 'All active contacts with the company.',
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        sort_field: "contacts.name",
        sql_sort_field: ["contacts.name", "name.display"],
        sort_direction: "ASC",
        sort_join: [],
        sort_method: "alphanumeric",
        filter_by_viewer: false,
        filters: [
            {
                fields: ["contacts.is_active"],
                field_types: ["checkbox"],
                operator: "equal",
                values: [
                    {
                        type: "value",
                        label: 'True',
                        input: true,
                        data: true,
                        data_type: 'boolean',
                        status: 'valid'
                    }
                ],
                is_hidden: true
            },
        ],
        search_value: "",
        visible_fields: [
            {
                label: 'Name',
                identifier: 'contacts.name',
                display_fields: ['contacts.name', 'name.display'],
                display_format: null,
                help_text: '',
                width: 200,
                is_locked: true
            },
            {
                label: 'Phone',
                identifier: 'contacts.phone',
                display_fields: ['contacts.phone', 'phone.auto'],
                display_format: null,
                help_text: '',
                width: 150,
                is_locked: false
            },
            {
                label: 'Email',
                identifier: 'contacts.email',
                display_fields: ['contacts.email', 'email.address'],
                display_format: null,
                help_text: '',
                width: 220,
                is_locked: false
            },
            {
                label: 'Type',
                identifier: 'contacts.type',
                display_fields: ['contacts.type', 'object_types.label'],
                display_format: null,
                help_text: '',
                width: 140,
                is_locked: false
            },
            {
                label: 'Account',
                identifier: 'contacts.account',
                display_fields: ['contacts.account', 'accounts.name', 'name.display'],
                display_format: null,
                help_text: '',
                width: 150,
                is_locked: false
            },
            {
                label: 'Stage',
                identifier: 'contacts.stage',
                display_fields: ['contacts.stage', 'dropdown.label'],
                display_format: null,
                help_text: '',
                width: 140,
                is_locked: false
            },
            {
                label: 'Priority',
                identifier: 'contacts.priority',
                display_fields: ['contacts.priority', 'dropdown.label'],
                display_format: null,
                help_text: '',
                width: 140,
                is_locked: false
            },
            {
                label: 'Description',
                identifier: 'contacts.description',
                display_fields: ['contacts.description'],
                display_format: null,
                help_text: '',
                width: 220,
                is_locked: false
            },
            {
                label: 'Owner',
                identifier: 'contacts.owner',
                display_fields: ['contacts.owner', 'users.name', 'name.display'],
                display_format: null,
                help_text: '',
                width: 160,
                is_locked: false
            },
            {
                label: 'Created At',
                identifier: 'contacts.created_at',
                display_fields: ['contacts.created_at'],
                display_format: 'MM/DD/YYYY',
                help_text: '',
                width: 160,
                is_locked: false
            },
        ],
        hidden_fields: [
            "contacts.id",
            "contacts.contact_id",
            "contacts.type",
            "contacts.sync_token",
            "contacts.time_zone",
            "contacts.currency",
            "contacts.language",
            "contacts.company",
            "contacts.do_not_call",
            "contacts.do_not_email",
            "contacts.do_not_mail",
            "contacts.account",
            "contacts.suggested_action"
        ],
        batch_actions: [
            "contacts.batch_edit",
            "contacts.batch_email",
            "contacts.batch_archive"
        ],
        item_actions: [
            "contacts.edit",
            "contacts.send_email",
            "contacts.call_phone",
            "contacts.archive"
        ],
        more_actions: [
            "contacts.edit",
            "contacts.call_phone",
            "contacts.send_email",
            "contacts.archive"
        ],
        pagination_amount: 25
    },
    
];

export default ContactListViewsData;