
// Import Modules
import React, { Component, Suspense } from 'react';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';


// Import Custom Classes
import AuthNavigator from '../../app_navigator/AuthNavigator';
import AppSnackbar from '../../../../components/feedback/snackbar/app/AppSnackbar';
import * as AppActionTypes from '../../../../redux/action_types/program/AppActionTypes';
import * as AuthenticationActionTypes from '../../../../redux/action_types/program/AuthenticationActionTypes';
import * as LayoutActionTypes from '../../../../redux/action_types/ui/LayoutActionTypes';
import * as NavigationActionTypes from '../../../../redux/action_types/program/NavigationActionTypes';
import AuthHeader from '../../app_header/auth/AuthHeader';
import * as ROUTES from '../../../../constants/navigation/routes';
import {
    AuthRoot,
    AuthBody,
    AuthMain
} from './Styles';


// UI constants that can be lazy loaded when they are needed.
const SetupCompanyDialog = React.lazy(() => import('../../../feedback/dialogs/setup_company/SetupCompanyDialog'));
const AuthBottomBar = React.lazy(() => import('../../app_bottom_bar/auth/AuthBottomBar'));



class AuthLayoutComponent extends Component {

    // React Functions Area
    constructor(props) {

        // React Super Props (required)
        super(props);

        // Bind all component functions
        this.handleSignOut = this.handleSignOut.bind(this);
        this.handleProfile = this.handleProfile.bind(this);
        this.handleMenuToggle = this.handleMenuToggle.bind(this);
        this.handleHome = this.handleHome.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleAlertSnackbarClose = this.handleAlertSnackbarClose.bind(this);
        this.isLeftHanded = this.isLeftHanded.bind(this);

        // Create component state
        this.state = {
            isFirstLoaded: false,
            isHandlingScroll: false,
            prevScrollPosition: 0,
            isTopBarVisible: true,
            isBottomBarVisible: true
        };

    }


    handleScroll()  {
        // Initialize variables
        const { prevScrollPosition } = this.state;
        const currentScrollPos = window.pageYOffset;
        // Check if the user is scrolling upwards or the current scrolling position is near the top
        const topBarVisible = prevScrollPosition > currentScrollPos || currentScrollPos <= 24;
        const bottomBarVisible = prevScrollPosition > currentScrollPos  || currentScrollPos <= 12;
        
        // reduce amount of updates to state
        let isStateUpdateNeeded = false;
        if (this.state.isTopBarVisible !== topBarVisible) {
            isStateUpdateNeeded = true;
        } else if (this.state.isBottomBarVisible !== bottomBarVisible) { 
            isStateUpdateNeeded = true;
        } else if (Math.abs(prevScrollPosition - currentScrollPos) > 16) {
            isStateUpdateNeeded = true;
        }
        // Update the state in one go, and only if needed
        if (isStateUpdateNeeded) {
            this.setState({
                ...this.state,
                prevScrollPosition: currentScrollPos,
                isTopBarVisible: topBarVisible,
                isBottomBarVisible: bottomBarVisible,
            });
        }
        
    }

    componentDidMount() {
        // Check if the viewport display type is mobile
        if (this.props.display_type === 'mobile') {
            // Add event listener for window scroll & set state to know we are handling scroll
            window.addEventListener('scroll', this.handleScroll);
            this.setState({
                ...this.state,
                isHandlingScroll: true
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.display_type === 'mobile' && !this.state.isHandlingScroll) {
            // Check if the viewport display type is now mobile & we have handled scroll yet
            // Add event listener for window scroll & set state to know we are handling scroll
            window.addEventListener('scroll', this.handleScroll);
            this.setState({
                ...this.state,
                isHandlingScroll: true
            })
        }else if (this.props.display_type !== 'mobile' && this.state.isHandlingScroll ) {
            // Check if the viewport display type is now NOT mobile & we previously handled scroll
            // Remove event listener for window scroll & set state to not handle scroll
            window.removeEventListener('scroll', this.handleScroll);
            this.setState({
                ...this.state,
                isHandlingScroll: false
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }


    // AuthLayout Functions Area
    handleProfile() {
        this.props.navigate(ROUTES.SETTINGS_USER);
    }

    handleHome() {
        this.props.navigate(ROUTES.HOME);
    }

    handleSignOut() {
        // Sign out of AWS Cognito & ProHelper
        try {
            Auth.signOut().then((result) => {

                this.props.removeAuth();
                this.props.setAuthNavigatorCategories([]);
                this.props.setSettingsCategories([]);
                this.props.navigate(ROUTES.LOG_IN);
            });
        } catch (err) {
            console.log(err);
        }
    }

    handleMenuToggle() {

        // Reverse the current auth navigator is open status
        this.props.setAuthNavigatorIsOpen(!this.props.authNavigatorIsOpen);
    }


    /**
     * Handle the click away for the app's alert snackbar. 
     * If the user clicks on the snackbar, then close it by redux dispatch callback.
     * 
     * @param {Object} event The full event object for this callback
     * @param {String} reason The reason for this alert's event
     * 
     */
     handleAlertSnackbarClose(event, reason) {

        if (reason === 'clickaway') {
            return;
        }

        // Close snackbar w/ redux
        this.props.setSnackbar({
            open: false,
            message: '',
            severity: ''
        });
    };

    isLeftHanded() {
        let isLeftHanded = false;
        // Check for non empty auth
        if (!_.isEmpty(this.props.auth)) {
            // Check for non empty auth user
            if (!_.isEmpty(this.props.auth.user)) {
                isLeftHanded = this.props.auth.user.is_left_handed ? true : false;
            }
        }
        return isLeftHanded;
    }


    render() {

        const isLeftHanded = this.isLeftHanded();
        
        return (
            // The Auth Root Container that holds everything
            <AuthRoot>
                {/* Authenticated Header Top Bar (Menu Button, ProHelper Branding, Help, Notifications, and User Settings Buttons) */}
                <AuthHeader
                    permissions={this.props.permissions}
                    display_type={this.props.display_type}
                    header_type={this.props.header_type}
                    authNavigatorIsOpen={this.props.authNavigatorIsOpen}
                    onMenuToggle={() => this.handleMenuToggle()}
                    handleSignOut={() => this.handleSignOut()}
                    is_mobile={this.props.is_mobile}
                    isTopBarVisible={this.state.isTopBarVisible}
                    $isLeftHanded={isLeftHanded}
                />

                {/* Authenticated Body Area, contains the sidebar authenticated navigator and the main screen containing this component's props.children. */}
                <AuthBody >
                    {/* Authenticated Navigator Sidebar */}
                    <AuthNavigator
                        authNavigatorIsOpen={this.props.authNavigatorIsOpen}
                        authNavigatorCategories={this.props.authNavigatorCategories}
                        onMenuToggle={() => this.handleMenuToggle()}
                        onClose={() => { }}
                        is_mobile={this.props.is_mobile}
                        $isLeftHanded={isLeftHanded}
                    />

                    {/* Main Screen Area */}
                    <AuthMain
                        $isOpen={this.props.authNavigatorIsOpen} 
                        $display_type={this.props.display_type}
                    >
                        {/* React Router Screen Component should be shown as the props.children here. */}
                        {this.props.children}
                    </AuthMain>
                </AuthBody>

                {this.props.display_type === 'mobile' ?
                    <Suspense fallback={<div />} >
                        <AuthBottomBar
                            auth={this.props.auth}
                            permissions={this.props.permissions}
                            authNavigatorIsOpen={this.props.authNavigatorIsOpen}
                            onMenuToggle={() => this.handleMenuToggle()}
                            handleSignOut={() => this.handleSignOut()}
                            handleProfile={() => this.handleProfile()}
                            handleHome={() => this.handleHome()}
                            is_mobile={this.props.is_mobile}
                            isAuthFooterVisible={this.props.isAuthFooterVisible}
                            isBottomBarVisible={this.state.isBottomBarVisible}
                            $isLeftHanded={isLeftHanded}
                        />
                    </Suspense>
                    :
                    null
                }

                {/* Screen Snackbar (Redux - state.program.app.snackbar data) */}
                {this.props.snackbar.open ?
                    <AppSnackbar
                        open={this.props.snackbar.message !== ""}
                        onClose={this.handleAlertSnackbarClose}
                        label={this.props.snackbar.message}
                        severity={this.props.snackbar.severity}
                        isAuthFooterVisible={this.props.display_type === 'mobile' ? this.props.isAuthFooterVisible : false}
                    /> : null
                }

                {/* Check for auth object, then check if auth company status needs setup, if so, lazy load the setup company check popup */}
                {(this.props.auth ? this.props.auth.company ? ((this.props.auth.company.status || '') === 'setup'): false : false) ?
                    <Suspense fallback={<div />} >
                        <SetupCompanyDialog />
                    </Suspense>
                    :
                    null
                }
            </AuthRoot>
        );

    }

};

// Wrapped SearchViewSettings Screen (for history hook passed as props)
const AuthLayout = props => {
    // react-router navigate hook
    const navigate = useNavigate();
    // Insert navigate as a props and others too
    return (<AuthLayoutComponent navigate={navigate} {...props} />);
}


const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.program.authentication.auth,
        permissions: state.program.permissions.all_permissions,
        authNavigatorIsOpen: state.program.navigation.authNavigatorIsOpen,
        isAuthFooterVisible: state.ui.layout.isAuthFooterVisible,
        display_type: state.ui.layout.display_type,
        is_mobile: state.ui.layout.is_mobile,
        snackbar: state.program.app.snackbar,
        current_screen: state.ui.layout.current_screen
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSnackbar: (snackbar) => dispatch({ type: AppActionTypes.SET_SNACKBAR, snackbar: snackbar }),
        setAuthFooterVisible: (isAuthFooterVisible) => dispatch({ type: LayoutActionTypes.SET_AUTH_FOOTER_VISIBLE, payload: {isAuthFooterVisible: isAuthFooterVisible} }),
        setAuthNavigatorIsOpen: (authNavigatorIsOpen) => dispatch({ type: NavigationActionTypes.SET_AUTH_NAVIGATOR_IS_OPEN, payload: {authNavigatorIsOpen: authNavigatorIsOpen} }),
        setAuthNavigatorCategories: (authNavigatorCategories) => dispatch({ type: NavigationActionTypes.SET_AUTH_NAVIGATOR_CATEGORIES, payload: { authNavigatorCategories: authNavigatorCategories } }),
        setSettingsCategories: (settingsCategories) => dispatch({ type: NavigationActionTypes.SET_SETTINGS_CATEGORIES, payload: { settingsCategories: settingsCategories } }),
        updateLoginStatus: (login_status) => dispatch({ type: AuthenticationActionTypes.UPDATE_LOGIN_STATUS, payload: { login_status: login_status } }),
        removeAuth: () => dispatch({ type: AuthenticationActionTypes.REMOVE_AUTH })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout);