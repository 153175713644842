
// Import NPM Modules
import _ from 'lodash';
import uniqid from 'uniqid';
import ParseFullName from 'parse-full-name';

// Import Custom Modules




/**
 * ~~ Class Description ~~
 *  A class containing functions that are related to the authenication areas in the program.
 *  Authenitication includes the ProHelper auth object and cognito user areas.
 */
export default class AuthenticationHelper {

    /**
     * A temporary function that takes in the current V0.1 Auth object and returns a new v0.5 Auth object
     * 
     * @param {Object} oldAuth 
     * @returns {Object} A new V0.5 compatible authenitication object
     */
    CreateAuthV2(oldAuth) {
        // Start with null auth
        let newAuth = null;
        // Fake secret key generator
        const secret_key_length = 32;
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
        let secret_key = "";
        for (var i = 0, n = charset.length; i < secret_key_length; ++i) {
            secret_key += charset.charAt(Math.floor(Math.random() * n));
        }

        // If old auth exists, then continue
        if (_.isObject(oldAuth)) {
            const parsedName = ParseFullName.parseFullName(oldAuth.user_full_name);

            const authUserName = {
                first: parsedName.first,
                middle: parsedName.middle,
                last: parsedName.last,
                title: parsedName.title,
                suffix: parsedName.suffix,
                legal: oldAuth.user_full_name,
                display: oldAuth.user_full_name,
                raw: oldAuth.user_full_name,
                is_person: true
            };

            // Create user object
            const authUser = {
                id: oldAuth.user_id,
                cognito_id: oldAuth.id,
                company: oldAuth.company_id,
                user_id: uniqid('user_'),
                sync_token: 1,
                name: authUserName,
                type: null,
                status: null,
                profile_image: null,
                about: null,
                email: null,
                phone: null,
                address: null,
                department: null,
                skills: null,
                tags: null,
                role: null,
                permission_sets: null,
                manager: null,
                secondary_email: null,
                secondary_phone: null,
                birthday: null,
                billing_address: null,
                work_address: null,
                last_working_address: null,
                manager_notes: null,
                online_status: null,
                working_status: null,
                communication_method: null,
                is_active: true,
                is_employee: true,
                is_driver: true,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                is_draft: true,
                payroll_method: null,
                is_billable_time: false,
                is_left_handed: true,
                cost_rate: 0,
                is_taxable: false,
                tax_id: null,
                popup_alert: null,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                last_activity_at: new Date(),
                last_login: new Date(),
                start_date: null,
                end_date: null,
                device_type: null,
                archived_alert: null,
                archived_at: null,
                archived_by: null,
                theme_overrides: null,
                suggested_action: null,
                user_settings: null
            };
            // Create company object
            const authCompany = {
                id: oldAuth.company_id,
                name: oldAuth.company_name,
                description: "",
                status: oldAuth.company_status,
                time_zone: 'America/Los_Angeles',
                currency: 'usd',
                country: 'us',
                language: 'en',
                industry: 'pool_service',
                location: { lat: 37.8218072886671, lng: -122.00027823155402 },
                secret_key: secret_key
            };
            
            // Create role object
            const authRole = {
                id: oldAuth.role_id,
                name: oldAuth.role_name,
                description: ""
            };
            // // Create permission sets array
            // const authPermissionSets = [];
            // // Create permissions array
            // const authPermissions = [];

            // Combine all together for new auth object
            newAuth = {
                id: oldAuth.id,
                user: authUser,
                company: authCompany,
                role: authRole,
            };
        }

        return newAuth;

    }

};