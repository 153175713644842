
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';

const AppSnackbar = styled(Snackbar)`
    margin-bottom: ${props => props.$isAuthFooterVisible ? props.theme.variables.headerMobileHeight + 10 : 0 }px;

    @media (min-height: ${props => props.theme.variables.mobileTallHeight}px) {
        margin-bottom: ${props => props.$isAuthFooterVisible ? props.theme.variables.headerMobileTallHeight + 10 : 0 }px;
    }
`;

export default AppSnackbar;