// This constats file is a representation of how data for object fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as ActionTypes from '../../types/ActionTypes';
import * as ActionUseTypes from '../../types/ActionUseTypes';

const InvoiceActionsData = [

    // Item Actions
    //
    // New Invoice
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "New Invoice",
        short_label: "New",
        identifier: "new",
        icon: "file-invoice-dollar",
        sort_order: 1,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.NEW,
        description: "Create a new Invoice.",
        help_text: "Create a new Invoice.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Edit Invoice
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Edit Invoice",
        short_label: "Edit",
        identifier: "edit",
        icon: "pen-to-square",
        sort_order: 2,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.EDIT,
        description: "Edit the Fields for a Invoice.",
        help_text: "Edit the Fields for a Invoice.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Edit Recurrence Schedule
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Edit Recurrence Schedule",
        short_label: "Edit Recurrence",
        identifier: "edit_recurrence",
        icon: "repeat",
        sort_order: 3,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.EDIT_RECURRENCE,
        description: "Edit the recurrence schedule for a Invoice.",
        help_text: "Edit the recurrence schedule for a Invoice.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Print Invoice
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Print Invoice",
        short_label: "Print Invoice",
        identifier: "print_invoice",
        icon: "print",
        sort_order: 4,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.PRINT,
        description: "Print an Invoice.",
        help_text: "Print an Invoice.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Email Invoice
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Email Invoice",
        short_label: "Email Invoice",
        identifier: "email_invoice",
        icon: "at",
        sort_order: 5,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.EMAIL,
        description: "Email an Invoice.",
        help_text: "Email an Invoice.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Mark Sent
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Mark Sent",
        short_label: "Mark Sent",
        identifier: "mark_sent",
        icon: "paper-plane",
        sort_order: 6,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.MARK_SENT,
        description: "Mark an Invoice as sent.",
        help_text: "Mark an Invoice as sent.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Mark Paid
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Mark Paid",
        short_label: "Mark Paid",
        identifier: "mark_paid",
        icon: "cash-register",
        sort_order: 8,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.MARK_PAID,
        description: "Mark an Invoice as paid.",
        help_text: "Mark an Invoice as paid.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Duplicate Invoice
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Duplicate Invoice",
        short_label: "Duplicate",
        identifier: "duplicate",
        icon: "copy",
        sort_order: 8,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.DUPLICATE,
        description: "Mark an Invoice as paid.",
        help_text: "Mark an Invoice as paid.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Archive Invoice
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Archive Invoice",
        short_label: "Archive",
        identifier: "archive",
        icon: "box-archive",
        sort_order: 9,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.ARCHIVE,
        description: "Archive a invoice to be hidden from normal invoices in the program.",
        help_text: "Archive a invoice, hidden but not deleted.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Delete Invoice
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Delete Invoice",
        short_label: "Delete",
        identifier: "delete",
        icon: "trash",
        sort_order: 10,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.DELETE,
        description: "Permantently deletes an Invoice. Not retrievable by users after this point.",
        help_text: "Permantently deletes an Invoice. Not retrievable by users after this point.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Batch Actions
    //

    // Batch Edit
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Batch Edit",
        short_label: "Batch Edit",
        identifier: "batch_edit",
        icon: "pen-to-square",
        sort_order: 1,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_EDIT,
        description: "Edit the Fields for multiple selected Invoices at once.",
        help_text: "Edit the Fields for multiple selected Invoices at once.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Batch Email
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Batch Email",
        short_label: "Batch Email",
        identifier: "batch_email",
        icon: "at",
        sort_order: 2,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_EMAIL,
        description: "Batch send emails to customers for multiple selected Invoices.",
        help_text: "Batch send emails to customers for multiple selected Invoices.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Batch Print
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Batch Print",
        short_label: "Batch Print",
        identifier: "batch_print",
        icon: "print",
        sort_order: 3,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_PRINT,
        description: "Batch print multiple selected Invoices.",
        help_text: "Batch print multiple selected Invoices.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Batch Mark Sent
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Batch Mark Sent",
        short_label: "Batch Mark Sent",
        identifier: "batch_mark_sent",
        icon: "paper-plane",
        sort_order: 4,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_MARK_SENT,
        description: "Batch mark sent for multiple selected Invoices.",
        help_text: "Batch mark sent for multiple selected Invoices.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Batch Mark Paid
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Batch Mark Paid",
        short_label: "Batch Mark Paid",
        identifier: "batch_mark_paid",
        icon: "cash-register",
        sort_order: 4,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_MARK_PAID,
        description: "Batch mark paid for multiple selected Invoices.",
        help_text: "Batch mark paid for multiple selected Invoices.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Batch Archive
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Batch Archive",
        short_label: "Batch Archive",
        identifier: "batch_archive",
        icon: 'box-archive',
        sort_order: 6,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_ARCHIVE,
        description: "Archive multiple selected Invoices to be hidden from normal invoices in the program.",
        help_text: "Archive multiple selected Invoices to be hidden from normal invoices in the program.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },

];


export default InvoiceActionsData;