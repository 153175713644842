
// Import NPM Modules
import _ from 'lodash';

// Import Custom Modules
import SettingsList from '../../constants/_layout/SettingsList';


/**
 * ~~ Class Description ~~
 *  A class containing functions that are related to the navigation areas in the program.
 *  Navigation includes the ProHelper navigation sidebar and areas the user has access to.
 */
 export default class NavigationHelper {


    /**
     * Takes in an authentication object, and permissions object and returns a tailored settings array.
     * The array is made up of JSON objects and also contains nested children for each setting category.
     * 
     * @param {Object} auth An authentication object (V0.5 New)
     * @param {Object} permissions An object of permissions object for the user. Each permissions category is a nested object of permissions.
     * 
     * @returns {Object[]} An array of settings filtered for the user, grouped by the category
     */
    CreateSettingsCategories(auth, permissions) {

        // Start with an empty settings category list
        let result = [];

        // Check for non-empty auth & permission objects
        if (!_.isEmpty(auth) && !_.isEmpty(permissions)) {

            // Loop through settings list and only add items if the user has permission access
            for (let i = 0; i < SettingsList.length; i++) {
                // Reference the current settings category item
                let currentSettingsCategory = SettingsList[i];
                let newSettingCategory = {...currentSettingsCategory, children: []};

                // Loop through the setting list category item's children
                for (let j = 0; j < currentSettingsCategory.children.length; j++) {
                    // Reference the current settings category item
                    let currentSettingsScreenItem = currentSettingsCategory.children[j];
                    let isAdded = false;

                    // Check if there are any requirements for this settings screen item
                    if (!_.isEmpty(currentSettingsScreenItem.permission_requirements)) {

                        // Loop through the current settings screen item permission requirements
                        for (let k = 0; k < currentSettingsScreenItem.permission_requirements.length; k++) {

                            // Reference the current settings screen item permission requirement
                            let currentPermissionRequirement = currentSettingsScreenItem.permission_requirements[k];

                            // Switch based on the permission requirement type category
                            switch(currentPermissionRequirement.type) {

                                case('admin_permissions'):
                                    // Check the admin permissions category for the specific permission requirement identifier
                                    if (!_.isEmpty(permissions.admin_permissions[currentPermissionRequirement.value])) {
                                        if (permissions.admin_permissions[currentPermissionRequirement.value].privileges) {
                                            if (permissions.admin_permissions[currentPermissionRequirement.value].privileges.use) {
                                                if (!isAdded) {
                                                    // Add this settings screen to the children array of this settings category
                                                    newSettingCategory.children.push(currentSettingsScreenItem);
                                                    isAdded = true;
                                                }
                                            }
                                        }
                                    }
                                    break;

                                default:
                                    console.log('Error: this permission requirement type is not handled.', currentPermissionRequirement);
                                    break;
                            }

                        }

                    } else {
                        if (!isAdded) {
                            // If no permission requirements, then add this item to the settings category children array
                            newSettingCategory.children.push(currentSettingsScreenItem);
                            isAdded = true;
                        }
                    }
                }

                // If any of this settings category's children screens are valid with permissions,
                // then add this category with the valid children to the result settings array
                if (!_.isEmpty(newSettingCategory.children)) {
                    result.push(newSettingCategory);
                }
            }
 
        }

        // Return result array
        return result;
    }

    

    /**
     * Takes in an authentication object, navigation items, & navigation item permissions and 
     * returns an array of ordered navigation categories with nested navigation items to be 
     * displayed in the authenticated navigation sidebar areas.
     * 
     * @param {Object} auth An authentication object (V0.5 New)
     * @param {Object[]} navigation_items An array of navigation items from the customizations object
     * @param {Object} navigation_item_permissions An object of nested navigation item permissions by identifier key from the user's all_permissions. 
     * 
     * @returns {Object[]} An array of navigation item visible to the user grouped by the category 
     */
    CreateAuthNavigationItemsLayout(auth, navigation_items, navigation_item_permissions) {
        let result = null;

        if (!_.isEmpty(auth) && !_.isEmpty(navigation_items) && !_.isEmpty(navigation_item_permissions)) {

            // Create a new empty object mapping to store the navigation items by each group { group_identifier : { nav_item_identifier : nav_item } }
            let navigationItemGroups = {};

            // Loop through all the navigation items, convert the array of navigation items to grouped navigation categories 
            for (let i = 0; i < navigation_items.length; i++) {

                // Reference the current navigation item
                let currentNavigationItem = navigation_items[i];

                // Check if the navigation item permission is valid
                if (!_.isEmpty(navigation_item_permissions[currentNavigationItem.identifier])) {
                    // Check if the navigation item is active & visible by the user
                    if (navigation_item_permissions[currentNavigationItem.identifier].privileges.active && navigation_item_permissions[currentNavigationItem.identifier].privileges.visible) {

                        // Check if there is a group already created for this navigation item's group, if not create one
                        if (!_.isObject(navigationItemGroups[currentNavigationItem.group.identifier])) {
                            // Copy the current navigation item's group to the navigation item groups object mapping for this group identifier
                            navigationItemGroups[currentNavigationItem.group.identifier] = { top_item: null, children: [] };
                        }

                        if (currentNavigationItem.is_section) {
                            navigationItemGroups[currentNavigationItem.group.identifier].top_item = {...currentNavigationItem, open: false};
                        } else {
                            // Add this current navigation item to the children array in this item's group object mapping 
                            navigationItemGroups[currentNavigationItem.group.identifier].children.push(currentNavigationItem);
                        }
                        
                    }
                }
            }

            // Turn the navigation item groups object mapping into an array 
            let navigationItemGroupsArray = Object.values(navigationItemGroups);

            // Sort each navigation item group's children arrays
            let sortedNavigationItemGroupsArray = _.sortBy(navigationItemGroupsArray, "order");

            // Loop through each navigation item group in the sorted array and sort the children array
            for (let i=0; i < sortedNavigationItemGroupsArray.length; i++) {
                // Sort each navigation item group's children array by the order of the items
                sortedNavigationItemGroupsArray[i].children = _.sortBy(sortedNavigationItemGroupsArray[i].children, "order");
            }

            // Update the result to reference the newly created navigation item groups
            result = sortedNavigationItemGroupsArray;
        }

        return result;
    }

};