
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';
import * as ROUTES from '../../../../navigation/routes';
import * as ScreenTypes from '../../../../types/ScreenTypes';
import * as ScreenIdentifiers from '../../../../screens/ScreenIdentifiers';
import * as ComponentTypes from '../../../../types/ProHelperComponentTypes';


const LedgerPageLayoutsData = [
    // Default
    {
        public_id: uniqid('page_'),
        sync_token: 1,
        identifier: identifier('Default').toLowerCase(),
        label: 'Default',
        description: 'View a Ledger in detail.',
        status: 'published',
        revisions: 1,
        route: ROUTES.LEDGER_DETAIL_PREFIX,
        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
        screen_identifier: ScreenIdentifiers.LEDGER_PAGE_LAYOUT,
        template: null,
        object: null,
        object_identifier: 'ledgers',
        object_type: null,
        group: {
            label: "Audit",
            identifier: "audit",
            order: 6
        },
        sub_group: {
            label: "Ledgers",
            identifier: "ledgers",
            order: 2
        },
        components: {
            component_1: {
                id: "component_1",
                label: 'Header',
                object_identifier: 'ledgers',
                connection_type: 'parent',
                type: ComponentTypes.OBJECT_HEADER,
                header_type: 'action_bar',
                special_type: 'header',
                is_editable: true,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                    fields: [
                        {
                            label: "Subject",
                            identifier: "ledgers.subject",
                            is_required: true,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Balance",
                            identifier: "ledgers.balance",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Category",
                            identifier: "ledgers.category",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Type",
                            identifier: "ledgers.type",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Sub Type",
                            identifier: "ledgers.sub_type",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        }
                    ],
                    first_visible_amount: {
                        mobile: 4,
                        tablet: 4,
                        desktop: 2
                    }
                }
            },
            component_2: {
                id: "component_2",
                label: 'Transactions List',
                object_identifier: 'ledgers',
                connection_type: 'parent',
                type: ComponentTypes.TRANSACTIONS_LIST,
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: true,
                metadata: {
                    
                }
            },
            component_3: {
                id: "component_3",
                label: 'Details',
                object_identifier: 'ledgers',
                connection_type: 'parent',
                type: ComponentTypes.OBJECT_DETAILS,
                special_type: null,
                header_type: 'normal',
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {
                    sections: [
                        // General
                        {
                            label: "Ledger Information",
                            id: 'ledger_info',
                            tab_order: 'left-right',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 6,
                                tablet: 4,
                                desktop: 8
                            },
                            fields: [
                                {
                                    label: "Description",
                                    identifier: "ledgers.description",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Is Monetary",
                                    identifier: "ledgers.is_monetary",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Last Transaction At",
                                    identifier: "ledgers.last_transaction_at",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                }
                            ],
                        },
                        // System Information
                        {
                            label: "System Information",
                            id: 'system_info',
                            tab_order: 'left-right',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: false,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 6,
                                tablet: 4,
                                desktop: 8
                            },
                            fields: [
                                {
                                    label: "Popup Alert",
                                    identifier: "ledgers.popup_alert",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Ledger ID",
                                    identifier: "ledgers.ledger_id",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Created At",
                                    identifier: "ledgers.created_at",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Created By",
                                    identifier: "ledgers.created_by",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Last Updated At",
                                    identifier: "ledgers.last_updated_at",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Last Updated By",
                                    identifier: "ledgers.last_updated_by",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },

                            ],
                        },
                    ]
                }
            },
            component_4: {
                id: "component_4",
                label: 'Ledger Report',
                object_identifier: 'ledgers',
                connection_type: 'parent',
                type: ComponentTypes.REPORT,
                special_type: null,
                header_type: 'normal',
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {
                    
                }
            },
            component_5: {
                id: "component_5",
                label: 'Timeline',
                object_identifier: 'ledgers',
                connection_type: 'parent',
                type: ComponentTypes.TIMELINE,
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                    notifcations: {
                        contacts: true,
                        properties: true,
                        tasks: true,
                        events: true,
                        notes: true,
                        attachments: true
                    }
                }
            }
        },
        sections: [
            {
                id: uniqid('section_'),
                anchor_name: 'header',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'seperate',
                columns: {
                    mobile: [{ id: '1', flexSize: 1, components: ["component_1"] }],
                    tablet: [{ id: '1', flexSize: 1, components: ["component_1"] }],
                    desktop: [{ id: '1', flexSize: 1, components: ["component_1"] }]
                },
                
            },
            {
                id: uniqid('section_'),
                anchor_name: 'line_items_schedule',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'tabs',
                columns: {
                    mobile: [{ id: '2', flexSize: 1, components: [["component_2", "component_3"]] }],
                    tablet: [{ id: '2', flexSize: 1, components: [["component_2", "component_3"]] }],
                    desktop: [{ id: '2', flexSize: 2, components: [["component_2", "component_3"]] }]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'related_schedule_activity_timeline',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'tabs',
                columns: {
                    mobile: [{ id: '2', flexSize: 1, components: [["component_4", "component_5"]] }],
                    tablet: [{ id: '2', flexSize: 1, components: ["component_4"] }, { id: '3', flexSize: 1, components: [["component_5"]] }],
                    desktop: [{ id: '2', flexSize: 2, components: ["component_4"] }, { id: '3', flexSize: 1, components: [["component_5"]] }]
                }
            },
        ],
        item_actions: [
            "ledgers.edit",
            "ledgers.adjust_balance",
            "ledgers.archive"
        ],
        more_actions: [
            "ledgers.duplicate",
            "ledgers.delete"
        ],
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        published_at: new Date(),
        published_by: null,
        
    }
];

export default LedgerPageLayoutsData;