import { styled } from '@mui/material/styles';

const PreAuthBody = styled('nav')`
    margin: 0;
    display: flex;
    flex-direction: row;
    margin-top: ${props => props.theme.variables.headerMobileHeight}px;
    width: 100%;

    @media (min-width: ${props => props.theme.breakpoints.values.md}px) {
        margin-top: ${props => props.theme.variables.headerDesktopHeight}px;
    }
`;


export { PreAuthBody }; 