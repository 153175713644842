
// This constants file is a representation of how data for object fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as FieldTypes from '../../types/FieldTypes';
import * as DatabaseTypes from '../../types/DatabaseTypes';

const RoleDropdownSetsData = [
    // Role - Statuses
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'roles',
        sync_token: 1,
        label: "Role Statuses",
        identifier: 'roles.statuses',
        dropdown_plural_label: 'Statuses',
        dropdown_singular_label: "Status",
        description: "A set of all the different statuses for a Role.",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: false,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "roles",
                sync_token: 1,
                label: "Active",
                identifier: "active",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "active"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "roles",
                sync_token: 1,
                label: "Inactive",
                identifier: "inactive",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "inactive"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    },
    // Roles - Tags
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'roles',
        sync_token: 1,
        label: "Role Tags",
        identifier: 'roles.tags',
        description: "A set of all the different tags for a Role.",
        dropdown_plural_label: 'Tags',
        dropdown_singular_label: "Tag",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "roles",
                sync_token: 1,
                label: "Employee",
                identifier: "employee",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "employee"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "roles",
                sync_token: 1,
                label: "Admin Only",
                identifier: "admin_only",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "admin_only"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "roles",
                sync_token: 1,
                label: "Guest",
                identifier: "guest",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "guest"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    },
]

export default RoleDropdownSetsData;