
// This constants file is a representation of how data for object types will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';


const NoteObjectTypesData = [

    // ----------------------------------------
    // Note Object Types
    // ----------------------------------------

    // Call
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "notes",
        sync_token: 1,
        label: "Call",
        identifier: "call",
        description: "A note for a call.",
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Meeting
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "notes",
        sync_token: 1,
        label: "Meeting",
        identifier: "meeting",
        description: "A note for a meeting.",
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Pickup/Dropoff
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "notes",
        sync_token: 1,
        label: "Pickup/Dropoff",
        identifier: "pickup_dropoff",
        description: "A note for a pickup or dropoff.",
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "notes",
        sync_token: 1,
        label: "Other",
        identifier: "other",
        description: "An uncategorized note type.",
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
];

export default NoteObjectTypesData;