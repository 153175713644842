//
// A file containing all the different back-end postgresql database types to be used for properly updating the database with the correct data.
// This file is created to maintain a centeral name for database types around the program.
// 
//
export const BIGINT = 'bigint';
export const BIGINT_ARRAY = 'bigint[]';
export const BYTEA = 'bytea';
export const BOOLEAN = 'boolean';
export const DATE = 'date';
export const GEOGRAPHY = 'geography';
export const INTEGER = 'integer';
export const INTERVAL = 'interval';
export const JSONB = 'jsonb';
export const JSONB_ARRAY = 'jsonb[]';
export const NUMERIC = 'numeric';
export const TEXT = 'text';
export const TEXT_ARRAY = 'text[]';
export const TIMESTAMPTZ = 'timestamptz';
export const TSQUERY = 'tsquery';
export const TSVECTOR = 'tsvector';
export const VARCHAR_SHORT = 'varchar(255)';
export const DECIMAL = 'decimal(19,4)';