
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';
import * as ROUTES from '../../../../navigation/routes';
import * as ScreenTypes from '../../../../types/ScreenTypes';
import * as ScreenIdentifiers from '../../../../screens/ScreenIdentifiers';


const WorkPageLayoutsData = [
    {
        public_id: uniqid('page_'),
        sync_token: 1,
        identifier: identifier('Default').toLowerCase(),
        label: 'Default',
        description: 'The default view of the Work screen.',
        status: 'published',
        revisions: 1,
        route: ROUTES.WORK,
        type: ScreenTypes.APP_PAGE_LAYOUT,
        screen_identifier: ScreenIdentifiers.WORK_OVERVIEW,
        template: null,
        object: null,
        object_identifier: null,
        object_type: null,
        group: {
            label: 'Work',
            identifier: 'work',
            order: 3
        },
        sub_group: {
            label: 'Work Overview',
            identifier: 'work_overview',
            order: 1
        },
        components: {
            component_1: {
                id: "component_1",
                label: 'Recommended Actions',
                object_identifier: null,
                connection_type: 'user',
                type: 'recommended_actions',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                                        
                }
            },
            component_2: {
                id: "component_2",
                label: 'Requests',
                object_identifier: null,
                connection_type: '',
                type: 'view',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                                        
                }
            },
            component_3: {
                id: "component_3",
                label: 'Estimates',
                object_identifier: null,
                connection_type: '',
                type: 'view',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                                        
                }
            },
            component_4: {
                id: "component_4",
                label: 'Tasks',
                object_identifier: null,
                connection_type: '',
                type: 'view',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                                        
                }
            },
            component_5: {
                id: "component_5",
                label: 'Jobs',
                object_identifier: null,
                connection_type: '',
                type: 'view',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                                        
                }
            },
            component_6: {
                id: "component_6",
                label: 'Routes',
                object_identifier: null,
                connection_type: '',
                type: 'view',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                                        
                }
            },
            component_7: {
                id: "component_7",
                label: 'Request Stats',
                object_identifier: null,
                connection_type: 'none',
                type: 'report',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                                        
                }
            },
            component_8: {
                id: "component_8",
                label: 'Job Stats',
                object_identifier: null,
                connection_type: 'none',
                type: 'report',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                                        
                }
            },
            component_9: {
                id: "component_9",
                label: 'Route Stats',
                object_identifier: null,
                connection_type: 'none',
                type: 'report',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                                        
                }
            },
            component_10: {
                id: "component_10",
                label: 'Visits Stats',
                object_identifier: null,
                connection_type: '',
                type: 'report',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                                        
                }
            },
            component_11: {
                id: "component_1",
                label: 'Work Schedule',
                object_identifier: null,
                connection_type: 'user',
                type: 'schedule',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {
                    
                }
            },
        },
        sections: [
            {
                id: uniqid('section_'),
                anchor_name: 'recommended_actions_schedule',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'tabs',
                columns: {
                    mobile: [{ id: '1', flexSize: 1, components: [["component_1", "component_2"]]}],
                    tablet: [{ id: '1', flexSize: 1, components: ["component_1"]}, { id: '2', flexSize: 1, components: [["component_2", "component_3", "component_4", "component_5", "component_6"]]}],
                    desktop: [{ id: '1', flexSize: 1, components: ["component_1"]}, { id: '2', flexSize: 1, components: [["component_2", "component_3", "component_4", "component_5", "component_6"]]}]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'reports',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'seperate',
                columns: {
                    mobile: [{ id: '3', flexSize: 1, components: [["component_7", "component_8", "component_9", "component_10"]]}],
                    tablet: [{ id: '3', flexSize: 1, components: ["component_7"]}, { id: '4', flexSize: 1, components: ["component_8"]}, { id: '5', flexSize: 1, components: ["component_9"]}, { id: '6', flexSize: 1, components: ["component_10"]} ],
                    desktop: [{ id: '3', flexSize: 1, components: ["component_7"]}, { id: '4', flexSize: 1, components: ["component_8"]}, { id: '5', flexSize: 1, components: ["component_9"]}, { id: '6', flexSize: 1, components: ["component_10"]} ]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'schedule',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'seperate',
                columns: {
                    mobile: [{ id: '8', flexSize: 1, components: ["component_11"]}],
                    tablet: [{ id: '8', flexSize: 1, components: ["component_11"]}],
                    desktop: [{ id: '8', flexSize: 1, components: ["component_11"]}]
                }
            },
        ],
        item_actions: [
            "requests.new",
            "estimates.new",
            "tasks.new",
            "jobs.new",
            "routes.new",
        ],
        more_actions: [ ],
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        published_at: new Date(),
        published_by: null,
        
    },
];

export default WorkPageLayoutsData;