
//
// A file containing all the different Screen types for various unique Screens.
// This file is created to maintain a centeral name for Screen types around the program.
// 
//

export const OBJECT_PAGE_LAYOUT = 'object_page_layout';
export const OBJECT_LIST_VIEW = 'object_list_view';
export const APP_PAGE_LAYOUT = 'app_page_layout';
export const SETTINGS = 'settings';