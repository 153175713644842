// This constats file is a representation of how data for object fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as ActionTypes from '../../types/ActionTypes';
import * as ActionUseTypes from '../../types/ActionUseTypes';

const VisitActionsData = [
    //
    // Item Actions
    //
    // New Visit
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'visits',
        sync_token: 1,
        label: "New Visit",
        short_label: "New",
        identifier: "new",
        icon: "calendar-check",
        sort_order: 1,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.NEW,
        description: "Create a new Visit.",
        help_text: "Create a new Visit.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Edit Visit
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'visits',
        sync_token: 1,
        label: "Edit Visit",
        short_label: "Edit",
        identifier: "edit",
        icon: "pen-to-square",
        sort_order: 2,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.EDIT,
        description: "Edit the Fields for a Visit.",
        help_text: "Edit the Fields for this Visit.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Start Visit
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'visits',
        sync_token: 1,
        label: "Start Visit",
        short_label: "start",
        identifier: "start",
        icon: "play",
        sort_order: 3,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.COMPLETE,
        description: "Start a Visit which means the user is currently working on a Visit.",
        help_text: "Start a Visit which means the user is currently working on a Visit.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // End Visit
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'visits',
        sync_token: 1,
        label: "End Visit",
        short_label: "End",
        identifier: "end",
        icon: "square",
        sort_order: 4,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.COMPLETE,
        description: "End a Visit which means the user is currently stopped working on a Visit.",
        help_text: "End a Visit which means the user is currently stopped working on a Visit.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Complete Visit
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'visits',
        sync_token: 1,
        label: "Complete Visit",
        short_label: "Complete",
        identifier: "complete",
        icon: "square-check",
        sort_order: 4,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.COMPLETE,
        description: "Mark a Visit as completed.",
        help_text: "Mark this Visit as completed.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Close Visit
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'visits',
        sync_token: 1,
        label: "Close Visit",
        short_label: "Close",
        identifier: "close",
        icon: "square-minus",
        sort_order: 5,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.CLOSE,
        description: "Mark a Visit as closed.",
        help_text: "Mark this Visit as closed.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Convert to Job
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'visits',
        sync_token: 1,
        label: "Convert to Job",
        short_label: "Convert to Job",
        identifier: "convert_to_job",
        icon: "briefcase",
        sort_order: 6,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.CONVERT,
        description: "Converts a Visit to a Job.",
        help_text: "Convert this Visit to a Job.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Convert to Invoice
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'visits',
        sync_token: 1,
        label: "Convert to Invoice",
        short_label: "Convert to Invoice",
        identifier: "convert_to_invoice",
        icon: "file-invoice-dollar",
        sort_order: 6,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.CONVERT,
        description: "Converts a Visit to an Invoice.",
        help_text: "Convert this Visit to an Invoice.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Duplicate Visit
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'visits',
        sync_token: 1,
        label: "Duplicate Visit",
        short_label: "Duplicate",
        identifier: "duplicate",
        icon: "copy",
        sort_order: 7,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.DUPLICATE,
        description: "Create a new Visit record by copying information from an existing Visit record.",
        help_text: "Create a new Visit record by copying information from this Visit.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Archive Visit
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'visits',
        sync_token: 1,
        label: "Archive Visit",
        short_label: "Archive",
        identifier: "archive",
        icon: "box-archive",
        sort_order: 8,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.ARCHIVE,
        description: "Archive a Visit to be hidden from normal visits in the program.",
        help_text: "Archive a Visit, hidden but not deleted.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Delete Visit
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'visits',
        sync_token: 1,
        label: "Delete Visit",
        short_label: "Delete",
        identifier: "delete",
        icon: "trash",
        sort_order: 9,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.DELETE,
        description: "Permantently deletes a Visit. Not retrievable by users after this point.",
        help_text: "Permantently deletes a Visit. Not retrievable by users after this point.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Edit Recurrence Schedule
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'visits',
        sync_token: 1,
        label: "Edit Recurrence Schedule",
        short_label: "Edit Recurrence",
        identifier: "edit_recurrence",
        icon: "clock",
        sort_order: 10,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.EDIT_RECURRENCE,
        description: "Edit the recurrence schedule for a Visit.",
        help_text: "Edit this Visit's recurrence schedule.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    //
    // Batch Actions
    //

    // Batch Edit
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'visits',
        sync_token: 1,
        label: "Batch Edit",
        short_label: "Batch Edit",
        identifier: "batch_edit",
        icon: "pen-to-square",
        sort_order: 1,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_EDIT,
        description: "Edit the Fields for multiple selected Visits at once.",
        help_text: "Edit the Fields for multiple selected Visits at once.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Batch Archive
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'visits',
        sync_token: 1,
        label: "Batch Archive",
        short_label: "Batch Archive",
        identifier: "batch_archive",
        icon: 'box-archive',
        sort_order: 2,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_ARCHIVE,
        description: "Archive multiple selected Visits to be hidden from normal visits in the program.",
        help_text: "Archive multiple selected Visits to be hidden from normal visits in the program.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },

];


export default VisitActionsData;