//
// Pre Auth Routes
//
export const SIGN_UP = '/signup';
export const LOG_IN = '/login';
export const FORGOT_PASSWORD = '/forgot_password';
export const NEW_PASSWORD = '/new_password';
export const SETUP_ACCOUNT = '/setup_account';

// ------------------------------------------------------

//
// Post Auth Routes
//

export const HOME = '/home';

// Schedule Area
export const SCHEDULE = '/schedule';
export const EVENTS = '/events';
export const EVENT_DETAIL_PREFIX = '/events/:id';
export const VISITS = '/visits';
export const VISIT_DETAIL_PREFIX = '/visits/:id';

// Work Area
export const WORK = '/work';
export const TASKS = '/tasks';
export const TASK_DETAIL_PREFIX = '/tasks/:id';
export const JOBS = '/jobs';
export const JOB_DETAIL_PREFIX = '/jobs/:id';
export const REQUESTS = '/requests';
export const REQUEST_DETAIL_PREFIX = '/requests/:id';
export const ROUTES = '/routes';
export const ROUTE_DETAIL_PREFIX = '/routes/:id';

// Billing Area
export const BILLING = '/billing';
export const INVOICES = '/invoices';
export const INVOICE_DETAIL_PREFIX = '/invoices/:id';
export const ESTIMATES = '/estimates';
export const ESTIMATE_DETAIL_PREFIX = '/estimates/:id';
export const SUBSCRIPTIONS = '/subscriptions';
export const SUBSCRIPTION_DETAIL_PREFIX = '/subscriptions/:id';
export const PAYMENTS = '/payments';
export const PAYMENT_DETAIL_PREFIX = '/payments/:id';
export const ITEMS = '/items';
export const ITEM_DETAIL_PREFIX = '/items/:id';

// Customer Area
export const CLIENTS = '/clients';
export const ACCOUNTS = '/accounts';
export const ACCOUNT_DETAIL_PREFIX = '/accounts/:id';
export const NEW_ACCOUNT = '/accounts/new';
export const CONTACTS = '/contacts';
export const CONTACT_DETAIL_PREFIX = '/contacts/:id';
export const NEW_CONTACT = '/contacts/new';
export const PROPERTIES = '/properties';
export const PROPERTY_DETAIL_PREFIX = '/properties/:id';
export const NEW_PROPERTY = '/properties/new';

// Team Area
export const TEAM = '/team';
export const USERS = '/users';
export const USERS_NEW = '/users/new';
export const USER_DETAIL_PREFIX = '/users/:id';

// Security Area
export const SECURITY = '/security';
export const ROLES = '/roles';
export const ROLES_NEW = '/roles/new';
export const ROLE_DETAIL_PREFIX = '/roles/:id';
export const PERMISSION_SETS = '/permission_sets';
export const PERMISSION_SETS_NEW = '/permission_sets/new';
export const PERMISSION_SET_DETAIL_PREFIX = '/permission_sets/:id';

// Audit Area
export const AUDIT = '/audit';
export const LEDGERS = '/ledgers';
export const LEDGERS_NEW = '/ledgers/new';
export const LEDGER_DETAIL_PREFIX = '/ledgers/:id';
export const REPORTS = '/reports';
export const REPORTS_NEW = '/reports/new';
export const REPORT_DETAIL_PREFIX = '/reports/:id';

// Settings Area (General)
export const SETTINGS = '/settings';

// Settings Area (User)
export const SETTINGS_USER = '/user_settings';

// Settings Area (Company)
export const SETTINGS_COMPANY = '/settings/company';
export const SETTINGS_COMPANY_BRANDING = '/settings/company/branding';
export const SETTINGS_COMPANY_FINANCIAL_INFORMATION = '/settings/company/financials';

// Settings Area (ProHelper)
export const SETTINGS_PROHELPER_OVERVIEW = '/settings/prohelper/overview';
export const SETTINGS_PROHELPER_SUBSCRIPTION = '/settings/prohelper/subscription';
export const SETTINGS_PROHELPER_PLANS = '/settings/prohelper/plans';
export const SETTINGS_PROHELPER_PAYMENTS = '/settings/prohelper/payments';

// Settings Area (Customization)
export const SETTINGS_CUSTOMIZATIONS_MANAGER = '/settings/customize/customizations_manager';
export const SETTINGS_CUSTOMIZE_OBJECTS = '/settings/customize/objects';
export const SETTINGS_CUSTOMIZE_OBJECT_TYPES = '/settings/customize/object_types';
export const SETTINGS_CUSTOMIZE_OBJECT_FIELDS = '/settings/customize/object_fields';
export const SETTINGS_CUSTOMIZE_DROPDOWN_SETS = '/settings/customize/dropdown_sets';
export const SETTINGS_CUSTOMIZE_LIST_VIEWS = '/settings/customize/list_views';
export const SETTINGS_CUSTOMIZE_PAGE_LAYOUTS = '/settings/customize/page_layouts';
export const SETTINGS_CUSTOMIZE_PAGE_LAYOUT = '/settings/customize/page_layout';
export const SETTINGS_CUSTOMIZE_SEARCH_VIEWS = '/settings/customize/search_views';

// Settings Area (Features)
export const SETTINGS_FEATURES_WORK = '/features/work';
export const SETTINGS_FEATURES_SCHEDULE = '/features/schedule';
export const SETTINGS_FEATURES_BILLING = '/features/billing';
export const SETTINGS_FEATURES_CUSTOMERS = '/features/customers';
export const SETTINGS_FEATURES_TEAM = '/features/team';
export const SETTINGS_FEATURES_REPORTS = '/features/reports';

// Settings Area (Data)
export const SETTINGS_DATA_MANAGE_INTEGRATIONS = '/settings/data/manage_integrations';
export const SETTINGS_DATA_QBO = '/settings/data/quickbooks';
export const SETTINGS_DATA_STRIPE = '/settings/data/stripe';
export const SETTINGS_DATA_IMPORT = '/settings/data/import';
export const SETTINGS_DATA_EXPORT = '/settings/data/export';
export const SETTINGS_DATA_ARCHIVE = '/settings/data/archive';

// Settings Area (Owner)
export const SETTINGS_OWNER_DETAILS = '/settings/owner/details';
export const SETTINGS_DELETE_COMPANY = '/settings/owner/delete_company';


// Other
export const TOS = '/terms_of_service';
export const PP = '/privacy_policy';
export const CHANGE_EMAIL = '/change_email';
export const CHANGE_PASSWORD = '/change_password';
export const SETTINGS_SUPPORT = '/product_support';
export const SETTINGS_HELP = '/help';

