
// Import NPM Modules
import _ from 'lodash';

// Import Custom Modules
import * as actionTypes from '../../action_types/program/NavigationActionTypes';

// Empty inital state
const initialState =
{
    authNavigatorIsOpen: false,
    authNavigatorCategories: [],
    settingsCategories: [],
};


/**
 * This Reducer handles all ProHelper Navigation data (authNavigationCategories, authNavigatorIsOpen, settingsCategories)
 * 
 */
const navigationReducer = (state = initialState, action) => {
 
    switch (action.type) {

        // Set Navigation takes in a payload which is an object optionally consisting of (authNavigatorCategories, )
        case actionTypes.SET_NAVIGATION:

            // Create a copy of the current redux state
            let newNavigationState = { ...state };

            if (_.isArray(action.payload.authNavigatorCategories)) {
                // Check for valid authNavigatorCategories then update the redux state
                newNavigationState.authNavigatorCategories = action.payload.authNavigatorCategories;
            }

            if (_.isArray(action.payload.settingsCategories)) {
                // Check for valid settingsCategories then update the redux state
                newNavigationState.settingsCategories = action.payload.settingsCategories;
            }

            return newNavigationState;



        case actionTypes.SET_AUTH_NAVIGATOR_IS_OPEN:
            return {
                ...state,
                authNavigatorIsOpen: action.payload.authNavigatorIsOpen
            };

        case actionTypes.SET_AUTH_NAVIGATOR_CATEGORIES:
            return {
                ...state,
                authNavigatorCategories: action.payload.authNavigatorCategories
            };

        case actionTypes.SET_SETTINGS_CATEGORIES:
            return {
                ...state,
                settingsCategories: action.payload.settingsCategories
            };
            
        default:
            return state;
    }


};

export default navigationReducer;