// This constants file is a representation of how data for object fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as ActionTypes from '../../types/ActionTypes';
import * as ActionUseTypes from '../../types/ActionUseTypes';

const PropertyActionsData = [

    // Item Actions
    //
    // New Property
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'properties',
        sync_token: 1,
        label: "New Property",
        short_label: "New",
        identifier: "new",
        icon: 'house-building',
        sort_order: 1,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.NEW,
        description: "Create a new a Property.",
        help_text: "Create a new a Property.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Edit Property
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'properties',
        sync_token: 1,
        label: "Edit Property",
        short_label: "Edit",
        identifier: "edit",
        icon: 'pen-to-square',
        sort_order: 2,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.EDIT,
        description: "Edit the Object Fields for a Property.",
        help_text: "Edit the Object Fields for a Property.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Send Mail
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'properties',
        sync_token: 1,
        label: "Send Mail",
        short_label: "Send Mail",
        identifier: "send_mail",
        icon: "mailbox",
        sort_order: 3,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.EMAIL,
        description: "Create a printable letter to be mailed to a Property.",
        help_text: "Create a printable letter to be mailed to a Property.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // View in Maps
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'properties',
        sync_token: 1,
        label: "View in Maps",
        short_label: "View in Maps",
        identifier: "view_in_maps",
        icon: "map-location",
        sort_order: 4,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.PROHELPER_LINK,
        description: "Links to the default mapping software or website to get directions to the primary address/property.",
        help_text: "Links to the default mapping software or website to get directions to the primary address/property.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Duplicate Property
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'properties',
        sync_token: 1,
        label: "Duplicate Property",
        short_label: "Duplicate",
        identifier: "duplicate",
        icon: "copy",
        sort_order: 7,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.PROHELPER_LINK,
        description: "Copy and duplicate the contents of this Property to a new one.",
        help_text: "Copy and duplicate the contents of this Property to a new one.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Archive Property
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'properties',
        sync_token: 1,
        label: "Archive Property",
        short_label: "Archive",
        identifier: "archive",
        icon: "box-archive",
        sort_order: 8,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.ARCHIVE,
        description: "Archive an property to be hidden from normal users in the program.",
        help_text: "Archive an property, hidden but not deleted.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Delete Property
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'properties',
        sync_token: 1,
        label: "Delete Property",
        short_label: "Delete",
        identifier: "delete",
        icon: "trash",
        sort_order: 9,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.DELETE,
        description: "Archive an property to be hidden from normal users in the program.",
        help_text: "Archive an property, hidden but not deleted.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },


    //
    // Batch Actions
    //

    // Batch Edit
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'properties',
        sync_token: 1,
        label: "Batch Edit",
        short_label: "Batch Edit",
        identifier: "batch_edit",
        icon: "pen-to-square",
        sort_order: 1,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_EDIT,
        description: "Edit the Object Fields for multiple selected Properties at once.",
        help_text: "Edit the Object Fields for multiple selected Properties at once.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Batch Archive
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'properties',
        sync_token: 1,
        label: "Batch Archive",
        short_label: "Batch Archive",
        identifier: "batch_archive",
        icon: "box-archive",
        sort_order: 6,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_ARCHIVE,
        description: "Archive multiple selected Properties to be hidden from normal users in the program.",
        help_text: "Archive multiple selected Properties to be hidden from normal users in the program.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
];


export default PropertyActionsData;