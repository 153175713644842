
// This constants file is a representation of how data for Object Types will be recieved by the ProHelper database once linked.

// Schedule
import VisitObjectTypesData from './object_types/VisitObjectTypesData';

// Work
import RequestObjectTypes from './object_types/RequestObjectTypesData';
import EstimateObjectTypesData from './object_types/EstimateObjectTypesData';
import TaskObjectTypesData from './object_types/TaskObjectTypesData';
import JobObjectTypesData from './object_types/JobObjectTypesData';
import RouteObjectTypesData from './object_types/RouteObjectTypesData';

// Clients
import AccountObjectTypesData from './object_types/AccountObjectTypesData';
import ContactObjectTypesData from './object_types/ContactObjectTypesData';
import PropertyObjectTypesData from './object_types/PropertyObjectTypesData';

// Billing
import InvoiceObjectTypesData from './object_types/InvoiceObjectTypesData';
import ItemObjectTypesData from './object_types/ItemObjectTypesData';
import LineItemObjectTypesData from './object_types/LineItemObjectTypesData';

// Audit
import LedgerObjectTypes from './object_types/LedgerObjectTypes';
import ReportObjectTypesData from './object_types/ReportObjectTypesData';

// Team
import UserObjectTypesData from './object_types/UserObjectTypesData';

// Security
import RoleObjectTypes from './object_types/RoleObjectTypesData';
import PermissionSetObjectTypes from './object_types/PermissionSetObjectTypes';

// Global
import NoteObjectTypesData from './object_types/NoteObjectTypesData';


// Add (concact) all the individual object types arrays into one big array
const ObjectTypesData = [].concat(
    VisitObjectTypesData,
    RequestObjectTypes,
    EstimateObjectTypesData,
    TaskObjectTypesData,
    JobObjectTypesData,
    RouteObjectTypesData,
    AccountObjectTypesData, 
    ContactObjectTypesData, 
    PropertyObjectTypesData,
    LedgerObjectTypes,
    ReportObjectTypesData,
    InvoiceObjectTypesData,
    ItemObjectTypesData,
    UserObjectTypesData,
    RoleObjectTypes,
    PermissionSetObjectTypes,
    LineItemObjectTypesData,
    NoteObjectTypesData
);

export default ObjectTypesData;