
// Import NPM Modules
import _ from 'lodash';

// Import Custom Modules
import * as actionTypes from '../../action_types/data/PageLayoutDataActionTypes';


const initialState = {
    object: null,
    object_identifier: '',
    starting_data: null,
    raw_data: {},
    shown_data: {},
    components_data_mapping: {}
};

// const initialState =
// {
//     [ObjectIdentifiers.ACCOUNTS]: {...STARTING_PAGE_LAYOUT_DATA},
//     [ObjectIdentifiers.CONTACTS]: {...STARTING_PAGE_LAYOUT_DATA},
//     [ObjectIdentifiers.PROPERTIES]: {...STARTING_PAGE_LAYOUT_DATA},
//     [ObjectIdentifiers.USERS]: {...STARTING_PAGE_LAYOUT_DATA},
//     [ObjectIdentifiers.ROLES]: {...STARTING_PAGE_LAYOUT_DATA},
//     [ObjectIdentifiers.PERMISSION_SETS]: {...STARTING_PAGE_LAYOUT_DATA},
//     [ObjectIdentifiers.PERMISSIONS]: {...STARTING_PAGE_LAYOUT_DATA},
// };


const PageLayoutDataReducer = (state = {...initialState}, action) => {

    switch (action.type) {
        case actionTypes.SET_PAGE_LAYOUT_DATA:
            return {
                ...state,
                object: _.isObject(action.payload.object) ? action.payload.object : state.object,
                object_identifier: _.isString(action.payload.object_identifier) ? action.payload.object_identifier : state.object_identifier,
                starting_data: action.payload.starting_data ? action.payload.starting_data : state.starting_data,
                raw_data: action.payload.raw_data ? action.payload.raw_data : state.raw_data,
                shown_data: action.payload.shown_data ? action.payload.shown_data : state.shown_data,
                components_data_mapping: action.payload.components_data_mapping ? action.payload.components_data_mapping : state.components_data_mapping,
            }

        case actionTypes.REMOVE_PAGE_LAYOUT_DATA:
            return initialState;

        default:
            return state;
    }
};


export default PageLayoutDataReducer;
