
const StandardRolesData = {
    admin : {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'admin',
        label: 'Admin',
        description: "Admin's have access to all of the ProHelper platform to operate the company.",
        type: 'admin'
    },
    manager : {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'manager',
        label: 'Manager',
        description: "Managers have access to the core areas of the ProHelper platform to operate their area of the company.",
        type: 'admin'
    },
    field_technician : {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'field_technician',
        label: 'Field Technician',
        description: "Experienced in-the-field workers that have more access around the program. ",
        type: 'service'
    },
    service_employee : {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'service_employee',
        label: 'Service Employee',
        description: "In-the-field workers of the company that have access to their assigned work items.",
        type: 'service'
    },
    dispatcher : {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'dispatcher',
        label: 'Dispatcher',
        description: "Dispatchers have access to scheduling and assigning team members to work items and more editing permissions.",
        type: 'office'
    },
    accountant : {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'accountant',
        label: 'Accountant',
        description: "The financial accountant that manages the billing of the company.",
        type: 'billing'
    },
    viewer : {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'viewer',
        label: 'Viewer',
        description: "Viewer's have access to limited areas of the ProHelper Platform with read-only access.",
        type: 'other'
    },
};

export default StandardRolesData;