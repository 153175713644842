// NPM Modules
import _ from 'lodash';

// User Info Reducer
import * as actionTypes from '../../action_types/ui/LayoutActionTypes';


const initialState =
{
  is_mobile: false,
  isAuthFooterVisible: true,
  display_type: 'not-set',
  router_location: null,
  theme: null,
  current_screen: {
      title: null,
      identifier: null,
      description: null,
      icon: null,
      route: null
  },
  current_object: null,
  header_type: 'loading',
  footer_type: 'main'
};

const layoutReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.SET_LAYOUT:
            return {
                ...state,
                is_mobile: _.isBoolean(action.payload.is_mobile) ? action.payload.is_mobile : state.is_mobile,
                isAuthFooterVisible: _.isBoolean(action.payload.isAuthFooterVisible) ? action.payload.isAuthFooterVisible : state.isAuthFooterVisible,
                router_location: !_.isNull(action.payload.router_location) ? action.payload.router_location : state.router_location,
                display_type: _.isString(action.payload.display_type) ? action.payload.display_type : state.display_type,
                theme: _.isObject(action.payload.theme) ? action.payload.theme : state.theme,
                current_screen: _.isObject(action.payload.current_screen) ? action.payload.current_screen : state.current_screen,
                current_object: _.isObject(action.payload.current_object) ? action.payload.current_object : state.current_object,
                header_type: _.isString(action.payload.header_type) ? action.payload.header_type : state.header_type,
                footer_type: _.isString(action.payload.footer_type) ? action.payload.footer_type : state.footer_type,
            };
        case actionTypes.SET_IS_MOBILE:
            return {
                ...state,
                is_mobile: action.payload.is_mobile
            };

        case actionTypes.SET_DISPLAY_TYPE:
            return {
                ...state,
                display_type: action.payload.display_type
            };

        case actionTypes.SET_AUTH_FOOTER_VISIBLE:
            return {
                ...state,
                isAuthFooterVisible: action.payload.isAuthFooterVisible
            };

        case actionTypes.SET_ROUTER_LOCATION:
            return {
                ...state,
                router_location: action.payload.router_location,
                header_type: action.payload.header_type ? action.payload.header_type : state.header_type,
                footer_type: action.payload.footer_type ? action.payload.footer_type : state.footer_type,
                current_screen: action.payload.current_screen ? action.payload.current_screen : state.current_screen,
                current_object: action.payload.current_object ? action.payload.current_object : state.current_object
            };

        case actionTypes.SET_CURRENT_SCREEN:
            return {
                ...state,
                current_screen: action.currentScreen ? action.currentScreen : state.current_screen
            };

        case actionTypes.SET_HEADER_TYPE:
            return {
                ...state,
                header_type: action.payload.header_type
            };

        case actionTypes.SET_FOOTER_TYPE:
            return {
                ...state,
                footer_type: action.payload.footer_type
            };

        default:
            return state;
    }
};

export default layoutReducer;