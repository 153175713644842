
// Import NPM Modules
import _ from 'lodash';

// Objects Reducer
import * as actionTypes from '../../action_types/customizations/ObjectsActionTypes';

// Empty initial state
const initialState = { };

const objectsReducer = (state = initialState, action) => {

    switch (action.type) {


        case actionTypes.SET_ALL_OBJECTS:

            // Create a copy of the current redux state
            let newObjectsState = {...state};

            if (_.isArray(action.payload.objects)) {
                action.payload.objects.forEach((object) => {
                    newObjectsState[object.identifier] = object;
                })
            }

            return newObjectsState;

        case actionTypes.REMOVE_ALL_OBJECTS:

            // Return an empty object's JSON object.
            return {};

        default:
            return state;
    }
};

export default objectsReducer;