
// Authentication Reducer
import * as actionTypes from '../../action_types/program/AuthenticationActionTypes';

// Empty inital state
const initialState = {
    cognito_user: null,
    prohelper_user: null,
    login_status: true,
    auth: {}
};


// This Reducer handles all ProHelper Authentication data (user, auth, login_status)
const authenticationReducer = (state = initialState, action) => {
    switch (action.type) {
        // Update the AWS Cognito User
        case actionTypes.UPDATE_COGNITO_USER:
            return {
                ...state,
                cognito_user: action.payload.cognito_user
                    ? action.payload.cognito_user
                    : state.cognito_user,
            };
        // Remove the AWS Cognito User
        case actionTypes.REMOVE_COGNITO_USER:
            return {
                ...state,
                cognito_user: null,
                prohelper_user: null,
                login_status: false,
                auth: {}
            };
        // Update the ProHelper User
        case actionTypes.UPDATE_PROHELPER_USER:
            return {
                ...state,
                prohelper_user: action.payload.prohelper_user
                    ? action.payload.prohelper_user
                    : state.prohelper_user,
            };
        // Remove the ProHelper User
        case actionTypes.REMOVE_PROHELPER_USER:
            return {
                ...state,
                prohelper_user: null,
            };
        // Update the Cognito Login Status
        case actionTypes.UPDATE_LOGIN_STATUS:
            return {
                ...state,
                login_status: action.payload.login_status,
            };
        // Update the ProHelper Auth Object
        case actionTypes.UPDATE_AUTH:
            return {
                ...state,
                cognito_user: action.payload.cognito_user
                    ? action.payload.cognito_user
                    : state.cognito_user,
                prohelper_user: action.payload.prohelper_user
                    ? action.payload.prohelper_user
                    : state.prohelper_user,
                login_status: action.payload.login_status
                    ? action.payload.login_status
                    : state.login_status,
                auth: action.payload.auth ? action.payload.auth : state.auth,
            };
        // Remove the ProHelper Auth Object
        case actionTypes.REMOVE_AUTH:
            return {
                cognito_user: null,
                prohelper_user: null,
                login_status: false,
                auth: {},
            };
        // Default Case
        default:
            return state;
    }
};

export default authenticationReducer;