
// This constants file is a representation of how data for object types will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';


const TaskObjectTypesData = [

    // ----------------------------------------
    // Task Object Types
    // ----------------------------------------

    // Call
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "tasks",
        sync_token: 1,
        label: "Call",
        identifier: "call",
        description: "A task to make a call.",
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Meeting
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "tasks",
        sync_token: 1,
        label: "Meeting",
        identifier: "meeting",
        description: "A task to have a meeting.",
        sort_order: 2,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Pickup/Dropoff
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "tasks",
        sync_token: 1,
        label: "Pickup/Dropoff",
        identifier: "pickup/dropoff",
        description: "A task to pickup or dropoff something.",
        sort_order: 3,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "tasks",
        sync_token: 1,
        label: "Other",
        identifier: "other",
        description: "A task that doesn't fit in a category.",
        sort_order: 4,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
];

export default TaskObjectTypesData;