
const StandardPermissionSetsData = {
    admin_permission_set: {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'admin_permission_set',
        label: 'Admin',
        description: "Admin's have access to all of the ProHelper platform to operate the company.",
        type: 'admin'
    },
    manager_permission_set : {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'manager_permission_set',
        label: 'Manager Permission Set',
        description: "Managers have access to the core areas of the ProHelper platform to operate their area of the company.",
        type: 'admin'
    },
    field_technician_permission_set : {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'field_technician_permission_set',
        label: 'Field Technician Permission Set',
        description: "Experienced in-the-field workers that have more access around the program. ",
        type: 'service'
    },
    service_employee_permission_set : {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'service_employee_permission_set',
        label: 'Service Employee Permission Set',
        description: "In-the-field workers of the company that have access to their assigned work items.",
        type: 'service'
    },
    dispatcher_permission_set : {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'dispatcher_permission_set',
        label: 'Dispatcher Permission Set',
        description: "Dispatchers have access to scheduling and assigning team members to work items and more editing permissions.",
        type: 'office'
    },
    accountant_permission_set : {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'accountant_permission_set',
        label: 'Accountant Permission Set',
        description: "The financial accountant that manages the billing of the company.",
        type: 'billing'
    },
    viewer_permission_set : {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'viewer_permission_set',
        label: 'Viewer Permission Set',
        description: "Viewer's have access to limited areas of the ProHelper Platform with read-only access.",
        type: 'other'
    },
};

export default StandardPermissionSetsData;