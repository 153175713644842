
// Import NPM Modules
import _ from 'lodash';

// Theme Reducer Action Types
import * as actionTypes from '../../action_types/ui/ThemeActionTypes';


// Initial Sample State
const initialState = { };
 

const themeReducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SET_THEME:
            // Able to optionally update any part of the color theme reducer
            if (_.isEmpty(action.payload.theme)) {
                return state;
            }   
            // Copy the incoming theme object for the whole state
            return {...action.payload.theme};

        default:
            // Do nothing for non-existant action types
            return state;
    }
};

export default themeReducer;