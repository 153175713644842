
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';
import * as ROUTES from '../../../../navigation/routes';
import * as ScreenTypes from '../../../../types/ScreenTypes';
import * as GraphQLQueryTypes from '../../../../types/GraphQLQueryTypes';
import * as GraphQLTypes from '../../../../types/GraphQLTypes';
import * as ScreenIdentifiers from '../../../../screens/ScreenIdentifiers';
import * as ComponentTypes from '../../../../types/ProHelperComponentTypes';

const AccountPageLayoutsData = [
    // Default
    {
        public_id: uniqid('page_'),
        sync_token: 1,
        identifier: identifier('Default').toLowerCase(),
        label: 'Default',
        description: 'The default view for an Account record.',
        status: 'published',
        revisions: 1,
        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
        route: ROUTES.ACCOUNT_DETAIL_PREFIX,
        screen_identifier: ScreenIdentifiers.ACCOUNT_PAGE_LAYOUT,
        template: null,
        object: null,
        object_identifier: 'accounts',
        object_type: null,
        group: {
            label: 'Clients',
            identifier: 'clients',
            order: 4
        },
        sub_group: {
            label: 'Accounts',
            identifier: 'accounts',
            order: 2
        },
        components: {
            component_1: {
                id: "component_1",
                label: 'Header',
                object_identifier: 'accounts',
                connection_type: 'parent',
                type: ComponentTypes.OBJECT_HEADER,
                header_type: 'action_bar',
                special_type: 'header',
                is_editable: true,
                visibility: ['mobile', 'tablet', 'desktop'],
                api_request: {
                    [GraphQLQueryTypes.GetAccount]: {
                        inputs: {
                            company_id: GraphQLTypes.ID,
                            account_id: GraphQLTypes.ID,
                            user_id: GraphQLTypes.ID,
                            page_layout_id: GraphQLTypes.ID
                        },
                        outputs: {
                            name: {
                                display: GraphQLTypes.String
                            },
                            balance: GraphQLTypes.BigInt,
                            type: {
                                id: GraphQLTypes.ID,
                                public_id: GraphQLTypes.ID,
                                label: GraphQLTypes.String,
                                identifier: GraphQLTypes.String
                            },
                            primary_contact: {
                                id: GraphQLTypes.ID,
                                contact_id: GraphQLTypes.ID,
                                name: {
                                    display: GraphQLTypes.String
                                }
                            },
                            primary_property: {
                                id: GraphQLTypes.ID,
                                property_id: GraphQLTypes.ID,
                                address: {
                                    display: GraphQLTypes.String
                                }
                            },
                            owner: {
                                id: GraphQLTypes.ID,
                                user_id: GraphQLTypes.ID,
                                name: {
                                    display: GraphQLTypes.String
                                }
                            }
                        }
                    }
                },
                metadata: {
                    fields: [
                        {
                            label: "Name",
                            identifier: "accounts.name",
                            is_required: true,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Balance",
                            identifier: "accounts.balance",
                            is_required: true,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Type",
                            identifier: "accounts.type",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Primary Contact",
                            identifier: "accounts.primary_contact",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Primary Property",
                            identifier: "accounts.primary_property",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Owner",
                            identifier: "accounts.owner",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                    ],
                    first_visible_amount: {
                        mobile: 4,
                        tablet: 4,
                        desktop: 1
                    }
                }
            },
            component_2: {
                id: "component_2",
                label: 'Details',
                object_identifier: 'accounts',
                connection_type: 'parent',
                type: ComponentTypes.OBJECT_DETAILS,
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: true,
                api_request: {
                    [GraphQLQueryTypes.GetAccount]: {
                        inputs: {
                            company_id: GraphQLTypes.ID,
                            account_id: GraphQLTypes.ID,
                            user_id: GraphQLTypes.ID,
                            page_layout_id: GraphQLTypes.ID
                        },
                        outputs: {
                            description: GraphQLTypes.String,
                            status: {
                                id: GraphQLTypes.ID,
                                public_id: GraphQLTypes.ID,
                                label: GraphQLTypes.String,
                                identifier: GraphQLTypes.String,
                                value: GraphQLTypes.JSON
                            },
                            priority: {
                                id: GraphQLTypes.ID,
                                public_id: GraphQLTypes.ID,
                                label: GraphQLTypes.String,
                                identifier: GraphQLTypes.String,
                                value: GraphQLTypes.JSON
                            },
                            rating: {
                                id: GraphQLTypes.ID,
                                public_id: GraphQLTypes.ID,
                                label: GraphQLTypes.String,
                                identifier: GraphQLTypes.String,
                                value: GraphQLTypes.JSON
                            },
                            source: {
                                id: GraphQLTypes.ID,
                                public_id: GraphQLTypes.ID,
                                label: GraphQLTypes.String,
                                identifier: GraphQLTypes.String,
                                value: GraphQLTypes.JSON
                            },
                            tags: {
                                id: GraphQLTypes.ID,
                                public_id: GraphQLTypes.ID,
                                label: GraphQLTypes.String,
                                identifier: GraphQLTypes.String,
                                value: GraphQLTypes.JSON
                            },
                            primary_contact: {
                                id: GraphQLTypes.ID,
                                contact_id: GraphQLTypes.ID,
                                name: {
                                    display: GraphQLTypes.String
                                }
                            },
                            primary_property: {
                                id: GraphQLTypes.ID,
                                property_id: GraphQLTypes.ID,
                                address: {
                                    display: GraphQLTypes.String
                                }
                            },
                            phone: {
                                number: GraphQLTypes.Int,
                                national: GraphQLTypes.String,
                                international: GraphQLTypes.String,
                                country: GraphQLTypes.String
                            },
                            email: {
                                address: GraphQLTypes.String
                            },
                            website: GraphQLTypes.String,
                            zone: {
                                id: GraphQLTypes.ID,
                                public_id: GraphQLTypes.ID,
                                label: GraphQLTypes.String,
                                identifier: GraphQLTypes.String,
                                value: GraphQLTypes.JSON
                            },
                            billing_contact: {
                                id: GraphQLTypes.ID,
                                contact_id: GraphQLTypes.ID,
                                name: {
                                    display: GraphQLTypes.String
                                }
                            },
                            billing_property: {
                                id: GraphQLTypes.ID,
                                property_id: GraphQLTypes.ID,
                                address: {
                                    display: GraphQLTypes.String
                                }
                            },
                            is_taxable: GraphQLTypes.Boolean,
                            tax_id: GraphQLTypes.String,
                            balance_with_sub_accounts: GraphQLTypes.BigInt,
                            payment_status: {
                                id: GraphQLTypes.ID,
                                public_id: GraphQLTypes.ID,
                                label: GraphQLTypes.String,
                                identifier: GraphQLTypes.String,
                                value: GraphQLTypes.JSON
                            },
                            shipping_contact: {
                                id: GraphQLTypes.ID,
                                contact_id: GraphQLTypes.ID,
                                name: {
                                    display: GraphQLTypes.String
                                }
                            },
                            shipping_property: {
                                id: GraphQLTypes.ID,
                                property_id: GraphQLTypes.ID,
                                address: {
                                    display: GraphQLTypes.String
                                }
                            },
                            delivery_method: {
                                id: GraphQLTypes.ID,
                                public_id: GraphQLTypes.ID,
                                label: GraphQLTypes.String,
                                identifier: GraphQLTypes.String,
                                value: GraphQLTypes.JSON
                            },
                            communication_method: {
                                id: GraphQLTypes.ID,
                                public_id: GraphQLTypes.ID,
                                label: GraphQLTypes.String,
                                identifier: GraphQLTypes.String,
                                value: GraphQLTypes.JSON
                            },
                            parent: {
                                id: GraphQLTypes.ID,
                                account_id: GraphQLTypes.ID,
                                name: {
                                    display: GraphQLTypes.String
                                }
                            },
                            account_id: GraphQLTypes.ID,
                            created_by: {
                                id: GraphQLTypes.ID,
                                user_id: GraphQLTypes.ID,
                                name: {
                                    display: GraphQLTypes.String
                                }
                            },
                            created_at: GraphQLTypes.Date,
                            last_activity_by: {
                                id: GraphQLTypes.ID,
                                user_id: GraphQLTypes.ID,
                                name: {
                                    display: GraphQLTypes.String
                                }
                            },
                            last_activity_at: GraphQLTypes.Date,
                            last_viewed_by: {
                                id: GraphQLTypes.ID,
                                user_id: GraphQLTypes.ID,
                                name: {
                                    display: GraphQLTypes.String
                                }
                            },
                            last_viewed_at: GraphQLTypes.Date,
                            popup_alert: {
                                message: GraphQLTypes.String,
                                is_shown: GraphQLTypes.Boolean,
                                type: GraphQLTypes.String,
                                date: GraphQLTypes.Date
                            },
                            action_alert: {
                                message: GraphQLTypes.String,
                                is_shown: GraphQLTypes.Boolean,
                                type: GraphQLTypes.String,
                                date: GraphQLTypes.Date
                            },
                            start_date: GraphQLTypes.Date
                        }
                    }
                },
                metadata: {
                    sections: [
                        {
                            label: "Account Information",
                            id: 'account_info',
                            tab_order: 'left-right',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 6,
                                tablet: 4,
                                desktop: 2
                            },
                            fields: [
                                {
                                    label: "Description",
                                    identifier: "accounts.description",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Status",
                                    identifier: "accounts.status",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Priority",
                                    identifier: "accounts.priority",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Rating",
                                    identifier: "accounts.rating",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Source",
                                    identifier: "accounts.source",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Tags",
                                    identifier: "accounts.tags",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                            ],
                        },
                        {
                            label: "Contact & Address Information",
                            id: 'contact_address_info',
                            tab_order: 'left-right',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 6,
                                tablet: 4,
                                desktop: 2
                            },
                            fields: [
                                {
                                    label: "Primary Contact",
                                    identifier: "accounts.primary_contact",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Primary Property",
                                    identifier: "accounts.primary_property",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Phone",
                                    identifier: "accounts.phone",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Email",
                                    identifier: "accounts.email",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Website",
                                    identifier: "accounts.website",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Zone",
                                    identifier: "accounts.zone",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                            ],
                        },
                        {
                            label: "Billing & Shipping Information",
                            id: 'billing_and_shipping_info',
                            tab_order: 'top-bottom',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: false,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 6,
                                tablet: 4,
                                desktop: 2
                            },
                            fields: [
                                {
                                    label: "Billing Contact",
                                    identifier: "accounts.billing_contact",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Billing Property",
                                    identifier: "accounts.billing_property",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Is Taxable",
                                    identifier: "accounts.is_taxable",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Tax ID",
                                    identifier: "accounts.tax_id",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: false
                                },
                                {
                                    label: "Balance with Sub Accounts",
                                    identifier: "accounts.balance_with_sub_accounts",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Payment Status",
                                    identifier: "accounts.payment_status",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Shipping Contact",
                                    identifier: "accounts.shipping_contact",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Shipping Property",
                                    identifier: "accounts.shipping_property",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Delivery Method",
                                    identifier: "accounts.delivery_method",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Communication Method",
                                    identifier: "accounts.communication_method",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                            ],
                        },
                        {
                            label: "System Information",
                            id: 'system_info',
                            tab_order: 'left-right',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: false,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 6,
                                tablet: 4,
                                desktop: 2
                            },
                            fields: [
                                {
                                    label: "Parent Account",
                                    identifier: "accounts.parent",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Account ID",
                                    identifier: "accounts.account_id",
                                    is_required: false,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Created By",
                                    identifier: "accounts.created_by",
                                    is_required: false,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Created At",
                                    identifier: "accounts.created_at",
                                    is_required: false,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },

                                {
                                    label: "Last Activity By",
                                    identifier: "accounts.last_activity_by",
                                    is_required: false,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Last Activity At",
                                    identifier: "accounts.last_activity_at",
                                    is_required: false,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Last Viewed By",
                                    identifier: "accounts.last_viewed_by",
                                    is_required: false,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Last Viewed At",
                                    identifier: "accounts.last_viewed_at",
                                    is_required: false,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Popup Alert",
                                    identifier: "accounts.popup_alert",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Action Alert",
                                    identifier: "accounts.action_alert",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Start Date",
                                    identifier: "accounts.start_date",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "On Hold Start Date (TEST)",
                                    identifier: "accounts.on_hold_start_date",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                }
                            ],
                        },
                    ]
                }
            },
            component_3: {
                id: "component_3",
                label: 'Transactions List',
                object_identifier: 'accounts',
                connection_type: 'parent',
                type: ComponentTypes.TRANSACTIONS_LIST,
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {

                }
            },
            component_4: {
                id: "component_4",
                label: 'Related',
                object_identifier: 'accounts',
                connection_type: 'parent',
                type: ComponentTypes.RELATED,
                special_type: null,
                header_type: 'normal',
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {
                    related: [
                        // Contacts
                        {
                            label: 'Contacts',
                            object_identifier: 'contacts',
                            display_field: 'contacts.name',
                            show_records_container_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            show_record_cell_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            records_tabs_first_visible_amount: {
                                mobile: 2,
                                tablet: 2,
                                desktop: 4
                            },
                            record_cells_first_visible_amount: {
                                mobile: 2,
                                tablet: 2,
                                desktop: 4
                            },
                            filters: [
                                {
                                    fields: ["contacts.is_active"],
                                    field_types: ["checkbox"],
                                    operator: "equal",
                                    values: [
                                        {
                                            type: "value",
                                            label: 'True',
                                            input: true,
                                            data: true,
                                            data_type: 'boolean',
                                            status: 'valid'
                                        }
                                    ]
                                },
                                {
                                    fields: ["contacts.account", "accounts.id"],
                                    field_types: ["lookup_relationship", "id"],
                                    operator: "equal",
                                    values: [
                                        {
                                            type: "relationship",
                                            label: 'Parent Account',
                                            input: 'parent.id',
                                            data: null,
                                            data_type: 'relationship',
                                            status: 'valid'
                                        }
                                    ]
                                }
                            ],
                            sort_field: "contacts.last_updated_at",
                            sort_by: ["contacts.last_updated_at"],
                            sort_method: "chronological",
                            sort_direction: "DESC",
                            search_value: '',
                            fields: [
                                {
                                    label: 'Job Title',
                                    identifier: 'contacts.title',
                                    is_read_only: false,
                                    is_visible: true
                                },
                                {
                                    label: 'Email',
                                    identifier: 'contacts.email',
                                    is_read_only: false,
                                    is_visible: true
                                },
                                {
                                    label: 'Phone',
                                    identifier: 'contacts.phone',
                                    is_read_only: false,
                                    is_visible: true
                                }
                            ]
                        },
                        // Properties
                        {
                            label: 'Properties',
                            object_identifier: 'properties',
                            display_field: 'properties.address',
                            show_records_container_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            show_record_cell_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            records_tabs_first_visible_amount: {
                                mobile: 2,
                                tablet: 2,
                                desktop: 4
                            },
                            record_cells_first_visible_amount: {
                                mobile: 2,
                                tablet: 2,
                                desktop: 4
                            },
                            filters: [
                                {
                                    fields: ["properties.is_active"],
                                    field_types: ["checkbox"],
                                    operator: "equal",
                                    values: [
                                        {
                                            type: "value",
                                            label: 'True',
                                            input: true,
                                            data: true,
                                            data_type: 'boolean',
                                            status: 'valid'
                                        }
                                    ]
                                },
                                {
                                    fields: ["properties.account", "accounts.id"],
                                    field_types: ["lookup_relationship", "id"],
                                    operator: "equal",
                                    values: [
                                        {
                                            type: "relationship",
                                            label: 'Parent Account',
                                            input: 'parent.id',
                                            data: null,
                                            data_type: 'relationship',
                                            status: 'valid'
                                        }
                                    ]
                                }
                            ],
                            sort_field: "properties.last_updated_at",
                            sort_by: ["properties.last_updated_at"],
                            sort_method: "chronological",
                            sort_direction: "DESC",
                            sort_nulls_last: true,
                            sort_join: [],
                            search_value: '',
                            fields: [
                                {
                                    label: 'Location Type',
                                    identifier: 'properties.type',
                                    is_read_only: false,
                                    is_visible: true
                                },
                                {
                                    label: 'Condition',
                                    identifier: 'properties.condition',
                                    is_read_only: false,
                                    is_visible: true
                                },
                                {
                                    label: 'Zone',
                                    identifier: 'properties.zone',
                                    is_read_only: false,
                                    is_visible: true
                                }
                            ]
                        }
                    ]
                }
            },
            component_5: {
                id: "component_5",
                label: 'Activity',
                object_identifier: 'accounts',
                connection_type: 'parent',
                type: ComponentTypes.ACTIVITY,
                special_type: null,
                header_type: 'normal',
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                    activities: [
                        // Tasks
                        {
                            label: 'Tasks',
                            identifier: 'tasks_0',
                            object_identifier: 'tasks',
                            display_field: 'tasks.subject',
                            show_records_container_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            record_cells_first_visible_amount: {
                                mobile: 3,
                                tablet: 2,
                                desktop: 2
                            },
                            filters: [
                                {
                                    fields: ["tasks.is_active"],
                                    field_types: ["checkbox"],
                                    operator: "equal",
                                    values: [
                                        {
                                            type: "value",
                                            label: 'True',
                                            input: true,
                                            data: true,
                                            data_type: 'boolean',
                                            status: 'valid'
                                        }
                                    ]
                                }
                            ],
                            sort_field: "tasks.last_updated_at",
                            sort_by: ["tasks.last_updated_at"],
                            sort_method: "chronological",
                            sort_direction: "DESC",
                            sort_nulls_last: true,
                            sort_join: [],
                            search_value: '',
                            item_actions: [
                                "tasks.edit",
                                "tasks.mark_completed",
                                "tasks.mark_closed",
                                "tasks.convert_to_job",
                                "tasks.archive",
                                "tasks.delete"
                            ],
                            fields: [
                                {
                                    label: 'Subject',
                                    identifier: 'tasks.subject',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.subject"],
                                    display_format: null
                                },
                                {
                                    label: 'Description',
                                    identifier: 'tasks.description',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.description"],
                                    display_format: null
                                },
                                {
                                    label: 'Type',
                                    identifier: 'tasks.type',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.type"],
                                    display_format: null
                                },
                                {
                                    label: 'Status',
                                    identifier: 'tasks.status',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.status", "dropdowns.label"],
                                    display_format: null
                                },
                                {
                                    label: 'Start Date',
                                    identifier: 'tasks.start_date',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.start_date"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'Due Date',
                                    identifier: 'tasks.due_date',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.due_date"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'Assigned To',
                                    identifier: 'tasks.assigned_to',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.assigned_to", "users.name", "name.display"],
                                    display_format: null
                                },
                            ]
                        },
                        // Visits
                        {
                            label: 'Visits',
                            identifier: 'visits_2',
                            object_identifier: 'visits',
                            display_field: 'visits.subject',
                            show_records_container_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            record_cells_first_visible_amount: {
                                mobile: 3,
                                tablet: 2,
                                desktop: 2
                            },
                            filters: [
                                {
                                    fields: ["visits.is_active"],
                                    field_types: ["checkbox"],
                                    operator: "equal",
                                    values: [
                                        {
                                            type: "value",
                                            label: 'True',
                                            input: true,
                                            data: true,
                                            data_type: 'boolean',
                                            status: 'valid'
                                        }
                                    ]
                                }
                            ],
                            sort_field: "visits.last_updated_at",
                            sort_by: ["visits.last_updated_at"],
                            sort_method: "chronological",
                            sort_direction: "DESC",
                            sort_nulls_last: true,
                            sort_join: [],
                            search_value: '',
                            item_actions: [
                                "visits.edit",
                                "visits.mark_completed",
                                "visits.mark_closed",
                                "visits.convert_to_invoice",
                                "visits.archive",
                                "visits.delete"
                            ],
                            fields: [
                                {
                                    label: 'Subject',
                                    identifier: 'visits.subject',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.subject"],
                                    display_format: null
                                },
                                {
                                    label: 'Description',
                                    identifier: 'visits.description',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.description"],
                                    display_format: null
                                },
                                {
                                    label: 'Type',
                                    identifier: 'visits.type',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.type"],
                                    display_format: null
                                },
                                {
                                    label: 'Status',
                                    identifier: 'visits.status',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.status", "dropdowns.label"],
                                    display_format: null
                                },
                                {
                                    label: 'Start Date',
                                    identifier: 'visits.start_date',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.start_date"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'End Date',
                                    identifier: 'visits.end_date',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.end_date"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'Duration',
                                    identifier: 'visits.duration',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.duration"],
                                    display_format: null
                                },
                                {
                                    label: 'Assigned To',
                                    identifier: 'visits.assigned_to',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.assigned_to", "users.name", "name.display"],
                                    display_format: null
                                },
                                {
                                    label: 'Address',
                                    identifier: 'visits.address',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.address", "properties.address", "address.display"],
                                    display_format: null
                                },
                            ]
                        },
                        // Notes
                        {
                            label: 'Notes',
                            identifier: 'notes_3',
                            object_identifier: 'notes',
                            display_field: 'notes.title',
                            show_records_container_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            record_cells_first_visible_amount: {
                                mobile: 3,
                                tablet: 2,
                                desktop: 2
                            },
                            filters: [
                                {
                                    fields: ["notes.is_active"],
                                    field_types: ["checkbox"],
                                    operator: "equal",
                                    values: [
                                        {
                                            type: "value",
                                            label: 'True',
                                            input: true,
                                            data: true,
                                            data_type: 'boolean',
                                            status: 'valid'
                                        }
                                    ]
                                }
                            ],
                            sort_field: "notes.created_at",
                            sort_by: ["notes.created_at"],
                            sort_method: "chronological",
                            sort_direction: "DESC",
                            sort_nulls_last: true,
                            sort_join: [],
                            search_value: '',
                            item_actions: [],
                            fields: [
                                {
                                    label: 'Title',
                                    identifier: 'notes.title',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.title"],
                                    display_format: null
                                },
                                {
                                    label: 'Status',
                                    identifier: 'notes.status',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.status","dropdowns.label"],
                                    display_format: null
                                },
                                {
                                    label: 'Created At',
                                    identifier: 'notes.created_at',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.created_at"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'Created By',
                                    identifier: 'notes.created_by',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.created_by","users.name","name.display"],
                                    display_format: null
                                },
                                {
                                    label: 'Body',
                                    identifier: 'notes.body',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.body"],
                                    display_format: null
                                }
                            ]
                        }
                    ]
                }
            },
            component_6: {
                id: "component_6",
                label: 'Timeline',
                object_identifier: 'accounts',
                connection_type: 'parent',
                type: ComponentTypes.TIMELINE,
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                    notifcations: {
                        contacts: true,
                        properties: true,
                        tasks: true,
                        events: true,
                        notes: true,
                        attachments: true
                    }
                }
            },
            component_7: {
                id: "component_7",
                label: 'Account Schedule',
                object_identifier: null,
                connection_type: 'user',
                type: 'schedule',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {

                }
            },
        },
        sections: [
            {
                id: uniqid('section_'),
                anchor_name: 'header',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'seperate',
                columns: {
                    mobile: [{ id: '1', flexSize: 1, components: ["component_1"] }],
                    tablet: [{ id: '1', flexSize: 1, components: ["component_1"] }],
                    desktop: [{ id: '1', flexSize: 1, components: ["component_1"] }]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'details_transactions',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'tabs',
                columns: {
                    mobile: [{ id: '2', flexSize: 1, components: [["component_2", "component_3", "component_7"]] }],
                    tablet: [{ id: '2', flexSize: 1, components: [["component_2", "component_3", "component_7"]] }],
                    desktop: [{ id: '2', flexSize: 1, components: [["component_2", "component_3", "component_7"]] }]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'related_and_activities',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: null,
                template_identifier: null,
                shrink_method: 'tabs',
                columns: {
                    mobile: [{ id: '3', flexSize: 1, components: [["component_4", "component_5", "component_6"]] }],
                    tablet: [{ id: '3', flexSize: 2, components: ["component_4"] }, { id: '4', flexSize: 1, components: [["component_5", "component_6"]] }],
                    desktop: [{ id: '3', flexSize: 2, components: ["component_4"] }, { id: '4', flexSize: 1, components: [["component_5", "component_6"]] }]
                }
            }
        ],
        item_actions: [
            "contacts.new",
            "properties.new",
            "accounts.duplicate",
            "accounts.archive"
        ],
        more_actions: [
            "accounts.view_primary_contact",
            "accounts.view_primary_property",
            "accounts.call_phone",
            "accounts.send_email",
            "accounts.get_directions",
            "accounts.delete"
        ],
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        published_at: new Date(),
        published_by: null,

    }
];

export default AccountPageLayoutsData;