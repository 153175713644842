

// Screen Identifiers

// Starting Area
export const HOME = 'home';

// Schedule Area
export const SCHEDULE = 'schedule';
export const EVENTS_LIST_VIEW = 'events';
export const EVENT_PAGE_LAYOUT = 'event';
export const VISITS_LIST_VIEW = 'visits';
export const VISIT_PAGE_LAYOUT = 'visit';

// Work Area
export const WORK_OVERVIEW = 'work';
export const REQUESTS_LIST_VIEW = 'requests';
export const REQUEST_PAGE_LAYOUT = 'request';
export const TASKS_LIST_VIEW = 'tasks';
export const TASK_PAGE_LAYOUT = 'task';
export const JOBS_LIST_VIEW = 'jobs';
export const JOB_PAGE_LAYOUT = 'job';
export const ROUTES_LIST_VIEW = 'routes';
export const ROUTE_PAGE_LAYOUT = 'route';

// Clients Area
export const CLIENTS_OVERVIEW = 'clients';
export const ACCOUNTS_LIST_VIEW = 'accounts';
export const ACCOUNT_PAGE_LAYOUT = 'account';
export const PROPERTIES_LIST_VIEW = 'properties';
export const PROPERTY_PAGE_LAYOUT = 'property';
export const CONTACTS_LIST_VIEW = 'contacts';
export const CONTACT_PAGE_LAYOUT = 'contact';

// Billing Area
export const BILLING_OVERVIEW = 'billing';
export const ESTIMATES_LIST_VIEW = 'estimates';
export const ESTIMATE_PAGE_LAYOUT = 'estimate';
export const INVOICES_LIST_VIEW = 'invoices';
export const INVOICE_PAGE_LAYOUT = 'invoice';
export const SUBSCRIPTIONS_LIST_VIEW = 'subscriptions';
export const SUBSCRIPTION_PAGE_LAYOUT = 'subscription';
export const PAYMENTS_LIST_VIEW = 'payments';
export const PAYMENT_PAGE_LAYOUT = 'payment';
export const ITEMS_LIST_VIEW = 'items';
export const ITEM_PAGE_LAYOUT = 'item';

// Audit Area
export const AUDIT_OVERVIEW = 'audit';
export const LEDGERS_LIST_VIEW = 'ledgers';
export const LEDGER_PAGE_LAYOUT = 'ledger';
export const REPORTS_LIST_VIEW = 'reports';
export const REPORT_PAGE_LAYOUT = 'report';

// Team Area
export const TEAM_OVERVIEW = 'team';
export const USERS_LIST_VIEW = 'users';
export const USER_PAGE_LAYOUT = 'user';

// Security Area
export const SECURITY_OVERVIEW = 'security';
export const ROLES_LIST_VIEW = 'roles';
export const ROLE_PAGE_LAYOUT = 'role';
export const PERMISSION_SETS_LIST_VIEW = 'permission_sets';
export const PERMISSION_SET_PAGE_LAYOUT = 'permission_set';

// Settings
export const SETTINGS = 'settings';
export const USER_SETTINGS = 'user_settings';

export const SETTINGS_CUSTOMIZE_OBJECTS = 'settings_customize_objects';
export const SETTINGS_CUSTOMIZATIONS_MANAGER = 'settings_customizations_manager';

export const SETTINGS_COMPANY_SETTINGS = 'settings_company_settings';
export const SETTINGS_COMPANY_BRANDING = 'settings_company_branding';

export const SETTINGS_COMPANY_FINANCIALS = 'settings_company_financials';
export const SETTINGS_PROHELPER_OVERVIEW = 'settings_prohelper_overview';
export const SETTINGS_PROHELPER_SUBSCRIPTION = 'settings_prohelper_subscription';
export const SETTINGS_PROHELPER_PAYMENTS = 'settings_prohelper_payments';
export const SETTINGS_PROHELPER_PLANS = 'settings_prohelper_plans';

export const SETTINGS_DATA_IMPORT = 'settings_data_import';
export const SETTINGS_DATA_EXPORT = 'settings_data_export';
export const SETTINGS_DATA_ARCHIVE = 'settings_data_archive';
export const SETTINGS_MANAGE_INTEGRATIONS = 'settings_manage_integrations';