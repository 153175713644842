
// Import NPM Modules
import React, { useMemo } from 'react';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

//Import Custom Modules
import SnackbarAlert from '../../alert/snackbar/SnackbarAlert';
import Snackbar from './Styles';


const AppSnackbar = (props) => {

    return useMemo(() => (
        <Snackbar
            $isAuthFooterVisible={props.isAuthFooterVisible}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={props.open}
            autoHideDuration={4000}
            onClose={props.onClose}
            action={
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={props.onClose}
                >
                    <FontAwesomeIcon
                        icon={faTimes}
                        size="1x"
                        style={{
                            color: "#002D47",
                            margin: '25px auto'
                        }}
                    />
                </IconButton>
            }
        >
            <SnackbarAlert 
                elevation={6} 
                variant="filled" 
                onClose={props.onClose}
                severity={props.severity}
            >
                {props.label || ''}
            </SnackbarAlert>
        </Snackbar>

    // eslint-disable-next-line
    ), [props.label, props.severity, props.open]);
};

export default AppSnackbar;



