
// This constants file is a representation of how data for object types will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';


const RequestObjectTypesData = [

    // ----------------------------------------
    // Request Object Types
    // ----------------------------------------
    // Service
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "requests",
        sync_token: 1,
        label: "Service",
        identifier: "service",
        description: "A request for service work.",
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Repair
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "requests",
        sync_token: 1,
        label: "Repair",
        identifier: "repair",
        description: "A request for repair of an existing product or issue.",
        sort_order: 2,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Installation
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "requests",
        sync_token: 1,
        label: "Installation",
        identifier: "installation",
        description: "A request for install of an new product or equipment.",
        sort_order: 3,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Remodel
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "requests",
        sync_token: 1,
        label: "Remodel",
        identifier: "remodel",
        description: "A request for pool remodel.",
        sort_order: 4,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Pool Startup
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "requests",
        sync_token: 1,
        label: "Pool Startup",
        identifier: "pool_startup",
        description: "A request for pool startup.",
        sort_order: 5,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Inspection
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "requests",
        sync_token: 1,
        label: "Inspection",
        identifier: "inspection",
        description: "A request for pool inspection.",
        sort_order: 6,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "requests",
        sync_token: 1,
        label: "Other",
        identifier: "other",
        description: "An uncategorized request.",
        sort_order: 7,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
];

export default RequestObjectTypesData;