
// This constants file is a representation of how data for object types will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';


const ContactObjectTypesData = [

    // ----------------------------------------
    // Contact Object Types
    // ----------------------------------------

    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "contacts",
        sync_token: 1,
        label: "Person",
        identifier: "person",
        description: "A real person represented as a Contact.",
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "contacts",
        sync_token: 1,
        label: "Company",
        identifier: "company",
        description: "A company entity represented as a Contact.",
        sort_order: 2,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },

];

export default ContactObjectTypesData;