// Import NPM Modules
import _ from 'lodash';

// Import Custom Modules
import * as actionTypes from '../../action_types/data/ListViewDataActionTypes';

const STARTING_LIST_VIEW_DATA = {
    object_data: null,
    raw_data: [],
    sanitized_data: [],
    shown_data: []
};


const ListViewDataReducer = (state = STARTING_LIST_VIEW_DATA, action) => {

    switch (action.type) {
        
        case actionTypes.SET_OBJECT_LIST_VIEW_DATA:

            return {
                object_data: action.payload.object ? action.payload.object : state.object_data,
                raw_data: action.payload.raw_data ? action.payload.raw_data : state.raw_data,
                sanitized_data: action.payload.sanitized_data ? action.payload.sanitized_data : state.sanitized_data,
                shown_data: action.payload.shown_data ? action.payload.shown_data : state.shown_data
            }

        case actionTypes.SET_UPDATED_LIST_VIEW_ITEM_DATA:
            if (_.isEmpty(action.payload.object) || _.isEmpty(action.payload.item_data) ) { return state; }
            
            let new_raw_data = [];
            let new_sanitized_data = [];
            let new_shown_data = [];

            state[action.payload.object.identifier].raw_data.forEach((raw_item_data, index) => {
                new_raw_data.push((raw_item_data.id === action.payload.item_data.id) ? action.payload.item_data : raw_item_data )
            });

            state[action.payload.object.identifier].sanitized_data.forEach((sanitized_item_data, index) => {
                new_sanitized_data.push((sanitized_item_data.id === action.payload.item_data.id) ? action.payload.item_data : sanitized_item_data )
            });

            state[action.payload.object.identifier].shown_data.forEach((shown_item_data, index) => {
                new_shown_data.push((shown_item_data.id === action.payload.item_data.id) ? action.payload.item_data : shown_item_data )
            });

            return {
                ...state,
                raw_data: new_raw_data,
                sanitized_data: new_sanitized_data,
                shown_data: new_shown_data
            }

        case actionTypes.ARCHIVE_LIST_VIEW_ITEM_DATA:

            // Find archived_item index in raw_data & splice remove
            let archived_items_map = {};
            let new_raw_data_archived = [];
            let new_sanitized_data_archived = [];
            let new_shown_data_archived = [];

            for (let i = 0; i < action.payload.archived_items.length; i++) {
                const current_archived_item = action.payload.archived_items[i];
                archived_items_map[current_archived_item.id] = current_archived_item;
            }

            for (let i = 0; i < state[action.payload.object.identifier].raw_data.length; i++) {
                const current_item_data = state[action.payload.object.identifier].raw_data[i];
                if (_.isEmpty(archived_items_map[current_item_data.id])) {
                    new_raw_data_archived.push(current_item_data);
                }
            }

            for (let i = 0; i < state[action.payload.object.identifier].sanitized_data.length; i++) {
                const current_item_data = state[action.payload.object.identifier].sanitized_data[i];
                if (_.isEmpty(archived_items_map[current_item_data.id])) {
                    new_sanitized_data_archived.push(current_item_data);
                }
            }

            for (let i = 0; i < state[action.payload.object.identifier].shown_data.length; i++) {
                const current_item_data = state[action.payload.object.identifier].shown_data[i];
                if (_.isEmpty(archived_items_map[current_item_data.id])) {
                    new_shown_data_archived.push(current_item_data);
                }
            }

            return {
                ...state,
                raw_data: new_raw_data_archived,
                sanitized_data: new_sanitized_data_archived,
                shown_data: new_shown_data_archived
            }

        default:
            return state;
    }
};


export default ListViewDataReducer;
