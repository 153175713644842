import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'; 
import PreAuthLayout from '../pre_auth/PreAuthLayout';
import AuthLayout from '../auth/AuthLayout';



class LayoutContainer extends Component {

    render() {
        return (
            <Fragment>
                {!this.props.login_status ?
                    <PreAuthLayout> {this.props.children} </PreAuthLayout>
                    :
                    <AuthLayout> {this.props.children} </AuthLayout>
                }
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        login_status: state.program.authentication.login_status
    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutContainer);