// This constats file is a representation of how data for object fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as ActionTypes from '../../types/ActionTypes';
import * as ActionUseTypes from '../../types/ActionUseTypes';

const SubscriptionActionsData = [

    // Item Actions
    //
    // New Subscription
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "New Subscription",
        short_label: "New",
        identifier: "new",
        icon: "money-bill-transfer",
        sort_order: 1,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.NEW,
        description: "Create a new Subscription.",
        help_text: "Create a new Subscription.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Edit Subscription
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Edit Subscription",
        short_label: "Edit",
        identifier: "edit",
        icon: "pen-to-square",
        sort_order: 2,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.EDIT,
        description: "Edit the Fields for a Subscription.",
        help_text: "Edit the Fields for this Subscription.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Edit Payment Method
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Edit Payment Method",
        short_label: "Edit Payment Method",
        identifier: "edit_payment_method",
        icon: "credit-card",
        sort_order: 3,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.EDIT_PAYMENT_METHOD,
        description: "Edit the payment method and payment source for a Subscription.",
        help_text: "Edit the payment method and payment source for this Subscription.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Edit Line Items
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Edit Line Items",
        short_label: "Edit Line Items",
        identifier: "edit_line_items",
        icon: "list-ol",
        sort_order: 4,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.EDIT_LINE_ITEMS,
        description: "Edit the line items for a Subscription.",
        help_text: "Edit the line items for this Subscription.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Edit Recurrence Schedule
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Edit Recurrence Schedule",
        short_label: "Edit Recurrence",
        identifier: "edit_line_items",
        icon: "clock",
        sort_order: 5,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.EDIT_RECURRENCE,
        description: "Edit the recurrence schedule for a Subscription.",
        help_text: "Edit the recurrence schedule for this Subscription.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Duplicate Subscription
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Duplicate Subscription",
        short_label: "Duplicate",
        identifier: "duplicate",
        icon: "copy",
        sort_order: 6,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.DUPLICATE,
        description: "Create a new Subscription record by copying information from an existing Subscription record.",
        help_text: "Create a new Subscription record by copying information from an existing Subscription record.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Archive Subscription
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Archive Subscription",
        short_label: "Archive",
        identifier: "archive",
        icon: "box-archive",
        sort_order: 7,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.ARCHIVE,
        description: "Archive a subscription to be hidden from normal subscriptions in the program.",
        help_text: "Archive a subscription, hidden but not deleted.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Delete Subscription
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Delete Subscription",
        short_label: "Delete",
        identifier: "delete",
        icon: "trash",
        sort_order: 8,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.DELETE,
        description: "Permantently deletes an Subscription. Not retrievable by users after this point.",
        help_text: "Permantently delete this Subscription. Not retrievable after this point.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },


    //
    // Batch Actions
    //

    // Batch Edit
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Batch Edit",
        short_label: "Batch Edit",
        identifier: "batch_edit",
        icon: "pen-to-square",
        sort_order: 1,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_EDIT,
        description: "Edit the Fields for multiple selected Subscriptions at once.",
        help_text: "Edit the Fields for multiple selected Subscriptions at once.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Batch Archive
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Batch Archive",
        short_label: "Batch Archive",
        identifier: "batch_archive",
        icon: 'box-archive',
        sort_order: 6,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_ARCHIVE,
        description: "Archive multiple selected Subscriptions to be hidden from normal subscriptions in the program.",
        help_text: "Archive multiple selected Subscriptions to be hidden from normal subscriptions in the program.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },

];


export default SubscriptionActionsData;