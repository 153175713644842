
// Import NPM Modules
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import Amplify, { Auth } from 'aws-amplify';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createHttpLink } from '@apollo/client/link/http';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from "@fortawesome/pro-light-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";

// Import Custom Modules
import App from './app';
import Reducer from './redux/reducers/Reducer';
import * as serviceWorker from './serviceWorker';

// Import Global CSS
import './index.css';


// Setup AWS Amplify
Amplify.configure({
    Auth: {
        identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
        region: process.env.REACT_APP_AWS_REGION,
        identityPoolRegion: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID
    }
});

// Add font-awesome icons to the library
library.add(fal, far, fas, fad);

// Setup ProHelper Redux Store for cross screen data storage
const store = createStore(Reducer);

// Create Apollo HTTP Link and SetContext with Cognito Credentials
// const httpLink = createHttpLink({
//     uri: process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT,
//     fetchOptions: {
//         mode: 'no-cors'
//     }
// });

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT,
});

// Special authorization connection between AWS Cognito and GraphQL
const authLink = setContext(async (_, { headers }) => {
    // Get Amplify auth token, if any
    const token = await Auth.currentSession();
    // Add authorization token to header
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token.getIdToken().getJwtToken()}` : null,
        }
    }
});

// Create Apollo Client for the Apollo Provider
const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

const container = document.getElementById('root');

const root = createRoot(container); 

// Setup Redux Provider and Apollo Provider
root.render(
    <Provider store={store}>
        <ApolloProvider client={client}>
            <App apolloClient={client} />
        </ApolloProvider>
    </Provider>
);

// (no idea what this does)
serviceWorker.unregister();
