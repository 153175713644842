
// This constants file is a representation of how data for object types will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';


const LineItemObjectTypesData = [

    // ----------------------------------------
    // Item Object Types
    // ----------------------------------------

    // Income
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "line_items",
        sync_token: 1,
        label: "Income",
        identifier: "income",
        description: "A sellable line item that is an income type.",
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "line_items",
        sync_token: 1,
        label: "Expense",
        identifier: "expense",
        description: "A cost for a line item that is an expense.",
        sort_order: 2,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    }
];

export default LineItemObjectTypesData;