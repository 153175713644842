// Import NPM Modules
import _ from 'lodash';

// Import Custom Modules
import * as actionTypes from '../../action_types/data/FakeDBDataActionTypes';

const INITAL_STATE = {
    sync_token : 1
}

const FakeDBDataReducer = (state = INITAL_STATE, action) => {

    switch (action.type) {

        case actionTypes.SET_FAKE_DB_STATE:
            return action.payload.fake_db ? action.payload.fake_db : state;
        
        case actionTypes.SET_ALL_OBJECT_RECORDS_DATA:

            // Create a copy of the current redux state
            let newFakeDBObject = { ...state };

            // Convert the action.payload.records, { [full_record_identifier] : record_data }, into nested records by object
            if (_.isObject(action.payload.records)) {

                for (let full_record_identifier in action.payload.records) {
                    const record_data = action.payload.records[full_record_identifier];
                    const [ object_identifier, public_record_id ] = full_record_identifier.split('.');

                    if (_.isEmpty(newFakeDBObject[object_identifier])) {
                        newFakeDBObject[object_identifier] = { };
                    }

                    newFakeDBObject[object_identifier][public_record_id] = record_data;
                }

                newFakeDBObject.sync_token = newFakeDBObject.sync_token + 1;
            }

            return newFakeDBObject;

        case actionTypes.UPDATE_OBJECT_RECORDS_DATA:
            // Input validation
            if (_.isEmpty(action.payload)) { return state; }
            if (_.isEmpty(action.payload.customizations)) { return state; }
            if (_.isEmpty(action.payload.updated_records_mapping)) { return state; }

            // Create a copy of the current redux state
            let updatedFakeDBObject = { ...state };
            let updated_data_arrays_map = {};
            let updated_records_array = Object.keys(action.payload.updated_records_mapping);

            // Loop through object identifier's records and check if any match payload data
            updated_records_array.forEach((updated_record_full_id) => {
                const [object_identifier, object_record_identifier] = updated_record_full_id.split('.');
                const updated_record_data = action.payload.updated_records_mapping[updated_record_full_id];

                if (_.isEmpty(updated_data_arrays_map[object_identifier])) {
                    updated_data_arrays_map[object_identifier] = [];
                }
                updated_data_arrays_map[object_identifier].push(updated_record_data);
            });

            if (!_.isEmpty(updated_data_arrays_map)) {
                const updated_data_arrays_object_identifiers_array = Object.keys(updated_data_arrays_map);

                updated_data_arrays_object_identifiers_array.forEach((object_identifier) => {
                    const current_object_data = action.payload.customizations.objects[object_identifier];
                    const updated_object_records_array = updated_data_arrays_map[object_identifier];
                    
                    let isFoundMapping = {};
                    let isFoundMappingData = {};
    
                    let newFakeObjectRecordsArr = updatedFakeDBObject[object_identifier].map((object_record) => {
                        return updated_object_records_array.find((updated_record) => { 
                            if (!_.isBoolean(isFoundMapping[updated_record[current_object_data.public_id_field]])) {
                                isFoundMapping[updated_record[current_object_data.public_id_field]] = false;
                                isFoundMappingData[updated_record[current_object_data.public_id_field]] = updated_record;
                            }
                            
                            if (object_record[current_object_data.public_id_field] === updated_record[current_object_data.public_id_field]) {
                                isFoundMapping[updated_record[current_object_data.public_id_field]] = true; 
                                delete isFoundMappingData[updated_record[current_object_data.public_id_field]];
                            } 
                            return object_record[current_object_data.public_id_field] === updated_record[current_object_data.public_id_field];
                        }) || object_record;
                    });

                    for (let updated_record_public_id in isFoundMapping) {
                        const isUpdatedRecordFound = isFoundMapping[updated_record_public_id];

                        if (!isUpdatedRecordFound) {
                            const updatedRecord = isFoundMappingData[updated_record_public_id];
                            newFakeObjectRecordsArr.push(updatedRecord);
                        }
                    }

                    updatedFakeDBObject[object_identifier] = newFakeObjectRecordsArr;
                })

                updatedFakeDBObject.sync_token = updatedFakeDBObject.sync_token + 1;
            }
            
            return updatedFakeDBObject;

        
        default:
            return state;
    }
};


export default FakeDBDataReducer;
