
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';

// Import Custom Modules
import * as SortingMethods from '../../sorting/SortingMethods';
import * as SortingDirectionIdentifiers from '../../sorting/SortDataTypeIdentifiers';


const TaskListViewsData = [
    // All Tasks
    {
        object: null,
        object_identifier: 'tasks',
        public_id: uniqid('view_'),
        sync_token: 1,
        type: "list_view",
        identifier: identifier('All Tasks').toLowerCase(),
        name: 'All Tasks',
        description: 'All tasks with the company.',
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        sort_field: "tasks.status",
        sql_sort_field: ["tasks.status", "dropdowns.sort_order"],
        sort_direction: SortingDirectionIdentifiers.ASCENDING,
        sort_join: ["tasks.status", "dropdowns.id"],
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        filter_by_viewer: false,
        filters: [ ],
        search_value: "",
        visible_fields: [
            {
                label: 'Subject',
                identifier: 'tasks.subject',
                display_fields: ["tasks.subject"],
                display_format: null,
                width: 220,
                is_locked: true
            },
            {
                label: 'Due Date',
                identifier: 'tasks.due_date',
                display_fields: ["tasks.due_date"],
                display_format: null,
                width: 140,
                is_locked: false
            },
            {
                label: 'Description',
                identifier: 'tasks.description',
                display_fields: ['tasks.description'],
                display_format: null,
                width: 340,
                is_locked: false
            },
            {
                label: 'Status',
                identifier: 'tasks.status',
                display_fields: ['tasks.status', 'dropdown.label'],
                display_format: null,
                width: 140,
                is_locked: false
            },
            {
                label: 'Priority',
                identifier: 'tasks.priority',
                display_fields: ['tasks.priority', 'dropdown.label'],
                display_format: null,
                width: 140,
                is_locked: false
            },
            {
                label: 'Created At',
                identifier: 'tasks.created_at',
                display_fields: ['tasks.created_at'],
                display_format: null,
                width: 220,
                is_locked: false
            },
        ],
        hidden_fields: [
            "tasks.id",
            "tasks.type",
            "tasks.task_id",
            "tasks.type",
            "tasks.sync_token",
            "tasks.company",
            "tasks.suggested_action",
            "tasks.time_zone",
            "tasks.language"
        ],
        batch_actions: [
            "tasks.batch_edit",
            "tasks.batch_archive"
        ],
        item_actions: [
            "tasks.edit",
            "tasks.mark_completed",
            "tasks.mark_closed",
            "tasks.convert_to_estimate",
            "tasks.convert_to_job",
            "tasks.convert_to_invoice",
            "tasks.archive"
        ],
        more_actions: [ 
            "tasks.duplicate",
            "tasks.delete"
        ],
        pagination_amount: 25
    },
    
];

export default TaskListViewsData;