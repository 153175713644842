

// This constants file is a representation of how data for object fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as FieldTypes from '../../types/FieldTypes';
import * as DatabaseTypes from '../../types/DatabaseTypes';

const InvoiceDropdownSetsData = [
    // Invoice - Statuses
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Invoice Statuses",
        identifier: 'invoices.statuses',
        dropdown_plural_label: 'Statuses',
        dropdown_singular_label: "Status",
        description: "A set of all the different statuses for a Invoice.",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: false,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Draft
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "invoices",
                sync_token: 1,
                label: "Draft",
                identifier: "draft",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "draft"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Open
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "invoices",
                sync_token: 1,
                label: "Open",
                identifier: "open",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "open"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Paid
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "invoices",
                sync_token: 1,
                label: "Paid",
                identifier: "paid",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "paid"
                },
                color: null,
                sort_order: 3,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Uncollectable
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "invoices",
                sync_token: 1,
                label: "Uncollectable",
                identifier: "uncollectable",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "uncollectable"
                },
                color: null,
                sort_order: 4,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Void
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "invoices",
                sync_token: 1,
                label: "Void",
                identifier: "void",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "void"
                },
                color: null,
                sort_order: 5,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            }
        ]
    },
    // Invoices - Tags
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Invoice Tags",
        identifier: 'invoices.tags',
        description: "A set of all the different tags for a Invoice.",
        dropdown_plural_label: 'Tags',
        dropdown_singular_label: "Tag",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Important
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "invoices",
                sync_token: 1,
                label: "Important",
                identifier: "important",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "important"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            }
        ]
    },
    // Invoices - Send Methods
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Send Methods",
        identifier: 'invoices.send_methods',
        description: "A set of all the different ways to send an Invoice to a customer.",
        dropdown_plural_label: 'Send Methods',
        dropdown_singular_label: "Send Method",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Email
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "invoices",
                sync_token: 1,
                label: "Email",
                identifier: "mail",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "mail"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Mail
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "invoices",
                sync_token: 1,
                label: "Mail",
                identifier: "mail",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "mail"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            }
        ]
    },
    // Invoices - Billing Reasons
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Billing Reasons",
        identifier: 'invoices.billing_reasons',
        description: "A set of all the different reasons for billing an Invoice to a customer.",
        dropdown_plural_label: 'Billing Reasons',
        dropdown_singular_label: "Billing Reason",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Subscription
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "invoices",
                sync_token: 1,
                label: "Subscription",
                identifier: "subscription",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "subscription"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Manual
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "invoices",
                sync_token: 1,
                label: "Manual",
                identifier: "manual",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "manual"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Outstanding Balance
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "invoices",
                sync_token: 1,
                label: "Outstanding Balance",
                identifier: "outstanding_balance",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "outstanding_balance"
                },
                color: null,
                sort_order: 3,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Deposit
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "invoices",
                sync_token: 1,
                label: "Deposit",
                identifier: "deposit",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "deposit"
                },
                color: null,
                sort_order: 4,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    }
]

export default InvoiceDropdownSetsData;