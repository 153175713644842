
// Import NPM Modules
import _ from 'lodash';

// Import Custom Modules
import * as actionTypes from '../../action_types/program/AppActionTypes';

// Inital structure of this reducer
const initialState =
{
    snackbar: {
        open: false,
        message: '',
        severity: ''
    },
    fab: {
        show: false,
        type: 'multi',
        actions: []
    }
};

/**
 * Redux Reducer storage for whole app level items like the alert snackbar.
 */
const AppReducer = (state = initialState, action) => {

    switch (action.type) {
        // Able to set/update snackbar data if it should be shown
        case actionTypes.SET_SNACKBAR:
            return !_.isEmpty(action.snackbar) ? {
                ...state,
                snackbar: {
                    open: action.snackbar.open,
                    message: action.snackbar.message,
                    severity: action.snackbar.severity,
                }
            } : state;

        case actionTypes.SET_FAB:
            return !_.isEmpty(action.fab) ? {
                ...state,
                fab: {
                    show: action.fab.show,
                    type: action.fab.type,
                    actions: action.fab.actions,
                }
            } : state;
        default:
            return state;
    }
};


export default AppReducer;
