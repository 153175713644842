

// This constants file is a representation of how data for object fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as FieldTypes from '../../types/FieldTypes';
import * as DatabaseTypes from '../../types/DatabaseTypes';

const PaymentDropdownSetsData = [
    // Payment - Statuses
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'payments',
        sync_token: 1,
        label: "Payment Statuses",
        identifier: 'payments.statuses',
        dropdown_plural_label: 'Statuses',
        dropdown_singular_label: "Status",
        description: "A set of all the different statuses for a Payment.",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: false,
        is_editable_inline: false,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Requires Payment Source
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "payments",
                sync_token: 1,
                label: "Requires Payment Source",
                identifier: "requires_payment_source",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "requires_payment_source"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Requires Confirmation
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "payments",
                sync_token: 1,
                label: "Requires Confimration",
                identifier: "requires_confirmation",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "requires_confirmation"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Requires Action
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "payments",
                sync_token: 1,
                label: "Requires Action",
                identifier: "requires_action",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "requires_action"
                },
                color: null,
                sort_order: 3,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Processing
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "payments",
                sync_token: 1,
                label: "Processing",
                identifier: "processing",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "processing"
                },
                color: null,
                sort_order: 4,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Requires Capture
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "payments",
                sync_token: 1,
                label: "Requires Capture",
                identifier: "requires_capture",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "requires_capture"
                },
                color: null,
                sort_order: 5,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Canceled
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "payments",
                sync_token: 1,
                label: "Canceled",
                identifier: "canceled",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "canceled"
                },
                color: null,
                sort_order: 6,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Succeeded
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "payments",
                sync_token: 1,
                label: "Succeeded",
                identifier: "succeeded",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "succeeded"
                },
                color: null,
                sort_order: 7,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    },
    // Payments - Capture Methods
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'payments',
        sync_token: 1,
        label: "Payment Capture Methods",
        identifier: 'payments.capture_methods',
        description: "A set of all the different capture methods for a Payment.",
        dropdown_plural_label: 'Capture Methods',
        dropdown_singular_label: "Capture Method",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: false,
        is_editable_inline: false,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Automatic
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "payments",
                sync_token: 1,
                label: "Automatic",
                identifier: "automatic",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "automatic"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Automatic Async
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "payments",
                sync_token: 1,
                label: "Automatic Async",
                identifier: "automatic_async",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "automatic_async"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Manual
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "payments",
                sync_token: 1,
                label: "Manual",
                identifier: "manual",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "manual"
                },
                color: null,
                sort_order: 3,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            }
        ]
    },
    // Payments - Confirmation Methods
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'payments',
        sync_token: 1,
        label: "Payment Confirmation Methods",
        identifier: 'payments.confirmation_methods',
        description: "A set of all the different confirmation methods for a Payment.",
        dropdown_plural_label: 'Confirmation Methods',
        dropdown_singular_label: "Confirmation Method",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: false,
        is_editable_inline: false,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Automatic
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "payments",
                sync_token: 1,
                label: "Automatic",
                identifier: "automatic",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "automatic"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Manual
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "payments",
                sync_token: 1,
                label: "Manual",
                identifier: "manual",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "manual"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            }
        ]
    },
    // Payments - Setup Future Usages
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'payments',
        sync_token: 1,
        label: "Payment Setup Future Usages",
        identifier: 'payments.setup_future_usages',
        description: "A set of all the different options for setting up future usage for a Payment.",
        dropdown_plural_label: 'Setup Future Usages',
        dropdown_singular_label: "Setup Future Usage",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: false,
        is_editable_inline: false,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // On Session
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "payments",
                sync_token: 1,
                label: "On Session",
                identifier: "on_session",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "on_session"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Off Session
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "payments",
                sync_token: 1,
                label: "Off Session",
                identifier: "off_session",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "off_session"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            }
        ]
    },
    // Payments - Cancellation Reasons
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'payments',
        sync_token: 1,
        label: "Payment Cancellation Reasons",
        identifier: 'payments.cancellation_reasons',
        description: "A set of all the different reasons for canceling a Payment.",
        dropdown_plural_label: 'Cancellation Reasons',
        dropdown_singular_label: "Cancellation Reason",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: false,
        is_editable_inline: false,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Duplicate
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "payments",
                sync_token: 1,
                label: "Duplicate",
                identifier: "duplicate",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "duplicate"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Fraudulent
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "payments",
                sync_token: 1,
                label: "Fraudulent",
                identifier: "fraudulent",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "fraudulent"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Requested By Customer
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "payments",
                sync_token: 1,
                label: "Requested By Customer",
                identifier: "requested_by_customer",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "requested_by_customer"
                },
                color: null,
                sort_order: 3,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Abandoned
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "payments",
                sync_token: 1,
                label: "Abandoned",
                identifier: "abandoned",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "abandoned"
                },
                color: null,
                sort_order: 4,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Failed Invoice
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "payments",
                sync_token: 1,
                label: "Failed Invoice",
                identifier: "failed_invoice",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "failed_invoice"
                },
                color: null,
                sort_order: 5,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Automatic
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "payments",
                sync_token: 1,
                label: "Automatic",
                identifier: "automatic",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "automatic"
                },
                color: null,
                sort_order: 6,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    },
]

export default PaymentDropdownSetsData;