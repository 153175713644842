// This constants file is a representation of how data for contact fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as FieldTypes from '../../types/FieldTypes';
import * as DatabaseTypes from '../../types/DatabaseTypes';
import * as SpecialTypes from '../../types/SpecialTypes';
import * as FilterOperatorTypeIdenifiers from '../../../constants/filtering/operators/FilterOperatorTypeIdentifiers';
import * as LookupRelationshipTypes from '../../types/LookupRelationshipTypes';
import * as SortingMethods from '../../sorting/SortingMethods';


const ContactFieldsData = [
    // ID
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Database ID",
        identifier: "id",
        field_type: "id",
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The database id of this Contact.",
        help_text: "The ID used for the ProHelper database.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Company
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Company",
        identifier: "company",
        field_type: "lookup_relationship",
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The parent company for this Contact.",
        help_text: "The parent company for this Contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Name
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Name",
        identifier: "name",
        field_type: "name",
        data_type: DatabaseTypes.JSONB,
        special_type: SpecialTypes.MAIN_LABEL,
        description: "Used for display internally around the ProHelper platform.",
        help_text: "The display name for a Contact which is used around the platform.",
        default_value: null,
        placeholder_text: "Enter a name...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 1,
        is_required: true,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 1,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Phone Number
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Phone",
        identifier: "phone",
        field_type: "phone",
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The primary phone number to be used for a contact.",
        help_text: "The primary phone number to be used for a contact.",
        default_value: null,
        placeholder_text: "Enter a phone number...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 2,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 1,
        is_sortable: true,
        sort_order: 1,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: false,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Email Address
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Email",
        identifier: "email",
        field_type: "email",
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The primary email address for the contact.",
        help_text: "The primary email address for the contact.",
        default_value: null,
        placeholder_text: "Enter an email address...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 3,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 2,
        is_sortable: true,
        sort_order: 3,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: false,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Primary Property
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Primary Property",
        identifier: "primary_property",
        field_type: "lookup_relationship",
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "References the primary property/address for this Contact; the main place of residence or business associated with the Person.",
        help_text: "The primary property/place associated with this Property.",
        default_value: null,
        placeholder_text: "Enter a Primary Property...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 4,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 3,
        is_sortable: true,
        sort_order: 4,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: {
            type: LookupRelationshipTypes.INDEPENDENT,
            object_identifier: "properties",
            filters: [
                {
                    fields: ["properties.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
            ],
            display_field: "address",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Address
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Address",
        identifier: "address",
        field_type: "address",
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The address is the main location associated with this contact.",
        help_text: "The main address used for a contact.",
        default_value: null,
        placeholder_text: "Enter an address...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 5,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 4,
        is_sortable: true,
        sort_order: 5,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: false,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },  
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Account
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Account",
        identifier: "account",
        field_type: "lookup_relationship",
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The account to which a contact is linked to. A contact can belong to only one account.",
        help_text: "The account that a contact is related to.",
        default_value: null,
        placeholder_text: "Enter a parent account...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 6,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 5,
        is_sortable: true,
        sort_order: 6,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: {
            type: LookupRelationshipTypes.INDEPENDENT,
            object_identifier: "accounts",
            filters: [
                {
                    fields: ["accounts.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Contact ID
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Contact ID",
        identifier: "contact_id",
        field_type: "public_id",
        data_type: "text",
        special_type: "public_id",
        description: "The unique ID for a Contact shown to publically to users. Prefix is 'cont_'",
        help_text: "The unique ID for a Contact shown to publically to users.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 7,
        is_required: true,
        is_editable: false,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Description
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Description",
        identifier: "description",
        field_type: "long_text",
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "Detailed information about a contact.",
        help_text: "Detailed information about a contact.",
        default_value: null,
        placeholder_text: "Enter a description...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 8,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 7,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: false,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Type
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Type",
        identifier: "type",
        field_type: "type_dropdown",
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the object type of the Contact.",
        help_text: "A custom type to categorize a Contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 9,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 7,
        is_sortable: true,
        sort_order: 8,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Stage
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Stage",
        identifier: "stage",
        field_type: "custom_dropdown",
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the current lifecycle stage of this contact.",
        help_text: "A custom lifecycle stage for a contact in it's lifecycle.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 10,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 8,
        is_sortable: true,
        sort_order: 9,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "contacts.stages",
            object_identifier: "contacts",
            type: "custom_dropdown"
        },
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Priority
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Priority",
        identifier: "priority",
        field_type: "custom_dropdown",
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the priority, or urgency, for this contact. Defaults to Account priority",
        help_text: "A ranking of importantance/urgency of an contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 11,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 9,
        is_sortable: true,
        sort_order: 10,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "global.priorities",
            object_identifier: "global",
            type: "custom_dropdown"
        },
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Rating
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Rating",
        identifier: "rating",
        field_type: "custom_dropdown",
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents a subjective rating, or value, for a contact.",
        help_text: "A subjective rating for a contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 12,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 10,
        is_sortable: true,
        sort_order: 11,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "global.ratings",
            object_identifier: "global",
            type: "custom_dropdown"
        },
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Source
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Source",
        identifier: "source",
        field_type: "custom_dropdown",
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents where the contact first originated from.",
        help_text: "The source, or origin, of a contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 13,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 11,
        is_sortable: true,
        sort_order: 12,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "global.sources",
            object_identifier: "global",
            type: "custom_dropdown"
        },
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Tags
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Tags",
        identifier: "tags",
        field_type: "text_dropdown",
        data_type: DatabaseTypes.TEXT_ARRAY,
        special_type: null,
        description: "A list of contact tags linked to this contact. Is a custom multi-select dropdown for contact tags.",
        help_text: "Identifier labels for extra information about a contact.",
        default_value: null,
        placeholder_text: "Enter some tags for this contact...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 14,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: "A",
        is_filterable: true,
        filter_order: 12,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: true,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: false,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "contacts.tags",
            object_identifier: "contacts",
            type: "text_dropdown"
        },
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Contact Image
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Contact Image",
        identifier: "image",
        field_type: "image",
        data_type: "jsonb",
        special_type: null,
        description: "The link to the profile image for this contact.",
        help_text: "The profile image link for this contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 15,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: false,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Owner
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Owner",
        identifier: "owner",
        field_type: "lookup_relationship",
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "References the user responsible for maintaing the information for the Contact.",
        help_text: "The user responsible for maintaining the information of this Contact.",
        default_value: null,
        placeholder_text: "Enter a user responsible...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 16,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 13,
        is_sortable: true,
        sort_order: 13,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: {
            type: LookupRelationshipTypes.INDEPENDENT,
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
                {
                    fields: ["users.is_employee"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false 

        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Secondary Phone Number
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Secondary Phone",
        identifier: "secondary_phone",
        field_type: "phone",
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The secondary phone number to be used for the Contact.",
        help_text: "The secondary phone number to be used for this Contact.",
        default_value: null,
        placeholder_text: "Enter a phone number...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 17,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: "B",
        is_filterable: true,
        filter_order: 14,
        is_sortable: true,
        sort_order: 14,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: false,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 2
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Secondary Email Address
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Secondary Email",
        identifier: "secondary_email",
        field_type: "email",
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The secondary email address for the contact.",
        help_text: "The secondary email address for the contact.",
        default_value: null,
        placeholder_text: "Enter an email address...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 18,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: "B",
        is_filterable: true,
        filter_order: 15,
        is_sortable: true,
        sort_order: 15,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: false,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 2
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Birthday
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Birthday",
        identifier: "birthday",
        field_type: FieldTypes.DATE,
        data_type: DatabaseTypes.DATE,
        special_type: null,
        description: "The contact's birthday.",
        help_text: "The contact's birthday.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 18,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 16,
        is_sortable: true,
        sort_order: 16,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: false,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 2
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Website
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Website",
        identifier: "website",
        field_type: FieldTypes.URL,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "A website url link associated with this contact.",
        help_text: "A linked website for an contact.",
        default_value: null,
        placeholder_text: "Enter a website...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 20,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: "A",
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 17,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 2
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Assistant
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Assistant",
        identifier: "assistant",
        field_type: "lookup_relationship",
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "A reference to an optional contact record for this person's assistant.",
        help_text: "A contact's assistant.",
        default_value: null,
        placeholder_text: "Select a contact...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 21,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: null,
        is_sortable: true,
        sort_order: 18,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: false,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 2
        },
        lookup_relationship_options: {
            type: LookupRelationshipTypes.INDEPENDENT,
            object_identifier: "contacts",
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true,
            filters: [
                {
                    fields: ["contacts.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                }
            ],
        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Communication Method
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Communication Method",
        identifier: "communication_method",
        field_type: "text_dropdown",
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The preferred method to communicate with the Contact.",
        help_text: "The preferred method to communicate with this Contact.",
        default_value: null,
        placeholder_text: "Select a communication method...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 22,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 17,
        is_sortable: true,
        sort_order: 19,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 2
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "global.communication_methods",
            object_identifier: "global",
            type: "text_dropdown"
        },
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Language
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Language",
        identifier: "language",
        field_type: "text_dropdown",
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The language the Contact/Person speaks.",
        help_text: "The language this Person speaks.",
        default_value: { data_type: 'text', data: 'en' },
        placeholder_text: "Select a language...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 23,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 18,
        is_sortable: true,
        sort_order: 20,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 2
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.languages",
            object_identifier: "static",
            type: "text_dropdown"
        },
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Time Zone
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Time Zone",
        identifier: "time_zone",
        field_type: "text_dropdown",
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The timezone that the Contact is in.",
        help_text: "What time zone this Contact is based in.",
        default_value: { data_type: 'text', data: 'pst' },
        placeholder_text: "Select a time zone...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 24,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 19,
        is_sortable: true,
        sort_order: 21,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 2
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.time_zones",
            object_identifier: "static",
            type: "text_dropdown"
        },
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Action Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Action Alert",
        identifier: "action_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Shows an important action related alert message as a banner or dialog when viewing the Contact record in detail.",
        help_text: "An alert message detailing some action that needs to be performed on this Contact.",
        default_value: null,
        placeholder_text: "Enter an action alert message...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 25,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: "A",
        is_filterable: true,
        filter_order: 20,
        is_sortable: true,
        sort_order: 22,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 2
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Popup Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Popup Alert",
        identifier: "popup_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Represents some important notification to show to users when they view the contact detail screen.",
        help_text: "An important message to show when a user views a contact.",
        default_value: null,
        placeholder_text: "Enter notification ...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 26,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: "A",
        is_filterable: true,
        filter_order: 21,
        is_sortable: true,
        sort_order: 23,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: false,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 2
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Do Not Call
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Do Not Call",
        identifier: "do_not_call",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, no team member should call the listed phone numbers for this contact.",
        help_text: "Whether a contact wants to be contacted by phone.",
        default_value: { data_type: DatabaseTypes.BOOLEAN, data: false },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 27,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 22,
        is_sortable: true,
        sort_order: 24,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Checks",
            identifier: "contact_checks",
            order: 3
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Do Not Call", false: "Callable" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Do Not Email
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Do Not Email",
        identifier: "do_not_email",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, no team member or system process should email the listed emails for this contact.",
        help_text: "Whether a contact wants to receive email.",
        default_value: { data_type: DatabaseTypes.BOOLEAN, data: false },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 28,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 23,
        is_sortable: true,
        sort_order: 25,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Checks",
            identifier: "contact_checks",
            order: 3
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Do Not Email", false: "Emailable" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Do Not Mail
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Do Not Mail",
        identifier: "do_not_mail",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, no team member should mail to the listed addresses for this contact.",
        help_text: "Whether a contact wants to receive mail.",
        default_value: { data_type: DatabaseTypes.BOOLEAN, data: false },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 28,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 23,
        is_sortable: true,
        sort_order: 25,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: false,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Checks",
            identifier: "contact_checks",
            order: 3
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Do Not Mail", false: "Mailable" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Is Active
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Is Active",
        identifier: "is_active",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if the contact is currently engaged with your company in some aspect (vendor relationship, customer relationship). Contacts can be set to automatically go inactive with no activitiy on the contact after a set duration. Pre-set to 12 months.",
        help_text: "A contact that is actively engaged with your company.",
        default_value: { data_type: "BOOLEAN", data: true },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 30,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 25,
        is_sortable: true,
        sort_order: 27,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Checks",
            identifier: "contact_checks",
            order: 3
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Active", false: "Not Active" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Is Person
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Is Person",
        identifier: "is_person",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, the account is a person contact and uses the full name instead of the company name for the display name.",
        help_text: "Is this Contact a person? Will use full name as the main name.",
        default_value: { data_type: DatabaseTypes.BOOLEAN, data: false },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 31,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 26,
        is_sortable: true,
        sort_order: 28,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: false,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Checks",
            identifier: "contact_checks",
            order: 3
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Person", false: "Company" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Is Archived
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Is Archived",
        identifier: "is_archived",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if the Contact is archived, hidden but not deleted. Admin Users can still access, view, and restore archived Contacts.",
        help_text: "Represents if this Contact is archived, in the Trash but not deleted yet.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_editable_inline: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Archived", false: "Not Archived" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Is Deleted
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Is Deleted",
        identifier: "is_deleted",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if this Contact is permanently deleted. Erased from system in 180 days.",
        help_text: "Represents if a contact is permanently deleted.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_editable_inline: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Deleted", false: "Not Deleted" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Is Live
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Is Live",
        identifier: "is_live",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if the Contact is live for regular use if true, or testing only.",
        help_text: "Represents if this Contact is live data for regular use, or for testing only.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_editable_inline: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Live Data", false: "Test Data" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Is Draft
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Is Draft",
        identifier: "is_draft",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if the Contact is a draft, saved before publishing publically.",
        help_text: "Represents if the Contact is a draft, saved before publishing publically.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Draft", false: "Public" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Is Template
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Is Template",
        identifier: "is_template",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if the Contact is a template. Save some pre-filled data for similar accounts in the future.",
        help_text: "Save an account's data as a template to be pre-filled in for similar accounts later.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Template", false: "Normal" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Created At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Created At",
        identifier: "created_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the timestamp the Contact was created. System generated timestamp.",
        help_text: "The timestamp when this Contact was created.",
        default_value: { data_type: "TIMESTAMPTZ", data: new Date() },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 32,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 27,
        is_sortable: true,
        sort_order: 29,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 4
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Created By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Created By",
        identifier: "created_by",
        field_type: "lookup_relationship",
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the user who created the Contact. System generated.",
        help_text: "The user who created this Contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 33,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 27,
        is_sortable: true,
        sort_order: 29,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 4
        },
        lookup_relationship_options: {
            type: LookupRelationshipTypes.INDEPENDENT,
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Last Updated At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Last Updated At",
        identifier: "last_updated_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the timestamp the Contact was last updated. System generated timestamp.",
        help_text: "The timestamp when this Contact was last updated.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 34,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 29,
        is_sortable: true,
        sort_order: 31,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 4
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Last Updated By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Last Updated By",
        identifier: "last_updated_by",
        field_type: "lookup_relationship",
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the user who last updated this contact. System generated.",
        help_text: "The user who last updated this contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 35,
        is_required: true,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 30,
        is_sortable: true,
        sort_order: 32,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 4
        },
        lookup_relationship_options: {
            type: LookupRelationshipTypes.INDEPENDENT,
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Last Activity At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Last Activity At",
        identifier: "last_activity_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represent the last timestamp when a related item to a contact was created, updated, or deleted.",
        help_text: "The timestamp when the last activtiy was on a contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 36,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 31,
        is_sortable: true,
        sort_order: 33,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 4
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Last Activity By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Last Activity By",
        identifier: "last_activity_by",
        field_type: "lookup_relationship",
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the last User who created, updated, or deleted a related item to a Contact. ",
        help_text: "The User who last completed some activity for this Contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 47,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 50,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 4
        },
        lookup_relationship_options: {
            type: LookupRelationshipTypes.INDEPENDENT,
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Last Viewed At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Last Viewed At",
        identifier: "last_viewed_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the time when a User last viewed the Contact's detail page.",
        help_text: "The time when a User last viewed this Contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 38,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 33,
        is_sortable: true,
        sort_order: 35,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 4
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Last Viewed By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Last Viewed By",
        identifier: "last_viewed_by",
        field_type: "lookup_relationship",
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the user who last viewed this Contact's detail page.",
        help_text: "The user who last viewed a Contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 39,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 34,
        is_sortable: true,
        sort_order: 36,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 4
        },
        lookup_relationship_options: {
            type: LookupRelationshipTypes.INDEPENDENT,
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Archived Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Archived Alert",
        identifier: "archived_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Shows an alert message why this Contact is archived. Shown as a banner or dialog when viewing the Contact record in detail in the Trash.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Archived At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Archived At",
        identifier: "archived_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the time a contact was archieved. System generated timestamp.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Archived By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Archived By",
        identifier: "archived_by",
        field_type: "lookup_relationship",
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the User who archived this Contact. System generated.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_editable_inline: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: {
            type: LookupRelationshipTypes.INDEPENDENT,
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Suggested Item Action
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Suggested Item Action",
        identifier: "suggested_action",
        field_type: "lookup_relationship",
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The first shown item action for the Contact.",
        help_text: "The first shown item action for this User.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: {
            type: LookupRelationshipTypes.INDEPENDENT,
            object_identifier: "actions",
            filters: [
                {
                    fields: ["actions.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
                {
                    fields: ["actions.object_identifier"],
                    field_types: ["id"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'Contacts',
                            input: "contacts",
                            data: "contacts",
                            data_type: 'text',
                            status: 'valid'
                        }
                    ]
                }
            ],
            display_field: "label",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false 
        },
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Sync Token
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Sync Token",
        identifier: "sync_token",
        field_type: FieldTypes.HIDDEN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the current sync token of this Contact. If a user tries an operation on a Contact with an invalid sync token, the operation will fail. (i.e. Offline-Conflicting Issue)",
        help_text: "",
        default_value: { data_type: 'integer', data: 1 },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Metadata
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Metadata",
        identifier: "metadata",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Some extra metadata that can be added to the Contact.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Settings
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Settings",
        identifier: "settings",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Represents some settings for the Contact.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Custom Fields
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Custom Fields",
        identifier: "custom_fields",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Custom fields for the Contact.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
];

export default ContactFieldsData;