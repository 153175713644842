import { styled } from '@mui/material/styles';

const AuthHeaderPrimaryContainer = styled('div')`
    background-color: ${props => props.theme.palette.app.primary};
    color: ${props => props.theme.palette.app.primaryContrastText};
    border-bottom: 1px solid ${props => props.theme.palette.primary.dark};
    box-shadow: ${props => props.theme.boxShadows.lg};
    height: ${props => props.theme.variables.headerMobileHeight}px;
    box-sizing: border-box;
    margin: 0px;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    z-index: ${props => props.theme.zIndex.appBar};

    /* Hiding CSS */
    transition-timing-function: ease-in;
    transition:0.5s;
    ${props => props.$isShown ? 'top: 0px;' : `top: -${props.theme.variables.headerMobileHeight}px;`}
`;

const AuthHeaderSecondaryContainer = styled('div')`
    background-color: ${props => props.theme.palette.background.secondary};
    color: ${props => props.theme.palette.text.primary};
    border-bottom: 1px solid ${props => props.theme.palette.primary.dark};
    box-shadow: ${props => props.theme.boxShadows.lg};
    top: ${props => props.theme.variables.headerMobileHeight}px;
    height: ${props => props.theme.variables.headerMobileSecondaryHeight}px;
    box-sizing: border-box;
    margin: 0px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    z-index: ${props => props.theme.zIndex.appBar};

    /* Hiding CSS */
    transition-timing-function: ease-in;
    transition:0.5s;
    ${props => props.$isShown ? `
        top: ${props.theme.variables.headerMobileHeight}px;
        z-index: ${props.theme.zIndex.appBar};
    ` : 
        `top: -${props.theme.variables.headerMobileSecondaryHeight}px;
        z-index: 0;
    `}
`;


const AuthHeaderTitle = styled('h1')`
    color: ${props => props.theme.palette.app.primaryContrastText};
    font-family: ${props => props.theme.typography.fontFamily};
    font-size: ${props => props.theme.typography.h6.fontSize};
    font-weight:  ${props => props.theme.typography.fontWeightSemiBold};
    line-height: normal;
    margin: auto 0px;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
    text-align: left;

    // Desktop 
    @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
        font-size: 1.375rem;
        font-weight: 700;
    }
`;

const AuthHeaderLeftArea = styled('div')`
    text-align: left;
    margin: auto 0px;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
`;

const AuthHeaderMiddleArea = styled('div')`
    display: flex;
    flex: 1;
    justify-content: left;
    align-items: left;
    text-align: left;
    margin: auto 4px;
    max-width: calc(100% - 100px);
`;

const AuthHeaderRightArea = styled('div')`
    text-align: right;
    margin: auto 0px;
    display: flex;
    justify-content: flex-end;  
`;


const AuthHeaderMobileButton = styled('button')`
    border: none;
    padding: 6px;
    background: transparent;
    color: ${props => props.theme.palette.app.primaryContrastText};

    ${props => {
        if (props.$left) {
            return `
                margin: auto 0px auto 9px;
            `;
        } else if (props.$right) {
            return `
                margin: auto 9px auto 0px;
            `;
        } 
    }}
`;



export { 
    AuthHeaderPrimaryContainer,
    AuthHeaderSecondaryContainer,
    AuthHeaderTitle,
    AuthHeaderLeftArea,
    AuthHeaderMiddleArea,
    AuthHeaderRightArea,
    AuthHeaderMobileButton
};