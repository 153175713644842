import { styled } from '@mui/material/styles';

const AuthRoot = styled('div')`
    margin: 0;
    width: 100%;
    height: -webkit-fill-available;
    display: flex;
`;

const AuthBody = styled('div')`
    margin: 0;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    padding-top: ${props => props.theme.variables.headerMobileHeight}px;
    padding-bottom: ${props => props.theme.variables.headerMobileHeight}px;

    @media (min-width: 800px) {
        padding-top: ${props => props.theme.variables.headerDesktopHeight}px;
        padding-bottom: 0px;
    }
`;

const AuthMain = styled('div')`

    display: flex;
    flex-direction: column;

    ${props => {
        // Desktop
        if (props.$display_type === 'desktop') {
            return `
                width: calc(100% - ${props.$isOpen ? props.theme.variables.sidebarFullWidth : props.theme.variables.sidebarMediumWidth}px);
                margin-left: ${props.$isOpen ? props.theme.variables.sidebarFullWidth : props.theme.variables.sidebarMediumWidth}px;
            `;
        }
        if (props.$display_type === 'tablet' || props.$display_type === 'mobile') {
            return `
                width: 100%;
                margin: 0px;
            `;
        }
    }}

`;


export { 
    AuthRoot,
    AuthBody,
    AuthMain
};