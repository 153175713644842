
const PlatformPermissionsData = [
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'company_access',
        label: 'Company Access',
        description: 'Allows the user to create an account and login to the ProHelper company web application.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    }
];

export default PlatformPermissionsData;