
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';
import * as ROUTES from '../../../../navigation/routes';
import * as ScreenTypes from '../../../../types/ScreenTypes';
import * as ScreenIdentifiers from '../../../../screens/ScreenIdentifiers';


const TeamPageLayoutsData = [
    {
        public_id: uniqid('page_'),
        sync_token: 1,
        identifier: identifier('Default').toLowerCase(),
        label: 'Default',
        description: 'The default view of the Team screen.',
        status: 'published',
        revisions: 1,
        route: ROUTES.TEAM,
        type: ScreenTypes.APP_PAGE_LAYOUT,
        screen_identifier: ScreenIdentifiers.TEAM_OVERVIEW,
        template: null,
        object: null,
        object_identifier: null,
        object_type: null,
        group: {
            label: 'Team',
            identifier: 'team',
            order: 6
        },
        sub_group: {
            label: 'Team Overview',
            identifier: 'team_overview',
            order: 1
        },
        components: {
            component_1: {
                id: "component_1",
                label: 'Recommended Actions',
                object_identifier: null,
                connection_type: 'user',
                type: 'recommended_actions',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {

                }
            },
            component_2: {
                id: "component_2",
                label: 'Active Users',
                object_identifier: null,
                connection_type: 'none',
                type: 'view',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {

                }
            },
            component_3: {
                id: "component_3",
                label: 'Schedule',
                object_identifier: null,
                connection_type: 'user',
                type: 'schedule',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {

                }
            },
            component_4: {
                id: "component_4",
                label: 'Employee Map',
                object_identifier: null,
                connection_type: 'none',
                type: 'map',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {

                }
            }
        },
        sections: [
            {
                id: uniqid('section_'),
                anchor_name: 'recommended_actions_list_view',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'tabs',
                columns: {
                    mobile: [{ id: '1', flexSize: 1, components: [["component_1", "component_2"]] }],
                    tablet: [{ id: '1', flexSize: 1, components: ["component_1"] }, { id: '2', flexSize: 1, components: ["component_2"] }],
                    desktop: [{ id: '1', flexSize: 1, components: ["component_1"] }, { id: '2', flexSize: 1, components: ["component_2"] }]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'schedule',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'seperate',
                columns: {
                    mobile: [{ id: '3', flexSize: 1, components: ["component_3", "component_4"] }],
                    tablet: [{ id: '3', flexSize: 1, components: ["component_3"] }, { id: '4', flexSize: 1, components: ["component_4"] }],
                    desktop: [{ id: '3', flexSize: 1, components: ["component_3"] }, { id: '4', flexSize: 1, components: ["component_4"] }]
                }
            },
        ],
        item_actions: [
            "users.new"
        ],
        more_actions: [ ],
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        published_at: new Date(),
        published_by: null,
        
    },
];

export default TeamPageLayoutsData;