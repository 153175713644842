
const AdminPermissionsData = [
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'edit_company_information',
        label: 'Edit Company Information',
        description: "Allows the admin user to view and edit the settings for the company’s information.",
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'edit_company_branding',
        label: 'Edit Company Branding',
        description: "Allows the admin user to view and edit the settings for public facing information visible to customers and the public.",
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'edit_financial_information',
        label: 'Edit Financial Information',
        description: "Allows the admin user to view and edit the settings for the company’s financial information.",
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'primary_company_contact',
        label: 'Primary Company Contact',
        description: "Allows the admin user to view and manage the primary user / owner and contact information. This user has full control and can delete the company.",
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'customizations_manager',
        label: 'Customizations Manager',
        description: "Allows the admin user to view and manage all the Object & Global Customizations in the company.",
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'customize_objects',
        label: 'Customize Objects',
        description: "Allows the admin user to view and edit the information for main objects for the company program.",
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'customize_list_views',
        label: 'Customize List Views',
        description: 'Allows the admin user to view and manage all the List Views in the company.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'customize_page_layouts',
        label: 'Customize Page Layouts',
        description: 'Allows the admin user to view and manage all the Page Layouts in the company.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'customize_search_views',
        label: 'Customize Search Views',
        description: 'Allows the admin user to view and manage all the search views in the company.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'manage_prohelper_subscription',
        label: 'Manage ProHelper Subscription',
        description: "Allows the admin user to view and edit all the prohelper subscription related information. (subscription, payments, payment methods, plans, and more)",
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'manage_internal_users',
        label: 'Manage Internal Users',
        description: 'Allows the admin user to view and manage internal users for the program. Users can have a role and permission sets attached to them.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'manage_external_users',
        label: 'Manage External Users',
        description: 'Allows the admin user to view and manage internal users for the program. Users can have a role and permission sets attached to them.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'manage_roles_and_permission_sets',
        label: 'Manage Roles & Permission Sets',
        description: 'Allows the admin user to view, edit, delete, and assign roles and permission sets for the program. Roles have role specific permissions sets that give users access to the ProHelper platform. Permission Sets are a group of permissions for the ProHelper program that can be attached to Roles or Users.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'manage_integrations',
        label: 'Manage Integrations',
        description: 'Allows the admin user to view and manage all integrations to the company, even if individual integration permissions are not selected.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'view_all_data',
        label: 'View All Data',
        description: "Allows the admin user to view all objects and data in the ProHelper platform, even if the object and object types aren’t shared with them.",
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'modify_all_data',
        label: 'Modify All Data',
        description: "View and manage all archived object records. Able to permentantly delete records or restore them.",
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'data_importing_and_exporting',
        label: 'Data Importing & Exporting',
        description: 'Allows the admin user to mass import and export data for any object they have access to.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'customizations_importing_and_exporting',
        label: 'Customizations Importing & Exporting',
        description: 'Allows the admin user to import and export customizations data for any object they have access to.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'work_settings',
        label: 'Work Settings',
        description: 'Allows the admin user to view and manage work specific preferences for the program.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'schedule_settings',
        label: 'Schedule Settings',
        description: 'Allows the admin user to view and manage calendar and planner specific preferences for the program.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'customer_settings',
        label: 'Customer Settings',
        description: 'Allows the admin user to view and manage account, contact, and property specific preferences for the program.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'billing_settings',
        label: 'Billing Settings',
        description: 'Allows the admin user to view and manage estimate, invoice, payment, and item specific preferences for the program.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'team_settings',
        label: 'Team Settings',
        description: 'Allows the admin user to view and manage user specific preferences for the program.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'performance_settings',
        label: 'Performance Settings',
        description: 'Allows the admin user to view and manage reporting and time sheet specific preferences for the program.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'communication_settings',
        label: 'Communication Settings',
        description: 'Allows the admin user to view and manage email and other communication preferences for the program.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
        dependent_permissions: null
    },

];

export default AdminPermissionsData;