

// This constants file is a representation of how data for object fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as FieldTypes from '../../types/FieldTypes';
import * as DatabaseTypes from '../../types/DatabaseTypes';

const LedgerDropdownSetsData = [
    // Ledgers - Categories
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Categories",
        identifier: 'ledgers.categories',
        description: "A set of all the different main categories for Ledger types and sub-types.",
        dropdown_plural_label: 'Categories',
        dropdown_singular_label: "Category",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Asset
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "ledgers",
                sync_token: 1,
                label: "Asset",
                identifier: "asset",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "asset"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Equity
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "ledgers",
                sync_token: 1,
                label: "Equity",
                identifier: "equity",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "equity"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Expense
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "ledgers",
                sync_token: 1,
                label: "Expense",
                identifier: "expense",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "expense"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Liability
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "ledgers",
                sync_token: 1,
                label: "Liability",
                identifier: "liability",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "liability"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Revenue
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "ledgers",
                sync_token: 1,
                label: "Revenue",
                identifier: "revenue",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "revenue"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    },
    // Ledgers - Billing Reasons
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Billing Reasons",
        identifier: 'ledgers.billing_reasons',
        description: "A set of all the different reasons for billing an Ledger to a customer.",
        dropdown_plural_label: 'Billing Reasons',
        dropdown_singular_label: "Billing Reason",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Subscription
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "ledgers",
                sync_token: 1,
                label: "Subscription",
                identifier: "subscription",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "subscription"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Manual
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "ledgers",
                sync_token: 1,
                label: "Manual",
                identifier: "manual",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "manual"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Outstanding Balance
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "ledgers",
                sync_token: 1,
                label: "Outstanding Balance",
                identifier: "outstanding_balance",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "outstanding_balance"
                },
                color: null,
                sort_order: 3,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Deposit
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "ledgers",
                sync_token: 1,
                label: "Deposit",
                identifier: "deposit",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "deposit"
                },
                color: null,
                sort_order: 4,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    }
]

export default LedgerDropdownSetsData;