

// This constants file is a representation of how data for object fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as FieldTypes from '../../types/FieldTypes';
import * as DatabaseTypes from '../../types/DatabaseTypes';

const ReportDropdownSetsData = [
    // Reports - Categories
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'reports',
        sync_token: 1,
        label: "Categories",
        identifier: 'reports.categories',
        description: "A set of all the different main categories for Report types and sub-types.",
        dropdown_plural_label: 'Categories',
        dropdown_singular_label: "Category",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Admin
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "reports",
                sync_token: 1,
                label: "Admin",
                identifier: "admin",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "admin"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Schedule
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "reports",
                sync_token: 1,
                label: "Schedule",
                identifier: "schedule",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "schedule"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Work
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "reports",
                sync_token: 1,
                label: "Work",
                identifier: "work",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "work"
                },
                color: null,
                sort_order: 3,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Clients
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "reports",
                sync_token: 1,
                label: "Clients",
                identifier: "clients",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "clients"
                },
                color: null,
                sort_order: 4,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Billing
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "reports",
                sync_token: 1,
                label: "Billing",
                identifier: "billing",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "billing"
                },
                color: null,
                sort_order: 5,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Audit
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "reports",
                sync_token: 1,
                label: "Audit",
                identifier: "audit",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "audit"
                },
                color: null,
                sort_order: 6,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Team
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "reports",
                sync_token: 1,
                label: "Team",
                identifier: "team",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "team"
                },
                color: null,
                sort_order: 7,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Security
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "reports",
                sync_token: 1,
                label: "Security",
                identifier: "security",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "security"
                },
                color: null,
                sort_order: 8,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // File & Content
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "reports",
                sync_token: 1,
                label: "File & Content",
                identifier: "file_content",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "file_content"
                },
                color: null,
                sort_order: 9,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Campaigns
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "reports",
                sync_token: 1,
                label: "Campaigns",
                identifier: "campaigns",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "campaigns"
                },
                color: null,
                sort_order: 10,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // General
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "reports",
                sync_token: 1,
                label: "General",
                identifier: "general",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "general"
                },
                color: null,
                sort_order: 11,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Personal
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "reports",
                sync_token: 1,
                label: "Personal",
                identifier: "personal",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "personal"
                },
                color: null,
                sort_order: 12,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Other
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "reports",
                sync_token: 1,
                label: "Other",
                identifier: "other",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "other"
                },
                color: null,
                sort_order: 13,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    }
]

export default ReportDropdownSetsData;