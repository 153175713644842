
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';

// Import Custom Modules
import * as SortingMethods from '../../sorting/SortingMethods';
import * as SortingDirectionIdentifiers from '../../sorting/SortDataTypeIdentifiers';


const UserListViewsData = [
    // All Users
    {
        object: null,
        object_identifier: 'users',
        public_id: uniqid('view_'),
        sync_token: 1,
        type: "list_view",
        identifier: identifier('All Users').toLowerCase(),
        name: 'All Users',
        description: 'All users with the company.',
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        sort_field: "users.name",
        sql_sort_field: ["users.name", "name.display"],
        sort_direction: SortingDirectionIdentifiers.ASCENDING,
        sort_join: null,
        sort_method: SortingMethods.ALPHANUMERIC,
        filter_by_viewer: false,
        filters: [ ],
        search_value: "",
        visible_fields: [
            {
                label: 'Name',
                identifier: 'users.name',
                display_fields: ['users.name', 'name.display'],
                display_format: null,
                width: 180,
                is_locked: true
            },
            {
                label: 'Type',
                identifier: 'users.type',
                display_fields: ['users.type', 'object_types.label'],
                display_format: null,
                width: 140,
                is_locked: false
            },
            {
                label: 'Role',
                identifier: 'users.role',
                display_fields: ['users.role', 'roles.label'],
                display_format: null,
                width: 180,
                is_locked: false
            },
            {
                label: 'Online Status',
                identifier: 'users.online_status',
                display_fields: ['users.online_status', 'dropdown.label'],
                display_format: null,
                width: 140,
                is_locked: true
            },
            {
                label: 'Phone',
                identifier: 'users.phone',
                display_fields: ['users.phone', 'dropdown.label'],
                display_format: null,
                width: 160,
                is_locked: false
            },
            {
                label: 'Email',
                identifier: 'users.email',
                display_fields: ['users.email', 'email.address'],
                display_format: null,
                width: 200,
                is_locked: false
            },
            {
                label: 'Department',
                identifier: 'users.department',
                display_fields: ['users.department', 'dropdowns.label'],
                display_format: null,
                width: 160,
                is_locked: false
            },
            {
                label: 'Manager',
                identifier: 'users.manager',
                display_fields: ['users.manager', 'users.name', 'name.display'],
                display_format: null,
                width: 160,
                is_locked: false
            },
            {
                label: 'Start Date',
                identifier: 'users.start_date',
                display_fields: ['users.start_date'],
                display_format: 'MM/DD/YYYY',
                width: 160,
                is_locked: false
            },
        ],
        hidden_fields: [
            "users.id",
            "users.user_id",
            "users.type",
            "users.sync_token",
            "users.time_zone",
            "users.company",
            "users.language",
            "users.suggested_action"
        ],
        batch_actions: [
            "users.batch_edit",
            "users.batch_archive"
        ],
        item_actions: [
            "users.send_email",
            "users.archive"
        ],
        more_actions: [
            "users.delete"
        ],
        pagination_amount: 25
    },
    
];

export default UserListViewsData;