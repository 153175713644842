
// Import NPM Modules
import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';


// Import Custom Modules
import * as LayoutActionTypes from '../../../../redux/action_types/ui/LayoutActionTypes';
import LayoutHelper from '../../../../helpers/ui/LayoutHelper';


//  External const lazy loaded
const AuthHeaderDesktop = React.lazy(() => import('./desktop/AuthHeaderDesktop'));
const AuthHeaderMobile = React.lazy(() => import('./mobile/AuthHeaderMobile'));
const layoutHelper = new LayoutHelper();


const AuthHeader = (props) => {
    const auth = useSelector(state => state.program.authentication.auth);
    const customizations = useSelector(state => state.customizations);
    const current_screen = useSelector(state => state.ui.layout.current_screen);
    const location = useLocation();
    const dispatch = useDispatch();

    /**
     * Handle router location update and update layout reducer with the current screen.
     */
    useEffect(() => {
        // Get updated router location pathname
        const pathname = location.pathname;
        // Figure out current screen from pathname
        const currentScreen = layoutHelper.GetCurrentScreen(pathname, customizations);
        // Update the layout redux reducer with the new current screen information
        dispatch({ type: LayoutActionTypes.SET_CURRENT_SCREEN, currentScreen: currentScreen });
        // Only update when the props.location is updated
    }, [location, customizations, dispatch]);


    // Destructure and get the ProHelper auth object from a reducer
    // Switch between the header types for the correct one to render
    switch (props.display_type) {

        case ('desktop'):
        case ('tablet'):
            // Used for tablet and desktop viewports
            return (
                <Suspense fallback={<div />} >
                    <AuthHeaderDesktop
                        auth={auth}
                        permissions={props.permissions}
                        handleSignOut={() => props.handleSignOut()}
                        onMenuToggle={() => props.onMenuToggle()}
                    />
                </Suspense>
            );
        case ('mobile'):
            // Used for mobile viewports
            return (
                <Suspense fallback={<div />} >
                    <AuthHeaderMobile
                        auth={auth}
                        permissions={props.permissions}
                        isTopBarVisible={props.isTopBarVisible}
                        current_screen={current_screen}
                    />
                </Suspense>
            );
        default:
            return null;
    }

}

export default AuthHeader;

