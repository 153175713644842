
const UserPermissionsData = [
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'edit_personal_information',
        label: 'Edit Personal Information',
        description: 'Allows the user to edit their own personal information in the user settings screen.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'perform_batch_actions',
        label: 'Perform Batch Actions',
        description: 'Allows the user to view and use batch actions.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'change_type',
        label: 'Change Type',
        description: 'Allows the user to change the type of an object record.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'transfer_owner',
        label: 'Transfer Owner',
        description: 'Allows the user to change the user that owns an object record.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'set_popups',
        label: 'Set Popups',
        description: 'Allows the user to change and set popups for an action required or a general popup.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'send_emails',
        label: 'Send Emails',
        description: 'Allows the user to send emails to customers from the ProHelper platform.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
    },

    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'batch_email',
        label: 'Batch Email',
        description: 'Allows the user to send mass emails to multiple people at once from the ProHelper platform.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'view_encrypted_data',
        label: 'View Encrypted Data',
        description: 'Allows the user to view encrypted fields and their values.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'view_sensitive_data',
        label: 'View Sensitive Data',
        description: 'Allows the user to view sensitive fields and their data.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'manage_archived_data',
        label: 'Manage Archived Data',
        description: 'Allows the user to view archived data for objects they have access to, also able to convert archived data back to normal.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
    },
    {
        created_version: 0.5,
        current_version: 0.5,
        identifier: 'manage_test_data',
        label: 'Manage Test Data',
        description: 'Allows the user to view and use test data around the program. Able to enter a playground that does not affect real data.',
        group: {
            label: "Platform",
            identifier: 'platform',
            order: 1
        },
    },
];

export default UserPermissionsData;