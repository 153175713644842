
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';
import * as ROUTES from '../../../../navigation/routes';
import * as ScreenTypes from '../../../../types/ScreenTypes';
import * as ScreenIdentifiers from '../../../../screens/ScreenIdentifiers';


const ClientsPageLayoutsData = [
    {
        public_id: uniqid('page_'),
        sync_token: 1,
        identifier: identifier('Default').toLowerCase(),
        label: 'Default',
        description: 'The default view of the Clients screen.',
        status: 'published',
        revisions: 1,
        route: ROUTES.CLIENTS,
        type: ScreenTypes.APP_PAGE_LAYOUT,
        screen_identifier: ScreenIdentifiers.CLIENTS_OVERVIEW,
        template: null,
        object: null,
        object_identifier: null,
        object_type: null,
        group: {
            label: 'Clients',
            identifier: 'clients',
            order: 4
        },
        sub_group: {
            label: 'Clients Overview',
            identifier: 'clients_overview',
            order: 1
        },
        components: {
            component_1: {
                id: "component_1",
                label: 'Customer Summary',
                object_identifier: null,
                connection_type: 'none',
                type: 'report',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {

                }
            },
            component_2: {
                id: "component_2",
                label: 'Customer Balance Summary',
                object_identifier: null,
                connection_type: 'user',
                type: 'report',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {

                }
            },
            component_3: {
                id: "component_3",
                label: 'Map',
                object_identifier: null,
                connection_type: 'user',
                type: 'map',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {

                }
            },
            component_4: {
                id: "component_4",
                label: 'Accounts',
                object_identifier: null,
                connection_type: 'user',
                type: 'view',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {
                    object: 'accounts'
                }
            },
            component_5: {
                id: "component_5",
                label: 'Contacts',
                object_identifier: null,
                connection_type: 'user',
                type: 'view',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {
                    object: 'contacts'
                }
            },
            component_6: {
                id: "component_6",
                label: 'Properties',
                object_identifier: null,
                connection_type: 'user',
                type: 'view',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {
                    object: 'properties'
                }
            },
        },
        sections: [
            {
                id: uniqid('section_'),
                anchor_name: 'reports',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'tabs',
                columns: {
                    mobile: [{ id: '1', flexSize: 1, components: [["component_1", "component_2"]] }],
                    tablet: [{ id: '1', flexSize: 1, components: ["component_1"] }, { id: '2', flexSize: 1, components: ["component_2"] }],
                    desktop: [{ id: '1', flexSize: 1, components: ["component_1"] }, { id: '2', flexSize: 1, components: ["component_2"] }]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'map',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'seperate',
                columns: {
                    mobile: [{ id: '3', flexSize: 1, components: ["component_3"] }],
                    tablet: [{ id: '3', flexSize: 1, components: ["component_3"] }],
                    desktop: [{ id: '3', flexSize: 1, components: ["component_3"] }]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'views',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'tabs',
                columns: {
                    mobile: [{ id: '4', flexSize: 1, components: ["component_4"] }, { id: '5', flexSize: 1, components: ["component_5"] }, { id: '6', flexSize: 1, components: ["component_6"] }],
                    tablet: [{ id: '4', flexSize: 1, components: ["component_4"] }, { id: '5', flexSize: 1, components: ["component_5"] }, { id: '6', flexSize: 1, components: ["component_6"] }],
                    desktop: [{ id: '4', flexSize: 1, components: [["component_4", "component_5", "component_6"]] }]
                }
            },
        ],
        item_actions: [
            "accounts.new",
            "contacts.new",
            "properties.new",
        ],
        more_actions: [ ],
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        published_at: new Date(),
        published_by: null,
        
    },
];

export default ClientsPageLayoutsData;