// This constats file is a representation of how data for object fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as ActionTypes from '../../types/ActionTypes';
import * as ActionUseTypes from '../../types/ActionUseTypes';

const RequestActionsData = [

    // Item Actions
    //
    // New Request
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "New Request",
        short_label: "New",
        identifier: "new",
        icon: "inbox",
        sort_order: 1,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.NEW,
        description: "Create a new Request.",
        help_text: "Create a new Request.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Edit Request
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Edit Request",
        short_label: "Edit",
        identifier: "edit",
        icon: "pen-to-square",
        sort_order: 2,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.EDIT,
        description: "Edit the Fields for a Request.",
        help_text: "Edit the Fields for a Request.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Duplicate Request
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Duplicate Request",
        short_label: "Duplicate",
        identifier: "duplicate",
        icon: "copy",
        sort_order: 3,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.DUPLICATE,
        description: "Copy and duplicate the contents of a Request to a new one.",
        help_text: "Copy and duplicate the contents of this Request to a new one.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Start Request
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Start Request",
        short_label: "Start",
        identifier: "start",
        icon: "play",
        sort_order: 4,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.COMPLETE,
        description: "Mark the Request as started.",
        help_text: "Mark this Request as started.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Complete Request
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Complete Request",
        short_label: "Complete",
        identifier: "complete",
        icon: "square-check",
        sort_order: 5,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.COMPLETE,
        description: "Mark the Request as completed.",
        help_text: "Mark this Request as completed.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Close Request
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Close Request",
        short_label: "Close",
        identifier: "close",
        icon: "square-minus",
        sort_order: 6,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.CLOSE,
        description: "Mark the Request as closed.",
        help_text: "Mark this Request as closed.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Convert to Task
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Convert to Task",
        short_label: "Convert",
        identifier: "convert_to_task",
        icon: "list-check",
        sort_order: 7,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.CONVERT,
        description: "Convert the details of the Request to a new Task.",
        help_text: "Convert the details of this Request to a new Task.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Convert to Estimate
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Convert to Estimate",
        short_label: "Convert",
        identifier: "convert_to_estimate",
        icon: "envelope-open-dollar",
        sort_order: 8,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.CONVERT,
        description: "Convert the details of the Request to a new Estimate.",
        help_text: "Convert the details of this Request to a new Estimate.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Convert to Job
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Convert to Job",
        short_label: "Convert",
        identifier: "convert_to_job",
        icon: "briefcase",
        sort_order: 9,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.CONVERT,
        description: "Convert the details of the Request to a new Job.",
        help_text: "Convert the details of this Request to a new Job.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Convert to Invoice
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Convert to Invoice",
        short_label: "Convert",
        identifier: "convert_to_invoice",
        icon: "file-invoice-dollar",
        sort_order: 10,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.CONVERT,
        description: "Convert the details of the Request to a new Invoice.",
        help_text: "Convert the details of this Request to a new Invoice.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Convert to Subscription
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Convert to Subscription",
        short_label: "Convert",
        identifier: "convert_to_subscription",
        icon: "money-bill-transfer",
        sort_order: 11,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.CONVERT,
        description: "Convert the details of the Request to a new Subscription.",
        help_text: "Convert the details of this Request to a new Subscription.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Archive Request
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Archive Request",
        short_label: "Archive",
        identifier: "archive",
        icon: "box-archive",
        sort_order: 12,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.ARCHIVE,
        description: "Archive a request to be hidden from normal requests in the program.",
        help_text: "Archive a request, hidden but not deleted.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Delete Request
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Delete Request",
        short_label: "Delete",
        identifier: "delete",
        icon: "trash",
        sort_order: 13,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.DELETE,
        description: "Permantently deletes a Request. Not retrievable by users after this point.",
        help_text: "Permantently deletes this Request. Not retrievable by users after this point.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    

    //
    // Batch Actions
    //

    // Batch Edit
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Batch Edit",
        short_label: "Batch Edit",
        identifier: "batch_edit",
        icon: "pen-to-square",
        sort_order: 1,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_EDIT,
        description: "Edit the Fields for multiple selected Requests at once.",
        help_text: "Edit the Fields for multiple selected Requests at once.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Batch Archive
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Batch Archive",
        short_label: "Batch Archive",
        identifier: "batch_archive",
        icon: 'box-archive',
        sort_order: 6,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_ARCHIVE,
        description: "Archive multiple selected Requests to be hidden from normal requests in the program.",
        help_text: "Archive multiple selected Requests to be hidden from normal requests in the program.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },

];


export default RequestActionsData;