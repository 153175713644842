import { styled } from '@mui/material/styles';

const PreAuthHeaderContainer = styled('nav')`
    background-color: ${props => props.theme.palette.app.primary};
    color: ${props => props.theme.palette.app.primaryContrastText};
    border-top: 1px solid ${props => props.theme.palette.primary.dark};
    border-bottom: 1px solid ${props => props.theme.palette.primary.dark};
    box-shadow: 1px 1px 2px ${props => props.theme.boxShadows.color};
    height: ${props => props.theme.variables.headerMobileHeight}px;
    z-index: ${props => props.theme.zIndex.appBar};
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (min-width: ${props => props.theme.breakpoints.values.md}px) {
        border-top: none;
        border-bottom: 2x solid ${props => props.theme.palette.primary.dark};
        height: ${props => props.theme.variables.headerDesktopHeight}px;
        box-shadow: 2px 2px 3px ${props => props.theme.boxShadows.color};
    }
`;

const PreAuthHeaderLeftArea = styled('div')`
    margin: auto 5px auto 10px;
    padding: 0px;
    display: flex;
    flex-direction: row;

    @media (min-width: ${props => props.theme.breakpoints.values.md}px) {
        margin: auto 5px auto 15px;
    }
`;

const PreAuthHeaderRightArea = styled('div')`
    margin: auto 10px auto 5px;
    width: auto;
    display: flex;
    flex-direction: row;

    @media (min-width: ${props => props.theme.breakpoints.values.md}px) {
        margin: auto 15px auto 5px;
    }
`;

const PreAuthHeaderProHelperTitle = styled('h1')`
    color:  inherit;
    font-family: ${props => props.theme.typography.h3.fontFamily};
    font-size: ${props => props.theme.typography.h3.fontSize};
    font-weight: ${props => props.theme.typography.fontWeightBold};
    margin: 0px 0px 0px 10px;
    line-height: normal;
`;

const PreAuthHeaderButton = styled('button')`
    color:  inherit;
    font-family: ${props => props.theme.typography.h4.fontFamily};
    font-size: ${props => props.theme.typography.h4.fontSize};
    font-weight: ${props => props.theme.typography.h4.fontWeightRegular};
    text-shadow: none;
    padding: 8px 10px 8px 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
        font-weight: 700;
        text-shadow: ${props => props.theme.boxShadows.lg};
    }

    ${({variant}) => (variant==='active') && `
        font-weight: 700;
        text-shadow: ${props => props.theme.boxShadows.lg};
    `}

    @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
        padding: 10px 15px 10px 15px;
    }
`;


export { 
    PreAuthHeaderContainer, 
    PreAuthHeaderLeftArea, 
    PreAuthHeaderRightArea, 
    PreAuthHeaderProHelperTitle, 
    PreAuthHeaderButton 
}; 