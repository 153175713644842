
// This constants file is a representation of how data for object types will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';


const LedgerObjectTypesData = [

    // ----------------------------------------
    // Ledger Object Types
    // ----------------------------------------

    // Bank
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Bank",
        identifier: "bank",
        description: "A bank ledger type.",
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Bank - Checking
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Checking",
        identifier: "checking",
        description: "A bank checking ledger type.",
        sort_order: 2,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'bank',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Bank - Savings
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Savings",
        identifier: "savings",
        description: "A bank savings ledger type.",
        sort_order: 3,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'bank',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Bank - Cash on Hand
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Cash on Hand",
        identifier: "cash_on_hand",
        description: "A cash on hand ledger type.",
        sort_order: 4,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'bank',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Bank - Money Market
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Money Market",
        identifier: "money_market",
        description: "A money in market (stock, real estate) ledger type.",
        sort_order: 5,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'bank',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Bank - Other Earned Bank Accounts
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Other Earned Bank Accounts",
        identifier: "other_earned_bank_accounts",
        description: "Other earned bank accounts ledger type.",
        sort_order: 6,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'bank',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Current Asset
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Other Current Asset",
        identifier: "other_current_asset",
        description: "Other current asset ledger type.",
        sort_order: 7,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Current Asset - Allowance for Bad Debts
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Allowance for Bad Debts",
        identifier: "allowance_for_bad_debts",
        description: "Allowance for Bad Debts ledger type.",
        sort_order: 8,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_current_asset',
        category: 'other_current_asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Current Asset - Development Costs
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Development Costs",
        identifier: "development_costs",
        description: "A Development Costs ledger.",
        sort_order: 9,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_current_asset',
        category: 'other_current_asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Current Asset - Employee Cash Advances
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Employee Cash Advances",
        identifier: "employee_cash_advances",
        description: "A Employee Cash Advances ledger.",
        sort_order: 10,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_current_asset',
        category: 'other_current_asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Current Asset - Other Current Assets
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Other Current Assets",
        identifier: "other_current_assets",
        description: "Records other short-term assets not covered by specific categories.",
        sort_order: 11,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_current_asset',
        category: 'other_current_asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Current Asset - Inventory
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Inventory",
        identifier: "inventory",
        description: "Records details of products held for sale.",
        sort_order: 12,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_current_asset',
        category: 'other_current_asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Current Asset - Investment
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Investment",
        identifier: "investment",
        description: "Tracks investments made by the company.",
        sort_order: 13,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_current_asset',
        category: 'other_current_asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Current Asset - Investment Tax Exempt
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Investment Tax Exempt",
        identifier: "investment_tax_exempt_securities",
        description: "Records tax-exempt investment transactions.",
        sort_order: 14,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_current_asset',
        category: 'other_current_asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Current Asset - Investment US Government Obligations
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Investment US Government Obligations",
        identifier: "investment_us_government_obligations",
        description: "Tracks investments in US government obligations.",
        sort_order: 15,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_current_asset',
        category: 'other_current_asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Current Asset - Loans to Officers
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Loans to Officers",
        identifier: "loans_to_officers",
        description: "Records loans provided to company officers.",
        sort_order: 16,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_current_asset',
        category: 'other_current_asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Current Asset - Loans to Others
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Loans to Others",
        identifier: "loans_to_others",
        description: "Tracks loans given to external parties.",
        sort_order: 17,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_current_asset',
        category: 'other_current_asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Current Asset - Loans to Stockholders
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Loans to Stockholders",
        identifier: "loans_to_stockholders",
        description: "Records loans to company stockholders.",
        sort_order: 18,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_current_asset',
        category: 'other_current_asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Current Asset - Prepaid Expenses
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Prepaid Expenses",
        identifier: "prepaid_expenses",
        description: "Tracks expenses paid in advance.",
        sort_order: 19,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_current_asset',
        category: 'other_current_asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Current Asset - Retainage
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Retainage",
        identifier: "retainage",
        description: "Records withheld amounts for project completion.",
        sort_order: 20,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_current_asset',
        category: 'other_current_asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Current Asset - Undeposited Funds
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Undeposited Funds",
        identifier: "undeposited_funds",
        description: "Tracks funds yet to be deposited into bank accounts.",
        sort_order: 21,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_current_asset',
        category: 'other_current_asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Fixed Asset
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Fixed Asset",
        identifier: "fixed_asset",
        description: "Fixed asset ledger.",
        sort_order: 22,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Fixed Asset - Accumulated Depreciation
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Accumulated Depreciation",
        identifier: "accumulated_depreciation",
        description: "Tracks accumulated depreciation of fixed assets.",
        sort_order: 23,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'fixed_asset',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Fixed Asset - Computer
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Computer",
        identifier: "computer",
        description: "Records computers as fixed assets.",
        sort_order: 24,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'fixed_asset',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Fixed Asset - Phone
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Phone",
        identifier: "phone",
        description: "Records phones as fixed assets.",
        sort_order: 25,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'fixed_asset',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Fixed Asset - Software
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Software",
        identifier: "software",
        description: "Records owned software as fixed assets.",
        sort_order: 26,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'fixed_asset',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Fixed Asset - Tools and Equipment
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Tools and Equipment",
        identifier: "tools_and_equipment",
        description: "Records owned tools and equipment as fixed assets.",
        sort_order: 27,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'fixed_asset',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Fixed Asset - Land
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Land",
        identifier: "land",
        description: "Tracks land owned by the company.",
        sort_order: 28,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'fixed_asset',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Fixed Asset - Other Fixed Assets
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Other Fixed Assets",
        identifier: "other_fixed_assets",
        description: "Records other long-term tangible assets.",
        sort_order: 29,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'fixed_asset',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Fixed Asset - Accumulated Amortization
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Accumulated Amortization",
        identifier: "accumulated_amortization",
        description: "Tracks accumulated amortization of intangible assets.",
        sort_order: 30,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'fixed_asset',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Fixed Asset - Buildings
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Buildings",
        identifier: "buildings",
        description: "Records buildings as fixed assets.",
        sort_order: 31,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'fixed_asset',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Fixed Asset - Intangible Assets
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Intangible Assets",
        identifier: "intangable_assets",
        description: "Records intangable as fixed assets.",
        sort_order: 32,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'fixed_asset',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Fixed Asset - Machinery
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Machinery",
        identifier: "machinery",
        description: "Records machinery as fixed assets.",
        sort_order: 33,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'fixed_asset',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Fixed Asset - Vehicles
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Vehicles",
        identifier: "vehicles",
        description: "Records vehicles as fixed assets.",
        sort_order: 34,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'fixed_asset',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Asset
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Other Asset",
        identifier: "other_asset",
        description: "Other asset ledger.",
        sort_order: 35,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Asset - Lease Buyout
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Lease Buyout",
        identifier: "lease_buyout",
        description: "The cost associated with purchasing or obtaining a leased asset before the end of the lease term.",
        sort_order: 36,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_asset',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Asset - Other Long Term Assets
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Other Long Term Assets",
        identifier: "other_long_term_assets",
        description: "Non-current assets other than property, plant, and equipment that are expected to be used for more than one accounting period.",
        sort_order: 37,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_asset',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Asset - Security Deposits
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Security Deposits",
        identifier: "security_deposits",
        description: "Deposits made by a tenant to a landlord or by a customer to a vendor to cover any potential damages or losses that may occur during the course of a lease or transaction.",
        sort_order: 38,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_asset',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Asset - Accumulated Amoritization of Other Assets
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Accumulated Amoritization of Other Assets",
        identifier: "accumulated_amortization_of_other_assets",
        description: "Tracks accumulated amortization of miscellaneous intangible assets.",
        sort_order: 39,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_asset',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Asset - Goodwill
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Goodwill",
        identifier: "goodwill",
        description: "Intangible asset that arises as a result of the acquisition of one company by another at a price greater than the fair market value of its net assets.",
        sort_order: 40,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_asset',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Asset - Licenses
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Licenses",
        identifier: "licenses",
        description: "Legal permissions provided by an authority to an entity to conduct certain activities or operate in a specific industry.",
        sort_order: 41,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_asset',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Asset - Organizational Costs
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Organizational Costs",
        identifier: "organization_costs",
        description: "Expenses incurred during the formation of a legal entity, such as corporation or partnership.",
        sort_order: 42,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_asset',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Asset - Deferred Taxes
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Deferred Taxes",
        identifier: "deferred_taxes",
        description: "Taxes that will be paid in the future due to differences between accounting and tax rules.",
        sort_order: 43,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_asset',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Accounts Receivable
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Accounts Receivable",
        identifier: "accounts_receivable",
        description: "Money owed to a company by its clients or customers for products or services that have been delivered but not yet paid for.",
        sort_order: 44,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Accounts Receivable - Accounts Receivable
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Accounts Receivable",
        identifier: "accounts_receivable",
        description: "Money owed to a company by its clients or customers for products or services that have been delivered but not yet paid for.",
        sort_order: 45,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'accounts_receivable',
        category: 'asset',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Equity
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Equity",
        identifier: "equity",
        description: "Equity asset ledger.",
        sort_order: 46,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'equity',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Equity - Opening Balance Equity
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Opening Balance Equity",
        identifier: "opening_balance_equity",
        description: "Records opening equity balances.",
        sort_order: 47,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'equity',
        category: 'equity',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Equity - Partners Equity
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Partners Equity",
        identifier: "partners_equity",
        description: "Tracks equity for partners in a partnership.",
        sort_order: 48,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'equity',
        category: 'equity',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Equity - Retained Earnings
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Retained Earnings",
        identifier: "retained_earnings",
        description: "Records retained profits over time.",
        sort_order: 49,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'equity',
        category: 'equity',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Equity - Accumulated Adjustment
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Accumulated Adjustment",
        identifier: "accumulated_adjustment",
        description: "Tracks the balance of accumulated earnings or losses of a corporation that have not been distributed as dividends.",
        sort_order: 50,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'equity',
        category: 'equity',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Equity - Owners Equity
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Owners Equity",
        identifier: "owners_equity",
        description: "Represents the residual interest in the assets of an entity after deducting liabilities. It represents the ownership interest of the owners in a company.",
        sort_order: 51,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'equity',
        category: 'equity',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Equity - Partner Contributions
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Partner Contributions",
        identifier: "partner_contributions",
        description: "Tracks the amount of money or assets contributed by partners to a partnership.",
        sort_order: 52,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'equity',
        category: 'equity',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Equity - Partner Distributions
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Partner Distributions",
        identifier: "partner_distributions",
        description: "Tracks the amount of money or assets distributed to partners from a partnership.",
        sort_order: 53,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'equity',
        category: 'equity',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Equity - Preferred Stock
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Preferred Stock",
        identifier: "preferred_stock",
        description: "Represents equity ownership in a corporation that has a higher claim on assets and earnings than common stock.",
        sort_order: 54,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'equity',
        category: 'equity',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Equity - Common Stock
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Common Stock",
        identifier: "common_stock",
        description: "Represents equity ownership in a corporation that has voting rights and a claim on assets and earnings after preferred stock.",
        sort_order: 55,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'equity',
        category: 'equity',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Equity - Treasury Stock
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Treasury Stock",
        identifier: "treasury_stock",
        description: "Tracks the number of shares of a company's own stock that has been repurchased by the company and is being held in its treasury.",
        sort_order: 56,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'equity',
        category: 'equity',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Equity - Estimated Taxes
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Estimated Taxes",
        identifier: "estimated_taxes",
        description: "Tracks the amount of taxes that are expected to be paid in the future based on current earnings and tax laws.",
        sort_order: 57,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'equity',
        category: 'equity',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Equity - Healthcare
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Healthcare",
        identifier: "healthcare",
        description: "Tracks the cost of medical care and related expenses for employees.",
        sort_order: 58,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'equity',
        category: 'equity',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Equity - Personal Income
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Personal Income",
        identifier: "personal_income",
        description: "Tracks the income earned by an individual outside of their employment or business.",
        sort_order: 59,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'equity',
        category: 'equity',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Equity - Personal Expense
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Personal Expense",
        identifier: "personal_expense",
        description: "Tracks the expenses incurred by an individual for personal, non-business-related purposes.",
        sort_order: 60,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'equity',
        category: 'equity',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Expense",
        identifier: "expense",
        description: "Expense asset ledger.",
        sort_order: 61,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: false,
        parent: null,
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Advertising
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Advertising",
        identifier: "advertising",
        description: "Record the costs associated with promoting a business's products or services.",
        sort_order: 62,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Bad Debts
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Bad Debts",
        identifier: "bad_debts",
        description: "Record the amount of money that a business is owed but is unlikely to collect from its debtors.",
        sort_order: 63,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Bank Charges
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Bank Charges",
        identifier: "bank_charges",
        description: "Record the fees charged by banks for services such as account maintenance, wire transfers, and overdrafts.",
        sort_order: 64,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Charitable Contributions
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Charitable Contributions",
        identifier: "charitable_contributions",
        description: "Record donations made by a business to charitable organizations.",
        sort_order: 65,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Commissions & Fees
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Commissions & Fees",
        identifier: "commisions_fees",
        description: "Record the costs associated with paying commissions to salespeople or fees charged by third-party service providers.",
        sort_order: 66,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Entertainment
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Entertainment",
        identifier: "entertainment",
        description: "Record the costs associated with entertaining clients or customers.",
        sort_order: 67,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Entertainment Meals
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Entertainment Meals",
        identifier: "entertainment_meals",
        description: "Record the costs associated with providing meals or refreshments during business-related entertainment events.",
        sort_order: 68,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Equipment Rental
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Equipment Rental",
        identifier: "equipment_rentals",
        description: "Record the costs associated with renting equipment or machinery for business purposes.",
        sort_order: 69,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Finance Costs
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Finance Costs",
        identifier: "finance_costs",
        description: "Record the costs associated with borrowing money or obtaining financing for a business.",
        sort_order: 70,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Global Tax Expense
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Global Tax Expense",
        identifier: "global_tax_expense",
        description: "Record the amount of taxes owed by a business to foreign governments.",
        sort_order: 71,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Insurance
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Insurance",
        identifier: "insurance",
        description: "Record the cost of insurance premiums paid by the business to protect against potential losses or damages.",
        sort_order: 72,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Interest Paid
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Interest Paid",
        identifier: "interest_paid",
        description: "Record the interest paid by the business on loans or other forms of debt.",
        sort_order: 73,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Legal Professional Fees
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Legal Professional Fees",
        identifier: "legal_professional_fees",
        description: "Record the fees paid to lawyers or legal professionals for legal services provided to the business.",
        sort_order: 74,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Office Expenses
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Office Expenses",
        identifier: "office_expenses",
        description: "Record the cost of supplies and other materials used in the day-to-day operations of the business.",
        sort_order: 75,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Office General Administrative Expenses
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Office General Administrative Expenses",
        identifier: "office_general_administrative_expenses",
        description: "Record the cost of administrative expenses that are not related to any specific department or function within the business.",
        sort_order: 76,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Other Business Expenses
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Other Business Expenses",
        identifier: "other_business_expenses",
        description: "Record any expenses that do not fit into any other category.",
        sort_order: 77,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Other Service Costs
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Other Service Costs",
        identifier: "other_service_costs",
        description: "Record the cost of services provided by third-party service providers that are not related to any specific department or function within the business.",
        sort_order: 78,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Promotional Meals
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Promotional Meals",
        identifier: "promotional_meals",
        description: "Record the cost of meals or refreshments provided to clients or customers for promotional purposes.",
        sort_order: 79,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Rent or Lease of Buildings
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Rent or Lease of Buildings",
        identifier: "rent_or_lease_of_buildings",
        description: "Record the cost of renting or leasing buildings or office space for the business.",
        sort_order: 80,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Repair Maintence
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Repair Maintence",
        identifier: "repair_maintence",
        description: "Record the cost of repairs and maintenance performed on the business's property or equipment.",
        sort_order: 81,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Shipping
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Shipping",
        identifier: "shipping",
        description: "Record the cost of shipping products or materials to customers or other locations.",
        sort_order: 82,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Supplies & Materials
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Supplies & Materials",
        identifier: "supplies_materials",
        description: "Record the cost of supplies and materials used in the day-to-day operations of the business.",
        sort_order: 83,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Travel
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Travel",
        identifier: "travel",
        description: "Record the cost of travel expenses incurred by the business, such as airfare, lodging, and transportation.",
        sort_order: 84,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Travel Meals
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Travel Meals",
        identifier: "travel_meals",
        description: "Record the cost of meals or refreshments that are part of business-related travel.",
        sort_order: 85,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Utilities
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Utilities",
        identifier: "utilities",
        description: "Record the cost of utilities such as electricity, gas, water, and internet services used by the business",
        sort_order: 86,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Auto
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Auto",
        identifier: "auto",
        description: "Record the cost of owning and operating company vehicles.",
        sort_order: 87,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Cost of Labor
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Cost of Labor",
        identifier: "cost_of_labor",
        description: "Record the cost of employee wages and benefits.",
        sort_order: 88,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Dues & Subscriptions
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Dues & Subscriptions",
        identifier: "dues_subscriptions",
        description: "Record the cost of professional memberships, subscriptions to industry publications, and other types of dues.",
        sort_order: 89,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Payroll Expenses
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Payroll Expenses",
        identifier: "payroll_expenses",
        description: "Record the costs associated with paying employees, such as payroll taxes, insurance, and benefits.",
        sort_order: 90,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Taxes Paid
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Taxes Paid",
        identifier: "taxes_paid",
        description: "Record the amount of taxes paid by the business, such as income taxes, sales taxes, and property taxes.",
        sort_order: 91,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Expense - Unapplied Cash Bill Payment Expense
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Unapplied Cash Bill Payment Expense",
        identifier: "unapplied_cash_bill_payment_expense",
        description: "Record the amount of cash payments made by the business that have not yet been applied to a specific expense account.",
        sort_order: 92,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        is_sub_type: true,
        parent: 'expense',
        category: 'expense',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Expense
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Other Expense",
        identifier: "other_expense",
        description: "Record any expenses that do not fit into any other category.",
        sort_order: 93,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Expense - Depreciation
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Depreciation",
        identifier: "depreciation",
        description: "Tracks the decrease in value of a long-term asset over time due to wear and tear or obsolescence.",
        sort_order: 94,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_expense',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Expense - Exchange Gain or Loss
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Exchange Gain or Loss",
        identifier: "exchange_gain_or_loss",
        description: "Tracks the gains or losses incurred by a business due to changes in currency exchange rates.",
        sort_order: 95,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_expense',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Expense - Other Miscellaneous Expense
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Other Miscellaneous Expense",
        identifier: "other_miscellaneous_expense",
        description: "Tracks any expenses that do not fit into any other category.",
        sort_order: 96,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_expense',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Expense - Penalties Settlements
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Penalties Settlements",
        identifier: "penalties_settlements",
        description: "Tracks the costs associated with penalties or legal settlements paid by a business.",
        sort_order: 97,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_expense',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Expense - Amortization
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Amortization",
        identifier: "amorization",
        description: "Tracks the decrease in value of an intangible asset over time due to obsolescence or expiration.",
        sort_order: 98,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_expense',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Expense - Gas and Fuel
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Gas and Fuel",
        identifier: "gas_and_fuel",
        description: "Tracks the cost of gasoline or fuel used by a business for transportation or equipment.",
        sort_order: 99,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_expense',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Expense - Home Office
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Home Office",
        identifier: "home_office",
        description: "Tracks the costs associated with operating a business from a home office.",
        sort_order: 100,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_expense',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Expense - Home Owner Rental Insurance
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Home Owner Rental Insurance",
        identifier: "home_owner_rental_insurance",
        description: "Tracks the cost of insurance paid by a business for a rented or leased home office.",
        sort_order: 101,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_expense',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Expense - Other Home Office Expenses
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Other Home Office Expenses",
        identifier: "other_home_office_expenses",
        description: "Tracks any expenses associated with operating a business from a home office that do not fit into any other category.",
        sort_order: 102,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_expense',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Expense - Mortgage Interest
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Mortgage Interest",
        identifier: "mortgage_interest",
        description: "Tracks the interest paid by a business on a mortgage loan for property.",
        sort_order: 103,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_expense',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Expense - Rent and Lease
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Rent and Lease",
        identifier: "rent_lease",
        description: "Tracks the cost of renting or leasing property, such as office space or equipment.",
        sort_order: 104,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_expense',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Expense - Repair and Maintenance
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Repair and Maintenance",
        identifier: "repair_maintence",
        description: "Record the costs associated with repairing and maintaining vehicles used by the business.",
        sort_order: 105,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_expense',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Expense - Parking and Tolls
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Parking and Tolls",
        identifier: "parking_tolls",
        description: "Record the costs associated with parking and tolls paid for business-related travel.",
        sort_order: 106,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_expense',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Expense - Vehicles
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Vehicles",
        identifier: "vehicles",
        description: "Record the cost of purchasing or leasing vehicles used for business purposes.",
        sort_order: 107,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_expense',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Expense - Vehicle Insurance
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Vehicle Insurance",
        identifier: "vehicle_insurance",
        description: "Record the cost of insurance premiums paid by the business to protect against potential losses or damages related to company vehicles.",
        sort_order: 108,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_expense',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Expense - Vehicle Lease
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Vehicle Lease",
        identifier: "vehicle_lease",
        description: "Record the costs associated with leasing vehicles for business purposes.",
        sort_order: 109,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_expense',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Expense - Vehicle Loan Interest
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Vehicle Loan Interest",
        identifier: "vehicle_loan_interest",
        description: "Record the interest paid by the business on loans taken out to purchase or lease company vehicles.",
        sort_order: 110,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_expense',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Expense - Vehicle Loan
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Vehicle Loan",
        identifier: "vehicle_loan",
        description: "Record the amount of money borrowed by the business to purchase or lease company vehicles.",
        sort_order: 111,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_expense',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Expense - Vehicle Registration
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Vehicle Registration",
        identifier: "vehicle_registration",
        description: "Record the costs associated with registering company vehicles with the appropriate authorities.",
        sort_order: 112,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_expense',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Expense - Vehicle Repairs
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Vehicle Repairs",
        identifier: "vehicle_repairs",
        description: "Record the costs associated with repairing company vehicles.",
        sort_order: 113,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_expense',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Expense - Other Vehicle Expenses
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Other Vehicle Expenses",
        identifier: "other_vehicle_expenses",
        description: "Record any expenses associated with company vehicles that do not fit into any other category.",
        sort_order: 114,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_expense',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Expense - Wash and Road Services
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Wash and Road Services",
        identifier: "wash_and_road_servies",
        description: "Record the costs associated with washing and cleaning company vehicles, as well as expenses related to road services such as towing or roadside assistance.",
        sort_order: 115,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_expense',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Cost of Goods Sold
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Cost of Goods Sold",
        identifier: "cost_of_goods_sold",
        description: "Record the direct costs associated with producing and selling goods, including the cost of materials, labor, and overhead expenses.",
        sort_order: 116,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Cost of Goods Sold - Equipment Rental
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Equipment Rental",
        identifier: "equipment_rental",
        description: "Record the costs associated with renting equipment or machinery for business purposes.",
        sort_order: 117,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'cost_of_goods_sold',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Cost of Goods Sold - Other Costs of Service
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Other Costs of Service",
        identifier: "other_costs_of_service",
        description: "Record any indirect costs associated with providing a service, such as administrative expenses or overhead costs.",
        sort_order: 118,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'cost_of_goods_sold',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Cost of Goods Sold - Shipping Frieght Delivery
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Shipping Frieght Delivery",
        identifier: "shipping_frieght_delivery",
        description: "Record the costs associated with shipping and delivering goods to customers or other locations.",
        sort_order: 119,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'cost_of_goods_sold',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Cost of Goods Sold - Supplies Materials
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Supplies Materials",
        identifier: "supplies_materials",
        description: "Record the cost of supplies and materials used in the day-to-day operations of the business.",
        sort_order: 120,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'cost_of_goods_sold',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Cost of Goods Sold - Cost of Labor
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Cost of Labor",
        identifier: "cost_of_labor",
        description: "Record the cost of employee wages and benefits associated with producing and selling goods or providing services.",
        sort_order: 121,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'cost_of_goods_sold',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Cost of Goods Sold - Cost of Sales
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Cost of Sales",
        identifier: "cost_of_sales",
        description: "Record the direct costs associated with producing and selling goods, including the cost of materials, labor, and overhead expenses.",
        sort_order: 122,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'cost_of_goods_sold',
        category: 'expense',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Accounts Payable
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Accounts Payable",
        identifier: "accounts_payable",
        description: "Record the amount of money owed by a business to suppliers or vendors for goods or services received but not yet paid for.",
        sort_order: 123,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'liability',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Accounts Payable - Accounts Payable
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Accounts Payable",
        identifier: "accounts_payable",
        description: "Record the amount of money owed by a business to suppliers or vendors for goods or services received but not yet paid for.",
        sort_order: 124,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'accounts_payable',
        category: 'liability',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Credit Card
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Credit Card",
        identifier: "credit_card",
        description: "Record the charges made by a business on credit cards for goods or services purchased.",
        sort_order: 125,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'liability',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Credit Card
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Credit Card",
        identifier: "credit_card",
        description: "Record the charges made by a business on credit cards for goods or services purchased.",
        sort_order: 125,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'credit_card',
        category: 'liability',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Long Term Liability
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Long Term Liability",
        identifier: "long_term_liability",
        description: "Tracks the debts or obligations of a business that are due in more than one year.",
        sort_order: 127,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'liability',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Long Term Liability - Notes Payable
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Notes Payable",
        identifier: "notes_payable",
        description: "Tracks the amount of money owed by a business to lenders or bondholders for loans or other forms of debt.",
        sort_order: 128,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'long_term_liability',
        category: 'liability',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Long Term Liability - Other Long Term Liabilities
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Other Long Term Liabilities",
        identifier: "other_long_term_liabilities",
        description: "Tracks any long-term debts or obligations of a business that do not fit into any other category.",
        sort_order: 129,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'long_term_liability',
        category: 'liability',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Long Term Liability - Shareholder Notes Payable
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Shareholder Notes Payable",
        identifier: "shareholder_notes_payable",
        description: "Tracks the amount of money owed by a business to its shareholders for loans or other forms of debt.",
        sort_order: 130,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'long_term_liability',
        category: 'liability',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Long Term Liability - Accruals and Deferred Income
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Accruals and Deferred Income",
        identifier: "accrual_and_deferred_income",
        description: "Tracks revenue or expenses that have been earned or incurred but have not yet been recorded in the general ledger.",
        sort_order: 131,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'long_term_liability',
        category: 'liability',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Long Term Liability - Accrued Long Term Liabilities
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Accrued Long Term Liabilities",
        identifier: "accrued_long_term_liabilities",
        description: "Tracks long-term debts or obligations of a business that have been incurred but not yet paid.",
        sort_order: 132,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'long_term_liability',
        category: 'liability',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Long Term Liability - Accrued Vacation Payable
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Accrued Vacation Payable",
        identifier: "accrued_vacation_payable",
        description: "Tracks the amount of vacation pay owed to employees but not yet paid.",
        sort_order: 133,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'long_term_liability',
        category: 'liability',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Long Term Liability - Bank Loans
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Bank Loans",
        identifier: "bank_loans",
        description: "Tracks the amount of money borrowed by a business from banks or other financial institutions.",
        sort_order: 134,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'long_term_liability',
        category: 'liability',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Long Term Liability - Debts Relating to Participating Interests
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Debts Relating to Participating Interests",
        identifier: "debts_relating_to_participating_interests",
        description: "Tracks the amount of money owed by a business to its partners or other equity holders for loans or other forms of debt.",
        sort_order: 135,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'long_term_liability',
        category: 'liability',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Long Term Liability - Deferred Tax Liabilities
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Deferred Tax Liabilities",
        identifier: "deferred_tax_liabilities",
        description: "Tracks the amount of taxes that a business will owe in the future due to temporary differences between accounting and tax rules.",
        sort_order: 136,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'long_term_liability',
        category: 'liability',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Long Term Liability - Government and Other Public Authorities
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Government and Other Public Authorities",
        identifier: "government_and_other_public_authorities",
        description: "Tracks the amount of money owed by a business to government agencies or other public entities for taxes or other forms of debt.",
        sort_order: 137,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'long_term_liability',
        category: 'liability',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Long Term Liability - Long Term Borrowings
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Long Term Borrowings",
        identifier: "long_term_borrowings",
        description: "Records the company's long-term loans or borrowings.",
        sort_order: 138,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'long_term_liability',
        category: 'liability',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Long Term Liability - Long Term Debit
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Long Term Debit",
        identifier: "long_term_debit",
        description: "Tracks long-term debits or charges that will be settled over an extended period.",
        sort_order: 139,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'long_term_liability',
        category: 'liability',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Long Term Liability - Long Term Employee Benefit Obligations
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Long Term Employee Benefit Obligations",
        identifier: "long_term_employee_benefit_obligations",
        description: "Records long-term obligations related to employee benefits, like pensions or retirement plans.",
        sort_order: 140,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'long_term_liability',
        category: 'liability',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Long Term Liability - Other Long Term Provisions
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Other Long Term Provisions",
        identifier: "other_long_term_provisions",
        description: "Tracks long-term provisions for specific liabilities not covered elsewhere.",
        sort_order: 141,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'long_term_liability',
        category: 'liability',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Long Term Liability - Provision for Liabilities
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Provision for Liabilities",
        identifier: "provision_for_liabilities",
        description: "Records amounts set aside for potential future liabilities or expenses.",
        sort_order: 142,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'long_term_liability',
        category: 'liability',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Current Liability
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Other Current Liability",
        identifier: "other_current_liability",
        description: "Tracks short-term liabilities not categorized elsewhere.",
        sort_order: 143,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'liability',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Current Liability
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Sales Tax Payable",
        identifier: "sales_tax_payable",
        description: "Tracks short-term liabilities not categorized elsewhere.",
        sort_order: 144,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_current_liability',
        category: 'liability',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Income
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Income",
        identifier: "income",
        description: "Records various sources of revenue earned by the company.",
        sort_order: 144,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'revenue',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Income - Non Profit Income
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Non Profit Income",
        identifier: "non_profit_income",
        description: "Tracks income received by non-profit organizations.",
        sort_order: 145,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'income',
        category: 'revenue',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Income - Other Primary Income
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Other Primary Income",
        identifier: "other_primary_income",
        description: "Records primary sources of income not covered by specific categories.",
        sort_order: 146,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'income',
        category: 'revenue',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Income - Sales of Product Income
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Sales of Product Income",
        identifier: "sales_of_product_income",
        description: "Tracks income generated from sales of products.",
        sort_order: 147,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'income',
        category: 'revenue',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Income - Service Fee Income
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Service Fee Income",
        identifier: "service_fee_income",
        description: "Records income from service fees charged by the company.",
        sort_order: 148,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'income',
        category: 'revenue',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Income - Discounts Refunds Given
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Discounts Refunds Given",
        identifier: "discount_refunds_given",
        description: "Records the value of discounts or refunds provided to customers.",
        sort_order: 149,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'income',
        category: 'revenue',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Income - Unapplied Cash Payment Income
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Unapplied Cash Payment Income",
        identifier: "unapplied_cash_payment_income",
        description: "Tracks income from cash payments not yet allocated to specific transactions.",
        sort_order: 150,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'income',
        category: 'revenue',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Income - Cash Receipt Income
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Cash Receipt Income",
        identifier: "cash_receipt_income",
        description: "Records income received in the form of cash.",
        sort_order: 151,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'income',
        category: 'revenue',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Income - Operating Grants
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Operating Grants",
        identifier: "operating_grants",
        description: "Tracks grants received for funding operational activities.",
        sort_order: 152,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'income',
        category: 'revenue',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Income - Other Current Operating Income
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Other Current Operating Income",
        identifier: "other_current_operating_income",
        description: "Records additional income generated from ongoing operations.",
        sort_order: 153,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'income',
        category: 'revenue',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Income - Own Work Capitalized
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Own Work Capitalized",
        identifier: "own_work_capitalized",
        description: "Tracks the value of internal work capitalized as an asset.",
        sort_order: 154,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'income',
        category: 'revenue',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Income
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Other Income",
        identifier: "other_income",
        description: "Records miscellaneous sources of income.",
        sort_order: 155,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'revenue',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Income - Dividend Income
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Dividend Income",
        identifier: "dividend_income",
        description: "Tracks income received from dividends on investments.",
        sort_order: 156,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_income',
        category: 'revenue',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Income - Interest Earned
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Interest Earned",
        identifier: "interest_earned",
        description: "Records income earned from interest on investments or bank accounts.",
        sort_order: 157,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_income',
        category: 'revenue',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Income - Other Investment Income
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Other Investment Income",
        identifier: "other_investment_income",
        description: "Tracks income from investments not categorized elsewhere.",
        sort_order: 158,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_income',
        category: 'revenue',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Income - Other Miscellaneous Income
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Other Miscellaneous Income",
        identifier: "other_miscellaneous_income",
        description: "Records miscellaneous sources of income.",
        sort_order: 159,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_income',
        category: 'revenue',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Income - Tax Exempt Interest
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Tax Exempt Interest",
        identifier: "tax_exempt_interest",
        description: "Tracks interest income that is exempt from taxation.",
        sort_order: 160,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_income',
        category: 'revenue',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Income - Gain Loss on Sale of Fixed Assets
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Gain Loss on Sale of Fixed Assets",
        identifier: "gain_loss_on_sale_of_fixed_assets",
        description: "Records gains or losses from the sale of fixed assets.",
        sort_order: 161,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_income',
        category: 'revenue',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Income - Gain Loss on Sale of Investment
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Gain Loss on Sale of Investment",
        identifier: "gain_loss_on_sale_of_investment",
        description: "Tracks gains or losses from the sale of investments.",
        sort_order: 162,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_income',
        category: 'revenue',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Income - Loss on Disposal of Assets
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Loss on Disposal of Assets",
        identifier: "loss_on_disposal_of_assets",
        description: "Records losses incurred from disposing of assets.",
        sort_order: 163,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_income',
        category: 'revenue',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Income - Other Operating Income
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "ledgers",
        sync_token: 1,
        label: "Other Operating Income",
        identifier: "other_operating_income",
        description: "Records additional sources of operating income.",
        sort_order: 164,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: true,
        parent: 'other_income',
        category: 'revenue',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
];

export default LedgerObjectTypesData;