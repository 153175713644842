
// This constants file is a representation of how data for object types will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';


const UserObjectTypesData = [

    // ----------------------------------------
    // User Object Types
    // ----------------------------------------

    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "users",
        sync_token: 1,
        label: "Employee",
        identifier: "employee",
        description: "A person that is employed by the company and performs work.",
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "users",
        sync_token: 1,
        label: "Independet Contractor",
        identifier: "independent_contractor",
        description: "A person that is employed as an independent contractor by the company and performs work.",
        sort_order: 2,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "users",
        sync_token: 1,
        label: "Sub Contractor",
        identifier: "sub_contractor",
        description: "A person that is employed as a sub contractor by the company and performs work.",
        sort_order: 3,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "users",
        sync_token: 1,
        label: "Guest",
        identifier: "guest",
        description: "A person that is guest to the company and can only view certain information.",
        sort_order: 4,
        is_active: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },

];

export default UserObjectTypesData;