import gql from 'graphql-tag';

const GET_AUTH = gql`
  query GetAuth($id:ID!){
    getAuth(id: $id) {
      id
      user_id
      user_full_name
      user_account_status
      user_active
      company_id
      company_status
      company_active
      company_name
      role_id
      role_name
    }
  }
`;

export { GET_AUTH };