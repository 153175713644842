

import { combineReducers } from 'redux';
import LogInReducer from './LogInReducer';
import SignUpReducer from './SignUpReducer';
import ForgotPasswordReducer from './ForgotPasswordReducer';
import ObjectScreensReducer from './ObjectScreensReducer';
import CustomizePageLayoutReducer from './CustomizePageLayoutReducer';

export default combineReducers({
    loginScreen: LogInReducer,
    signupScreen: SignUpReducer,
    forgotPasswordScreen: ForgotPasswordReducer,
    objectScreens: ObjectScreensReducer,
    customizePageLayout: CustomizePageLayoutReducer
});