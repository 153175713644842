import { styled } from '@mui/material/styles';

const SidebarBackdrop = styled('div')`
    position: fixed;
    overflow-y: auto;
    width: 100%; 
    height: 100%; 
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0px;
    z-index: ${props => props.theme.zIndex.drawer - 2};
    -webkit-tap-highlight-color: transparent;
    border: none;

    ${props => {
        if (props.$isLeftHanded) {
            if (props.$isShown) {
                return `
                    left: 0px; 
                    opacity: 100%;
                `;
            } else {
                return `
                    left: -100%; 
                    opacity: 0%;
                `;
            }
        } else {
            if (props.$isShown) {
                return `
                    right: 0px; 
                    opacity: 100%;
                `;
            } else {
                return `
                    right: -100%; 
                    opacity: 0%;
                `;
            }
        }
    }}


    /* Hiding CSS */
    transition-timing-function: ease-in;
    transition: ${props => props.theme.transitions.duration.standard}ms;
`;

const SidebarBackdropButton = styled('button')`
    margin: 0;
    padding: 0;
    height: 100%; 
    background: rgba(0, 0, 0, 0.33);
    z-index: ${props => props.theme.zIndex.drawer - 1};
    -webkit-tap-highlight-color: transparent;
    border: none;
    width: 100%;

    /* Hiding CSS */
    transition-timing-function: ease-in;
    transition: ${props => props.theme.transitions.duration.standard}ms;
    ${props => props.$isShown ? 'display: flex;  opacity: 100%;' : `display: none; opacity: 0%;`}
`;

const SidebarDrawer = styled('div')`
    margin: 0px;
    position: fixed;
    overflow: auto;
    top: 0px;
    bottom: 0px;
    background-color: ${props => props.theme.palette.background.tertiary};
    box-shadow: -0.5px 0px 1px ${props => props.theme.boxShadows.color};
    
    display: flex;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
    display: flex;
    
    ${props => {
        // Handle width differences between mobile, tablet, and desktop
        if (props.$display_type === 'desktop') {
            if (props.$open) {
                return `
                    top: ${props.theme.variables.headerDesktopHeight}px;
                    min-width: ${props.theme.variables.sidebarFullWidth}px;
                    width: ${props.theme.variables.sidebarFullWidth}px;
                    max-width: ${props.theme.variables.sidebarFullWidth}px;
                    z-index: auto;
                    border-right: 1px solid ${props.theme.palette.app.primary};
                    border-left: none;
                    box-shadow: 0px 2px 3px ${props.theme.boxShadows.color};

                    /* Hiding CSS */
                    transition-timing-function: none;
                    transition: 0ms;
                `;
            } else {
                return `
                    top: ${props.theme.variables.headerDesktopHeight}px;
                    min-width: ${props.theme.variables.sidebarMediumWidth}px;
                    width: ${props.theme.variables.sidebarMediumWidth}px;
                    max-width: ${props.theme.variables.sidebarMediumWidth}px;
                    border-right: 1px solid ${props.theme.palette.app.primary};
                    border-left: none;
                    z-index: auto;
                    box-shadow: 0px 2px 3px ${props.theme.boxShadows.color};

                    /* Hiding CSS */
                    transition-timing-function: none;
                    transition: 0ms;
                `;
            }
            
        } else if (props.$display_type === 'tablet') {
            if (props.$open) {
                return `
                    top: 0px;
                    width: 60%;
                    min-width: 160px;
                    max-width: 240px;
                    z-index: ${props.theme.zIndex.drawer};
                    border-right: 1px solid ${props.theme.palette.app.primary};
                    border-left: none;
                    box-shadow: 0px 2px 3px ${props.theme.boxShadows.color};
                `;

            } else {
                return `
                    top: ${props.theme.variables.headerDesktopHeight}px;
                    min-width: ${props.theme.variables.sidebarMediumWidth}px;
                    width: ${props.theme.variables.sidebarMediumWidth}px;
                    max-width: ${props.theme.variables.sidebarMediumWidth}px;
                    border-right: 1px solid ${props.theme.palette.app.primary};
                    border-left: none;
                    z-index: auto;
                    box-shadow: 0px 2px 3px ${props.theme.boxShadows.color};

                    /* Hiding CSS */
                    transition-timing-function: none;
                    transition: 0ms;
                `;
            }
        } else if (props.$display_type === 'mobile') {
            if (props.$open) {
                return `
                    top: 0px;
                    width: 60%;
                    min-width: 160px;
                    max-width: 240px;
                    z-index: ${props.theme.zIndex.drawer};

                    transition-timing-function: ease-in;
                    transition: ${props.theme.transitions.duration.standard}ms;
                `;
            } else {
                return `
                    transition-timing-function: ease-in;
                    transition: ${props.theme.transitions.duration.standard}ms;
                `;
            }
        } 
    }}

    ${props => {
        if (props.$display_type === 'mobile') {
            if (props.$isLeftHanded) {
                if (props.$isShown) {
                    return `
                        left: 0px; 
                        right: auto;
                        border-right: 1px solid ${props.theme.palette.app.primary};
                        border-left: none;
                        box-shadow: 0px 2px 3px ${props.theme.boxShadows.color};
                    `;
                } else {
                    return `
                        left: -${props.theme.variables.sidebarFullWidth}px;
                        right: auto;
                        border-right: none;
                        border-left: none;
                        box-shadow: none;
                    `;
                }
            } else {
                if (props.$isShown) {
                    return `
                        right: 0px;
                        left: auto;
                        border-left: 1px solid ${props.theme.palette.app.primary};
                        border-right: none;
                        box-shadow: 0px -2px 3px ${props.theme.boxShadows.color};
                    `;
                } else {
                    return `
                        right: -${props.theme.variables.sidebarFullWidth}px;
                        left: auto;
                        border-left: 1px solid ${props.theme.palette.app.primary};
                        border-right: none;
                        box-shadow: none;
                    `;
                }
            }
        }
    }}

`;

const SidebarDrawerHeader = styled('div')`
    background-color: ${props => props.theme.palette.app.primary};
    color: ${props => props.theme.palette.app.primaryContrastText};
    border-bottom: 1px solid ${props => props.theme.palette.primary.dark};
    box-shadow: ${props => props.theme.boxShadows.lg};
    min-height: ${props => props.theme.variables.headerMobileHeight}px;
    z-index: ${props => props.theme.zIndex.drawer + 1};
    box-sizing: border-box;
    width: 100%;
    margin: 0px;
    padding: 0px 15px;
    font-size: 1.125rem;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    // Desktop 
    @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
        min-height: ${props => props.theme.variables.headerDesktopHeight}px;
        font-size: 1.375rem;
        font-weight: 700;
    }
`;


const SidebarDrawerList = styled('div')`
    margin: 0;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 20px;
    list-style: none;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 6px;
        display: block;
    }
    
    &:hover::-webkit-scrollbar {
        width: 6px;
        display: block;
    }
    
    &::-webkit-scrollbar-track {
        border-radius: 3px;
        height: 100%;
    }
    
    &::-webkit-scrollbar-thumb {
        background: ${props => props.theme.palette.text.disabled};
        border-radius: 3px;
        -webkit-box-shadow: inset 0 0 3px ${props => props.theme.boxShadows.color};
    }
`;

const SectionContainer = styled('div')`
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
`;

const SectionChildrenContainer = styled('div')`
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
    background-color: ${props => props.theme.palette.background.quaternary};
`;

const SectionTopItemContainer = styled('div')`
    color: ${props => props.theme.palette.text.primary};
    display: flex;
    flex-direction: row;
    margin: 0px;
    padding: 8px 12px 8px 6px;
    cursor: pointer;


    ${props => !props.$is_mobile ? `
        &:hover {
            background-color: ${props.theme.palette.background.quaternary};
            color: ${props.theme.palette.primary.main};
        }
    ` : null}

    ${props => props.$active && `
        color: ${props.theme.palette.primary.main};
        font-weight: 700;
    `}
    
    ${props => props.$section_open && `
        background-color: ${props.theme.palette.background.quaternary};
    `}

    ${props => {
        if ((props.$display_type === 'desktop' && !props.$open)) {
            // Desktop / Tablet 'medium'
            return `
                flex-direction: column-reverse;
                padding: 8px 0px;
            `;
        } else if ((props.$display_type === 'desktop' && props.$open) || (props.$display_type === 'tablet' && props.$open)) {
            // Desktop & Tablet 'full'
            return `
                
            `;
        } 
    }}
`;

const SectionDropdownButton = styled('button')`
    color: inherit;
    border: none;
    background-color: transparent;
    padding: 0px;
    margin: 0px auto;
    cursor: pointer;
    border-radius: 3px;

    ${props => !props.$is_mobile ? `
        &:hover {
            background-color: ${props.theme.palette.background.tertiary};
            box-shadow: 0.5px 0.5px 1px ${props.theme.boxShadows.color};
        }
    ` : null}

    
    ${props => {
        if ((props.$display_type === 'desktop' && !props.$open)) {
            // Desktop / Tablet 'medium'
            return `
                padding: 4px 0px;
            `;
        } else if ((props.$display_type === 'desktop' && props.$open) || (props.$display_type === 'tablet' && props.$open)) {
            // Desktop & Tablet 'full'
            return `
                
            `;
        } 
    }}
`;

const SectionTopItem = styled('div')`
    display: flex;
    flex-direction: row;
    margin: 0px;
    padding: 0px;
    width: -webkit-fill-available;
    background-color: transparent;
    border: none;

    ${props => {
        if ((props.$display_type === 'desktop' && !props.$open) || (props.$display_type === 'tablet' && !props.$open)) {
            // Desktop / Tablet 'medium'
            return `
                flex-direction: column;
                justify-content: center;
                align-items: center;
            `;
        } else if ((props.$display_type === 'desktop' && props.$open) || (props.$display_type === 'tablet' && props.$open)) {
            // Desktop & Tablet 'full'
            return `
                flex-direction: row;
            `;
        } 
    }}
`;


const SectionTopItemIcon = styled('div')`
    color: inherit;    
    min-width: auto;
    margin-right: 12px;
    display: inline-flex;
    flex-shrink: 0;
    cursor: pointer;

    ${props => {
        if ((props.$display_type === 'desktop' && !props.$open) || (props.$display_type === 'tablet' && !props.$open)) {
            return `
                margin: 0px;
            `;
        } else if ((props.$display_type === 'desktop' && props.$open) || (props.$display_type === 'tablet' && props.$open)) {
            return `
                margin-right: 10px;
            `;
        } 
    }}
`;

const SectionTopItemText = styled('h3')`
    flex: 1 1 auto;
    min-width: 0;
    max-width: 100%;
    margin: 0px auto;
    overflow: hidden;
    word-wrap: break-word;
    font-family: ${props => props.theme.typography.fontFamily};
    font-weight: ${props => props.theme.typography.fontWeightSemiBold};
    font-size: ${props => props.theme.typography.h6.fontSize};
    line-height: 1.5;

    ${props => {
        // Handle width differences between full, medium
        // "Medium" Sidebar (small text underneath)
        if ((props.$display_type === 'desktop' && !props.$open) || (props.$display_type === 'tablet' && !props.$open)) {
            return `
                font-family: ${props.theme.typography.fontFamily};
                font-weight: ${props.theme.typography.overline.fontWeight};
                font-size: ${props.theme.typography.overline.fontSize};
                text-align: center;
                margin: 1px 5px;
            `;
        } else if ((props.$display_type === 'desktop' && props.$open) || (props.$display_type === 'tablet' && props.$open)) {
            return `
                font-family: ${props.theme.typography.fontFamily};
                font-weight: ${props.theme.typography.fontWeightSemiBold};
                font-size: ${props.theme.typography.h6.fontSize};
                text-align: left;
                margin: 0px auto;
            `;
        } 
    }}

    ${props => props.$active ? `font-weight: ${props.theme.typography.fontWeightBold};` : null}

`;





const SidebarDrawerListItem = styled('div')`
    color: ${props => props.theme.palette.text.primary};
    margin: 1px 0px;
    padding: 8px 12px 8px 20px;
    font-size: ${props => props.theme.typography.h6};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    ${props => !props.$is_mobile ? `
        &:hover {
            color: ${props.theme.palette.primary.main};
            font-weight: 600;
        }
    ` : null}

    ${props => props.$active && `
        color: ${props.theme.palette.primary.main};
        font-weight: 700;
    `}

    ${props => {
        if ((props.$display_type === 'desktop' && !props.$open) || (props.$display_type === 'tablet' && !props.$open)) {
            // Desktop / Tablet 'medium'
            return `
                padding: 8px 0px;
                flex-direction: column;
            `;
        } else if ((props.$display_type === 'desktop' && props.$open) || (props.$display_type === 'tablet' && props.$open)) {
            // Desktop & Tablet 'full'
            return `
                padding: 8px 12px 8px 36px;
                flex-direction: row;
            `;
        } 
    }}
`;


const SidebarDrawerListItemIcon = styled('div')`
    color: inherit;    
    min-width: auto;
    margin-right: 12px;
    display: inline-flex;
    flex-shrink: 0;
    cursor: pointer;

    ${props => {
        if ((props.$display_type === 'desktop' && !props.$open) || (props.$display_type === 'tablet' && !props.$open)) {
            return `
                margin: 0px;
            `;
        } else if ((props.$display_type === 'desktop' && props.$open) || (props.$display_type === 'tablet' && props.$open)) {
            return `
                margin-right: 10px;
            `;
        } 
    }}
`;

const SidebarDrawerListItemText = styled('h3')`
    flex: 1 1 auto;
    min-width: 0;
    max-width: 100%;
    margin: 0px auto;
    overflow: hidden;
    word-wrap: break-word;
    font-family: ${props => props.theme.typography.fontFamily};
    font-weight: ${props => props.theme.typography.fontWeightMedium};
    font-size: ${props => props.theme.typography.body1.fontSize};
    line-height: 1.5;

    ${props => {
        // Handle width differences between full, medium
        // "Medium" Sidebar (small text underneath)
        if ((props.$display_type === 'desktop' && !props.$open) || (props.$display_type === 'tablet' && !props.$open)) {
            return `
                font-family: ${props.theme.typography.fontFamily};
                font-weight: ${props.theme.typography.overline.fontWeight};
                font-size: ${props.theme.typography.overline.fontSize};
                text-align: center;
                margin: 0px 5px;
            `;
        } else if ((props.$display_type === 'desktop' && props.$open) || (props.$display_type === 'tablet' && props.$open)) {
            return `
                font-family: ${props.theme.typography.fontFamily};
                font-weight: ${props.theme.typography.fontWeightMedium};
                font-size: ${props.theme.typography.body1.fontSize};
                text-align: left;
                margin: 0px auto;
            `;
        } 
    }}

    ${props => props.$active ? `font-weight: ${props.theme.typography.fontWeightBold};` : null}

`;


const SectionDivider = styled('div')`

    ${props => !props.$open ? `
        height: 2px;
        background-color: ${props.theme.palette.text.disabled};
        border-radius: 1px;
        margin: 0px 22px 10px 22px;
        padding: 0px;
    ` : null} 
`;


const Spacer = styled('div')`
    ${props => props.$width ? `width: ${props.$width}px;` : null} 
    ${props => props.$width ? `min-width: ${props.$width}px;` : null} 
    ${props => props.$width ? `max-width: ${props.$width}px;` : null} 
    ${props => props.$height ? `height: ${props.$height}px;` : null}
    ${props => props.$height ? `min-height: ${props.$height}px;` : null}
    ${props => props.$height ? `max-height: ${props.$height}px;` : null}
`;


export { 
    SidebarBackdrop,
    SidebarBackdropButton,
    SidebarDrawer,
    SidebarDrawerHeader,
    SectionDivider,
    SidebarDrawerList,
    SectionContainer,
    SectionChildrenContainer,
    SectionTopItemContainer,
    SectionDropdownButton,
    SectionTopItem,
    SectionTopItemIcon,
    SectionTopItemText,
    SidebarDrawerListItem,
    SidebarDrawerListItemIcon,
    SidebarDrawerListItemText,
    Spacer
};