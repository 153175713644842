
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';
import * as ROUTES from '../../../../navigation/routes';
import * as ScreenTypes from '../../../../types/ScreenTypes';
import * as ScreenIdentifiers from '../../../../screens/ScreenIdentifiers';
import * as ComponentTypes from '../../../../types/ProHelperComponentTypes';


const PaymentPageLayoutsData = [
    // Default
    {
        public_id: uniqid('page_'),
        sync_token: 1,
        identifier: identifier('Default').toLowerCase(),
        label: 'Default',
        description: 'View a Payment in detail.',
        status: 'published',
        revisions: 1,
        route: ROUTES.PAYMENT_DETAIL_PREFIX,
        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
        screen_identifier: ScreenIdentifiers.PAYMENT_PAGE_LAYOUT,
        template: null,
        object: null,
        object_identifier: 'payments',
        object_type: null,
        group: {
            label: 'Billing',
            identifier: 'billing',
            order: 5
        },
        sub_group: {
            label: 'Payments',
            identifier: 'payments',
            order: 3
        },
        components: {
            component_1: {
                id: "component_1",
                label: 'Header',
                object_identifier: 'payments',
                connection_type: 'parent',
                type: ComponentTypes.OBJECT_HEADER,
                header_type: 'action_bar',
                special_type: 'header',
                is_editable: true,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                    fields: [
                        {
                            label: "Subject",
                            identifier: "payments.subject",
                            is_required: true,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Description",
                            identifier: "payments.description",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Amount",
                            identifier: "payments.amount",
                            is_required: true,
                            is_read_only: true,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Status",
                            identifier: "payments.status",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Account",
                            identifier: "payments.account",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                    ],
                    first_visible_amount: {
                        mobile: 4,
                        tablet: 4,
                        desktop: 2
                    }
                }
            },
            component_2: {
                id: "component_2",
                label: 'Details',
                object_identifier: 'payments',
                connection_type: 'parent',
                type: ComponentTypes.OBJECT_DETAILS,
                special_type: null,
                header_type: 'normal',
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {
                    sections: [
                        // General
                        {
                            label: "Payment Information",
                            id: 'payment_info',
                            tab_order: 'left-right',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 6,
                                tablet: 4,
                                desktop: 8
                            },
                            fields: [
                                {
                                    label: "Primary Contact",
                                    identifier: "payments.primary_contact",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Primary Property",
                                    identifier: "payments.primary_property",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                }
                            ],
                        },
                        // System Information
                        {
                            label: "System Information",
                            id: 'system_info',
                            tab_order: 'left-right',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: false,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 6,
                                tablet: 4,
                                desktop: 8
                            },
                            fields: [
                                {
                                    label: "Popup Alert",
                                    identifier: "payments.popup_alert",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Payment ID",
                                    identifier: "payments.payment_id",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Created At",
                                    identifier: "payments.created_at",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Created By",
                                    identifier: "payments.created_by",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Last Updated At",
                                    identifier: "payments.last_updated_at",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Last Updated By",
                                    identifier: "payments.last_updated_by",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },

                            ],
                        },
                    ]
                }
            },
            component_3: {
                id: "component_3",
                label: 'Related',
                object_identifier: 'payments',
                connection_type: 'parent',
                type: ComponentTypes.RELATED,
                special_type: null,
                header_type: 'normal',
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {
                    related: [
                        {
                            label: 'Jobs',
                            identifier: 'related_jobs',
                            object_identifier: 'jobs',
                            display_field: 'jobs.title',
                            show_records_container_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            show_record_cell_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            records_tabs_first_visible_amount: {
                                mobile: 2,
                                tablet: 2,
                                desktop: 4
                            },
                            record_cells_first_visible_amount: {
                                mobile: 2,
                                tablet: 2,
                                desktop: 4
                            },
                            filters: [
                                {
                                    fields: ["jobs.account"],
                                    field_types: ["lookup_relationship"],
                                    operator: "equal",
                                    values: [
                                        { 
                                            type: "relationship",
                                            label: 'Parent Account',
                                            input: 'parent.id',
                                            data: 'parent.id',
                                            data_type: 'relationship',
                                            status: 'valid'
                                        }
                                    ]
                                },
                                {
                                    fields: ["jobs.is_active"],
                                    field_types: ["checkbox"],
                                    operator: "equal",
                                    values: [
                                        {
                                            type: "value",
                                            label: 'True',
                                            input: true,
                                            data: true,
                                            data_type: 'boolean',
                                            status: 'valid'
                                        }
                                    ]
                                }
                            ],
                            sort_field: "jobs.last_updated_at",
                            sort_by: ["jobs.last_updated_at"],
                            sort_method: "chronological",
                            sort_direction: "DESC",
                            search_value: '',
                            fields: [
                                {
                                    label: 'Subject',
                                    identifier: 'jobs.subject',
                                    is_read_only: false,
                                    is_visible: true
                                },
                                {
                                    label: 'Type',
                                    identifier: 'jobs.type',
                                    is_read_only: false,
                                    is_visible: true
                                },
                            ]
                        }
                    ]
                }
            },
            component_4: {
                id: "component_4",
                label: 'Timeline',
                object_identifier: 'payments',
                connection_type: 'parent',
                type: ComponentTypes.TIMELINE,
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                    notifcations: {
                        contacts: true,
                        properties: true,
                        tasks: true,
                        events: true,
                        notes: true,
                        attachments: true
                    }
                }
            }
        },
        sections: [
            {
                id: uniqid('section_'),
                anchor_name: 'header',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'seperate',
                columns: {
                    mobile: [{ id: '1', flexSize: 1, components: ["component_1"] }],
                    tablet: [{ id: '1', flexSize: 1, components: ["component_1"] }],
                    desktop: [{ id: '1', flexSize: 1, components: ["component_1"] }]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'line_items_schedule',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'tabs',
                columns: {
                    mobile: [{ id: '2', flexSize: 1, components: ["component_2"] }],
                    tablet: [{ id: '2', flexSize: 1, components: ["component_2"] }],
                    desktop: [{ id: '2', flexSize: 1, components: ["component_2"] }]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'related_schedule_activity_timeline',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'tabs',
                columns: {
                    mobile: [{ id: '2', flexSize: 1, components: [["component_3", "component_4"]] }],
                    tablet: [{ id: '2', flexSize: 1, components: ["component_3"] }, { id: '3', flexSize: 1, components: ["component_4"] }],
                    desktop: [{ id: '2', flexSize: 2, components: ["component_3"] }, { id: '3', flexSize: 1, components: ["component_4"] }]
                }
            },
        ],
        item_actions: [
            "payments.edit",
            "payments.archive"
        ],
        more_actions: [ ],
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        published_at: new Date(),
        published_by: null,
        
    }
];

export default PaymentPageLayoutsData;