
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';
import * as ROUTES from '../../../../navigation/routes';
import * as ScreenTypes from '../../../../types/ScreenTypes';
import * as ScreenIdentifiers from '../../../../screens/ScreenIdentifiers';
import * as ComponentTypes from '../../../../types/ProHelperComponentTypes';


const RolePageLayoutsData = [
    // Default
    {
        public_id: uniqid('page_'),
        sync_token: 1,
        identifier: identifier('Default').toLowerCase(),
        label: 'Default',
        description: 'View a Role in detail.',
        status: 'published',
        revisions: 1,
        route: ROUTES.ROLE_DETAIL_PREFIX,
        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
        screen_identifier: ScreenIdentifiers.ROLE_PAGE_LAYOUT,
        template: null,
        object: null,
        object_identifier: 'roles',
        object_type: null,
        group: {
            label: 'Security',
            identifier: 'security',
            order: 7
        },
        sub_group: {
            label: 'Roles',
            identifier: 'roles',
            order: 2
        },
        components: {
            component_1: {
                id: "component_1",
                label: 'Header',
                object_identifier: 'roles',
                connection_type: 'parent',
                type: ComponentTypes.OBJECT_HEADER,
                header_type: 'action_bar',
                special_type: 'header',
                is_editable: true,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                    fields: [
                        {
                            label: "Label",
                            identifier: "roles.label",
                            is_required: true,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Description",
                            identifier: "roles.description",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Type",
                            identifier: "roles.type",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Permission Set",
                            identifier: "roles.permission_set",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Owner",
                            identifier: "roles.owner",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                    ],
                    first_visible_amount: {
                        mobile: 4,
                        tablet: 4,
                        desktop: 2
                    }
                }
            },
            component_2: {
                id: "component_2",
                label: 'Details',
                object_identifier: 'roles',
                connection_type: 'parent',
                type: ComponentTypes.OBJECT_DETAILS,
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: true,
                metadata: {
                    sections: [
                        // General
                        {
                            label: "General Information",
                            id: 'general_info',
                            tab_order: 'left-right',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 4,
                                tablet: 2,
                                desktop: 2
                            },
                            fields: [
                                {
                                    label: "Description",
                                    identifier: "roles.description",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Tags",
                                    identifier: "roles.tags",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                            ],
                        },
                        // System Information
                        {
                            label: "System Information",
                            id: 'system_info',
                            tab_order: 'left-right',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: false,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 4,
                                tablet: 2,
                                desktop: 2
                            },
                            fields: [
                                {
                                    label: "Popup Alert",
                                    identifier: "roles.popup_alert",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Role ID",
                                    identifier: "roles.role_id",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Created At",
                                    identifier: "roles.created_at",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Created By",
                                    identifier: "roles.created_by",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Last Updated At",
                                    identifier: "roles.last_updated_at",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Last Updated By",
                                    identifier: "roles.last_updated_by",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },

                            ],
                        },
                    ]
                }
            },
            component_3: {
                id: "component_3",
                label: 'Permissions',
                object_identifier: 'roles',
                connection_type: 'parent',
                type: ComponentTypes.PERMISSIONS,
                special_type: null,
                header_type: 'normal',
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: true,
                metadata: {
                    
                }
            },
            component_4: {
                id: "component_4",
                label: 'Related',
                object_identifier: 'roles',
                connection_type: 'parent',
                type: ComponentTypes.RELATED,
                special_type: null,
                header_type: 'normal',
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {
                    
                }
            },
        },
        sections: [
            {
                id: uniqid('section_'),
                anchor_name: 'header',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'seperate',
                columns: {
                    mobile: [{ id: '1', flexSize: 1, components: ["component_1"]}],
                    tablet: [{ id: '1', flexSize: 1, components: ["component_1"]}],
                    desktop: [{ id: '1', flexSize: 1, components: ["component_1"]}]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'details',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'seperate',
                columns: {
                    mobile: [{ id: '2', flexSize: 1, components: ["component_2"]}],
                    tablet: [{ id: '2', flexSize: 1, components: ["component_2"]}],
                    desktop: [{ id: '2', flexSize: 1, components: ["component_2"]}]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'related_and_activities',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: null,
                template_identifier: null,
                shrink_method: 'tabs',
                columns: {
                    mobile: [{ id: '3', flexSize: 1, components: [["component_2", "component_3"]]}],
                    tablet: [{ id: '3', flexSize: 2, components: ["component_3"]}, { id: '4', flexSize: 1, components: ["component_4"]}],
                    desktop: [{ id: '3', flexSize: 2, components: ["component_3"]}, { id: '4', flexSize: 1, components: ["component_4"]}]
                }
            }
        ],
        item_actions: [
            "roles.edit",
            "roles.archive"
        ],
        more_actions: [ ],
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        published_at: new Date(),
        published_by: null,
        
    }
];

export default RolePageLayoutsData;