import moment from "moment";
import uniqid from 'uniqid'; // Import the uniqid library if you haven't already

export const attachmentList = [

    {
        file: {
            url: "https://picsum.photos/800/900", // Placeholder URL
            id: uniqid("db_"), // Generate unique ID using uniqid library
            size: "", // Size information can be filled dynamically based on file size
            type: "Image", // Example file type for image
            label: "Sample Image 1"
        }
    },
    {
        file: {
            url: "https://www.lorempixel.com/400/200", // Example URL for text file
            id: uniqid("db_"), // Generate unique ID using uniqid library
            size: "", // Size information can be filled dynamically based on file size
            type: "File", // Example file type for text file
            label: "Sample Text File"
        }
    },
    {
        file: {
            url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4", // Example URL for video file
            id: uniqid("db_"), // Generate unique ID using uniqid library
            size: "", // Size information can be filled dynamically based on file size
            type: "Video", // Example file type for video file
            label: "Sample Video"
        }
    }

];

export const emailList = [
    {
        address: "bobsmith37@gmail.com",
        is_verified: true,
        do_not_email: false
    },
    {
        address: "alovegas43@hotmail.com",
        is_verified: false,
        do_not_email: false
    },
    {
        address: "f4johnny@hotmail.com",
        is_verified: true,
        do_not_email: false
    },
    {
        address: "cloverpotato2@yahoo.com",
        is_verified: true,
        do_not_email: false
    },
    {
        address: "johnnysmith89@testcompany.com",
        is_verified: false,
        do_not_email: true
    }
];

export const phoneList = [
    {
        raw: "(925) 570-8890",
        number: "+19255708890",
        national: "(925) 570-8890",
        international: "+1 (925) 570-8890",
        rfc3966: 'tel:+1-925-570-8890',
        country: "US",
        calling_code: "+1",
        ext: '',
        area_code: '925',
        carrier_code: '',
        is_callable: true,
        do_not_call: false,
        is_textable: true,
        do_not_text: false,
        is_verified: true
    },
    {
        raw: "(510) 677-4529",
        number: "+15106774529",
        national: "(510) 677-4529",
        international: "+1 (510) 677-4529",
        rfc3966: 'tel:+1-510-677-4529',
        country: "US",
        calling_code: "+1",
        ext: '',
        area_code: '510',
        carrier_code: '',
        is_callable: true,
        do_not_call: false,
        is_textable: true,
        do_not_text: false,
        is_verified: true
    },
    {
        raw: "(510) 888-3498",
        number: "+15108883498",
        national: "(510) 888-3498",
        international: "+1 (510) 888-3498",
        country: "US",
        calling_code: "+1",
        ext: '',
        area_code: '510',
        carrier_code: '',
        is_callable: true,
        do_not_call: false,
        is_textable: true,
        do_not_text: false,
        is_verified: true
    },
    {
        raw: "(925) 788-6564",
        number: "+19257886564",
        national: "(925) 788-6564",
        international: "+1 (925) 788-6564",
        rfc3966: 'tel:+1-925-788-6564',
        country: "US",
        calling_code: "+1",
        ext: '',
        area_code: '925',
        carrier_code: '',
        is_callable: true,
        do_not_call: false,
        is_textable: true,
        do_not_text: false,
        is_verified: true
    },
];


export const emailTypeList = [
    "@gmail.com",
    "@yahoo.com",
    "@aol.com",
    '@danvillecoffeeshop.org'
];

export const companyList = [
    "Danville Pool Association",
    "San Ramon Yardscape",
    "Jandy Pool Service",
    'Pentair Inc.',
    'Home Depot',
    'Lowes',
    'General Pool & Spa'
];

export const urlList = [
    'https://fontawesome.com/v6/search?s=solid%2Cbrands',
    ';https://www.google.com/',
    'https://app.prohelper.io/'
];

export const websiteList = [
    "https://www.google.com/",
    "https://www.prohelper.com/",
    "https://www.quickbooks.intuit.com/",
    'https://www.google.com/',
    'https://www.atlantispoolsdanville.com/'
];

export const descriptionList = [
    "Barter agreement until 2021.",
    "New account, need to review all details soon.",
    "Converted account from Bob's pools",
    'This account was once an awesome customer, but then something bad happened to it like adding this really long description.',
    'Loves golfing and the 49ers.'
];

export const taxIDs = [
    "123-45-6789",
    "987-65-4321",
    "420-69-8008",
    '000-00-0000'
];

export const actionAlertList = [
    {
        message: "Need to setup a meeting to checkout their pool.",
        is_shown: true,
        type: "banner",
        date: new Date()
    },
    {
        message: "CUSTOMER HAS NOT PAID BILLS YET. NO SERVICE.",
        is_shown: true,
        type: "popup",
        date: new Date()
    },
    {
        message: "Create an invoice for recent work done.",
        is_shown: true,
        type: "banner",
        date: new Date()
    },
    {
        message: "Update account information, this is old account info.",
        is_shown: true,
        type: "banner",
        date: new Date()
    },
    {
        message: "BARTERING ONLY. DO NOT INVOICE.",
        is_shown: true,
        type: "popup",
        date: new Date()
    }
];

export const popupAlertList = [
    {
        message: "Update route stop day to tuesday.",
        is_shown: true,
        type: "banner",
        date: new Date()
    },
    {
        message: "Pays 15 days late each month.",
        is_shown: true,
        type: "banner",
        date: new Date()
    },
    {
        message: "DO NOT PARK ON THE DRIVEWAY",
        is_shown: true,
        type: "popup",
        date: new Date()
    },
    {
        message: "DO NOT PARK ON THE GRASS",
        is_shown: true,
        type: "popup",
        date: new Date()
    },
];


export const addressList = [
    {
        display: "91 Fawn Pl, Danville, CA 94506, USA",
        short: "91 Fawn Pl, Danville, CA, USA",
        long: "91 Fawn Place, Danville, California 94506, United States",
        line1: '91 Fawn Pl',
        line2: '',
        street_number: '91',
        route: 'Fawn Pl',
        prefix: '',
        locality: 'Danville',
        sub_locality: "",
        neighborhood: "",
        county: "Contra Costa County",
        state: 'ca',
        country: "US",
        postal_code: '94506',
        postal_code_prefix: '',
        postal_code_suffix: '',
        location: { lat: 37.7882812482197, lng: -121.98274377713619 },
        pluscode: 'Q2Q8+9VG Danville, California',
        place_id: "",
        types: [],
        floor: '',
        room: ''
    },
    {
        display: "9000 Crow Canyon Rd, Suite-S Box 369, Danville, CA 94506, USA",
        short: "9000 Crow Canyon Rd, Ste-S Box 369, Danville, CA, USA",
        long: "9000 Crow Canyon Rd, Suite-S Box 369, Danville, California 94506, United States",
        line1: '9000 Crow Canyon Rd',
        line2: 'Suite-S Box 369',
        street_number: '9000',
        route: 'Crow Canyon Rd',
        prefix: '',
        locality: 'Danville',
        sub_locality: "",
        neighborhood: "",
        county: "Contra Costa County",
        state: 'ca',
        country: "US",
        postal_code: '94506',
        postal_code_prefix: '',
        postal_code_suffix: '',
        location: { lat: 37.79787673161306, lng: -121.92315698592719 },
        pluscode: 'Q3XG+5P4 Danville, California',
        place_id: "",
        types: [],
        floor: '',
        room: ''
    },
    {
        display: "2100 Oneida Cir, Danville, CA 94526",
        short: "2100 Oneida Cir, Danville, CA, USA",
        long: "2100 Oneida Circle, Danville, California 94526, United States",
        line1: '2100 Oneida Cir',
        line2: '',
        street_number: '2100',
        route: 'Oneida Cir',
        prefix: '',
        locality: 'Danville',
        sub_locality: "",
        neighborhood: "",
        county: "Contra Costa County",
        state: 'ca',
        country: "US",
        postal_code: '94526',
        postal_code_prefix: '',
        postal_code_suffix: '',
        location: { lat: 37.78365909768782, lng: -121.96445886161854 },
        pluscode: 'Q2MP+F67 Danville, California',
        place_id: "",
        types: [],
        floor: '',
        room: ''
    },
    {
        display: "801 San Ramon Valley Blvd Suite D, Danville, CA 94526",
        short: "801 San Ramon Valley Blvd Ste-D, Danville, CA, USA",
        long: "801 San Ramon Valley Boulevard Suite D, Danville, California 94526, United States",
        line1: '801 San Ramon Valley Blvd',
        line2: 'Suite D',
        street_number: '529',
        route: 'San Ramon Valley Blvd',
        prefix: '',
        locality: 'Danville',
        sub_locality: "",
        neighborhood: "",
        county: "Contra Costa County",
        state: 'ca',
        country: "US",
        postal_code: '94526',
        postal_code_prefix: '',
        postal_code_suffix: '',
        location: { lat: 37.80957594785279, lng: -121.99431032395923 },
        pluscode: 'R254+R7 Danville, California',
        types: [],
        floor: '',
        room: ''
    },
];

export const imageList = [
    {
        url: "https://picsum.photos/200",
        path: null,
        label: "Lorem Ipsum Photo",
        description: "",
        keywords: ["Lorem Ipsum"],
        created_at: new Date(),
        last_updated_at: new Date(),
        size: [],
        location: null,
        byte_size: 1,
        file_type: "jpeg",
        author: "Test User"
    }
];


export const roleLabelList = [
    'Admin',
    'Service',
    'Office',
    'Billing',
    'Other'
];

export const requestLabelList = [
    'Can you come and clean my pool?',
    'I need my pool to be acid washed. Can you do that?',
    'My pool pump is not working. Can you repair it?',
    'There is a leak in my pool. Can you fix it?',
    'I need a new pool filter. Can you install it for me?',
    'My pool tiles are loose. Can you reattach them?',
    'Can you add pool chemicals for me?',
    'I need my pool to be drained and refilled. Can you do that?',
    'Can you replace my pool liner?',
    'I need my pool to be resurfaced. Can you do that?',
    'Can you repair my pool heater?',
    'My pool cover needs to be replaced. Can you install a new one?',
    'Can you install a new pool pump?',
    'I need to upgrade my pool lighting. Can you do that?',
    'Can you install a new pool filter system?',
    'I want to add a pool waterfall. Can you do that?',
    'Can you repair my pool skimmer?',
    'I need my pool to be winterized. Can you do that?',
    'Can you install a new pool heater?',
    'I need a new pool safety cover. Can you install it for me?'
];

export const footerMessageList = [
    'Exceeding Expectations, One Pool at a Time. We Appreciate Your Business!',
    'Making Your Pool Paradise a Reality, One Service at a Time.',
    'Your Pool, Our Priority.',
];

export const requestsList = [
    {
        subject: "Pool Cleaning Request",
        description: "Regular pool maintenance required.",
        instructions: "Clean and balance the pool chemicals; skim and vacuum the pool.",
        client_instructions: "Please clean and balance the pool chemicals, and make sure to skim and vacuum the pool during the maintenance.",
        type: 'service',
    },
    {
        subject: "Pool Repair Emergency",
        description: "Pool pump not working, urgent help needed.",
        instructions: "Diagnose and repair the pool pump ASAP.",
        client_instructions: "Diagnose and repair the pool pump urgently, as it's not working properly.",
        type: 'repair',
    },
    {
        subject: "Pool Remodel Inquiry",
        description: "Interested in remodeling an in-ground pool.",
        instructions: "Provide an estimate for an in-ground pool remodel.",
        client_instructions: "Provide us with an estimate for the remodel of an in-ground pool.",
        type: 'remodel',
    },
    {
        subject: "Pool Remodeling Consultation",
        description: "Considering a pool deck and lighting upgrade.",
        instructions: "Schedule a consultation to discuss remodeling options and costs.",
        client_instructions: "Please install a durable safety cover to ensure pool safety.",
        type: 'remodel',
    },
    {
        subject: "Pool Leak Detection",
        description: "Suspicion of a pool water leak.",
        instructions: "Conduct a leak detection test and repair any identified leaks.",
        client_instructions: "Conduct a leak detection test and promptly repair any identified leaks in the pool.",
        type: 'repair',
    },
    {
        subject: "Pool Cover Installation",
        description: "Need a safety cover for the pool.",
        instructions: "Install a durable safety cover for the pool.",
        client_instructions: "We need a cover for the pool; put a strong one on.",
        type: 'installation',
    },
    {
        subject: "Pool Startup",
        description: "Requesting seasonal pool startup. Community pool.",
        instructions: "Open the pool for summer, clean, and ensure equipment is operational.",
        client_instructions: "Get our pool ready for summer, make it clean and stuff.",
        type: 'pool_startup',
    },
    {
        subject: "Pool Heater Repair",
        description: "Pool heater not heating properly.",
        instructions: "Inspect and repair the pool heater for efficient heating.",
        client_instructions: "The pool heater isn't heating correctly; please inspect and repair.",
        type: 'repair',
    },
    {
        subject: "Pool Tile Replacement",
        description: "Cracked and damaged pool tiles.",
        instructions: "Replace damaged tiles and restore the pool's aesthetics.",
        client_instructions: "Replace damaged pool tiles to enhance the pool's appearance.",
        type: 'repair',
    },
    {
        subject: "Pool Fence Installation",
        description: "Need a safety fence around the pool.",
        instructions: "Install a secure pool fence for safety and compliance.",
        client_instructions: "We need a fence around the pool; put it up for our little baby's safety.",
        type: 'installation',
    },
    {
        subject: "Pool Chemical Balancing",
        description: "Requesting chemical balance check and adjustment.",
        instructions: "Test and balance pool chemicals for safe swimming conditions.",
        client_instructions: "Test and balance the pool chemicals to maintain safe swimming conditions.",
        type: 'service',
    },
    {
        subject: "Pool Pump Upgrade",
        description: "Interested in energy-efficient pool pump installation.",
        instructions: "Recommend and install an energy-efficient pool pump.",
        client_instructions: "Want a better pump for our pool; recommend and install it.",
        type: 'installation',
    },
    {
        subject: "Pool Filter Replacement",
        description: "Filter not functioning correctly.",
        instructions: "Assess and replace the pool filter system as needed.",
        client_instructions: "Assess and replace the pool filter system if it's not functioning correctly.",
        type: 'repair',
    },
    {
        subject: "Pool Lighting Repair",
        description: "Pool lights not working.",
        instructions: "Investigate and repair pool lighting for safety and ambiance.",
        client_instructions: "Our pool lights are out; see what's wrong and fix 'em.",
        type: 'repair',
    },
    {
        subject: "Pool Deck Resurfacing",
        description: "Considering resurfacing the pool deck.",
        instructions: "Provide estimate for pool deck resurfacing.",
        client_instructions: "Thinking of redoing the pool deck; what's it gonna cost?",
        type: 'remodel',
    },
    {
        subject: "Pool Vacuum Cleaner Purchase & Install",
        description: "Looking to buy a pool vacuum cleaner.",
        instructions: "Recommend and provide a quote for a suitable pool vacuum cleaner and installation.",
        client_instructions: "Recommend and provide a quote for a suitable pool vacuum cleaner for purchase",
        type: 'installation',
    },
    {
        subject: "Pool Algae Treatment",
        description: "Severe algae growth in the pool.",
        instructions: "Perform an intensive algae treatment to restore water clarity.",
        client_instructions: "Our pool's all green.",
        type: 'service',
    },
    {
        subject: "Pool Chlorine System Upgrade",
        description: "Interested in a more efficient chlorine system.",
        instructions: "Recommend and install an upgraded chlorine system.",
        client_instructions: "Please recommend and install a more efficient chlorine system for our pool.",
        type: 'installation',
    },
    {
        subject: "Pool Safety Inspection",
        description: "Need a safety inspection for pool compliance.",
        instructions: "Conduct a safety inspection and provide a report with recommendations.",
        client_instructions: "Conduct a thorough safety inspection of our pool and provide recommendations for compliance.",
        type: 'inspection',
    },
    {
        subject: "Pool Cover Repair",
        description: "Pool cover torn and in need of repair.",
        instructions: "Inspect and repair the pool cover for proper functionality.",
        client_instructions: "Inspect and repair our torn pool cover to ensure it functions correctly.",
        type: 'repair',
    },
    {
        subject: "Pool Control System Installation",
        description: "Considering an automated pool control system.",
        instructions: "Provide options and install an automated pool control system.",
        client_instructions: "Install an automated pool control system with your recommended features.",
        type: 'installation',
    },
    {
        subject: "Pool Tile Cleaning Request",
        description: "Pool tiles have become discolored and require cleaning.",
        instructions: "Clean and restore the appearance of pool tiles.",
        client_instructions: "Clean and restore the appearance of our pool tiles.",
        type: 'service',
    },
    {
        subject: "Pool Water Testing",
        description: "Uncertain about pool water quality, require testing.",
        instructions: "Test pool water for chemical balance and provide a detailed report.",
        client_instructions: "Test our pool water for chemical balance and provide a detailed report.",
        type: 'service',
    },
    {
        subject: "Pool Deck Expansion",
        description: "Interested in expanding the pool deck area.",
        instructions: "Provide design options and estimate for pool deck expansion.",
        client_instructions: "Discuss design options and provide an estimate for expanding our pool deck.",
        type: 'remodel',
    },
    {
        subject: "Pool Cover Installation Consultation",
        description: "Considering a new pool cover installation.",
        instructions: "Schedule a consultation to discuss pool cover options and costs.",
        client_instructions: "Schedule a consultation to discuss pool cover options and associated costs.",
        type: 'installation',
    },
    {
        subject: "Pool Heater Upgrade",
        description: "Looking to upgrade to a more energy-efficient pool heater.",
        instructions: "Recommend and install an energy-efficient pool heater.",
        client_instructions: "Recommend and install an energy-efficient pool heater upgrade.",
        type: 'installation',
    },
    {
        subject: "Pool Skimmer Replacement",
        description: "Current pool skimmer not functioning correctly.",
        instructions: "Replace the pool skimmer for better debris collection.",
        client_instructions: "Replace our malfunctioning pool skimmer for improved debris collection.",
        type: 'repair',
    },
    {
        subject: "Pool Filter Maintenance",
        description: "Need routine maintenance for the pool filter.",
        instructions: "Perform regular maintenance to ensure filter efficiency.",
        client_instructions: "Perform regular maintenance to ensure the efficiency of our pool filter.",
        type: 'service',
    },
    {
        subject: "Pool Fence Repair",
        description: "Pool fence in need of repair.",
        instructions: "Inspect and repair the pool fence for safety.",
        client_instructions: "Inspect and repair our pool fence to ensure safety.",
        type: 'repair',
    },
    {
        subject: "Pool Pump Maintenance",
        description: "Routine maintenance needed for the pool pump.",
        instructions: "Service and maintain the pool pump for optimal performance.",
        client_instructions: "Inspect and repair our pool fence to ensure safety.",
        type: 'service',
    },
    {
        subject: "Pool Lighting Upgrade",
        description: "Interested in upgrading to energy-efficient pool lighting.",
        instructions: "Recommend and install energy-efficient pool lighting.",
        client_instructions: "Recommend and install energy-efficient pool lighting upgrades.",
        type: 'installation',
    },
    {
        subject: "Pool Inspection for Home Sale",
        description: "Pool inspection needed for a home sale.",
        instructions: "Conduct a comprehensive pool inspection for potential buyers.",
        client_instructions: "Conduct a comprehensive pool inspection for potential buyers.",
        type: 'inspection',
    },
    {
        subject: "Pool Resurfacing Quote",
        description: "Considering resurfacing the pool interior.",
        instructions: "Provide a detailed quote for various pool resurfacing options.",
        client_instructions: "Provide a detailed quote for various pool resurfacing options.",
        type: 'remodel',
    },
    {
        subject: "Pool Deck Sealing",
        description: "Interested in sealing the pool deck for protection.",
        instructions: "Provide options for pool deck sealing and carry out the process.",
        client_instructions: "Provide options for pool deck sealing and carry out the process.",
        type: 'remodel',
    }
];

export const estimatesList = [
    {
        subject: "Pool Cleaning Service",
        description: "Regular pool cleaning and maintenance.",
        instructions: "Clean pool every two weeks.",
        client_instructions: "Use eco-friendly chemicals.",
        type: 'service',
        priority: 'medium',
        total: 12000,
        line_items: [
            {
                label: "Service - Pool Cleaning (Bi-Weekly)",
                type: "service",
                item: "pool_cleaning",
                price: 12000
            }
        ]
    },
    {
        subject: "Pool Heater Repair",
        description: "Repair malfunctioning pool heater.",
        instructions: "Diagnose and fix the heating issue.",
        client_instructions: "Use genuine replacement parts.",
        type: 'repair',
        priority: 'urgent',
        total: 35000,
        line_items: [
            {
                label: "Service - Pool Heater Repair",
                type: "service",
                item: "heater_repair",
                price: 35000
            },
        ]
    },
    {
        subject: "Pool Filter Replacement",
        description: "Replace old pool filter with a new one.",
        instructions: "Install a high-quality filter.",
        client_instructions: "Opt for a durable filter brand.",
        type: 'repair',
        priority: 'medium',
        total: 55000,
        line_items: [
            {
                label: "Pool Filter Part",
                type: "product",
                item: "pool_filter",
                price: 50000
            },
            {
                label: "Filter Replacement",
                type: "service",
                item: "labor",
                price: 5000
            },
        ]
    },
    {
        subject: "Pool Leak Detection",
        description: "Locate and repair pool leak.",
        instructions: "Use advanced detection methods.",
        client_instructions: "Provide a detailed report.",
        type: 'repair',
        priority: 'urgent',
        total: 60000,
        line_items: [
            {
                label: "Service - Leak Detection",
                type: "service",
                item: "repair",
                price: 60000
            },
        ]
    },
    {
        subject: "Pool Resurfacing",
        description: "Resurface pool interior with new plaster.",
        instructions: "Strip old plaster and apply new coat.",
        client_instructions: "Choose a smooth pebble finish.",
        type: 'remodel',
        priority: 'high',
        total: 800000,
        line_items: [
            {
                label: "Labor - Pool Resurfacing",
                type: "service",
                item: "labor",
                price: 600000
            },
            {
                label: "Materials - Plaster",
                type: "product",
                item: "plaster",
                price: 200000
            },
        ]
    },
    {
        subject: "Pool Pump Installation",
        description: "Install a new energy-efficient pool pump.",
        instructions: "Replace the old pump with a modern one.",
        client_instructions: "Recommend a cost-effective model.",
        type: 'installation',
        priority: 'medium',
        total: 90000,
        line_items: [
            {
                label: "Product - Pool Pump",
                type: "product",
                item: 'pool_pump',
                price: 70000
            },
            {
                label: "Labor - Pump Installation",
                type: "service",
                item: 'labor',
                price: 20000
            },
        ]
    },
    {
        subject: "Pool Deck Repairs",
        description: "Repair cracks and resurface the pool deck.",
        instructions: "Fix damaged areas and enhance safety.",
        client_instructions: "Use slip-resistant materials.",
        type: 'repair',
        priority: 'medium',
        total: 120000,
        line_items: [
            {
                label: "Labor - Pool Deck Repair",
                type: "service",
                item: "labor",
                price: 100000
            },
            {
                label: "Materials - Deck Resurfacing",
                type: "product",
                item: "resurfacing",
                price: 20000
            },
        ]
    },
    {
        subject: "Pool Safety Fence Installation",
        description: "Install a safety fence around the pool area.",
        instructions: "Ensure childproof locking system.",
        client_instructions: "Choose a sturdy design to prevent my kids going into the pool.",
        type: 'installation',
        priority: 'high',
        total: 250000,
        line_items: [
            {
                label: "Product - Safety Fence",
                type: "product",
                item: "safety_fence",
                price: 200000
            },
            {
                label: "Labor - Fence Installation",
                type: "service",
                item: "labor",
                price: 50000
            },
        ]
    },
    {
        subject: "Pool Light Upgrade",
        description: "Upgrade pool lighting to LED for energy efficiency.",
        instructions: "Replace existing lights with LED fixtures.",
        client_instructions: "Provide color-changing options.",
        type: 'installation',
        priority: 'medium',
        total: 80000,
        line_items: [
            {
                label: "Product - LED Pool Lights",
                type: "product",
                item: "pool_light",
                price: 60000
            },
            {
                label: "Labor - Light Installation",
                type: "service",
                item: "labor",
                price: 20000
            },
        ]
    },
    {
        subject: "Pool Tile Replacement",
        description: "Replace damaged tiles in the pool.",
        instructions: "Remove broken tiles and install new ones.",
        client_instructions: "Match the existing tile design.",
        type: 'repair',
        priority: 'medium',
        total: 100000,
        line_items: [
            {
                label: "Materials - Pool Tiles",
                type: "product",
                item: "tile",
                price: 80000
            },
            {
                label: "Labor - Tile Replacement",
                type: "service",
                item: "labor",
                price: 20000
            },
        ]
    },
    {
        subject: "Pool Cover Installation",
        description: "Install a retractable pool cover.",
        instructions: "Ensure easy operation and safety features.",
        client_instructions: "Opt for powered controls.",
        type: 'installation',
        priority: 'high',
        total: 300000,
        line_items: [
            {
                label: "Product - Pool Cover",
                type: "product",
                item: "pool_cover",
                price: 250000
            },
            {
                label: "Labor - Cover Installation",
                type: "service",
                item: "labor",
                price: 50000
            },
        ]
    },
    {
        subject: "Pool Automation System",
        description: "Install a smart pool automation system.",
        instructions: "Control pool functions remotely via smartphone.",
        client_instructions: "Choose a user-friendly interface.",
        type: 'installation',
        priority: 'high',
        total: 450000,
        line_items: [
            {
                label: "Product - Pool Automation System",
                type: "product",
                item: "computer_system",
                price: 400000
            },
            {
                label: "Labor - System Installation",
                type: "service",
                item: "labor",
                price: 50000
            },
        ]
    },
    {
        subject: "Pool Acid Washing",
        description: "Perform acid washing to remove stains.",
        instructions: "Thoroughly clean and restore pool's surface.",
        client_instructions: "Avoid damage to pool tiles.",
        type: 'service',
        priority: 'medium',
        total: 50000,
        line_items: [
            {
                label: "Service - Pool Acid Washing",
                type: "service",
                item: "acid_wash",
                price: 50000
            },
        ]
    },
    {
        subject: "Pool Pump Maintenance",
        description: "Perform routine maintenance on the pool pump.",
        instructions: "Check for leaks, lubricate, and replace worn parts.",
        client_instructions: "Ensure optimal pump performance.",
        type: 'maintenance',
        priority: 'medium',
        total: 20000,
        line_items: [
            {
                label: "Service - Pump Maintenance",
                type: "service",
                item: "pump_maintenance",
                price: 20000
            },
        ]
    },
    {
        subject: "Pool Algae Treatment",
        description: "Treat and eliminate algae growth in the pool.",
        instructions: "Apply algaecide and brush pool walls.",
        client_instructions: "Use a pet-safe algaecide.",
        type: 'service',
        priority: 'urgent',
        total: 30000,
        line_items: [
            {
                label: "Service - Algae Treatment",
                type: "service",
                item: "algae_cleaning",
                price: 30000
            },
        ]
    },
    {
        subject: "Pool Winterization",
        description: "Winterize the pool for the cold season.",
        instructions: "Drain, cover, and protect pool equipment.",
        client_instructions: "Ensure thorough winterization.",
        type: 'service',
        priority: 'high',
        total: 50000,
        line_items: [
            {
                label: "Service - Pool Winterization",
                type: "service",
                item: "pool_winterization",
                price: 50000
            },
        ]
    },
    {
        subject: "Pool Tile Cleaning",
        description: "Deep clean and restore pool tiles.",
        instructions: "Remove mineral deposits and stains.",
        client_instructions: "Use a cleaner thats safe for kids.",
        type: 'service',
        priority: 'low',
        total: 25000,
        line_items: [
            {
                label: "Service - Tile Cleaning",
                type: "service",
                item: "pool_cleaning",
                price: 25000
            },
        ]
    },
    {
        subject: "Pool Filter Upgrade",
        description: "Upgrade pool filter system for improved filtration.",
        instructions: "Replace the old filter with a high-efficiency model.",
        client_instructions: "Recommend a low-maintenance filter.",
        type: 'replacement',
        priority: 'high',
        total: 180000,
        line_items: [
            {
                label: "Product - Pool Filter Upgrade",
                type: "product",
                item: "filter",
                price: 150000
            },
            {
                label: "Labor - Filter Installation",
                type: "service",
                item: "labor",
                price: 30000
            },
        ]
    },
    {
        subject: "Pool Cleaning Service",
        description: "Regular pool cleaning and maintenance.",
        instructions: "Clean pool every week.",
        client_instructions: "Use eco-friendly chemicals.",
        type: 'service',
        priority: 'medium',
        total: 12000,
        line_items: [
            {
                label: "Service - Pool Cleaning (Bi-Weekly)",
                type: "service",
                item: "pool_cleaning",
                price: 12000
            },
        ]
    },
    {
        subject: "Pool Heater Repair",
        description: "Repair malfunctioning pool heater.",
        instructions: "Diagnose and fix the heating issue.",
        client_instructions: "Use genuine replacement parts.",
        type: 'repair',
        priority: 'urgent',
        total: 70000,
        line_items: [
            {
                label: "Service - Pool Heater Repair",
                type: "service",
                item: "heater_repair",
                price: 70000
            },
        ]
    },
    {
        subject: "Pool Filter Replacement",
        description: "Replace old pool filter with a new one.",
        instructions: "Install a high-quality filter.",
        client_instructions: "Opt for a durable filter brand.",
        type: 'replacement',
        priority: 'medium',
        total: 60000,
        line_items: [
            {
                label: "Product - Pool Filter Replacement",
                type: "product",
                item: "filter",
                price: 50000
            },
            {
                label: "Labor - Filter Replacement",
                type: "service",
                item: "labor",
                price: 10000
            },
        ]
    },
    {
        subject: "Pool Leak Inspection",
        description: "Locate and repair pool leak.",
        instructions: "Use advanced detection methods.",
        client_instructions: "Provide a detailed report.",
        type: 'repair',
        priority: 'urgent',
        total: 20000,
        line_items: [
            {
                label: "Service - Leak Inspection",
                type: "service",
                item: "leak_inspection",
                price: 20000
            },
        ]
    },
    {
        subject: "Pool Pump Installation",
        description: "Install a new energy-efficient pool pump.",
        instructions: "Replace the old pump with a modern one.",
        client_instructions: "Recommend a cost-effective model.",
        type: 'installation',
        priority: 'high',
        total: 85000,
        line_items: [
            {
                label: "Product - Pool Pump",
                type: "product",
                item: "pool_pump",
                price: 70000
            },
            {
                label: "Labor - Pump Installation",
                type: "service",
                item: "labor",
                price: 15000
            },
        ]
    }
]

export const taskLabelList = [
    'Clean and maintain the pool filter',
    'Test and balance the pool water chemistry',
    'Clean pool surfaces, including vacuuming, brushing, and scrubbing',
    'Repair or replacing pool equipment, such as pumps, heaters, and automatic cleaners',
    'Inspect and repair pool plumbing, including leaks and clogs',
    'Install pool cover and safety fence',
    'Provide regular pool maintenance services, weekly visit',
    'Upgrade pool features, such as lighting, waterfalls, and slides',
    'Provide pool opening and closing services for seasonal use',
    'Install and servicing pool heating systems',
    'Install and repairing pool liners',
    'Provide pool safety inspections and recommendations',
    'Provide pool remodeling and renovation services',
    'Install and servicing pool automation systems',
    'Provide pool and spa combination services',
    'Offer pool water treatment services, such as saltwater conversion or ozone generation',
    'Provide pool accessory sales and installation, such as ladders, handrails, and diving boards',
    'Provide pool and spa water testing and analysis services',
    'Offer pool financing and leasing options',
    'Provide pool and spa cleaning and maintenance supplies and products.'
];

export const jobLabelList = [
    'Pool Cleaning Service - Full',
    'Pool acid washing',
    'Pool pump replacement',
    'Pool leak repair (Diver Needed)',
    'Pool Filter Installion',
    'Pool tile reattachment',
    'Pool chemical balance',
    'Pool draining and refilling',
    'Pool liner inspection and replacement',
    'Pool resurfacing',
    'Pool heater replacement',
    'Pool cover replacement',
    'Pool pump replacement',
    'Pool light repair',
    'Pool filter replacement',
    'Inspection for waterfall installation',
    'Pool skimmer replacement',
    'Pool winterization',
    'Pool heater installation',
    'Pool cover inspection and installation'
];

export const workDescriptionList = [
    "I have a leaky faucet in my kitchen that needs fixing. It's been dripping for a week and I can't stand the sound anymore!",
    "I need someone to install a new toilet in my bathroom. The old one is broken and needs to be replaced.",
    "I just moved into a new house and I need to have the plumbing inspected to make sure everything is up to code.",
    "I have a clogged drain in my bathroom sink and no matter what I do, I can't seem to clear it.",
    "I need to replace the pipes under my sink because they are old and rusty.",
    "I have a leak in my basement that I need someone to come and fix as soon as possible.",
    "I need to have my water heater replaced. It's not heating up properly and I'm tired of cold showers.",
    "I have a pipe that's leaking in my wall and I need someone to come and fix it before it causes any more damage.",
    "I need to have a new garbage disposal installed in my kitchen sink.",
    "I have a leak in my roof that is causing water damage in my bathroom. I need someone to come and fix it.",
    "I need to have my septic tank pumped and cleaned.",
    "I have a clogged toilet and I need someone to come and unclog it.",
    "I need to have a new showerhead installed in my bathroom.",
    "I have a dripping faucet in my bathroom that's driving me crazy. Can someone come and fix it?",
    "I need to have a new gas line installed for my stove.",
    "I have a leak in my outdoor irrigation system and I need someone to come and fix it.",
    "I need to have my sump pump repaired or replaced.",
    "I have a backed up drain in my basement and I need someone to come and clear it.",
    "I need to have a new water line installed to my house.",
    "I have a leaky pipe in my crawl space and I need someone to come and fix it."
];

export const productsServicesLabelList = [
    'Pool cleaning and maintenance service',
    'Pool filter cleaning and replacement',
    'Pool pump repair and replacement',
    'Pool heater repair and replacement',
    'Pool leak detection and repair',
    'Pool liner repair and replacement',
    'Pool tile cleaning and repair',
    'Pool deck repair and resurfacing',
    'Pool fencing repair and installation',
    'Pool cover repair and installation',
    'Pool safety equipment installation and maintenance',
    'Pool lighting repair and installation',
    'Pool plumbing repair and installation',
    'Pool water chemistry maintenance',
    'Pool water testing and analysis',
    'Pool equipment upgrades',
    'Pool automation systems installation and repair',
    'Pool design and construction services',
    'Pool landscaping construction',
    'Pool safety inspections',
    'Pool accessories and supplies',
    'Pool resurfacing and refinishing'
];


export const eventLabelList = [
    'Meeting with Customer',
    'Techinical Training Seminar',
    'Internal Company Meeting',
    'Pickup Skimmer',
    'First Time Customer Meeting',
    'Pool Inspection',
    'Team Lunch',
    'Meeting with Vendor'
];

export const scheduleDescriptionList = [
    'Customer wants to review his service plan and talk about issues with his pool.',
    'Going over how to install and repair pool equipment like heaters, filters, and pumps.',
    'Company wide meeting to discuss previous week and upcoming items.',
    'Need to pickup customer skimmer from vendor',
    'Need to meet customer at house for the first time to show them services and check out pool.',
    'Need to inspect pool for any leaks or issues before servicing.',
    'Mandatory team lunch at arbys.',
    'Need to meet with pentair vendor to discuss warrenty program.'
];

export const getFakeEventScheduleInfo = () => {
    // Create variables
    let todayDate = new Date();
    const fakeEventSchedules = [];
    const isAllDay = Math.random() < 0.08;  // 30% chance for all-day events
    const isAnyTime = Math.random() < 0.1; // 10% chance for any-time events

    // Generate a random day within the week
    const randomDayOfWeek = Math.floor(Math.random() * 7);
    const eventDate = moment(todayDate).startOf('week').add(randomDayOfWeek, 'days');

    const randomHoursStart = Math.floor(Math.random() * 24);
    const randomHoursEnd = Math.floor(Math.random() * 24);

    const eventDuration = moment.duration(Math.ceil(((Math.floor(Math.random() * 180) + 1) / 10) * 10), 'minutes');
    let fakeEventStart = moment(eventDate).add(randomHoursStart, 'hours');
    let fakeEventEnd = moment(fakeEventStart).add(eventDuration);//.add(randomHoursEnd, 'hours');
    let minutesDuration = eventDuration.asMinutes();

    if (isAllDay) {
        fakeEventStart = moment(eventDate).startOf('day');
        fakeEventEnd = moment(eventDate).endOf('day');
    } else if (isAnyTime) {
        fakeEventStart = moment(eventDate);
        fakeEventEnd = moment(eventDate);
    }

    return {
        start: fakeEventStart.toDate(),
        end: fakeEventEnd.toDate(),
        duration: minutesDuration,
        is_all_day: isAllDay,
        is_any_time: isAnyTime
    };
};