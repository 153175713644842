
// This constants file is a representation of how data for object fields will be recieved by the ProHelper database once linked.
// import CompanyFieldsData from './object_fields/CompanyFieldsData';

// Schedule
import VisitFieldsData from './object_fields/VisitFieldsData';

// Work
import RequestFieldsData from './object_fields/RequestFieldsData';
import EstimateFieldsData from './object_fields/EstimateFieldsData';
import TaskFieldsData from './object_fields/TaskFieldsData';
import JobFieldsData from './object_fields/JobFieldsData';
import RouteFieldsData from './object_fields/RouteFieldsData';

// Client 
import AccountFieldsData from './object_fields/AccountFieldsData';
import ContactFieldsData from './object_fields/ContactFieldsData';
import PropertyFieldsData from './object_fields/PropertyFieldsData';

// Billing
import InvoiceFieldsData from './object_fields/InvoiceFieldsData';
import PaymentFieldsData from './object_fields/PaymentFieldsData';
import ItemFieldsData from './object_fields/ItemFieldsData';
import SubscriptionFieldsData from './object_fields/SubscriptionFieldsData';

// Audit
import LedgerFieldsData from './object_fields/LedgerFieldsData';
import ReportFieldsData from './object_fields/ReportFieldsData';

// Team
import UserFieldsData from './object_fields/UserFieldsData';

// Security
import RoleFieldsData from './object_fields/RoleFieldsData';
import PermissionSetFieldsData from './object_fields/PermissionSetFieldsData';

// Other
import LineItemFieldsData from './object_fields/LineItemFieldsData';
import NoteItemFieldsData from './object_fields/NoteFieldsData';

// Add Notes
// Add Attachments

// Add (concact) all the individual object fields data into one big array
const ObjectFieldsData = [].concat(
    RequestFieldsData,
    EstimateFieldsData,
    TaskFieldsData,
    JobFieldsData,
    RouteFieldsData,
    AccountFieldsData, 
    ContactFieldsData, 
    PropertyFieldsData, 
    LedgerFieldsData,
    ReportFieldsData,
    UserFieldsData,
    InvoiceFieldsData,
    PaymentFieldsData,
    SubscriptionFieldsData,
    ItemFieldsData,
    RoleFieldsData,
    PermissionSetFieldsData,
    VisitFieldsData,
    LineItemFieldsData,
    NoteItemFieldsData
);

// Export 1 main object fields array concatenation 
export default ObjectFieldsData;