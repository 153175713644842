

// This constants file is a representation of how data for object fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as FieldTypes from '../../types/FieldTypes';
import * as DatabaseTypes from '../../types/DatabaseTypes';

const SubscriptionDropdownSetsData = [
    // Subscription - Statuses
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Subscription Statuses",
        identifier: 'subscriptions.statuses',
        dropdown_plural_label: 'Statuses',
        dropdown_singular_label: "Status",
        description: "A set of all the different statuses for a Subscription.",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: false,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Trialing
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "subscriptions",
                sync_token: 1,
                label: "Trialing",
                identifier: "trialing",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "trialing"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Active
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "subscriptions",
                sync_token: 1,
                label: "Active",
                identifier: "active",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "active"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Past Due
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "subscriptions",
                sync_token: 1,
                label: "Past Due",
                identifier: "past_due",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "past_due"
                },
                color: null,
                sort_order: 3,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Unpaid
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "subscriptions",
                sync_token: 1,
                label: "Unpaid",
                identifier: "unpaid",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "unpaid"
                },
                color: null,
                sort_order: 4,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Cancelled
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "subscriptions",
                sync_token: 1,
                label: "Cancelled",
                identifier: "cancelled",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "cancelled"
                },
                color: null,
                sort_order: 5,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Incomplete
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "subscriptions",
                sync_token: 1,
                label: "Incomplete",
                identifier: "incomplete",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "incomplete"
                },
                color: null,
                sort_order: 6,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Expired
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "subscriptions",
                sync_token: 1,
                label: "Expired",
                identifier: "expired",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "expired"
                },
                color: null,
                sort_order: 7,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Expired
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "subscriptions",
                sync_token: 1,
                label: "Paused",
                identifier: "paused",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "paused"
                },
                color: null,
                sort_order: 8,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    },
    // Subscriptions - Tags
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Subscription Tags",
        identifier: 'subscriptions.tags',
        description: "A set of all the different tags for a Subscription.",
        dropdown_plural_label: 'Tags',
        dropdown_singular_label: "Tag",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Important
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "subscriptions",
                sync_token: 1,
                label: "Important",
                identifier: "important",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "important"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Recurring Service
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "subscriptions",
                sync_token: 1,
                label: "Recurring Service",
                identifier: "recurring_service",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "recurring_service"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    },
    // Subscriptions - Collection Methods
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Collection Methods",
        identifier: 'subscriptions.collection_methods',
        description: "A set of all the different ways to collect an Subscription from a customer.",
        dropdown_plural_label: 'Collection Methods',
        dropdown_singular_label: "Collection Method",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Charge Automatically
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "subscriptions",
                sync_token: 1,
                label: "Charge Automatically",
                identifier: "charge_automatically",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "charge_automatically"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Send Invoice
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "subscriptions",
                sync_token: 1,
                label: "Send Invoice",
                identifier: "send_invoice",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "send_invoice"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            }
        ]
    }
]

export default SubscriptionDropdownSetsData;