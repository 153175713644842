
// Import NPM Modules
import { combineReducers } from 'redux';

// Import Custom Modules
import NavigationItemsReducer from './NavigationItemsReducer';
import ObjectReducer from './ObjectsReducer';
import ObjectFieldsReducer from './ObjectFieldsReducer';
import ObjectTypesReducer from './ObjectTypesReducer';
import ActionsReducer from './ActionsReducer';
import DropdownSetsReducer from './DropdownSetsReducer';
import ListViewsReducer from './ListViewsReducer';
import PageLayoutsReducer from './PageLayoutsReducer';


// Combine Reducers and export as the default
export default combineReducers({
    actions: ActionsReducer,
    navigation_items: NavigationItemsReducer,
    objects: ObjectReducer,
    object_fields: ObjectFieldsReducer,
    object_types: ObjectTypesReducer,
    dropdown_sets: DropdownSetsReducer,
    list_views: ListViewsReducer,
    page_layouts: PageLayoutsReducer,
});