
// Import NPM Modules
import _ from 'lodash';
import uniqid from 'uniqid';
import moment from 'moment';

// Import Custom Modules
import PlatformPermissionsData from '../../constants/security/permissions/PlatformPermissionsData';
import AdminPermissionsData from '../../constants/security/permissions/AdminPermissionsData';
import UserPermissionsData from '../../constants/security/permissions/UserPermissionsData';
import StandardPermissionsData from '../../constants/security/standard_permissions/StandardPermissionsData';
import StandardPermissionSetsData from '../../constants/security/permission_sets/StandardPermissionSetsData';
import StandardRolesData from '../../constants/security/roles/StandardRolesData';
import * as FakeSecondaryData from '../../constants/fake_data/FakeSecondaryData';
/**
 * ~~ Class Description ~~
 *  A class containing functions that are related to the permissions and related areas in the program.
 *  
 */
export default class PermissionsHelper {



    CreateProgramPermissionsMap(Auth, Customizations) {
        // Check for valid input, if empty, early return nothing
        if (_.isEmpty(Customizations)) {
            return null;
        }

        let ProHelperPermissionsObjMap = {
            platform_permissions: {},
            admin_permissions: {},
            user_permissions: {},
            object_permissions: {},
            object_type_permissions: {},
            object_field_permissions: {},
            navigation_item_permissions: {},
            list_view_permissions: {},
            page_layout_permissions: {}
        };

        // Go through all standard static platform permissions and fill in the permission set information
        for (let i = 0; i < PlatformPermissionsData.length; i++) {
            // Get the current platform permission
            let currentPermission = { ...PlatformPermissionsData[i] };

            // Add dynamic information to the current permission
            currentPermission.id = uniqid('platform_pm_');
            currentPermission.company = Auth.company.id;
            currentPermission.type = 'platform_permission';

            // Set the Platform Permission privileges
            currentPermission.privileges = {
                use: true
            };
            // Add this permission to the platform permissions nested object mapping by identifier
            ProHelperPermissionsObjMap.platform_permissions[currentPermission.identifier] = currentPermission;
        }

        // Go through all standard static admin permissions and fill in the permission set information
        for (let i = 0; i < AdminPermissionsData.length; i++) {
            // Get the current admin permission
            let currentPermission = { ...AdminPermissionsData[i] };

            // Add dynamic information to the current permission
            currentPermission.id = uniqid('admin_pm_');
            currentPermission.company = Auth.company.id;
            currentPermission.type = 'admin_permission';

            // Set the Admin Permission privileges
            currentPermission.privileges = {
                use: true
            };

            // Add this permission to the admin permissions nested object mapping by identifier
            ProHelperPermissionsObjMap.admin_permissions[currentPermission.identifier] = currentPermission;
        }

        // Go through all standard static user permissions and fill in the permission set information
        for (let i = 0; i < UserPermissionsData.length; i++) {
            // Get the current user permission
            let currentPermission = { ...UserPermissionsData[i] };

            // Add dynamic information to the current permission
            currentPermission.id = uniqid('user_pm_');
            currentPermission.company = Auth.company.id;
            currentPermission.type = 'user_permission';

            // Set the User Permission privileges
            currentPermission.privileges = {
                use: true
            };

            // Add this permission to the user permissions nested object mapping by identifier
            ProHelperPermissionsObjMap.user_permissions[currentPermission.identifier] = currentPermission;
        }
        
        // Go through all standard static navigation data and fill in the permission set information
        for (let i = 0; i < Customizations.navigation_items.length; i++) {

            // Create blank current permission to mutate
            let currentNavigationItem = Customizations.navigation_items[i];
            let currentPermission = { };

            // Add dynamic information to the current permission
            currentPermission.id = uniqid('nav_pm_');
            currentPermission.company = Auth.company.id;
            currentPermission.type = 'navigation_item_permission';
            currentPermission.identifier = currentNavigationItem.identifier;
            currentPermission.label = currentNavigationItem.label;
            currentPermission.description = currentNavigationItem.description;
            currentPermission.group = currentNavigationItem.group;
            currentPermission.sub_group = currentNavigationItem.sub_group;
            currentPermission.dependent_permissions = currentNavigationItem.dependent_permissions;
            currentPermission.created_version = 0.5;
            currentPermission.current_version = 0.5;
            
            // Set the Navigation Item Permission privileges
            currentPermission.privileges = {
                active: currentNavigationItem.is_active,
                visible: currentNavigationItem.is_visible
            };
            
            // Add this permission to the Navigation Items permissions nested object mapping by identifier
            ProHelperPermissionsObjMap.navigation_item_permissions[currentPermission.identifier] = currentPermission;
        }

        // Go through all standard dynamic customizations objects data and fill in the permission set information
        for (let i = 0; i < Customizations.objects.length; i++) {
            // Create blank current permission to mutate`
            let currentObjectItem = Customizations.objects[i];
            let currentPermission = { };

            // Add dynamic information to the current permission
            currentPermission.id = uniqid('object_pm_');
            currentPermission.company = Auth.company.id;
            currentPermission.type = 'object_permission';
            currentPermission.linked_public_id = currentObjectItem.public_id;
            currentPermission.identifier = currentObjectItem.identifier;
            currentPermission.object_identifier = currentObjectItem.identifier;
            currentPermission.label = currentObjectItem.label_plural;
            currentPermission.description = currentObjectItem.description;
            currentPermission.group = currentObjectItem.group;
            currentPermission.sub_group = currentObjectItem.sub_group;
            currentPermission.dependent_permissions = currentObjectItem.dependent_permissions;
            currentPermission.created_version = 0.5;
            currentPermission.current_version = 0.5;

            // Set the Object Permission privileges
            currentPermission.privileges = {
                read: true,
                create: true,
                edit: true,
                delete: true,
                view_all: true,
                modify_all: true
            };

            // Add this permission to the Object permissions nested object mapping by identifier
            ProHelperPermissionsObjMap.object_permissions[currentPermission.identifier] = currentPermission;
        }

        // Go through all standard dynamic customizations object types data and fill in the permission set information
        for (let i = 0; i < Customizations.object_types.length; i++) {

            // Create blank current permission to mutate
            let currentObjectTypeItem = Customizations.object_types[i];
            let currentPermission = { };

            // Reference the parent object permission
            const parent_object_permission = ProHelperPermissionsObjMap.object_permissions[currentObjectTypeItem.object_identifier];

            // Add dynamic information to the current permission
            currentPermission.id = uniqid('object_type_pm_');
            currentPermission.company = Auth.company.id;
            currentPermission.type = 'object_type_permission';
            currentPermission.linked_public_id = currentObjectTypeItem.public_id;
            currentPermission.identifier = currentObjectTypeItem.identifier;
            currentPermission.label = currentObjectTypeItem.label;
            currentPermission.description = currentObjectTypeItem.description;
            currentPermission.dependent_permissions = currentObjectTypeItem.dependent_permissions;
            currentPermission.object_identifier = currentObjectTypeItem.object_identifier;
            currentPermission.group = parent_object_permission.group;
            currentPermission.sub_group = parent_object_permission.sub_group;
            currentPermission.created_version = 0.5;
            currentPermission.current_version = 0.5;

            // Set the Object Type Permission privileges
            currentPermission.privileges = {
                read: true,
                create: true,
                edit: true,
                delete: true
            };

            if (_.isEmpty(ProHelperPermissionsObjMap.object_type_permissions[currentPermission.object_identifier])) {
                ProHelperPermissionsObjMap.object_type_permissions[currentPermission.object_identifier] = {};
            }

            // Add this permission to the Object Types permissions nested object mapping by identifier
            ProHelperPermissionsObjMap.object_type_permissions[currentPermission.object_identifier][currentPermission.identifier] = currentPermission;
        }

        // Go through all standard dynamic customizations object fields data and fill in the permission set information
        for (let i = 0; i < Customizations.object_fields.length; i++) {

            // Create blank current permission to mutate
            const currentObjectFieldItem = Customizations.object_fields[i];
            let currentPermission = {};

            if (currentObjectFieldItem.is_active && currentObjectFieldItem.is_visible) {
                // Reference the parent object permission
                const parent_object_permission = ProHelperPermissionsObjMap.object_permissions[currentObjectFieldItem.object_identifier];

                // Add dynamic information to the current permission
                currentPermission.id = uniqid('object_field_pm_');
                currentPermission.company = Auth.company.id;
                currentPermission.type = 'object_field_permission';
                currentPermission.linked_public_id = currentObjectFieldItem.public_id;
                currentPermission.object_identifier = currentObjectFieldItem.object_identifier;
                currentPermission.identifier = currentObjectFieldItem.identifier;
                currentPermission.label = currentObjectFieldItem.label;
                currentPermission.description = currentObjectFieldItem.description;
                currentPermission.group = parent_object_permission.group;
                currentPermission.sub_group = parent_object_permission.sub_group;
                currentPermission.dependent_permissions = currentObjectFieldItem.dependent_permissions;
                currentPermission.created_version = 0.5;
                currentPermission.current_version = 0.5;

                // Set the Object Field Permission privileges
                currentPermission.privileges = {
                    read: true,
                    edit: true
                };

                if (_.isEmpty(ProHelperPermissionsObjMap.object_field_permissions[currentPermission.object_identifier])) {
                    ProHelperPermissionsObjMap.object_field_permissions[currentPermission.object_identifier] = {};
                }

                // Add this permission to the Object Fields permissions nested object mapping by identifier
                ProHelperPermissionsObjMap.object_field_permissions[currentPermission.object_identifier][currentPermission.identifier] = currentPermission;
            }

        }

        // Go through all standard dynamic customizations list views data and fill in the permission set information
        for (let i = 0; i < Customizations.list_views.length; i++) {

            // Create blank current permission to mutate
            let current_list_view = Customizations.list_views[i];
            let currentPermission = { };

            // Reference the parent object permission
            const parent_object_permission = ProHelperPermissionsObjMap.object_permissions[current_list_view.object_identifier];

            // Add dynamic information to the current permission
            currentPermission.id = uniqid('list_view_pm_');
            currentPermission.company = Auth.company.id;
            currentPermission.type = 'list_view_permission';
            currentPermission.linked_public_id = current_list_view.public_id;
            currentPermission.object_identifier = current_list_view.object_identifier;
            currentPermission.identifier = current_list_view.identifier;
            currentPermission.label = current_list_view.name;
            currentPermission.description = current_list_view.description;
            currentPermission.dependent_permissions = current_list_view.dependent_permissions;
            currentPermission.group = parent_object_permission.group;
            currentPermission.sub_group = parent_object_permission.sub_group;
            currentPermission.created_version = 0.5;
            currentPermission.current_version = 0.5;

            // Set the List View Permission privileges
            currentPermission.privileges = {
                read: true,
                edit: true
            };

            if (_.isEmpty(ProHelperPermissionsObjMap.list_view_permissions[currentPermission.object_identifier])) {
                ProHelperPermissionsObjMap.list_view_permissions[currentPermission.object_identifier] = {};
            }
            // Add this permission to the List Views permissions nested object mapping by identifier
            ProHelperPermissionsObjMap.list_view_permissions[currentPermission.object_identifier][currentPermission.identifier] = currentPermission;
        }

        // Go through all standard dynamic customizations page layouts data and fill in the permission set information
        for (let i = 0; i < Customizations.page_layouts.length; i++) {

            // Create blank current permission to mutate
            let current_page_layout = Customizations.page_layouts[i];
            let currentPermission = { };

            // Add dynamic information to the current permission
            currentPermission.id = uniqid('page_layout_pm_');
            currentPermission.company = Auth.company.id;
            currentPermission.type = 'page_layout_permission';
            currentPermission.linked_public_id = current_page_layout.public_id;
            currentPermission.object_identifier = current_page_layout.object_identifier;
            currentPermission.identifier = current_page_layout.identifier;
            currentPermission.label = current_page_layout.name;
            currentPermission.description = current_page_layout.description;
            currentPermission.dependent_permissions = current_page_layout.dependent_permissions;
            currentPermission.group = current_page_layout.group;
            currentPermission.sub_group = current_page_layout.sub_group;
            currentPermission.created_version = 0.5;
            currentPermission.current_version = 0.5;

            // Set the Page Layout Permission privileges
            currentPermission.privileges = {
                read: true,
                edit: true
            };

            if (_.isEmpty(ProHelperPermissionsObjMap.page_layout_permissions[currentPermission.group.identifier])) {
                ProHelperPermissionsObjMap.page_layout_permissions[currentPermission.group.identifier] = {};
            }

            if (_.isEmpty(ProHelperPermissionsObjMap.page_layout_permissions[currentPermission.group.identifier][currentPermission.sub_group.identifier])) {
                ProHelperPermissionsObjMap.page_layout_permissions[currentPermission.group.identifier][currentPermission.sub_group.identifier] = {};
            }
            
            // Add this permission to the Page Layout permissions nested object mapping by identifier
            ProHelperPermissionsObjMap.page_layout_permissions[currentPermission.group.identifier][currentPermission.sub_group.identifier][currentPermission.identifier] = currentPermission;
        }

        return ProHelperPermissionsObjMap;
    }


    CreatePermissionSet(PermissionSetIdentifier, Auth, ProHelperPermissions, Customizations) {
        // Check for valid inputs, if empty, early return nothing
        if (_.isEmpty(Auth) || _.isEmpty(Customizations) || _.isEmpty(ProHelperPermissions) || PermissionSetIdentifier === undefined || PermissionSetIdentifier === null) {
            return null;
        }

        let newPermissionSet = { };

        const StandardPermissionSetData = StandardPermissionSetsData[PermissionSetIdentifier];

        // Get all necessary dropdown dropdowns lists to fake populate data like getting from a graphql query (not raw)
        const permissionSetObjectFieldsMap = Customizations.object_fields.permission_sets;
        const permissionSetTypesMap = Customizations.object_types.permission_sets;
        const permissionSetStatusesObjMap = Customizations.dropdown_sets[permissionSetObjectFieldsMap.status.dropdown_set_options.object_identifier][permissionSetObjectFieldsMap.status.dropdown_set].dropdowns;
        const permissionSetStatusesList = Object.values(permissionSetStatusesObjMap);
        const permissionSetTagsObjMap = Customizations.dropdown_sets[permissionSetObjectFieldsMap.tags.dropdown_set_options.object_identifier][permissionSetObjectFieldsMap.tags.dropdown_set].dropdowns;
        const permissionSetTagsList = Object.values(permissionSetTagsObjMap);
        const permissionSetItemActionsObjMap = Customizations.actions.permission_sets.item_actions;
        const permissionSetItemActionsList = Object.values(permissionSetItemActionsObjMap);

        // Database ID
        newPermissionSet.id = uniqid(`db_`);
        // Identifier
        newPermissionSet.identifier = StandardPermissionSetData.identifier;
        // Permission Set ID
        newPermissionSet.permission_set_id = uniqid(`pmset_`);
        // Company ID
        newPermissionSet.company = Auth.company.id;
        // Label
        newPermissionSet.label = StandardPermissionSetData.label;
        // Description
        newPermissionSet.description = StandardPermissionSetData.description;
        // Init Permissions Field
        newPermissionSet.permissions = [];
        // Type
        newPermissionSet.type = permissionSetTypesMap[StandardPermissionSetData.type];
        // Status
        newPermissionSet.status =  (Math.random() < 0.94) ? permissionSetStatusesObjMap.active : (!_.isEmpty(permissionSetStatusesList) ? permissionSetStatusesList[_.random(0, (permissionSetStatusesObjMap.length - 1))] : null);
        // Tags
        const randomNumOfFakeTags = (_.random(0, 5));
        if (randomNumOfFakeTags > 0 && !_.isEmpty(permissionSetTagsList)) {
            let tagsObjectMapping = {};
            for (let i = 0; i < randomNumOfFakeTags; i++) {
                const fakeTag = permissionSetTagsList[_.random(0, (permissionSetTagsList.length - 1))];
                tagsObjectMapping[fakeTag.identifier] = fakeTag;
            }
            newPermissionSet.tags = Object.values(tagsObjectMapping);
        }
        // Owner
        newPermissionSet.owner = null;
        // Is Active
        newPermissionSet.is_active = (Math.random() < 0.92) ? true : false;
        // Is Archived
        newPermissionSet.is_archived = false;
        // Is Deleted
        newPermissionSet.is_deleted = false;
        // Is Draft
        newPermissionSet.is_draft = false;
        // Is Template
        newPermissionSet.is_template = false;
        // Is Custom
        newPermissionSet.is_custom = false;
        // Popup Alert
        newPermissionSet.popup_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;  
        // Created At
        newPermissionSet.created_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 90), 'days')).toDate();
        // Created By
        newPermissionSet.created_by = null;
        // Last Updated At
        newPermissionSet.last_updated_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Updated By
        newPermissionSet.last_updated_by = null;
        // Archived Alert
        newPermissionSet.archived_alert = null;
        // Archived At
        newPermissionSet.archived_at = null;
        // Archived By
        newPermissionSet.archived_by = null;
        // Sync Token
        newPermissionSet.sync_token = 1;
        // Suggested Action
        newPermissionSet.suggested_action = (Math.random() < 0.21) ? (permissionSetItemActionsList[_.random(1, (permissionSetItemActionsList.length - 1))]) : null;
        // Metadata
        newPermissionSet.metadata = {};
        // Settings
        newPermissionSet.settings = {};
        // Custom Fields
        newPermissionSet.custom_fields = {};
        // Created Version
        newPermissionSet.created_version = StandardPermissionSetData.created_version;
        // Current Version
        newPermissionSet.current_version = StandardPermissionSetData.current_version;

        let permissions_mapping = {
            platform_permissions: { },
            admin_permissions: { },
            user_permissions: { },
            object_permissions: { },
            object_type_permissions: { },
            object_field_permissions: { },
            navigation_item_permissions: { },
            list_view_permissions: { },
            page_layout_permissions: { }
        };

        const permission_set_permissions_skeleton = StandardPermissionsData[PermissionSetIdentifier];



        for (let permission_type in permission_set_permissions_skeleton) {

            const permission_type_privileges_container = permission_set_permissions_skeleton[permission_type];

            switch (permission_type) {
                case ('platform_permissions'):
                case ('admin_permissions'):
                case ('user_permissions'):
                case ('navigation_item_permissions'):
                case ('object_permissions'):
                    for (let permission_identifier in permission_type_privileges_container) {
                        const current_permission_privileges = permission_type_privileges_container[permission_identifier];

                        if (!_.isEmpty(ProHelperPermissions[permission_type][permission_identifier])) {
                            let new_platform_permission = {...ProHelperPermissions[permission_type][permission_identifier]};
                            new_platform_permission.permission_set = newPermissionSet.id;
    
                            if (!_.isEmpty(current_permission_privileges)) {
                                new_platform_permission.privileges = {...current_permission_privileges};
                            }

                            permissions_mapping[permission_type][new_platform_permission.identifier] = new_platform_permission;
    
                            newPermissionSet.permissions.push(new_platform_permission);
                        }
                    }
                    break;
                case ('object_type_permissions'):
                case ('object_field_permissions'):
                case ('list_view_permissions'):
                case ('page_layout_permissions'):
                case ('search_view_permissions'):

                    let customizations_sub_field = '';

                    switch (permission_type) {
                        case ('object_type_permissions'):
                            customizations_sub_field = 'object_types';
                            break;
                        case ('object_field_permissions'):
                            customizations_sub_field = 'object_fields';
                            break;
                        case ('list_view_permissions'):
                            customizations_sub_field = 'list_views';
                            break;
                        case ('page_layout_permissions'):
                            customizations_sub_field = 'page_layouts';
                            break;
                        case ('search_view_permissions'):
                            customizations_sub_field = 'search_views';
                            break;
                        default:
                            break;
                    }

                    for (let current_object_identifier in Customizations.object_types) {
                        const object_permission = permissions_mapping.object_permissions[current_object_identifier];
                        
                        const current_object_customizations_mapping = Customizations[customizations_sub_field][current_object_identifier];

                        if (!_.isEmpty(object_permission)) {
                            for (let current_object_customizations_identifier in current_object_customizations_mapping) {
                                if (!_.isEmpty(ProHelperPermissions[permission_type][current_object_identifier])) {
                                    if (!_.isEmpty(ProHelperPermissions[permission_type][current_object_identifier][current_object_customizations_identifier])) {
                                        let new_object_customization_permission = {...ProHelperPermissions[permission_type][current_object_identifier][current_object_customizations_identifier]};
                                        new_object_customization_permission.permission_set = newPermissionSet.id;
                                        new_object_customization_permission.group = object_permission.group;
        
                                        switch (permission_type) {
                                            case ('object_type_permissions'):
                                                new_object_customization_permission.privileges = { ...object_permission.privileges };
                                                break;
                                            case ('object_field_permissions'):
                                            case ('list_view_permissions'):
                                            case ('page_layout_permissions'):
                                            case ('search_view_permissions'):
                                                new_object_customization_permission.privileges = {
                                                    read: object_permission.privileges.read,
                                                    edit: object_permission.privileges.edit
                                                };
                                                break;
                                            default:
                                                break;
                                        }
        
                                        if (_.isEmpty(permissions_mapping[permission_type][current_object_identifier])) {
                                            permissions_mapping[permission_type][current_object_identifier] = {};
                                        }
        
                                        permissions_mapping[permission_type][current_object_identifier][current_object_customizations_identifier] = new_object_customization_permission;
        
                                        newPermissionSet.permissions.push(new_object_customization_permission);
                                    }
                                }
                            }
                        }
                    }
                    break;
                default:
                    console.log('permission_type not handled yet', permission_type);
                    break;
            }
        }

        return newPermissionSet;

    }


    
    CreateRole(RoleIdentifier, Auth, ProHelperPermissions, Customizations, PermissionSet) {
        // Check for valid inputs, if empty, early return nothing
        if (_.isEmpty(Auth) || _.isEmpty(Customizations) || _.isEmpty(ProHelperPermissions) || RoleIdentifier === undefined || RoleIdentifier === null) {
            return null;
        }

        let newRole = { };

        const StandardRoleData = StandardRolesData[RoleIdentifier];

        // Get all necessary dropdown dropdowns lists to fake populate data like getting from a graphql query (not raw)
        const roleObjectFieldsMap = Customizations.object_fields.roles;
        const roleTypesMap = Customizations.object_types.roles;
        const roleStatusesObjMap = Customizations.dropdown_sets[roleObjectFieldsMap.status.dropdown_set_options.object_identifier][roleObjectFieldsMap.status.dropdown_set].dropdowns;
        const roleStatusesList = Object.values(roleStatusesObjMap);
        const roleTagsObjMap = Customizations.dropdown_sets[roleObjectFieldsMap.tags.dropdown_set_options.object_identifier][roleObjectFieldsMap.tags.dropdown_set].dropdowns;
        const roleTagsList = Object.values(roleTagsObjMap);
        const roleItemActionsObjMap = Customizations.actions.permission_sets.item_actions;
        const roleItemActionsList = Object.values(roleItemActionsObjMap);

        // Database ID
        newRole.id = uniqid(`db_`);
        // Sync Token
        newRole.sync_token = 1;
        // Identifier
        newRole.identifier = StandardRoleData.identifier;
        // Role ID
        newRole.role_id = uniqid(`role_`);
        // Company ID
        newRole.company = Auth.company.id;
        // Label
        newRole.label = StandardRoleData.label;
        // Description
        newRole.description = StandardRoleData.description;
        // Type
        newRole.type = roleTypesMap[StandardRoleData.type];
        // Status
        newRole.status = (Math.random() < 0.94) ? roleStatusesObjMap.active : (!_.isEmpty(roleStatusesList) ? roleStatusesList[_.random(0, (roleStatusesList.length - 1))] : null);
        // Tags
        const randomNumOfFakeTags = (_.random(0, 5));
        if (randomNumOfFakeTags > 0 && !_.isEmpty(roleTagsList)) {
            let tagsObjectMapping = {};
            for (let i = 0; i < randomNumOfFakeTags; i++) {
                const fakeTag = roleTagsList[_.random(0, (roleTagsList.length - 1))];
                tagsObjectMapping[fakeTag.identifier] = fakeTag;
            }
            newRole.tags = Object.values(tagsObjectMapping);
        }
        // Permission Set
        newRole.permission_set = PermissionSet;
        // Owner 
        newRole.owner = null;
        // Is Active
        newRole.is_active = true;
        // Is Archived
        newRole.is_archived = false;
        // Is Deleted
        newRole.is_deleted = false;
        // Is Template
        newRole.is_template = false;
        // Is Live
        newRole.is_live = true;
        // Is Custom
        newRole.is_custom = true;
        // Popup Alert
        newRole.popup_alert = (Math.random() < 0.27) ? (FakeSecondaryData.popupAlertList[_.random(1, (FakeSecondaryData.popupAlertList.length - 1))]) : null;  
        // Created At
        newRole.created_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 90), 'days')).toDate();
        // Created By
        newRole.created_by = null;
        // Last Updated At
        newRole.last_updated_at = moment(new Date()).subtract(moment.duration(Math.floor(Math.random() * 60), 'days')).toDate();
        // Last Updated By
        newRole.last_updated_by = null;
        // Archived Alert
        newRole.archived_alert = null;
        // Archived At
        newRole.archived_at = null;
        // Archived By
        newRole.archived_by = null;
        // Sync Token
        newRole.sync_token = 1;
        // Suggested Action
        newRole.suggested_action = (Math.random() < 0.21) ? (roleItemActionsList[_.random(1, (roleItemActionsList.length - 1))]) : null;
        // Metadata
        newRole.metadata = {};
        // Settings
        newRole.settings = {};
        // Custom Fields
        newRole.custom_fields = {};
        // Created Version
        newRole.created_version = StandardRoleData.created_version;
        // Current Version
        newRole.current_version = StandardRoleData.current_version;


        return newRole;
    }


    /**
     * Takes in an input of an auth object, a role object, and a permissions sets array and returns an permissions object. 
     * Each permissions set input nested permissions object will have nested arrays of permissions. (from db)
     * The permissions object returned will have nested permissions categories that are are object and each permission is accessable by its identifier. 
     * 
     * @param {Object} auth A valid auth object V0.5
     * @param {Object} role The role for this user
     * @param {Object[]} permission_sets An array of permissions sets that this user has
     * 
     * @returns {Object} An object that houses the permission categories and each categories has a nested permissions object mapping based on the identifier
     */
    CreatePermissions(auth, permission_sets) {
        // Create new empty permissions object to fill in later
        let newPermissionsMap = {
            platform_permissions: { },
            admin_permissions: { },
            user_permissions: { },
            object_permissions: { },
            object_type_permissions: { },
            object_field_permissions: { },
            navigation_item_permissions: { },
            list_view_permissions: { },
            page_layout_permissions: { }
        };

        // Check if the payload's role object & permission sets array are not empty
        if (!_.isEmpty(auth) && !_.isEmpty(permission_sets)) {

            // Loop through each permission set and convert each nested array into the permissions object mapping.
            for (let i = 0; i < permission_sets.length; i++) {
                // Reference the current permission set
                const current_permission_set = permission_sets[i];

                // Check to make sure the permission set's permissions are not empty
                if (!_.isEmpty(current_permission_set.permissions)) {

                    for (let j = 0; j < current_permission_set.permissions.length; j++) {
                        const current_permission = current_permission_set.permissions[j];
                        const plural_permission_type = current_permission.type ? current_permission.type + 's' : null;
                        
                        if (!_.isObject(newPermissionsMap[plural_permission_type])) {
                            newPermissionsMap[plural_permission_type] = {};
                        }

                        if (current_permission.type === 'object_permission') {
                            // Doesn't handle multiple permission set merging yet
                            newPermissionsMap[plural_permission_type][current_permission.identifier] = current_permission;
                        } else if ((current_permission.object_identifier !== undefined && current_permission.object_identifier !== null)) {
                            if (_.isEmpty(newPermissionsMap[plural_permission_type][current_permission.object_identifier])) {
                                newPermissionsMap[plural_permission_type][current_permission.object_identifier] = {};
                            }
                            // Doesn't handle multiple permission set merging yet
                            newPermissionsMap[plural_permission_type][current_permission.object_identifier][current_permission.identifier] = current_permission;
                        } else {
                            // Doesn't handle multiple permission set merging yet
                            newPermissionsMap[plural_permission_type][current_permission.identifier] = current_permission;
                        }
                    }
                }
            }

        }
        // Return the new permissions object
        return newPermissionsMap;
    }

}