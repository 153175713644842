
// This constants file is a representation of how data for object types will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';


const ItemObjectTypesData = [

    // ----------------------------------------
    // Item Object Types
    // ----------------------------------------

    // Service
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "items",
        sync_token: 1,
        label: "Service",
        identifier: "service",
        description: "A sellable item that is a service.",
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Product
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "items",
        sync_token: 1,
        label: "Product",
        identifier: "product",
        description: "A sellable item that is a product.",
        sort_order: 2,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    }
];

export default ItemObjectTypesData;