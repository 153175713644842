
// Import NPM Modules
import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';

// Import Custom Modules
import { PreAuthHeaderContainer, PreAuthHeaderLeftArea, PreAuthHeaderRightArea, PreAuthHeaderProHelperTitle, PreAuthHeaderButton } from './Styles';
import * as ROUTES from '../../../../constants/navigation/routes';


function PreAuthHeader(props) {
    const location = useLocation();
    const pathName = location.pathname;

    return (
        <PreAuthHeaderContainer>
            <PreAuthHeaderLeftArea>
                <PreAuthHeaderProHelperTitle>
                    ProHelper
                </PreAuthHeaderProHelperTitle>
            </PreAuthHeaderLeftArea>

            <PreAuthHeaderRightArea>
                <LinkContainer to={ROUTES.LOG_IN} exact>
                    <PreAuthHeaderButton variant={(pathName === ROUTES.LOG_IN) ? 'active' : null} >
                        Log In
                    </PreAuthHeaderButton>
                </LinkContainer>

                <LinkContainer to={ROUTES.SIGN_UP} exact>
                    <PreAuthHeaderButton variant={(pathName === ROUTES.SIGN_UP) ? 'active' : null} >
                        Sign Up
                    </PreAuthHeaderButton>
                </LinkContainer>
            </PreAuthHeaderRightArea>
        </PreAuthHeaderContainer>
    );
}

export default PreAuthHeader;