
// This constants file is a representation of how data for object types will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';


const PermissionSetObjectTypesData = [

    // ----------------------------------------
    // Permission Set Object Types
    // ----------------------------------------
    // Admin
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "permission_sets",
        sync_token: 1,
        label: "Admin",
        identifier: "admin",
        description: "A Permission Set that has admin permissions.",
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Billing
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "permission_sets",
        sync_token: 1,
        label: "Billing",
        identifier: "billing",
        description: "A Permission Set that has billing permissions.",
        sort_order: 2,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Service
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "permission_sets",
        sync_token: 1,
        label: "Service",
        identifier: "service",
        description: "A Permission Set that has service permissions.",
        sort_order: 3,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Office
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "permission_sets",
        sync_token: 1,
        label: "Office",
        identifier: "office",
        description: "A Permission Set that has client and scheduling permissions.",
        sort_order: 4,
        is_active: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "permission_sets",
        sync_token: 1,
        label: "Other",
        identifier: "other",
        description: "A Permission Set that does not fit a category yet.",
        sort_order: 5,
        is_active: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },

];

export default PermissionSetObjectTypesData;