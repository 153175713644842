// Import NPM Modules
import _ from 'lodash';

// Import Custom Modules
import * as actionTypes from '../../action_types/data/DialogDataActionTypes';

const initialState = {
    edit_record_dialog: {
        display_label: '',
        object_data: null,
        list_view_data: null,
        page_layout_data: null,
        starting_data: [],
        raw_data: {},
        shown_data: {},
        edited_data: {},
        components_data_mapping: {},
        status: {
            overall: 'none',
            display_text: '',
            page_statuses_mapping: {}
        },
        pagination: {
            current_page: 0,
            total_pages: 1,
            current_page_settings: {},
            prevButtonLabel: 'Cancel',
            nextButtonLabel: 'Done'
        },
        search: {
            is_open: false,
            value: ''
        },
        is_edit_field_dialog_open: false,
        click_outside_disabled: false,
        preview_mode: false
    },
    edit_field_dialog: {
        display_label: '',
        public_id: '',
        starting_data: null,
        object_data: null,
        object_field_data: null,
        raw_record_data: {},
        shown_record_data: {},
        editing_element_data: {},
        raw_value: null,
        shown_value: null,
        edited_data: {},
        dropdowns: [],
        status: {
            overall: 'none',
            display_text: '',
        },
        pagination: {
            current_page: 0,
            total_pages: 1,
            prevButtonLabel: 'Cancel',
            nextButtonLabel: 'Done'
        },
        click_outside_disabled: false
    },
    new_record_dialog: {
        first_loaded: false,
        object_data: null,
        list_view_data: null,
        page_layout_data: null,
        starting_data: [],
        display_label: '',
        raw_data: {},
        shown_data: {},
        edited_data: {},
        components_data_mapping: {},
        status: {
            overall: 'none',
            display_text: '',
            page_statuses_mapping: {}
        },
        pagination: {
            current_page: 1,
            total_pages: 2,
            current_page_settings: {},
            prevButtonLabel: 'Cancel',
            nextButtonLabel: 'Done'
        },
        search: {
            is_open: false,
            value: ''
        },
        click_outside_disabled: false,
        preview_mode: false,
        prev_data: {
            list_view_data: null,
            object_data: null,
            record_data: null
        }
    },
    archive_record_dialog: [],
    customize_field_dialog: {
        starting_data: '',
        list_view_data: null,
        object_data: null,
        object_field_data: null,
        click_outside_disabled: false,
        display: {
            is_shown: true,
            is_main_display_field: false,
            is_shown_changed: false,
            is_changed: false,
            show_display_fields: false,
            display_field_expanded: true,
            display_fields: null,
            selected_display_field: null,
            is_display_field_changed: false,
            nested_display_field_expanded: true,
            nested_display_fields: null,
            nested_selected_display_field: null,
            is_nested_display_field_changed: false,
            show_display_formats: false,
            display_format_expanded: true,
            display_formats: null,
            selected_display_format: null,
            is_display_format_changed: false,
            display_order: {
                min: 2,
                value: 2,
                max: 12,
                step: 1,
                is_changed: false
            },
            column_width: {
                min: 50,
                value: 120,
                max: 600,
                step: 10,
                is_changed: false
            }
        },
        sort: {
            is_changed: false,
            field_data: null,
            asc_display_text: "",
            desc_display_text: "",
            nulls_last: true,
            sort_method: "",
            is_active_sort_field: false,
            sort_direction: null,
            sql_sort_field: null,
            is_nested_field: false,
            is_nested_field_area_expanded: true,
            nested_sort_fields: null,
            selected_nested_sort_field: null,
            is_lookup_relationship_field: false,
            is_lookup_relationship_area_expanded: true,
            lookup_relationship_sort_fields: null,
            selected_lookup_relationship_sort_field: null,
            available_sort_methods: null,
            selected_sort_method: null
        },
        filter: {
            is_changed: false,
            shown_list: [],
            deleted_filters: {}
        }
    }
};

const DialogDataReducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SET_EDIT_RECORD_DIALOG_DATA:
            return {
                ...state,
                edit_record_dialog: {
                    ...state.edit_record_dialog,
                    object_data: !_.isEmpty(action.payload.object_data) ? action.payload.object_data : state.edit_record_dialog.object_data,
                    list_view_data: !_.isEmpty(action.payload.list_view_data) ? action.payload.list_view_data : state.edit_record_dialog.list_view_data,
                    page_layout_data: !_.isEmpty(action.payload.page_layout_data) ? action.payload.page_layout_data : state.edit_record_dialog.page_layout_data,
                    display_label: _.isString(action.payload.display_label) ? action.payload.display_label : state.edit_record_dialog.display_label,
                    starting_data: !_.isEmpty(action.payload.starting_data) ? action.payload.starting_data : state.edit_record_dialog.starting_data,
                    raw_data: !_.isEmpty(action.payload.raw_data) ? action.payload.raw_data : state.edit_record_dialog.raw_data,
                    shown_data: !_.isEmpty(action.payload.shown_data) ? action.payload.shown_data : state.edit_record_dialog.shown_data,
                    edited_data: !_.isEmpty(action.payload.edited_data) ? action.payload.edited_data : state.edit_record_dialog.edited_data,
                    components_data_mapping: !_.isEmpty(action.payload.components_data_mapping) ? action.payload.components_data_mapping : state.edit_record_dialog.components_data_mapping,
                    status: !_.isEmpty(action.payload.status) ? action.payload.status : state.edit_record_dialog.status,
                    pagination: !_.isEmpty(action.payload.pagination) ? action.payload.pagination : state.edit_record_dialog.pagination,
                    search: !_.isEmpty(action.payload.search) ? action.payload.search : state.edit_record_dialog.search,
                    is_edit_field_dialog_open: _.isBoolean(action.payload.is_edit_field_dialog_open) ? action.payload.is_edit_field_dialog_open : state.edit_record_dialog.is_edit_field_dialog_open,
                    preview_mode: _.isBoolean(action.payload.preview_mode) ? action.payload.preview_mode : state.edit_record_dialog.preview_mode,
                    click_outside_disabled: _.isBoolean(action.payload.click_outside_disabled) ? action.payload.click_outside_disabled : state.edit_record_dialog.click_outside_disabled,
                }
            }

        case (actionTypes.REMOVE_EDIT_RECORD_DIALOG_DATA):
            return {
                ...state,
                edit_record_dialog: _.cloneDeep(initialState.edit_record_dialog)
            };

        case actionTypes.SET_NEW_RECORD_DIALOG_DATA:
            return {
                ...state,
                new_record_dialog: {
                    ...state.new_record_dialog,
                    object_data: !_.isEmpty(action.payload.object_data) ? action.payload.object_data : state.new_record_dialog.object_data,
                    first_loaded: _.isBoolean(action.payload.first_loaded) ? action.payload.first_loaded : state.new_record_dialog.first_loaded,
                    list_view_data: !_.isEmpty(action.payload.list_view_data) ? action.payload.list_view_data : state.new_record_dialog.list_view_data,
                    page_layout_data: !_.isEmpty(action.payload.page_layout_data) ? action.payload.page_layout_data : state.new_record_dialog.page_layout_data,
                    display_label: _.isString(action.payload.display_label) ? action.payload.display_label : state.new_record_dialog.display_label,
                    starting_data: !_.isEmpty(action.payload.starting_data) ? action.payload.starting_data : state.new_record_dialog.starting_data,
                    raw_data: !_.isEmpty(action.payload.raw_data) ? action.payload.raw_data : state.new_record_dialog.raw_data,
                    shown_data: !_.isEmpty(action.payload.shown_data) ? action.payload.shown_data : state.new_record_dialog.shown_data,
                    edited_data: !_.isEmpty(action.payload.edited_data) ? action.payload.edited_data : state.new_record_dialog.edited_data,
                    components_data_mapping: !_.isEmpty(action.payload.components_data_mapping) ? action.payload.components_data_mapping : state.new_record_dialog.components_data_mapping,
                    status: !_.isEmpty(action.payload.status) ? action.payload.status : state.new_record_dialog.status,
                    pagination: !_.isEmpty(action.payload.pagination) ? action.payload.pagination : state.new_record_dialog.pagination,
                    search: !_.isEmpty(action.payload.search) ? action.payload.search : state.new_record_dialog.search,
                    is_edit_field_dialog_open: _.isBoolean(action.payload.is_edit_field_dialog_open) ? action.payload.is_edit_field_dialog_open : state.new_record_dialog.is_edit_field_dialog_open,
                    preview_mode: _.isBoolean(action.payload.preview_mode) ? action.payload.preview_mode : state.new_record_dialog.preview_mode,
                    click_outside_disabled: _.isBoolean(action.payload.click_outside_disabled) ? action.payload.click_outside_disabled : state.new_record_dialog.click_outside_disabled,
                    prev_data: !_.isEmpty(action.payload.prev_data) ? {
                        ...state.new_record_dialog.prev_data,
                        list_view_data: !_.isEmpty(action.payload.prev_data.list_view_data) ? action.payload.prev_data.list_view_data : state.new_record_dialog.prev_data.list_view_data,
                        object_data: !_.isEmpty(action.payload.prev_data.object_data) ? action.payload.prev_data.object_data : state.new_record_dialog.prev_data.object_data,
                        record_data: !_.isEmpty(action.payload.prev_data.record_data) ? action.payload.prev_data.record_data : state.new_record_dialog.prev_data.record_data
                    } : state.new_record_dialog.prev_data,
                }
            }

        case (actionTypes.REMOVE_NEW_RECORD_DIALOG_DATA):
            return {
                ...state,
                new_record_dialog: _.cloneDeep(initialState.new_record_dialog)
            };

        case actionTypes.SET_EDIT_FIELD_DIALOG_DATA:
            return {
                ...state,
                edit_field_dialog: {
                    ...state.edit_field_dialog,
                    display_label: _.isString(action.payload.display_label) ? action.payload.display_label : state.edit_field_dialog.display_label,
                    public_id: _.isString(action.payload.public_id) ? action.payload.public_id : state.edit_field_dialog.public_id,
                    starting_data: _.isString(action.payload.starting_data) ? action.payload.starting_data : state.edit_field_dialog.starting_data,
                    object_data: _.isObject(action.payload.object_data) ? action.payload.object_data : state.edit_field_dialog.object_data,
                    object_field_data: !_.isEmpty(action.payload.object_field_data) ? action.payload.object_field_data : state.edit_field_dialog.object_field_data,
                    raw_record_data: !_.isEmpty(action.payload.raw_record_data) ? action.payload.raw_record_data : state.edit_field_dialog.raw_record_data,
                    shown_record_data: !_.isEmpty(action.payload.shown_record_data) ? action.payload.shown_record_data : state.edit_field_dialog.shown_record_data,
                    editing_element_data: !_.isEmpty(action.payload.editing_element_data) ? action.payload.editing_element_data : state.edit_field_dialog.editing_element_data,
                    raw_value: (action.payload.raw_value !== undefined && action.payload.raw_value !== null) ? action.payload.raw_value : state.edit_field_dialog.raw_value,
                    shown_value: (action.payload.shown_value !== undefined) ? action.payload.shown_value : state.edit_field_dialog.shown_value,
                    edited_data: _.isObject(action.payload.edited_data) ? action.payload.edited_data : state.edit_field_dialog.edited_data,
                    dropdowns: !_.isEmpty(action.payload.dropdowns) ? action.payload.dropdowns : state.edit_field_dialog.dropdowns,
                    status: !_.isEmpty(action.payload.status) ? action.payload.status : state.edit_field_dialog.status,
                    pagination: !_.isEmpty(action.payload.pagination) ? {
                        ...state.edit_field_dialog.pagination,
                        current_page: _.isNumber(action.payload.pagination.current_page) ? action.payload.pagination.current_page : state.edit_field_dialog.pagination.current_page,
                        total_pages: _.isNumber(action.payload.pagination.total_pages) ? action.payload.pagination.total_pages : state.edit_field_dialog.pagination.total_pages,
                        prevButtonLabel: _.isString(action.payload.pagination.prevButtonLabel) ? action.payload.pagination.prevButtonLabel : state.edit_field_dialog.pagination.prevButtonLabel,
                        nextButtonLabel: _.isString(action.payload.pagination.nextButtonLabel) ? action.payload.pagination.nextButtonLabel : state.edit_field_dialog.pagination.nextButtonLabel,
                    } : state.edit_field_dialog.pagination,
                    click_outside_disabled: _.isBoolean(action.payload.click_outside_disabled) ? action.payload.click_outside_disabled : state.edit_field_dialog.click_outside_disabled,
                }
            }

        case (actionTypes.REMOVE_EDIT_FIELD_DIALOG_DATA):
            return {
                ...state,
                edit_field_dialog: _.cloneDeep(initialState.edit_field_dialog)
            };

        case actionTypes.SET_ARCHIVE_DIALOG_DATA:
            return {
                ...state,
                archive_record_dialog: (action.payload.archive_record_dialog !== undefined && action.payload.archive_record_dialog !== null) ? action.payload.archive_record_dialog : state.archive_record_dialog
            }
        case (actionTypes.REMOVE_ARCHIVE_DIALOG_DATA):
            return {
                ...state,
                archive_record_dialog: []
            }

        case (actionTypes.SET_CUSTOMIZE_FIELD_DIALOG_DATA):
            return {
                ...state,
                customize_field_dialog: {
                    ...state.customize_field_dialog,
                    starting_data: _.isString(action.payload.starting_data) ? action.payload.starting_data : state.customize_field_dialog.starting_data,
                    object_data: !_.isEmpty(action.payload.object_data) ? action.payload.object_data : state.customize_field_dialog.object_data,
                    object_field_data: !_.isEmpty(action.payload.object_field_data) ? action.payload.object_field_data : state.customize_field_dialog.object_field_data,
                    list_view_data: !_.isEmpty(action.payload.list_view_data) ? action.payload.list_view_data : state.customize_field_dialog.list_view_data,
                    click_outside_disabled: !_.isEmpty(action.payload.click_outside_disabled) ? action.payload.click_outside_disabled : state.customize_field_dialog.click_outside_disabled,
                    display: !_.isEmpty(action.payload.display) ? {
                        ...state.customize_field_dialog.display,
                        is_shown: _.isBoolean(action.payload.display.is_shown) ? action.payload.display.is_shown : state.customize_field_dialog.display.is_shown,
                        is_main_display_field: _.isBoolean(action.payload.display.is_main_display_field) ? action.payload.display.is_main_display_field : state.customize_field_dialog.display.is_main_display_field,
                        is_shown_changed: _.isBoolean(action.payload.display.is_shown_changed) ? action.payload.display.is_shown_changed : state.customize_field_dialog.display.is_shown_changed,
                        is_changed: _.isBoolean(action.payload.display.is_changed) ? action.payload.display.is_changed : state.customize_field_dialog.display.is_changed,
                        show_display_fields: _.isBoolean(action.payload.display.show_display_fields) ? action.payload.display.show_display_fields : state.customize_field_dialog.display.show_display_fields,
                        display_field_expanded: _.isBoolean(action.payload.display.display_field_expanded) ? action.payload.display.display_field_expanded : state.customize_field_dialog.display.display_field_expanded,
                        display_fields: _.isArray(action.payload.display.display_fields) ? action.payload.display.display_fields : state.customize_field_dialog.display.display_fields,
                        selected_display_field: _.isObject(action.payload.display.selected_display_field) ? action.payload.display.selected_display_field : state.customize_field_dialog.display.selected_display_field,
                        is_display_field_changed: _.isBoolean(action.payload.display.is_display_field_changed) ? action.payload.display.is_display_field_changed : state.customize_field_dialog.display.is_display_field_changed,
                        nested_display_field_expanded: _.isBoolean(action.payload.display.nested_display_field_expanded) ? action.payload.display.nested_display_field_expanded : state.customize_field_dialog.display.nested_display_field_expanded,
                        nested_display_fields: _.isArray(action.payload.display.nested_display_fields) ? action.payload.display.nested_display_fields : state.customize_field_dialog.display.nested_display_fields,
                        nested_selected_display_field: _.isObject(action.payload.display.nested_selected_display_field) ? action.payload.display.nested_selected_display_field : state.customize_field_dialog.display.nested_selected_display_field,
                        is_nested_display_field_changed: _.isBoolean(action.payload.display.is_nested_display_field_changed) ? action.payload.display.is_nested_display_field_changed : state.customize_field_dialog.display.is_nested_display_field_changed,
                        show_display_formats: _.isBoolean(action.payload.display.show_display_formats) ? action.payload.display.show_display_formats : state.customize_field_dialog.display.show_display_formats,
                        display_format_expanded: _.isBoolean(action.payload.display.display_format_expanded) ? action.payload.display.display_format_expanded : state.customize_field_dialog.display.display_format_expanded,
                        display_formats: _.isArray(action.payload.display.display_formats) ? action.payload.display.display_formats : state.customize_field_dialog.display.display_formats,
                        selected_display_format: _.isObject(action.payload.display.selected_display_format) ? action.payload.display.selected_display_format : state.customize_field_dialog.display.selected_display_format,
                        is_display_format_changed: _.isBoolean(action.payload.display.is_display_format_changed) ? action.payload.display.is_display_format_changed : state.customize_field_dialog.display.is_display_format_changed,
                        display_order: !_.isEmpty(action.payload.display.display_order) ? {
                            ...state.customize_field_dialog.display.display_order,
                            min: _.isNumber(action.payload.display.display_order.min) ? action.payload.display.display_order.min : state.customize_field_dialog.display.display_order.min,
                            value: _.isNumber(action.payload.display.display_order.value) ? action.payload.display.display_order.value : state.customize_field_dialog.display.display_order.value,
                            max: _.isNumber(action.payload.display.display_order.max) ? action.payload.display.display_order.max : state.customize_field_dialog.display.display_order.max,
                            step: _.isNumber(action.payload.display.display_order.step) ? action.payload.display.display_order.step : state.customize_field_dialog.display.display_order.step,
                            is_changed: _.isBoolean(action.payload.display.display_order.is_changed) ? action.payload.display.display_order.is_changed : state.customize_field_dialog.display.display_order.is_changed,
                        } : state.customize_field_dialog.display.display_order,
                        column_width: !_.isEmpty(action.payload.display.column_width) ? {
                            ...state.customize_field_dialog.display.column_width,
                            min: _.isNumber(action.payload.display.column_width.min) ? action.payload.display.column_width.min : state.customize_field_dialog.display.column_width.min,
                            value: _.isNumber(action.payload.display.column_width.value) ? action.payload.display.column_width.value : state.customize_field_dialog.display.column_width.value,
                            max: _.isNumber(action.payload.display.column_width.max) ? action.payload.display.column_width.max : state.customize_field_dialog.display.column_width.max,
                            step: _.isNumber(action.payload.display.column_width.step) ? action.payload.display.column_width.step : state.customize_field_dialog.display.column_width.step,
                            is_changed: _.isBoolean(action.payload.display.column_width.is_changed) ? action.payload.display.column_width.is_changed : state.customize_field_dialog.display.column_width.is_changed,
                        } : state.customize_field_dialog.display.column_width,
                    } : state.customize_field_dialog.display,
                    sort: !_.isEmpty(action.payload.sort) ? {
                        ...state.customize_field_dialog.sort,
                        is_changed: _.isBoolean(action.payload.sort.is_changed) ? action.payload.sort.is_changed : state.customize_field_dialog.sort.is_changed,
                        field_data: _.isObject(action.payload.sort.field_data) ? action.payload.sort.field_data : state.customize_field_dialog.sort.field_data,
                        asc_display_text: _.isString(action.payload.sort.asc_display_text) ? action.payload.sort.asc_display_text : state.customize_field_dialog.sort.asc_display_text,
                        desc_display_text: _.isString(action.payload.sort.desc_display_text) ? action.payload.sort.desc_display_text : state.customize_field_dialog.sort.desc_display_text,
                        nulls_last: _.isBoolean(action.payload.sort.nulls_last) ? action.payload.sort.nulls_last : state.customize_field_dialog.sort.nulls_last,
                        sort_method: _.isString(action.payload.sort.sort_method) ? action.payload.sort.sort_method : state.customize_field_dialog.sort.sort_method,
                        is_active_sort_field: _.isBoolean(action.payload.sort.is_active_sort_field) ? action.payload.sort.is_active_sort_field : state.customize_field_dialog.sort.is_active_sort_field,
                        sort_direction: _.isString(action.payload.sort.sort_direction) ? action.payload.sort.sort_direction : state.customize_field_dialog.sort.sort_direction,
                        sql_sort_field: _.isObject(action.payload.sort.sql_sort_field) ? action.payload.sort.sql_sort_field : state.customize_field_dialog.sort.sql_sort_field,
                        is_nested_field: _.isBoolean(action.payload.sort.is_nested_field) ? action.payload.sort.is_nested_field : state.customize_field_dialog.sort.is_nested_field,
                        is_nested_field_area_expanded: _.isBoolean(action.payload.sort.is_nested_field_area_expanded) ? action.payload.sort.is_nested_field_area_expanded : state.customize_field_dialog.sort.is_nested_field_area_expanded,
                        nested_sort_fields: _.isArray(action.payload.sort.nested_sort_fields) ? action.payload.sort.nested_sort_fields : state.customize_field_dialog.sort.nested_sort_fields,
                        selected_nested_sort_field: _.isObject(action.payload.sort.selected_nested_sort_field) ? action.payload.sort.selected_nested_sort_field : state.customize_field_dialog.sort.selected_nested_sort_field,
                        is_lookup_relationship_field: _.isBoolean(action.payload.sort.is_lookup_relationship_field) ? action.payload.sort.is_lookup_relationship_field : state.customize_field_dialog.sort.is_lookup_relationship_field,
                        is_lookup_relationship_area_expanded: _.isBoolean(action.payload.sort.is_lookup_relationship_area_expanded) ? action.payload.sort.is_lookup_relationship_area_expanded : state.customize_field_dialog.sort.is_lookup_relationship_area_expanded,
                        lookup_relationship_sort_fields: _.isArray(action.payload.sort.lookup_relationship_sort_fields) ? action.payload.sort.lookup_relationship_sort_fields : state.customize_field_dialog.sort.lookup_relationship_sort_fields,
                        selected_lookup_relationship_sort_field: _.isObject(action.payload.sort.selected_lookup_relationship_sort_field) ? action.payload.sort.selected_lookup_relationship_sort_field : state.customize_field_dialog.sort.selected_lookup_relationship_sort_field,
                        available_sort_methods: _.isBoolean(action.payload.sort.available_sort_methods) ? action.payload.sort.available_sort_methods : state.customize_field_dialog.sort.available_sort_methods,
                        selected_sort_method: _.isBoolean(action.payload.sort.selected_sort_method) ? action.payload.sort.selected_sort_method : state.customize_field_dialog.sort.selected_sort_method,
                    } : state.customize_field_dialog.sort,
                    filter: !_.isEmpty(action.payload.filter) ? {
                        ...state.customize_field_dialog.filter,
                        is_changed: _.isBoolean(action.payload.filter.is_changed) ? action.payload.filter.is_changed : state.customize_field_dialog.filter.is_changed,
                        shown_list: _.isArray(action.payload.filter.shown_list) ? action.payload.filter.shown_list : state.customize_field_dialog.filter.shown_list,
                        deleted_filters: _.isObject(action.payload.filter.deleted_filters) ? action.payload.filter.deleted_filters : state.customize_field_dialog.filter.deleted_filters,
                    } : state.customize_field_dialog.filter,
                }
            }

        case (actionTypes.REMOVE_CUSTOMIZE_FIELD_DIALOG_DATA):
            return {
                ...state,
                customize_field_dialog: _.cloneDeep(initialState.customize_field_dialog)
            };

        default:
            return state;
    }
};


export default DialogDataReducer;
