
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';

// Import Custom Modules
import * as SortingMethods from '../../sorting/SortingMethods';
import * as SortingDirectionIdentifiers from '../../sorting/SortDataTypeIdentifiers';


const PropertyListViewsData = [
    // Active Properties
    {
        object: null,
        object_identifier: 'properties',
        public_id: uniqid('view_'),
        sync_token: 1,
        type: "list_view",
        identifier: identifier('Active Properties').toLowerCase(),
        name: 'Active Properties',
        description: 'All active properties with the company.',
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        sort_field: "properties.priority",
        sql_sort_field: ["properties.priority", "dropdowns.sort_order"],
        sort_direction: SortingDirectionIdentifiers.ASCENDING,
        sort_join: ["properties.priority", "dropdowns.id"],
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        filter_by_viewer: false,
        filters: [
            {
                fields: ["properties.is_active"],
                field_types: ["checkbox"],
                operator: "equal",
                values: [
                    {
                        type: "value",
                        label: 'True',
                        input: true,
                        data: true,
                        data_type: 'boolean',
                        status: 'valid'
                    }
                ],
                is_hidden: true
            },
        ],
        search_value: "",
        visible_fields: [
            {
                label: 'Address',
                identifier: 'properties.address',
                display_fields: ['properties.address', 'address.display'],
                display_format: null,
                help_text: "",
                width: 220,
                is_locked: true
            },
            {
                label: 'Type',
                identifier: 'properties.type',
                display_fields: ['properties.type', 'object_types.label'],
                display_format: null,
                help_text: "",
                width: 140,
                is_locked: false
            },
            {
                label: 'Condition',
                identifier: 'properties.condition',
                display_fields: ['properties.condition', 'dropdown.label'],
                display_format: null,
                help_text: "",
                width: 140,
                is_locked: false
            },
            {
                label: 'Priority',
                identifier: 'properties.priority',
                display_fields: ['properties.priority', 'dropdown.label'],
                display_format: null,
                help_text: "",
                width: 140,
                is_locked: false
            },
            {
                label: 'Account',
                identifier: 'properties.account',
                display_fields: ['properties.account', 'accounts.name', 'name.display'],
                display_format: null,
                help_text: "",
                width: 150,
                is_locked: false
            },
            {
                label: 'Zone',
                identifier: 'properties.zone',
                display_fields: ['properties.zone', 'dropdown.label'],
                display_format: null,
                help_text: "",
                width: 140,
                is_locked: false
            },
            {
                label: 'Description',
                identifier: 'properties.description',
                display_fields: ['properties.description'],
                display_format: null,
                help_text: "",
                width: 220,
                is_locked: false
            },
            {
                label: 'Created At',
                identifier: 'properties.created_at',
                display_fields: ['properties.created_at'],
                display_format: 'MM/DD/YYYY',
                help_text: "",
                width: 160,
                is_locked: false
            },
            
        ],
        hidden_fields: [
            "properties.id",
            "properties.property_id",
            "properties.type",
            "properties.sync_token",
            "properties.time_zone",
            "properties.company",
            "properties.account",
            "properties.suggested_action"
        ],
        batch_actions: [
            "properties.batch_edit",
            "properties.batch_archive"
        ],
        item_actions: [
            "properties.view_parent_account",
            "properties.view_primary_contact",
            "properties.send_mail",
            "properties.duplicate",
            "properties.archive"
        ],
        more_actions: [
            "properties.delete"
        ],
        pagination_amount: 25
    },
    
];

export default PropertyListViewsData;