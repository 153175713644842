
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';

// Import Custom Modules
import * as SortingMethods from '../../sorting/SortingMethods';
import * as SortingDirectionIdentifiers from '../../sorting/SortDataTypeIdentifiers';


const RoleListViewsData = [
    // All Permission Sets
    {
        object: null,
        object_identifier: 'permission_sets',
        public_id: uniqid('view_'),
        sync_token: 1,
        type: "list_view",
        identifier: identifier('All Permission Sets').toLowerCase(),
        name: 'All Permission Sets',
        description: 'All permission sets with the company.',
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        sort_field: "permission_sets.label",
        sql_sort_field: ["permission_sets.label"],
        sort_direction: SortingDirectionIdentifiers.ASCENDING,
        sort_join: null,
        sort_method: SortingMethods.ALPHANUMERIC,
        filter_by_viewer: false,
        filters: [ ],
        search_value: "",
        visible_fields: [
            {
                label: 'Permission Set Label',
                identifier: 'permission_sets.label',
                display_fields: ['permission_sets.label'],
                display_format: null,
                width: 180,
                is_locked: true
            },
            {
                label: 'Type',
                identifier: 'permission_sets.type',
                display_fields: ['permission_sets.type'],
                display_format: null,
                width: 140,
                is_locked: false
            },
            {
                label: 'Status',
                identifier: 'permission_sets.status',
                display_fields: ['permission_sets.status', 'dropdown.label'],
                display_format: null,
                width: 140,
                is_locked: false
            },
            {
                label: 'Description',
                identifier: 'permission_sets.description',
                display_fields: ['permission_sets.description'],
                display_format: null,
                width: 280,
                is_locked: false
            },
            {
                label: 'Updated At',
                identifier: 'permission_sets.last_updated_at',
                display_fields: ['permission_sets.last_updated_at'],
                display_format: 'MM/DD/YYYY',
                width: 160,
                is_locked: false
            },
            {
                label: 'Updated By',
                identifier: 'permission_sets.last_updated_by',
                display_fields: ['permission_sets.last_updated_by', 'user.name', 'name.display'],
                display_format: null,
                width: 180,
                is_locked: false
            },
        ],
        hidden_fields: [
            "permission_sets.id",
            "permission_sets.permission_set_id",
            "permission_sets.type",
            "permission_sets.sync_token",
            "permission_sets.company",
            "permission_sets.suggested_action"
        ],
        batch_actions: [
            "permission_sets.batch_edit",
            "permission_sets.batch_archive"
        ],
        item_actions: [
            "permission_sets.edit",
            "permission_sets.archive"
        ],
        more_actions: [ ],
        pagination_amount: 25
    },
    
];

export default RoleListViewsData;