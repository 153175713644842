
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';
import * as ROUTES from '../../../../navigation/routes';
import * as ScreenTypes from '../../../../types/ScreenTypes';
import * as ScreenIdentifiers from '../../../../screens/ScreenIdentifiers';
import * as ComponentTypes from '../../../../types/ProHelperComponentTypes';


const SubscriptionPageLayoutsData = [
    // Default
    {
        public_id: uniqid('page_'),
        sync_token: 1,
        identifier: identifier('Default').toLowerCase(),
        label: 'Default',
        description: 'View a Subscription in detail.',
        status: 'published',
        revisions: 1,
        route: ROUTES.SUBSCRIPTION_DETAIL_PREFIX,
        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
        screen_identifier: ScreenIdentifiers.SUBSCRIPTION_PAGE_LAYOUT,
        template: null,
        object: null,
        object_identifier: 'subscriptions',
        object_type: null,
        group: {
            label: 'Billing',
            identifier: 'billing',
            order: 5
        },
        sub_group: {
            label: 'Subscriptions',
            identifier: 'subscriptions',
            order: 3
        },
        components: {
            component_1: {
                id: "component_1",
                label: 'Header',
                object_identifier: 'subscriptions',
                connection_type: 'parent',
                type: ComponentTypes.OBJECT_HEADER,
                header_type: 'action_bar',
                special_type: 'header',
                is_editable: true,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                    fields: [
                        {
                            label: "Subject",
                            identifier: "subscriptions.subject",
                            is_required: true,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Description",
                            identifier: "subscriptions.description",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Status",
                            identifier: "subscriptions.status",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Payment Method",
                            identifier: "subscriptions.payment_method",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Account",
                            identifier: "subscriptions.account",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                    ],
                    first_visible_amount: {
                        mobile: 4,
                        tablet: 4,
                        desktop: 2
                    }
                }
            },
            component_2: {
                id: "component_2",
                label: 'Details',
                object_identifier: 'subscriptions',
                connection_type: 'parent',
                type: ComponentTypes.OBJECT_DETAILS,
                special_type: null,
                header_type: 'normal',
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {
                    sections: [
                        // General
                        {
                            label: "Subscription Information",
                            id: 'subscription_info',
                            tab_order: 'left-right',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 6,
                                tablet: 4,
                                desktop: 8
                            },
                            fields: [
                                {
                                    label: "Primary Contact",
                                    identifier: "subscriptions.primary_contact",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Primary Property",
                                    identifier: "subscriptions.primary_property",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Tags",
                                    identifier: "subscriptions.tags",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                }
                            ],
                        },
                        // System Information
                        {
                            label: "System Information",
                            id: 'system_info',
                            tab_order: 'left-right',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: false,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 6,
                                tablet: 4,
                                desktop: 8
                            },
                            fields: [
                                {
                                    label: "Popup Alert",
                                    identifier: "subscriptions.popup_alert",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Subscription ID",
                                    identifier: "subscriptions.subscription_id",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Created At",
                                    identifier: "subscriptions.created_at",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Created By",
                                    identifier: "subscriptions.created_by",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Last Updated At",
                                    identifier: "subscriptions.last_updated_at",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Last Updated By",
                                    identifier: "subscriptions.last_updated_by",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },

                            ],
                        },
                    ]
                }
            },
            component_3: {
                id: "component_3",
                label: 'Line Items',
                object_identifier: 'subscriptions',
                connection_type: 'parent',
                type: ComponentTypes.LINE_ITEMS,
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: true,
                metadata: {
                    summary_section: [
                        {
                            label:"Footer Message",
                            identifier: "subscriptions.footer_message",
                            type:"long_text",
                            display_fields: ["subscriptions.footer_message"],
                            display_format: null
                        }
                    ],
                    fields:[
                        {
                            label: "Product/Service",
                            identifier: "line_items.item",
                            type: "lookup_relationship",
                            display_fields: ["line_items.item","items.label"],
                            display_format: null,
                            flex_size: 1
                        },
                        {
                            label: "Description",
                            identifier: "line_items.description",
                            type: "long_text",
                            display_fields: ["line_items.description"],
                            display_format: null,
                            flex_size: 1
                        },
                        {
                            label: "Type",
                            identifier: "line_items.type",
                            type: "type_dropdown",
                            display_fields: ["line_items.type", "object_types.label"],
                            display_format: null,
                            flex_size: 1
                        },
                        {
                            label: "Quantity",
                            identifier: "line_items.quantity",
                            type: "decimal",
                            display_fields: ["line_items.quantity"],
                            display_format: null,
                            flex_size: 1
                        },
                        {
                            label: "Amount",
                            identifier: "line_items.amount",
                            type: "currency",
                            display_fields: ["line_items.amount"],
                            display_format: null,
                            flex_size: 1
                        },
                        {
                            label: "Total",
                            identifier: "line_items.total",
                            type: "currency",
                            display_fields: ["line_items.total"],
                            display_format: null,
                            flex_size: 1
                        },

                        
                    ]
                }
            },
            component_4: {
                id: "component_4",
                label: 'Related',
                object_identifier: 'subscriptions',
                connection_type: 'parent',
                type: ComponentTypes.RELATED,
                special_type: null,
                header_type: 'normal',
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {
                    related: [
                        {
                            label: 'Invoices',
                            identifier: 'related_invoices',
                            object_identifier: 'invoices',
                            display_field: 'invoices.subject',
                            show_records_container_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            show_record_cell_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            records_tabs_first_visible_amount: {
                                mobile: 2,
                                tablet: 2,
                                desktop: 4
                            },
                            record_cells_first_visible_amount: {
                                mobile: 2,
                                tablet: 2,
                                desktop: 4
                            },
                            filters: [
                                {
                                    fields: ["invoices.subscription"],
                                    field_types: ["lookup_relationship"],
                                    operator: "equal",
                                    values: [
                                        { 
                                            type: "relationship",
                                            label: 'Subscription',
                                            input: 'parent.id',
                                            data: 'parent.id',
                                            data_type: 'relationship',
                                            status: 'valid'
                                        }
                                    ]
                                },
                                {
                                    fields: ["invoices.is_active"],
                                    field_types: ["checkbox"],
                                    operator: "equal",
                                    values: [
                                        {
                                            type: "value",
                                            label: 'True',
                                            input: true,
                                            data: true,
                                            data_type: 'boolean',
                                            status: 'valid'
                                        }
                                    ]
                                }
                            ],
                            sort_field: "invoices.last_updated_at",
                            sort_by: ["invoices.last_updated_at"],
                            sort_method: "chronological",
                            sort_direction: "DESC",
                            search_value: '',
                            fields: [
                                {
                                    label: 'Subject',
                                    identifier: 'invoices.subject',
                                    is_read_only: false,
                                    is_visible: true
                                },
                                {
                                    label: 'Status',
                                    identifier: 'invoices.status',
                                    is_read_only: false,
                                    is_visible: true
                                },
                            ]
                        }
                    ]
                }
            },
            component_5: {
                id: "component_5",
                label: 'Schedule',
                object_identifier: 'subscriptions',
                connection_type: 'parent',
                type: ComponentTypes.SCHEDULE,
                special_type: null,
                header_type: 'normal',
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {
                    activities: [
                        {
                            // Visits
                            label: 'Visits',
                            identifier: 'visits_2',
                            object_identifier: 'visits',
                            display_field: 'visits.subject',
                            // show_records_container_on_first_load: {
                            //     mobile: false,
                            //     tablet: true,
                            //     desktop: true
                            // },
                            // record_cells_first_visible_amount: {
                            //     mobile: 3,
                            //     tablet: 2,
                            //     desktop: 2
                            // },
                            filters: [
                                {
                                    fields: ["visits.is_active"],
                                    field_types: ["checkbox"],
                                    operator: "equal",
                                    values: [
                                        {
                                            type: "value",
                                            label: 'True',
                                            input: true,
                                            data: true,
                                            data_type: 'boolean',
                                            status: 'valid'
                                        }
                                    ]
                                }
                            ],
                            sort_field: "visits.start_date",
                            sort_by: ["visits.start_date"],
                            sort_method: "chronological",
                            sort_direction: "ASC",
                            sort_nulls_last: true,
                            sort_join: [],
                            search_value: '',
                            item_actions: [
                                "visits.edit",
                                "visits.mark_completed",
                                "visits.mark_closed",
                                "visits.convert_to_invoice",
                                "visits.archive",
                                "visits.delete"
                            ],
                            fields: [
                                {
                                    label: 'Subject',
                                    identifier: 'visits.subject',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.subject"],
                                    display_format: null
                                },
                                {
                                    label: 'Description',
                                    identifier: 'visits.description',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.description"],
                                    display_format: null
                                },
                                {
                                    label: 'Type',
                                    identifier: 'visits.type',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.type"],
                                    display_format: null
                                },
                                {
                                    label: 'Status',
                                    identifier: 'visits.status',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.status", "dropdowns.label"],
                                    display_format: null
                                },
                                {
                                    label: 'Start Date',
                                    identifier: 'visits.start_date',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.start"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'End Date',
                                    identifier: 'visits.end_date',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.end"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'Duration',
                                    identifier: 'visits.duration',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.duration"],
                                    display_format: null
                                },
                                {
                                    label: 'Assigned To',
                                    identifier: 'visits.assigned_to',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.assigned_to", "users.name", "name.display"],
                                    display_format: null
                                },
                                {
                                    label: 'Address',
                                    identifier: 'visits.address',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.address", "properties.address", "address.display"],
                                    display_format: null
                                },
                            ]
                        }
                    ]
                }
            },
            component_6: {
                id: "component_6",
                label: 'Activity',
                object_identifier: 'subscriptions',
                connection_type: 'parent',
                type: ComponentTypes.ACTIVITY,
                special_type: null,
                header_type: 'normal',
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                    activities: [
                        // Tasks
                        {
                            label: 'Tasks',
                            identifier: 'tasks_0',
                            object_identifier: 'tasks',
                            display_field: 'tasks.subject',
                            show_records_container_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            record_cells_first_visible_amount: {
                                mobile: 3,
                                tablet: 2,
                                desktop: 2
                            },
                            filters: [
                                {
                                    fields: ["tasks.is_active"],
                                    field_types: ["checkbox"],
                                    operator: "equal",
                                    values: [
                                        {
                                            type: "value",
                                            label: 'True',
                                            input: true,
                                            data: true,
                                            data_type: 'boolean',
                                            status: 'valid'
                                        }
                                    ]
                                }
                            ],
                            sort_field: "tasks.last_updated_at",
                            sort_by: ["tasks.last_updated_at"],
                            sort_method: "chronological",
                            sort_direction: "DESC",
                            sort_nulls_last: true,
                            sort_join: [],
                            search_value: '',
                            item_actions: [
                                "tasks.edit",
                                "tasks.mark_completed",
                                "tasks.mark_closed",
                                "tasks.convert_to_job",
                                "tasks.archive",
                                "tasks.delete"
                            ],
                            fields: [
                                {
                                    label: 'Subject',
                                    identifier: 'tasks.subject',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.subject"],
                                    display_format: null
                                },
                                {
                                    label: 'Description',
                                    identifier: 'tasks.description',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.description"],
                                    display_format: null
                                },
                                {
                                    label: 'Type',
                                    identifier: 'tasks.type',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.type"],
                                    display_format: null
                                },
                                {
                                    label: 'Status',
                                    identifier: 'tasks.status',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.status", "dropdowns.label"],
                                    display_format: null
                                },
                                {
                                    label: 'Start Date',
                                    identifier: 'tasks.start_date',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.start_date"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'Due Date',
                                    identifier: 'tasks.due_date',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.due_date"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'Assigned To',
                                    identifier: 'tasks.assigned_to',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.assigned_to", "users.name", "name.display"],
                                    display_format: null
                                },
                            ]
                        },
                        // Visits
                        {
                            label: 'Visits',
                            identifier: 'visits_2',
                            object_identifier: 'visits',
                            display_field: 'visits.subject',
                            show_records_container_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            record_cells_first_visible_amount: {
                                mobile: 3,
                                tablet: 2,
                                desktop: 2
                            },
                            filters: [
                                {
                                    fields: ["visits.is_active"],
                                    field_types: ["checkbox"],
                                    operator: "equal",
                                    values: [
                                        {
                                            type: "value",
                                            label: 'True',
                                            input: true,
                                            data: true,
                                            data_type: 'boolean',
                                            status: 'valid'
                                        }
                                    ]
                                }
                            ],
                            sort_field: "visits.last_updated_at",
                            sort_by: ["visits.last_updated_at"],
                            sort_method: "chronological",
                            sort_direction: "DESC",
                            sort_nulls_last: true,
                            sort_join: [],
                            search_value: '',
                            item_actions: [
                                "visits.edit",
                                "visits.mark_completed",
                                "visits.mark_closed",
                                "visits.convert_to_invoice",
                                "visits.archive",
                                "visits.delete"
                            ],
                            fields: [
                                {
                                    label: 'Subject',
                                    identifier: 'visits.subject',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.subject"],
                                    display_format: null
                                },
                                {
                                    label: 'Description',
                                    identifier: 'visits.description',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.description"],
                                    display_format: null
                                },
                                {
                                    label: 'Type',
                                    identifier: 'visits.type',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.type"],
                                    display_format: null
                                },
                                {
                                    label: 'Status',
                                    identifier: 'visits.status',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.status", "dropdowns.label"],
                                    display_format: null
                                },
                                {
                                    label: 'Start Date',
                                    identifier: 'visits.start_date',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.start_date"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'End Date',
                                    identifier: 'visits.end_date',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.end_date"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'Duration',
                                    identifier: 'visits.duration',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.duration"],
                                    display_format: null
                                },
                                {
                                    label: 'Assigned To',
                                    identifier: 'visits.assigned_to',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.assigned_to", "users.name", "name.display"],
                                    display_format: null
                                },
                                {
                                    label: 'Address',
                                    identifier: 'visits.address',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["visits.address", "properties.address", "address.display"],
                                    display_format: null
                                },
                            ]
                        },
                        // Notes
                        {
                            label: 'Notes',
                            identifier: 'notes_3',
                            object_identifier: 'notes',
                            display_field: 'notes.title',
                            show_records_container_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            record_cells_first_visible_amount: {
                                mobile: 3,
                                tablet: 2,
                                desktop: 2
                            },
                            filters: [
                                {
                                    fields: ["notes.is_active"],
                                    field_types: ["checkbox"],
                                    operator: "equal",
                                    values: [
                                        {
                                            type: "value",
                                            label: 'True',
                                            input: true,
                                            data: true,
                                            data_type: 'boolean',
                                            status: 'valid'
                                        }
                                    ]
                                }
                            ],
                            sort_field: "notes.created_at",
                            sort_by: ["notes.created_at"],
                            sort_method: "chronological",
                            sort_direction: "DESC",
                            sort_nulls_last: true,
                            sort_join: [],
                            search_value: '',
                            item_actions: [],
                            fields: [
                                {
                                    label: 'Title',
                                    identifier: 'notes.title',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.title"],
                                    display_format: null
                                },
                                {
                                    label: 'Status',
                                    identifier: 'notes.status',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.status","dropdowns.label"],
                                    display_format: null
                                },
                                {
                                    label: 'Created At',
                                    identifier: 'notes.created_at',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.created_at"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'Created By',
                                    identifier: 'notes.created_by',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.created_by","users.name","name.display"],
                                    display_format: null
                                },
                                {
                                    label: 'Body',
                                    identifier: 'notes.body',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.body"],
                                    display_format: null
                                }
                            ]
                        }
                    ]
                }
            },
            component_7: {
                id: "component_7",
                label: 'Timeline',
                object_identifier: 'subscriptions',
                connection_type: 'parent',
                type: ComponentTypes.TIMELINE,
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                    notifcations: {
                        contacts: true,
                        properties: true,
                        tasks: true,
                        events: true,
                        notes: true,
                        attachments: true
                    }
                }
            }
        },
        sections: [
            {
                id: uniqid('section_'),
                anchor_name: 'header',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'seperate',
                columns: {
                    mobile: [{ id: '1', flexSize: 1, components: ["component_1"] }],
                    tablet: [{ id: '1', flexSize: 1, components: ["component_1"] }],
                    desktop: [{ id: '1', flexSize: 1, components: ["component_1"] }]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'line_items_schedule',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'tabs',
                columns: {
                    mobile: [{ id: '2', flexSize: 1, components: [["component_2", "component_3"]] }],
                    tablet: [{ id: '2', flexSize: 1, components: ["component_2"] }, { id: '3', flexSize: 1, components: ["component_3"] }],
                    desktop: [{ id: '2', flexSize: 2, components: ["component_2"] }, { id: '3', flexSize: 1, components: ["component_3"] }]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'related_schedule_activity_timeline',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'tabs',
                columns: {
                    mobile: [{ id: '2', flexSize: 1, components: [["component_4", "component_5", "component_6", "component_7"]] }],
                    tablet: [{ id: '2', flexSize: 1, components: [["component_4", "component_5"]] }, { id: '3', flexSize: 1, components: [["component_6", "component_7"]] }],
                    desktop: [{ id: '2', flexSize: 2, components: [["component_4", "component_5"]] }, { id: '3', flexSize: 1, components: [["component_6", "component_7"]] }]
                }
            },
        ],
        item_actions: [
            "subscriptions.edit",
            "subscriptions.edit_payment_method",
            "subscriptions.edit_line_items",
            "subscriptions.edit_recurrence",
            "subscriptions.cancel",
            "subscriptions.archive"
        ],
        more_actions: [
            "subscriptions.duplicate",
            "subscriptions.delete"
        ],
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        published_at: new Date(),
        published_by: null,
        
    }
];

export default SubscriptionPageLayoutsData;