
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';

// Import Custom Modules
import * as SortingMethods from '../../sorting/SortingMethods';
import * as SortingDirectionIdentifiers from '../../sorting/SortDataTypeIdentifiers';


const PaymentListViewsData = [
    // All Payments
    {
        object: null,
        object_identifier: 'payments',
        public_id: uniqid('view_'),
        sync_token: 1,
        type: "list_view",
        identifier: identifier('All Payments').toLowerCase(),
        name: 'All Payments',
        description: 'All payments with the company.',
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        sort_field: "payments.status",
        sql_sort_field: ["payments.status", "dropdowns.sort_order"],
        sort_direction: SortingDirectionIdentifiers.ASCENDING,
        sort_join: ["payments.status", "dropdowns.id"],
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        filter_by_viewer: false,
        filters: [ ],
        search_value: "",
        visible_fields: [
            {
                label: 'Subject',
                identifier: 'payments.subject',
                display_fields: ['payments.subject'],
                display_format: null,
                width: 260,
                is_locked: true
            },
            {
                label: 'Amount',
                identifier: 'payments.amount',
                display_fields: ['payments.amount'],
                display_format: null,
                width: 340,
                is_locked: false
            },
            {
                label: 'Status',
                identifier: 'payments.status',
                display_fields: ['payments.status', 'dropdown.label'],
                display_format: null,
                width: 140,
                is_locked: false
            },
            {
                label: 'Account',
                identifier: 'payments.account',
                display_fields: ['payments.account', 'accounts.name', 'name.display'],
                display_format: null,
                width: 180,
                is_locked: false
            },
            {
                label: 'Description',
                identifier: 'payments.description',
                display_fields: ['payments.description'],
                display_format: null,
                width: 340,
                is_locked: false
            },
            {
                label: 'Created At',
                identifier: 'payments.created_at',
                display_fields: ['payments.created_at'],
                display_format: null,
                width: 220,
                is_locked: false
            },
        ],
        hidden_fields: [
            "payments.id",
            "payments.payment_id",
            "payments.sync_token",
            "payments.company",
            "payments.suggested_action",
            "payments.time_zone",
            "payments.currency",
            "payments.language"
        ],
        batch_actions: [
            "payments.batch_edit",
            "payments.batch_archive"
        ],
        item_actions: [
            "payments.edit",
            "payments.archive"
        ],
        more_actions: [ ],
        pagination_amount: 25
    },
    
];

export default PaymentListViewsData;