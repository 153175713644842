const States = [
    { label: 'Alabama', short_label: "AL", value: 'AL' },
    { label: 'Alaska', short_label: "AK", value: 'AK' },
    { label: 'Arizona', short_label: "AZ", value: 'AZ' },
    { label: 'Arkansas', short_label: "AR", value: 'AR' },
    { label: 'California', short_label: "CA", value: 'CA' },
    { label: 'Colorado', short_label: "CO", value: 'CO' },
    { label: 'Connecticut', short_label: "CT", value: 'CT' },
    { label: 'Delaware', short_label: "DE", value: 'DE' },
    { label: 'District of Columbia', short_label: "DC", value: 'DC' },
    { label: 'Florida', short_label: "FL", value: 'FL' },
    { label: 'Georgia', short_label: "GA", value: 'GA' },
    { label: 'Hawaii', short_label: "HI", value: 'HI' },
    { label: 'Idaho', short_label: "ID", value: 'ID' },
    { label: 'Illinois', short_label: "IL", value: 'IL' },
    { label: 'Indiana', short_label: "IN", value: 'IN' },
    { label: 'Iowa', short_label: "IA", value: 'IA' },
    { label: 'Kansas', short_label: "KS", value: 'KS' },
    { label: 'Kentucky', short_label: "KY", value: 'KY' },
    { label: 'Louisiana', short_label: "LA", value: 'LA' },
    { label: 'Maine', short_label: "ME", value: 'ME' },
    { label: 'Maryland', short_label: "MD", value: 'MD' },
    { label: 'Massachusetts', short_label: "MA", value: 'MA' },
    { label: 'Michigan', short_label: "MI", value: 'MI' },
    { label: 'Minnesota', short_label: "MN", value: 'MN' },
    { label: 'Mississippi', short_label: "MS", value: 'MS' },
    { label: 'Missouri', short_label: "MO", value: 'MO' },
    { label: 'Montana', short_label: "MT", value: 'MT' },
    { label: 'Nebraska', short_label: "NE", value: 'NE' },
    { label: 'Nevada', short_label: "NV", value: 'NV' },
    { label: 'New Hampshire', short_label: "NH", value: 'NH' },
    { label: 'New Jersey', short_label: "NJ", value: 'NJ' },
    { label: 'New Mexico', short_label: "NM", value: 'NM' },
    { label: 'New York', short_label: "NY", value: 'NY' },
    { label: 'North Carolina', short_label: "NC", value: 'NC' },
    { label: 'North Dakota', short_label: "ND", value: 'ND' },
    { label: 'Ohio', short_label: "OH", value: 'OH' },
    { label: 'Oklahoma', short_label: "OK", value: 'OK' },
    { label: 'Oregon', short_label: "OR", value: 'OR' },
    { label: 'Pennsylvania', short_label: "PA", value: 'PA' },
    { label: 'Rhode Island', short_label: "RI", value: 'RI' },
    { label: 'South Carolina', short_label: "SC", value: 'SC' },
    { label: 'South Dakota', short_label: "SD", value: 'SD' },
    { label: 'Tennessee', short_label: "TN", value: 'TN' },
    { label: 'Texas', short_label: "TX", value: 'TX' },
    { label: 'Utah', short_label: "UT", value: 'UT' },
    { label: 'Vermont', short_label: "VT", value: 'VT' },
    { label: 'Virginia', short_label: "VA", value: 'VA' },
    { label: 'Washington', short_label: "WA", value: 'WA' },
    { label: 'West Virginia', short_label: "WV", value: 'WV' },
    { label: 'Wisconsin', short_label: "WI", value: 'WI' },
    { label: 'Wyoming', short_label: "WY", value: 'WY' }
];

export default States;