const Languages = [
    {
        label: "English",
        short_label: "EN",
        value: 'en',
        metadata: {
            eng_name: "English"
        }
    },
    {
        label: "Español",
        short_label: "ES",
        value: 'es',
        metadata: {
            eng_name: "Spanish"
        }
    },
    {
        label: "Français",
        short_label: "FR",
        value: 'fr',
        metadata: {
            eng_name: "French"
        }
    }
];

export default Languages;