import React, { Fragment } from 'react';
import PreAuthHeader from '../../app_header/pre_auth/PreAuthHeader';
import { PreAuthBody } from './Styles';

function PreAuthLayout(props) {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Fragment>
            <PreAuthHeader onDrawerToggle={handleDrawerToggle} />

            <PreAuthBody>
                {props.children}
            </PreAuthBody>
            
        </Fragment>
    );
}


export default PreAuthLayout;