
// This constants file is a representation of how data for Page Layouts will be recieved by the ProHelper database once linked.

// App
import HomePageLayoutsData from './page_layouts/app/home/HomePageLayoutsData';

// Schedule
import SchedulePageLayoutsData from './page_layouts/app/schedule/SchedulePageLayoutsData';
import VisitPageLayoutsData from './page_layouts/object/schedule/VisitPageLayoutsData';

// Work
import WorkPageLayoutsData from './page_layouts/app/work/WorkPageLayoutsData';
import RequestPageLayoutsData from './page_layouts/object/work/RequestPageLayoutsData';
import TaskPageLayoutsData from './page_layouts/object/work/TaskPageLayoutsData';
import JobPageLayoutsData from './page_layouts/object/work/JobPageLayoutsData';
import RoutePageLayoutsData from './page_layouts/object/work/RoutePageLayoutsData';

// Clients
import ClientsPageLayoutsData from './page_layouts/app/client/ClientsPageLayoutsData';
import AccountPageLayoutsData from './page_layouts/object/client/AccountPageLayoutsData';
import ContactPageLayoutsData from './page_layouts/object/client/ContactPageLayoutsData';
import PropertyPageLayoutsData from './page_layouts/object/client/PropertyPageLayoutsData';


// Billing
import BillingPageLayoutsData from './page_layouts/app/billing/BillingPageLayoutsData';
import EstimatePageLayoutsData from './page_layouts/object/billing/EstimatePageLayoutsData';
import InvoicePageLayoutsData from './page_layouts/object/billing/InvoicePageLayoutsData';
import PaymentPageLayoutsData from './page_layouts/object/billing/PaymentPageLayoutsData';
import ItemPageLayoutsData from './page_layouts/object/billing/ItemPageLayoutsData';
import SubscriptionPageLayoutsData from './page_layouts/object/billing/SubscriptionPageLayoutsData';

// Audit
import AuditPageLayoutsData from './page_layouts/app/audit/AuditPageLayoutsData';
import LedgerPageLayoutsData from './page_layouts/object/audit/LedgerPageLayoutsData';
import ReportPageLayoutsData from './page_layouts/object/audit/ReportPageLayoutsData';

// Team
import TeamPageLayoutsData from './page_layouts/app/team/TeamPageLayoutsData';
import UserPageLayoutsData from './page_layouts/object/team/UserPageLayoutsData';

// Security
import SecurityPageLayoutsData from './page_layouts/app/security/SecurityPageLayoutsData';
import RolePageLayoutsData from './page_layouts/object/security/RolePageLayoutsData';
import PermissionSetPageLayoutsData from './page_layouts/object/security/PermissionSetPageLayoutsData';



// Add (concact) all the individual page layouts arrays into one big array
const PageLayoutsData = [].concat(
    HomePageLayoutsData,
    SchedulePageLayoutsData,
    VisitPageLayoutsData,
    WorkPageLayoutsData,
    RequestPageLayoutsData,
    EstimatePageLayoutsData,
    TaskPageLayoutsData,
    JobPageLayoutsData,
    RoutePageLayoutsData,
    BillingPageLayoutsData,
    InvoicePageLayoutsData,
    PaymentPageLayoutsData,
    ItemPageLayoutsData,
    SubscriptionPageLayoutsData,
    AuditPageLayoutsData,
    LedgerPageLayoutsData,
    ReportPageLayoutsData,
    ClientsPageLayoutsData,
    AccountPageLayoutsData, 
    ContactPageLayoutsData, 
    PropertyPageLayoutsData,
    TeamPageLayoutsData,
    UserPageLayoutsData,
    SecurityPageLayoutsData,
    RolePageLayoutsData,
    PermissionSetPageLayoutsData
);

export default PageLayoutsData;