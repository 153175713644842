
// Layout Action Types
export const SET_LAYOUT = 'SET_LAYOUT';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_DISPLAY_TYPE = 'SET_DISPLAY_TYPE';
export const SET_HEADER_TYPE = 'SET_HEADER_TYPE';
export const SET_FOOTER_TYPE = 'SET_FOOTER_TYPE';
export const SET_AUTH_FOOTER_VISIBLE = 'SET_AUTH_FOOTER_VISIBLE';
export const SET_ROUTER_LOCATION = 'SET_ROUTER_LOCATION';
export const SET_CURRENT_SCREEN = 'SET_CURRENT_SCREEN';
