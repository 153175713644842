
// Import NPM Modules
import _ from 'lodash';


// Import Custom Modules
import * as actionTypes from '../../action_types/customizations/ListViewsActionTypes';


const ListViewsReducer = (state = { }, action) => {

    switch (action.type) {

        // Handles setting all the object's List Views data at once.
        case actionTypes.SET_ALL_LIST_VIEWS:

            // Create a copy of the current redux state
            let newListViewsState = {...state};

            // Convert the action.payload.list_views from an unordered array to a List Views map.
            if (_.isArray(action.payload.list_views)) {

                // Go through all the List Views received from the customization query and order them in this redux reducers state (state -> object -> list_view)
                action.payload.list_views.forEach((list_view) => {

                    // If this List View belongs to an object then store the action inside the parent object container
                    if (_.isString(list_view.object_identifier)) {
                        // Right now, there are only List Views for objects.

                        // If no JSON has been created for this object, then create a new JSON structure to hold this new object's actions.
                        if (!_.isObject(newListViewsState[list_view.object_identifier])) {
                            newListViewsState[list_view.object_identifier] = { };
                        }

                        newListViewsState[list_view.object_identifier][list_view.identifier] = list_view;
                    }
                });
            }

            // Return the new action state
            return newListViewsState;
        
        
        // Removes all the list views in this reducer and replaces it with blank JSON objects
        case actionTypes.REMOVE_ALL_LIST_VIEWS:

            // Create a copy of the current redux state
            let newRemovedState = { ...state };

            for (let key in newRemovedState) {
                newRemovedState[key] = { };
            }

            // Return the new list view state
            return newRemovedState;

        // Do nothing for the default besides return existing state
        default:
            return state;
    }
};


export default ListViewsReducer;
