
//
// A file containing all the different front-end UI field types for various fields.
// This file is created to maintain a centeral name for field types around the program.
// 
//

export const ID = 'id';
export const PUBLIC_ID = 'public_id';
export const HIDDEN = 'hidden';
export const MAIN_LABEL = 'main_label';
export const FULL_NAME = 'full_name';
export const NAME = 'name';
export const SHORT_TEXT = 'short_text';
export const LONG_TEXT = 'long_text';
export const URL = 'url';
export const IMAGE = 'image';
export const ICON = 'icon';
export const EMAIL = 'email';
export const PHONE = 'phone';
export const ADDRESS = 'address';
export const DATE = 'date';
export const DATE_TIME = 'date_time';
export const TIME = 'time';
export const INTEGER = 'integer';
export const DECIMAL = 'decimal';
export const BALANCE = 'balance';
export const CURRENCY = 'currency';
export const CHECKBOX = 'checkbox';
export const ENCRYPTED_STRING = 'encrypted_string';
export const TYPE_DROPDOWN = 'type_dropdown';
export const CUSTOM_DROPDOWN = 'custom_dropdown';
export const LOOKUP_RELATIONSHIP = 'lookup_relationship';
export const TEXT_DROPDOWN = 'text_dropdown';
export const ALERT = 'alert';
export const LOCATION = 'location';
export const SETTINGS = 'settings';
export const JSON = 'json';

export const ANY_RECORD = 'any_record';
export const SCHEDULE_FILTERS = 'schedule_filters';
export const REMINDER = 'reminder';
export const SIGNATURE = 'signature';