// Import NPM Modules
import { combineReducers } from 'redux';

// Import Reducers to combine
import AppReducer from './AppReducer';
import AuthenticationReducer from './AuthenticationReducer';
import NavigationReducer from './NavigationReducer';
import PermissionsReducer from './PermissionsReducer';

// Export combined reducers as "Program"
export default combineReducers({
    app: AppReducer,
    authentication: AuthenticationReducer,
    navigation: NavigationReducer,
    permissions: PermissionsReducer
});