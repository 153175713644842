
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';

// Import Custom Modules
import * as SortingMethods from '../../sorting/SortingMethods';
import * as SortingDirectionIdentifiers from '../../sorting/SortDataTypeIdentifiers';


const RoleListViewsData = [
    // All Roles
    {
        object: null,
        object_identifier: 'roles',
        public_id: uniqid('view_'),
        sync_token: 1,
        type: "list_view",
        identifier: identifier('All Roles').toLowerCase(),
        name: 'All Roles',
        description: 'All roles with the company.',
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        sort_field: "roles.label",
        sql_sort_field: ["roles.label"],
        sort_direction: SortingDirectionIdentifiers.ASCENDING,
        sort_join: null,
        sort_method: SortingMethods.ALPHANUMERIC,
        filter_by_viewer: false,
        filters: [ ],
        search_value: "",
        visible_fields: [
            {
                label: 'Role Label',
                identifier: 'roles.label',
                display_fields: ['roles.label'],
                display_format: null,
                width: 180,
                is_locked: true
            },
            {
                label: 'Type',
                identifier: 'roles.type',
                display_fields: ['roles.type', 'dropdown.label'],
                display_format: null,
                width: 140,
                is_locked: false
            },
            {
                label: 'Status',
                identifier: 'roles.status',
                display_fields: ['roles.status', 'dropdown.label'],
                display_format: null,
                width: 140,
                is_locked: false
            },
            {
                label: 'Description',
                identifier: 'roles.description',
                display_fields: ['roles.description'],
                display_format: null,
                width: 280,
                is_locked: false
            },
            {
                label: 'Updated At',
                identifier: 'roles.last_updated_at',
                display_fields: ['roles.last_updated_at'],
                display_format: 'MM/DD/YYYY',
                width: 160,
                is_locked: false
            },
            {
                label: 'Updated By',
                identifier: 'roles.last_updated_by',
                display_fields: ['roles.last_updated_by', 'user.name', 'name.display'],
                display_format: null,
                width: 180,
                is_locked: false
            },
        ],
        hidden_fields: [
            "roles.id",
            "roles.role_id",
            "roles.type",
            "roles.sync_token",
            "roles.company",
            "roles.suggested_action"
        ],
        batch_actions: [
            "roles.batch_edit",
            "roles.batch_archive"
        ],
        item_actions: [
            "roles.edit",
            "roles.archive"
        ],
        more_actions: [ ],
        pagination_amount: 25
    },
    
];

export default RoleListViewsData;