
export const GetAccount = 'GetAccount';
export const GetAccounts = 'GetAccounts';

export const GetContact = 'GetContact';
export const GetContacts = 'GetContacts';

export const GetProperty = 'GetProperty';
export const GetProperties = 'GetProperties';

export const GetUser = 'GetUser';
export const GetUsers = 'GetUsers';

export const GetRole = 'GetRole';
export const GetRoles = 'GetRoles';

export const GetPermissionSet = 'GetPermissionSet';
export const GetPermissionSets = 'GetPermissionSets';

export const GetTask = 'GetTask';
export const GetTasks = 'GetTasks';

export const GetRequest = 'GetRequest';
export const GetRequests = 'GetRequests';

export const GetJob = 'GetJob';
export const GetJobs = 'GetJobs';

export const GetRoute = 'GetRoute';
export const GetRoutes = 'GetRoutes';

export const GetEstimate = 'GetEstimate';
export const GetEstimates = 'GetEstimates';

export const GetInvoice = 'GetInvoice';
export const GetInvoices = 'GetInvoices';

export const GetSubscription = 'GetSubscription';
export const GetSubscriptions = 'GetSubscriptions';

export const GetPayment = 'GetPayment';
export const GetPayments = 'GetPayments';

export const GetItem = 'GetItem';
export const GetItems = 'GetPItem';

// Secondary 

export const GetEvent = 'GetEvent';
export const GetEvents = 'GetEvents';

export const GetVisit = 'GetVisit';
export const GetVisits = 'GetVisits';

export const GetNote = 'GetNote';
export const GetNotes = 'GetNotes';

export const GetAttachment = 'GetAttachment';
export const GetAttachments = 'GetAttachments';

export const GetTimelineEvent = 'GetTimelineEvent';
export const GetTimelineEvents = 'GetTimelineEvents';

export const GetLineItem = 'GetLineItem';
export const GetLineItems = 'GetLineItems';

export const GetPermission = 'GetPermission';
export const GetPermissions = 'GetPermissions';

export const GetPrice = 'GetPrice';
export const GetPrices = 'GetPrices';


// Customizations

export const GetAction = 'GetAction';
export const GetActions = 'GetActions';

