// This constats file is a representation of how data for object fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as ActionTypes from '../../types/ActionTypes';
import * as ActionUseTypes from '../../types/ActionUseTypes';

const UserActionsData = [

    // Item Actions
    //
    // New User
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'users',
        sync_token: 1,
        label: "New User",
        short_label: "New",
        identifier: "new",
        icon: "user",
        sort_order: 1,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.NEW,
        description: "Create a new User.",
        help_text: "Create a new User.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Edit User
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'users',
        sync_token: 1,
        label: "Edit User",
        short_label: "Edit",
        identifier: "edit",
        icon: "pen-to-square",
        sort_order: 2,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.EDIT,
        description: "Edit the Fields for a User.",
        help_text: "Edit the Fields for a User.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Call Phone
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'users',
        sync_token: 1,
        label: "Call Phone",
        short_label: "Call",
        identifier: "call_phone",
        icon: "phone",
        sort_order: 3,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.CALL_PHONE,
        description: "Call the primary phone number for a user.",
        help_text: "Call the primary phone number for a user.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Send Email
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'users',
        sync_token: 1,
        label: "Send Email",
        short_label: "Email",
        identifier: "send_email",
        icon: "envelope",
        sort_order: 4,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.EMAIL,
        description: "Send a email to the primary email address for a user.",
        help_text: "Send a email to the primary email address for a user.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // View Last Working Location
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'users',
        sync_token: 1,
        label: "View Last Working Location",
        short_label: "View Last Location",
        identifier: "view_last_working_location",
        icon: "map-location",
        sort_order: 5,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.PROHELPER_LINK,
        description: "Links to the default mapping software or website to get view the last working location of a User.",
        help_text: "View the last working location for this User.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Reset Password
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'users',
        sync_token: 1,
        label: "Reset Password",
        short_label: "Reset Password",
        identifier: "reset_password",
        icon: "key-skeleton",
        sort_order: 6,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.COGNITO_ACTION,
        description: "Resets the users password for authentication in the Program. Sent to the User's email.",
        help_text: "Resets the users password for authentication in the Program. Sent to the User's email.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Change Email
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'users',
        sync_token: 1,
        label: "Change Email",
        short_label: "Change Email",
        identifier: "change_email",
        icon: "envelope",
        sort_order: 7,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.COGNITO_ACTION,
        description: "Resets the users password for authentication in the Program. Sent to the User's email.",
        help_text: "Resets the users password for authentication in the Program. Sent to the User's email.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Archive User
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'users',
        sync_token: 1,
        label: "Archive User",
        short_label: "Archive",
        identifier: "archive",
        icon: "box-archive",
        sort_order: 8,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.ARCHIVE,
        description: "Archive a user to be hidden from normal users in the program.",
        help_text: "Archive a user, hidden but not deleted.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Delete User
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'users',
        sync_token: 1,
        label: "Delete User",
        short_label: "Delete",
        identifier: "delete",
        icon: "trash",
        sort_order: 9,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.DELETE,
        description: "Permatently deletes a User. Not retrievable after this point.",
        help_text: "Permatently deletes a User, Not viewable or retrievable by users after.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },


    //
    // Batch Actions
    //

    // Batch Edit
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'users',
        sync_token: 1,
        label: "Batch Edit",
        short_label: "Batch Edit",
        identifier: "batch_edit",
        icon: "pen-to-square",
        sort_order: 1,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_EDIT,
        description: "Edit the Fields for multiple selected Users at once.",
        help_text: "Edit the Fields for multiple selected Users at once.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Batch Email
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'users',
        sync_token: 1,
        label: "Batch Email",
        short_label: "Batch Email",
        identifier: "batch_email",
        icon: "envelope",
        sort_order: 2,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_EDIT_FIELD,
        description: "Send a email to all selected users' primary emails.",
        help_text: "Send a email to all selected users' primary emails.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Batch Archive
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'users',
        sync_token: 1,
        label: "Batch Archive",
        short_label: "Batch Archive",
        identifier: "batch_archive",
        icon: 'box-archive',
        sort_order: 6,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_ARCHIVE,
        description: "Archive multiple selected Users to be hidden from normal users in the program.",
        help_text: "Archive multiple selected Users to be hidden from normal users in the program.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },

];


export default UserActionsData;