

// Import Custom Modules
import * as actionTypes from '../../action_types/ui/PageListViewTableActionTypes';
import * as ObjectIdentifiers from '../../../constants/static_data/ObjectIdentifiers';


const STARTING_TABLE_DATA = {
    tableScrollPosition: 0,
    tableInnerLeftContainer: {
        totalContentSize: {
            width: 0,
            height: 0
        },
        header: {},
        headerCells: [],
        rows: [],
        cells: [],
        columns: []
    },
    tableInnerMiddleContainer: {
        totalContentSize: {
            width: 0,
            height: 0
        },
        header: {},
        headerCells: [],
        rows: [],
        cells: [],
        columns: []
    },
    tableInnerRightContainer: {
        totalContentSize: {
            width: 0,
            height: 0
        },
        header: {},
        headerCells: [],
        cells: [],
        column: {},
        itemActions: [],
        isColumnCollapsed: false
    },
    selectedRowIds: {},
    paginationFooter: {
        selectedAmount: 50,
        currentPage: 1,
        lastPage: 1
    },
    columnResizing: {}
};


const initialState =
{
    [ObjectIdentifiers.EVENTS]: {...STARTING_TABLE_DATA},
    [ObjectIdentifiers.VISITS]: {...STARTING_TABLE_DATA},
    [ObjectIdentifiers.TASKS]: {...STARTING_TABLE_DATA},
    [ObjectIdentifiers.REQUESTS]: {...STARTING_TABLE_DATA},
    [ObjectIdentifiers.ESTIMATES]: {...STARTING_TABLE_DATA},
    [ObjectIdentifiers.JOBS]: {...STARTING_TABLE_DATA},
    [ObjectIdentifiers.ROUTES]: {...STARTING_TABLE_DATA},
    [ObjectIdentifiers.ACCOUNTS]: {...STARTING_TABLE_DATA},
    [ObjectIdentifiers.CONTACTS]: {...STARTING_TABLE_DATA},
    [ObjectIdentifiers.PROPERTIES]: {...STARTING_TABLE_DATA},
    [ObjectIdentifiers.INVOICES]: {...STARTING_TABLE_DATA},
    [ObjectIdentifiers.PAYMENTS]: {...STARTING_TABLE_DATA},
    [ObjectIdentifiers.SUBSCRIPTIONS]: {...STARTING_TABLE_DATA},
    [ObjectIdentifiers.ITEMS]: {...STARTING_TABLE_DATA},
    [ObjectIdentifiers.LEDGERS]: {...STARTING_TABLE_DATA},
    [ObjectIdentifiers.REPORTS]: {...STARTING_TABLE_DATA},
    [ObjectIdentifiers.USERS]: {...STARTING_TABLE_DATA},
    [ObjectIdentifiers.ROLES]: {...STARTING_TABLE_DATA},
    [ObjectIdentifiers.PERMISSION_SETS]: {...STARTING_TABLE_DATA},
};


const PageListViewTableReducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SET_PAGE_LIST_VIEW_TABLE_STATE:

            return {
                ...state,
                [action.payload.object.identifier]: action.payload.state
            };

        case actionTypes.COLUMN_START_RESIZING:

            const { clientX, columnId, width, prevWidth, field, table } = action.payload;

            let newColumnResizingStartState = {
                ...state,
                [table]: {
                    ...state[table],
                    columnResizing: {
                        startX: clientX,
                        field: field,
                        width: width,
                        prevWidth: prevWidth,
                        isResizingColumn: columnId
                    }
                }
            };

            return newColumnResizingStartState;

        case actionTypes.COLUMN_RESIZING:

            const deltaX = action.payload.clientX - state[action.payload.table].columnResizing.startX;
            const percentageDeltaX = deltaX / state[action.payload.table].columnResizing.prevWidth;

            const newColumnWidth = Math.max(Math.round(state[action.payload.table].columnResizing.width + state[action.payload.table].columnResizing.width * percentageDeltaX), 0)

            let newColumnResizingState = {
                ...state,
                [action.payload.table]: {
                    ...state[action.payload.table],
                    columnResizing: {
                        ...state[action.payload.table].columnResizing,
                        width: newColumnWidth
                    }
                }
            };

            return newColumnResizingState;


        case actionTypes.COLUMN_DONE_RESIZING:

            let newColumnDoneResizingState = {
                ...state,
                [action.payload.table]: {
                    ...state[action.payload.table],
                    columnResizing: {
                        startX: null,
                        isResizingColumn: null
                    }
                }
            };

            return newColumnDoneResizingState;

        default:
            return state;

    }
};


export default PageListViewTableReducer;
