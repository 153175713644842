// This constants file is a representation of how data for object fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as ActionTypes from '../../types/ActionTypes';
import * as ActionUseTypes from '../../types/ActionUseTypes';

const ContactActionsData = [

    //
    // Item Actions
    //
    // New Contact
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "New Contact",
        short_label: "New",
        identifier: "new",
        icon: "address-book",
        sort_order: 1,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.NEW,
        description: "Create a new Contact.",
        help_text: "Create a new Contact.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Edit Contact
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Edit Contact",
        short_label: "Edit Contact",
        identifier: "edit",
        icon: "pen-to-square",
        sort_order: 2,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.EDIT,
        description: "Edit a Contact in complete detail that matches the page layout's information.",
        help_text: "Edit a Contact's complete information in detail.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Call Phone
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Call Phone",
        short_label: "Call",
        identifier: "call_phone",
        icon: "phone",
        sort_order: 3,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.CALL_PHONE,
        description: "Call the primary phone number for the Contact.",
        help_text: "Call the primary phone number for the Contact.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Send Email
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Send Email",
        short_label: "Email",
        identifier: "send_email",
        icon: "envelope",
        sort_order: 4,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.EMAIL,
        description: "Send an email to the primary email address for the Contact.",
        help_text: "Send an email to the primary email address for the Contact.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Send Mail
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Send Mail",
        short_label: "Mail",
        identifier: "send_mail",
        icon: "mailbox",
        sort_order: 5,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.EMAIL,
        description: "Create a printable letter to be mailed to the Contact.",
        help_text: "Create a printable letter to be mailed to the Contact.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // View Parent Account
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "View Parent Account",
        short_label: "View Parent",
        identifier: "view_parent_account",
        icon: "sitemap",
        sort_order: 6,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.PROHELPER_LINK,
        description: "View the parent Account linked for the Contact.",
        help_text: "View the parent Account linked for the Contact.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // View Property
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "View Property",
        short_label: "View Property",
        identifier: "view_property",
        icon: "house-building",
        sort_order: 7,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.PROHELPER_LINK,
        description: "View the primary Property linked for the Contact.",
        help_text: "View the primary Property linked for the Contact.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Duplicate Contact
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Duplicate Contact",
        short_label: "Duplicate",
        identifier: "duplicate",
        icon: "copy",
        sort_order: 8,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.PROHELPER_LINK,
        description: "Copy and duplicate the contents of this Contact to a new one.",
        help_text: "Copy and duplicate the contents of this Contact to a new one.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Archive Contact
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Archive Contact",
        short_label: "Archive",
        identifier: "archive",
        icon: "box-archive",
        sort_order: 9,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.ARCHIVE,
        description: "Archive a Contact to be hidden from normal users in the program.",
        help_text: "Archive a Contact, hidden but not deleted.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Delete Contact
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Delete Contact",
        short_label: "Delete",
        identifier: "delete",
        icon: "trash",
        sort_order: 10,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.DELETE,
        description: "Deletes a Contact to be hidden from normal users in the program.",
        help_text: "Deletes a Contact, hidden but not deleted.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },


    //
    // Batch Actions
    //

    // Batch Edit
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Batch Edit",
        short_label: "Batch Edit",
        identifier: "batch_edit",
        icon: "pen-to-square",
        sort_order: 1,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_EDIT,
        description: "Edit the Fields for multiple selected Contacts at once.",
        help_text: "Edit the Fields for multiple selected Contacts at once.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Batch Email
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Batch Email",
        short_label: "Batch Email",
        identifier: "batch_email",
        icon: "envelope",
        sort_order: 2,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_EDIT_FIELD,
        description: "Send an email to all selected Contacts' primary emails.",
        help_text: "end an email to all selected Contacts' primary emails.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Batch Mail
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Batch Mail",
        short_label: "Batch Mail",
        identifier: "batch_mail",
        icon: "mailbox",
        sort_order: 3,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_EDIT_FIELD,
        description: "Send an email to all selected Contacts' primary emails.",
        help_text: "end an email to all selected Contacts' primary emails.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Batch Archive 
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'contacts',
        sync_token: 1,
        label: "Batch Archive",
        short_label: "Batch Archive",
        identifier: "batch_archive",
        icon: "box-archive",
        sort_order: 4,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_ARCHIVE,
        description: "Archive multiple selected Contacts to be hidden from normal users in the program.",
        help_text: "Archive multiple selected Contacts to be hidden from normal users in the program.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
];


export default ContactActionsData;