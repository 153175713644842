
// Basic Identifiers
export const EQUAL = 'equal';
export const NOT_EQUAL = 'not_equal';
export const GREATER = 'greater';
export const GREATER_EQUAL = 'greater_equal';
export const LESS = 'less';
export const LESS_EQUAL = 'less_equal';
export const INNER_RANGE = 'inner_range';
export const OUTER_RANGE = 'outer_range';
export const RECENT_RANGE = 'recent_range';
export const NEXT_RANGE = 'next_range';
