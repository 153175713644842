
// Import NPM Modules
import _ from 'lodash';

// User Info Reducer
import * as actionTypes from '../../action_types/customizations/ObjectTypesActionTypes';

// Empty initial state sample
// const initialState = {
//     'accounts': {}
// };

const objectTypesReducer = (state = { }, action) => {

    switch (action.type) {

        // Set All the Object's Object Types at once
        case actionTypes.SET_ALL_OBJECT_TYPES:

            // Create a copy of the current redux state
            let newObjectTypesState = {...state};

            // Convert the action.payload.object_types from an array to a object types map.
            if (_.isArray(action.payload.object_types)) {

                // Go through all the object types received from the customization query and order them in this redux reducers state (state -> object -> object_type)
                for (let i=0; i< action.payload.object_types.length; i++) {

                    let currentObjectTypes = action.payload.object_types[i];

                    // If this currentObjectTypes belongs to an object then store the currentObjectTypes inside the parent object container
                    if (!_.isNull(currentObjectTypes.object) && !_.isNull(currentObjectTypes.object_identifier)) {
                        // This object type has a valid object and object_identifier and is a real object

                        // If no JSON has been created for this object, then create a new JSON structure to hold this new object's types.
                        if (!_.isObject(newObjectTypesState[currentObjectTypes.object_identifier])) {
                            newObjectTypesState[currentObjectTypes.object_identifier] = { };
                        }

                        // Check for an action type and switch between the correct object type object to store this object type.
                        if (!_.isEmpty(currentObjectTypes.identifier)) {
                            newObjectTypesState[currentObjectTypes.object_identifier][currentObjectTypes.identifier] = currentObjectTypes;
                        }
                    }
                }
            }

            return newObjectTypesState;
        

        // Removes all the dropdown sets in this reducer
        case actionTypes.REMOVE_ALL_OBJECT_TYPES:

            // Create a copy of the current redux state
            let newRemovedState = {...state};
            
            // Loop through each key in this reducer's state
            for (let key in newRemovedState) {
                // Delete each key's value 
                delete newRemovedState[key];
            }

            // Return the new action state
            return newRemovedState;
        

        // Default, do nothing
        default:
            return state;

    }
};

export default objectTypesReducer;