
// Basic Scalar Types
export const ID = 'ID';
export const String = 'String';
export const Int = 'Int';
export const Float = 'Float';
export const Boolean = 'Boolean';

// Not real but this is quirk (bc not true graphql schema)
export const Array_String = 'Array_String';
export const Array_Object = 'Array_Object';
export const Array_Filters = 'Array_Filters';

// Custom Scalar Types
export const BigInt = 'BigInt';
export const Date = 'Date';
export const JSON = 'JSON';

// Input Types


// Custom Type Structures
export const Account = 'Account';
export const Contact = 'Contact';
export const Property = 'Property';
export const User = 'User';
export const Role = 'Role';
export const PermissionSet = 'PermissionSet';
export const Task = 'Task';
export const Request = 'Request';
export const Job = 'Job';
export const Route = 'Route';
export const Item = 'Item';
export const Estimate = 'Estimate';
export const Invoice = 'Invoice';
export const Payment = 'Payment';
export const Expense = 'Expense';
export const Subscription = 'Subscription';
export const Dropdown = 'Dropdown';

// Secondary Type Structures
export const Event = 'Event';
export const Note = 'Note';
export const Attachment = 'Attachment';
export const Notification = 'Notification';
export const TimelineEvent = 'TimelineEvent';
export const Permission = 'Permission';
export const LineItem = 'LineItem';
export const Price = 'Price';


// JSON Type Structures
export const Name = 'Name';
export const Address = 'Address';
export const Alert = 'Alert';
export const Email = 'Email';
export const Phone = 'Phone';
export const AnyRecord = 'AnyRecord';
