
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';

// Import Custom Modules
import * as SortingMethods from '../../sorting/SortingMethods';
import * as SortingDirectionIdentifiers from '../../sorting/SortDataTypeIdentifiers';


const JobListViewsData = [
    // All Jobs
    {
        object: null,
        object_identifier: 'jobs',
        public_id: uniqid('view_'),
        sync_token: 1,
        type: "list_view",
        identifier: identifier('All Jobs').toLowerCase(),
        name: 'All Jobs',
        description: 'All jobs with the company.',
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        sort_field: "jobs.status",
        sql_sort_field: ["jobs.status", "dropdowns.sort_order"],
        sort_direction: SortingDirectionIdentifiers.ASCENDING,
        sort_join: ["jobs.status", "dropdowns.id"],
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        filter_by_viewer: false,
        filters: [ ],
        search_value: "",
        visible_fields: [
            {
                label: 'Subject',
                identifier: 'jobs.subject',
                display_fields: ['jobs.subject'],
                display_format: null,
                width: 260,
                is_locked: true
            },
            {
                label: 'Type',
                identifier: 'jobs.type',
                display_fields: ['jobs.type', 'dropdown.label'],
                display_format: null,
                width: 140,
                is_locked: false
            },
            {
                label: 'Account',
                identifier: 'jobs.account',
                display_fields: ['jobs.account', 'accounts.name', 'name.display'],
                display_format: null,
                width: 180,
                is_locked: false
            },
            {
                label: 'Description',
                identifier: 'jobs.description',
                display_fields: ['jobs.description'],
                display_format: null,
                width: 340,
                is_locked: false
            },
            {
                label: 'Status',
                identifier: 'jobs.status',
                display_fields: ['jobs.status', 'dropdown.label'],
                display_format: null,
                width: 140,
                is_locked: false
            },
            {
                label: 'Priority',
                identifier: 'jobs.priority',
                display_fields: ['jobs.priority', 'dropdown.label'],
                display_format: null,
                width: 140,
            is_locked: false
            },
            {
                label: 'Created At',
                identifier: 'jobs.created_at',
                display_fields: ['jobs.created_at'],
                display_format: null,
                width: 220,
                is_locked: false
            },
        ],
        hidden_fields: [
            "jobs.id",
            "jobs.type",
            "jobs.job_id",
            "jobs.type",
            "jobs.sync_token",
            "jobs.company",
            "jobs.suggested_action",
            "jobs.time_zone",
            "jobs.currency",
            "jobs.language"
        ],
        batch_actions: [
            "jobs.batch_edit",
            "jobs.batch_archive"
        ],
        item_actions: [
            "jobs.edit",
            "jobs.mark_completed",
            "jobs.mark_closed",
            "jobs.convert_to_invoice",
            "jobs.archive"
        ],
        more_actions: [ 
            "jobs.edit_recurrence",
            "jobs.duplicate",
            "jobs.delete"
        ],
        pagination_amount: 25
    },
    
];

export default JobListViewsData;