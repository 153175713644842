// This constats file is a representation of how data for object fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as ActionTypes from '../../types/ActionTypes';
import * as ActionUseTypes from '../../types/ActionUseTypes';

const LedgerActionsData = [

    // Item Actions
    //
    // New Ledger
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "New Ledger",
        short_label: "New",
        identifier: "new",
        icon: "file-ledger-dollar",
        sort_order: 1,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.NEW,
        description: "Create a new Ledger.",
        help_text: "Create a new Ledger.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Edit Ledger
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Edit Ledger",
        short_label: "Edit",
        identifier: "edit",
        icon: "pen-to-square",
        sort_order: 2,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.EDIT,
        description: "Edit the Fields for a Ledger.",
        help_text: "Edit the Fields for a Ledger.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Adjust Balance
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Adjust Balance",
        short_label: "Adjust Balance",
        identifier: "adjust_balance",
        icon: "scale-balanced",
        sort_order: 3,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.ADJUST_BALANCE,
        description: "Adjust the balance of a Ledger by creating a Credit or Debit transaction with an amount and note, in order to change the total to an updated amount.",
        help_text: "Adjust the balance of this Ledger by creating a Credit or Debit transaction with an amount and note, in order to change the total to an updated amount.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Duplicate Ledger
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Duplicate Ledger",
        short_label: "Duplicate",
        identifier: "duplicate",
        icon: "copy",
        sort_order: 4,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.DUPLICATE,
        description: "Copy the information from one ledger to newly created one.",
        help_text: "Copy the information from this ledger to newly created one.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Archive Ledger
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Archive Ledger",
        short_label: "Archive",
        identifier: "archive",
        icon: "box-archive",
        sort_order: 5,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.ARCHIVE,
        description: "Archive a ledger to be hidden from normal ledgers in the program.",
        help_text: "Archive a ledger, hidden but not deleted.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Delete Ledger
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Delete Ledger",
        short_label: "Delete",
        identifier: "delete",
        icon: "trash",
        sort_order: 6,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.DELETE,
        description: "Permantently deletes an Ledger. Not retrievable by users after this point.",
        help_text: "Permantently deletes an Ledger. Not retrievable by users after this point.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },

    //
    // Batch Actions
    //

    // Batch Edit
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Batch Edit",
        short_label: "Batch Edit",
        identifier: "batch_edit",
        icon: "pen-to-square",
        sort_order: 1,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_EDIT,
        description: "Edit the Fields for multiple selected Ledgers at once.",
        help_text: "Edit the Fields for multiple selected Ledgers at once.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Batch Archive
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Batch Archive",
        short_label: "Batch Archive",
        identifier: "batch_archive",
        icon: 'box-archive',
        sort_order: 2,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_ARCHIVE,
        description: "Archive multiple selected Ledgers to be hidden from normal ledgers in the program.",
        help_text: "Archive multiple selected Ledgers to be hidden from normal ledgers in the program.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },

];


export default LedgerActionsData;