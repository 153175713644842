// This constants file is a representation of how data for subscription fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as FieldTypes from '../../types/FieldTypes';
import * as DatabaseTypes from '../../types/DatabaseTypes';
import * as SpecialTypes from '../../types/SpecialTypes';
import * as FilterOperatorTypeIdenifiers from '../../filtering/operators/FilterOperatorTypeIdentifiers';
import * as LookupRelationshipTypes from '../../types/LookupRelationshipTypes';
import * as SortingMethods from '../../sorting/SortingMethods';

const SubscriptionFieldsData = [
    // Database ID
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Database ID",
        identifier: "id",
        field_type: FieldTypes.ID,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The ID of this Subscription in the ProHelper Database.",
        help_text: "The ID of this Subscription in the ProHelper Database.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Company
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Company",
        identifier: "company",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The organization in ProHelper that this Subscription is apart of.",
        help_text: "The organization for this Subscription in ProHelper.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Subject
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Subject",
        identifier: "subject",
        field_type: FieldTypes.LONG_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: SpecialTypes.MAIN_LABEL,
        description: "The primary subject/label for a Subscription.",
        help_text: "The primary subject/label of a Subscription.",
        default_value: null,
        placeholder_text: 'Enter a Subscription subject/title...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 1,
        is_required: true,
        is_editable: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 1,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Description
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Description",
        identifier: "description",
        field_type: FieldTypes.LONG_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "A description for the Subscription.",
        help_text: "Information about this Subscription.",
        default_value: null,
        placeholder_text: 'Add a Subscription description...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 2,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: 2,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Status
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Status",
        identifier: "status",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The status indicates its current step/stage in the subscription process; for tracking of progress.",
        help_text: "The current status of this subscription in its lifecycle.",
        default_value: null,
        placeholder_text: 'Select a status...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 3,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 1,
        is_sortable: true,
        sort_order: 3,
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "subscriptions.statuses",
            object_identifier: "subscriptions",
            type: "custom_dropdown"
        },
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Account
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Account",
        identifier: "account",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the customer account that is linked to this Subscription.",
        help_text: "The customer account charged for this Subscription.",
        default_value: null,
        placeholder_text: 'Select an Account...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 4,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 2,
        is_sortable: true,
        sort_order: 4,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "accounts",
            filters: [
                {
                    fields: ["accounts.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Primary Contact
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Primary Contact",
        identifier: "primary_contact",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the primary customer contact to reach out to about the subscription.",
        help_text: "Reference to the primary customer contact to reach out to about this subscription.",
        default_value: null,
        placeholder_text: 'Select a Primary Contact...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 5,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 3,
        is_sortable: true,
        sort_order: 5,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "contacts",
            filters: [
                {
                    fields: ["contacts.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Primary Property
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Primary Property",
        identifier: "primary_property",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the primary Property for the Subscription.",
        help_text: "Reference to the primary Property for the Subscription.",
        default_value: null,
        placeholder_text: 'Select a Property...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 6,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 4,
        is_sortable: true,
        sort_order: 6,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "properties",
            filters: [
                {
                    fields: ["properties.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "address",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Billing Contact
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Billing Contact",
        identifier: "billing_contact",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the billing contact to reach out to about the Subscription.",
        help_text: "Reference to the billing contact to reach out to about this Subscription.",
        default_value: null,
        placeholder_text: 'Select a Contact for Billing...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 7,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 5,
        is_sortable: true,
        sort_order: 7,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "contacts",
            filters: [
                {
                    fields: ["contacts.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Billing Property
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Billing Property",
        identifier: "billing_property",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the billing property for the Subscription.",
        help_text: "Reference to the billing property for this Subscription.",
        default_value: null,
        placeholder_text: 'Select a Billing Property...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 8,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 6,
        is_sortable: true,
        sort_order: 8,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "properties",
            filters: [
                {
                    fields: ["properties.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "address",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Service Contact
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Service Contact",
        identifier: "service_contact",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the service contact to reach out to about the Subscription.",
        help_text: "Reference to the service contact to reach out to about this Subscription.",
        default_value: null,
        placeholder_text: 'Select a Service Contact...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 9,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 7,
        is_sortable: true,
        sort_order: 9,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "contacts",
            filters: [
                {
                    fields: ["contacts.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Service Property
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Service Property",
        identifier: "service_property",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the service property for the Subscription.",
        help_text: "Reference to the service property for this Subscription.",
        default_value: null,
        placeholder_text: 'Select a Property for Service...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 10,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 8,
        is_sortable: true,
        sort_order: 10,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "properties",
            filters: [
                {
                    fields: ["properties.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "address",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Tags
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Tags",
        identifier: "tags",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.TEXT_ARRAY,
        special_type: null,
        description: "Label(s) attached to a Subscription for extra identification.",
        help_text: "Tag(s) are labels attached to a Subscription for extra identification.",
        default_value: null,
        placeholder_text: 'Add some Subscription Tags...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 11,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 9,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: true,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "subscriptions.tags",
            object_identifier: "subscriptions",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Subscription ID
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Subscription ID",
        identifier: "subscription_id",
        field_type: FieldTypes.PUBLIC_ID,
        data_type: DatabaseTypes.TEXT,
        special_type: SpecialTypes.PUBLIC_ID,
        description: "The unique ID for a Subscription shown to publically to subscriptions. Subscription Prefix is 'sub_'.",
        help_text: "The unique public ID for a Subscription.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 12,
        is_required: true,
        is_editable: false,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Subscription Number
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Subscription Number",
        identifier: "subscription_number",
        field_type: FieldTypes.INTEGER,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "A sequentially generated integer representing the number of Subscriptions this organization has created.",
        help_text: "A sequentially generated integer representing the number of Subscriptions this organization has created.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 13,
        is_required: true,
        is_editable: false,
        is_searchable: true,
        is_filterable: true,
        filter_order: 10,
        is_sortable: true,
        sort_order: 11,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: 'A',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Footer Message
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Footer Message",
        identifier: "footer_message",
        field_type: FieldTypes.LONG_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "A short message to include in the Footer in your Invoices when printed or emailed with ProHelper.",
        help_text: "A short message to include in the Footer in your Invoices when printed or emailed with ProHelper.",
        default_value: null,
        placeholder_text: 'Enter a footer message on the invoice...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 22,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'D',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 20,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Payment Method
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Payment Method",
        identifier: "payment_method",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The customer selected choice of payment for the Subscription.",
        help_text: "The customer selected choice of payment for this Subscription.",
        default_value: null,
        placeholder_text: 'Enter a Payment Method...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 14,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 11,
        is_sortable: true,
        sort_order: 12,
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "global.payment_methods",
            object_identifier: "global",
            type: "custom_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Default Payment Source
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Default Payment Source",
        identifier: "default_payment_source",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The default payment source for this Subscription.",
        help_text: "The default payment source for this Subscription.",
        default_value: null,
        placeholder_text: 'Enter a Payment Source...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 15,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 12,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "payment_sources",
            filters: [
                {
                    fields: ["payment_sources.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "subject",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Pending Payment
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Pending Payment",
        identifier: "pending_payment",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "You can use this pending Payment to collect user authentication when creating a subscription without immediate payment or updating a subscription's payment source so optimize off-session payments.",
        help_text: "You can use this pending Payment to collect user authentication when creating a subscription without immediate payment or updating a subscription's payment source so optimize off-session payments.",
        default_value: null,
        placeholder_text: 'Enter a Pending Payment...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 16,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 13,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "payments",
            filters: [
                {
                    fields: ["payments.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "subject",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Latest Invoice
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Latest Invoice",
        identifier: "latest_invoice",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The most recent invoice this subscription has generated.",
        help_text: "The most recent invoice this subscription has generated.",
        default_value: null,
        placeholder_text: 'Enter a Invoice...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 17,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 14,
        is_sortable: true,
        sort_order: 13,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "invoices",
            filters: [
                {
                    fields: ["invoices.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "subject",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Collection Method
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Collection Method",
        identifier: "collection_method",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Either charge_automatically, or send_invoice. When charging automatically, ProHelper will attempt to pay this subscription at the end of the cycle using the default payment source attached to the customer. When sending an invoice, ProHelper will email your customer an invoice with payment instructions and mark the subscription as active.",
        help_text: "Either charge_automatically, or send_invoice. When charging automatically, ProHelper will attempt to pay this subscription at the end of the cycle using the default payment source attached to the customer. When sending an invoice, ProHelper will email your customer an invoice with payment instructions and mark the subscription as active.",
        default_value: null,
        placeholder_text: 'Enter a Collection Method...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 18,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 15,
        is_sortable: true,
        sort_order: 14,
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "subscriptions.collection_methods",
            object_identifier: "subscriptions",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Days Until Due
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Days Until Due",
        identifier: "days_until_due",
        field_type: FieldTypes.INTEGER,
        data_type: DatabaseTypes.INTEGER,
        special_type: null,
        description: "Number of days a customer has to pay invoices generated by this subscription. This field is not used if Collection Method is Charge Automatically.",
        help_text: "Number of days a customer has to pay invoices generated by this subscription. This field is not used if Collection Method is Charge Automatically.",
        default_value: null,
        placeholder_text: 'Enter a days until due...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 19,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 16,
        is_sortable: true,
        sort_order: 15,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Discounts
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Discounts",
        identifier: "discounts",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT_ARRAY,
        special_type: null,
        description: "Reference to the discounts for the subscription.",
        help_text: "Reference to the discounts for the subscription.",
        default_value: null,
        placeholder_text: 'Select Discounts...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 20,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 17,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: true,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "discounts",
            filters: [
                {
                    fields: ["discounts.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "title",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Tax Rates
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Tax Rates",
        identifier: "tax_rates",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT_ARRAY,
        special_type: null,
        description: "Reference to the tax rates for the subscription.",
        help_text: "Reference to the tax rates for the subscription.",
        default_value: null,
        placeholder_text: 'Select Tax Rates...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 21,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 18,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: true,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "tax_rates",
            filters: [
                {
                    fields: ["tax_rates.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "title",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Trial Start
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Trial Start",
        identifier: "trial_start",
        field_type: FieldTypes.DATE,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "If the subscription has a trial, this is the beginning date of that trial.",
        help_text: "If this subscription has a trial, this is the beginning date of that trial.",
        default_value: null,
        placeholder_text: 'Select a Trial Start Date...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 22,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 19,
        is_sortable: true,
        sort_order: 16,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 2
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Trial End
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Trial End",
        identifier: "trial_end",
        field_type: FieldTypes.DATE,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "If the subscription has a trial, this is the end date of that trial.",
        help_text: "If this subscription has a trial, this is the end date of that trial.",
        default_value: null,
        placeholder_text: 'Select a Trial End Date...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 23,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 20,
        is_sortable: true,
        sort_order: 17,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 2
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Billing Cycle Anchor
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Billing Cycle Anchor",
        identifier: "billing_cycle_anchor",
        field_type: FieldTypes.DATE,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Determines the date of the first full invoice, and, for plans with month or year intervals, the day of the month for subsequent invoices. The timestamp is in UTC format.",
        help_text: "Determines the date of the first full invoice, and, for plans with month or year intervals, the day of the month for subsequent invoices. The timestamp is in UTC format.",
        default_value: null,
        placeholder_text: 'Select a Billing Cycle Anchor Date...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 24,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 21,
        is_sortable: true,
        sort_order: 18,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 2
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Application Fee Amount
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Application Fee Amount",
        identifier: "next_payment_attempt",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The fee that will be applied when the Subscription is paid.",
        help_text: "The fee that will be applied when this Subscription is paid.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 25,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Revenue Ledger
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Revenue Ledger",
        identifier: "revenue_ledger",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the accounting ledger for the revenue from the subscription.",
        help_text: "Reference to the accounting ledger for the revenue from the subscription.",
        default_value: null,
        placeholder_text: 'Select an Accounting Ledger for Revenue...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 26,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 22,
        is_sortable: true,
        sort_order: 19,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "ledgers",
            filters: [
                {
                    fields: ["ledgers.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "title",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Expense Ledger
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Expense Ledger",
        identifier: "expense_ledger",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the accounting ledger for the expenses from the subscription.",
        help_text: "Reference to the accounting ledger for the expenses from the subscription.",
        default_value: null,
        placeholder_text: 'Select an Accounting Ledger for Expenses...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 27,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 23,
        is_sortable: true,
        sort_order: 20,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "ledgers",
            filters: [
                {
                    fields: ["ledgers.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "title",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Started At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Started At",
        identifier: "started_at",
        field_type: FieldTypes.DATE,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Start of the current period that the subscription has been invoiced for.",
        help_text: "Start of the current period that the subscription has been invoiced for.",
        default_value: null,
        placeholder_text: 'Select thr Current Period Start Date...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 28,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 24,
        is_sortable: true,
        sort_order: 21,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Scheduling Information",
            identifier: "scheduling_information",
            order: 3
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Current Period Start
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Current Period Start",
        identifier: "current_period_start",
        field_type: FieldTypes.DATE,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Start of the current period that the subscription has been invoiced for.",
        help_text: "Start of the current period that the subscription has been invoiced for.",
        default_value: null,
        placeholder_text: 'Select the Current Period Start Date...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 29,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 25,
        is_sortable: true,
        sort_order: 22,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Scheduling Information",
            identifier: "scheduling_information",
            order: 3
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Current Period End
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Period End",
        identifier: "period_end",
        field_type: FieldTypes.DATE,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "End of the current period that the subscription has been invoiced for. At the end of this period, a new invoice will be created.",
        help_text: "End of the current period that the subscription has been invoiced for. At the end of this period, a new invoice will be created.",
        default_value: null,
        placeholder_text: 'Select the Current Period End Date...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 30,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 26,
        is_sortable: true,
        sort_order: 23,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Scheduling Information",
            identifier: "scheduling_information",
            order: 3
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Cancel At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Cancel At",
        identifier: "cancel_at",
        field_type: FieldTypes.DATE,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "A date in the future at which the subscription will automatically get canceled.",
        help_text: "A date in the future at which the subscription will automatically get canceled.",
        default_value: null,
        placeholder_text: 'Select the Cancel Date...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 32,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 28,
        is_sortable: true,
        sort_order: 25,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Scheduling Information",
            identifier: "scheduling_information",
            order: 3
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Canceled At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Canceled At",
        identifier: "canceled_at",
        field_type: FieldTypes.DATE,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "If the subscription has been canceled, the date of that cancellation. If the subscription was canceled with cancel_at_period_end, canceled_at will reflect the time of the most recent update request, not the end of the subscription period when the subscription is automatically moved to a canceled state.",
        help_text: "If the subscription has been canceled, the date of that cancellation. If the subscription was canceled with cancel_at_period_end, canceled_at will reflect the time of the most recent update request, not the end of the subscription period when the subscription is automatically moved to a canceled state.",
        default_value: null,
        placeholder_text: 'Select the Canceled Date...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 33,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 29,
        is_sortable: true,
        sort_order: 26,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Scheduling Information",
            identifier: "scheduling_information",
            order: 3
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Ended At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Ended At",
        identifier: "ended_at",
        field_type: FieldTypes.DATE,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "If the subscription has ended, the date the subscription ended.",
        help_text: "If the subscription has ended, the date the subscription ended.",
        default_value: null,
        placeholder_text: 'Select the Ended Date...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 34,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 30,
        is_sortable: true,
        sort_order: 27,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Scheduling Information",
            identifier: "scheduling_information",
            order: 3
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Recurrence Schedule
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Recurrence Schedule",
        identifier: "recurrence_schedule",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The recurring schedule for this Subscription.",
        help_text: "The recurring schedule for this Subscription.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 35,
        is_required: true,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Schedule Information",
            identifier: "schedule_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "recurrence_schedules",
            filters: [ ],
            display_field: "title",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Number of Invoices
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Number of Invoices",
        identifier: "num_of_invoices",
        field_type: FieldTypes.INTEGER,
        data_type: DatabaseTypes.INTEGER,
        special_type: null,
        description: "Number of Invoices that have been created by the Subscription.",
        help_text: "Number of Invoices that have been created by this Subscription.",
        default_value: null,
        placeholder_text: 'Enter number of invoices created...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 36,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 31,
        is_sortable: true,
        sort_order: 28,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 4
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Number of Payments
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Number of Payments",
        identifier: "num_of_payments",
        field_type: FieldTypes.INTEGER,
        data_type: DatabaseTypes.INTEGER,
        special_type: null,
        description: "Number of Payments that have been received related to the Subscription's invoices.",
        help_text: "Number of Payments that have been received related to the Subscription's invoices.",
        default_value: null,
        placeholder_text: 'Enter number of payments recieved...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 37,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 32,
        is_sortable: true,
        sort_order: 29,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 4
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Number of Times Past Due 30
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Number of Times Past Due 30",
        identifier: "num_of_times_past_due_30",
        field_type: FieldTypes.INTEGER,
        data_type: DatabaseTypes.INTEGER,
        special_type: null,
        description: "Number of times the customer has been past due over 30 days.",
        help_text: "Number of times the customer has been past due over 30 days.",
        default_value: null,
        placeholder_text: 'Enter number of times past due over 30 days...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 38,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 33,
        is_sortable: true,
        sort_order: 30,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 4
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Number of Times Past Due 60
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Number of Times Past Due 60",
        identifier: "num_of_times_past_due_60",
        field_type: FieldTypes.INTEGER,
        data_type: DatabaseTypes.INTEGER,
        special_type: null,
        description: "Number of times the customer has been past due over 60 days.",
        help_text: "Number of times the customer has been past due over 60 days.",
        default_value: null,
        placeholder_text: 'Enter number of times past due over 60 days...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 39,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 34,
        is_sortable: true,
        sort_order: 31,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 4
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Number of Times Past Due 90
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Number of Times Past Due 90",
        identifier: "num_of_times_past_due_90",
        field_type: FieldTypes.INTEGER,
        data_type: DatabaseTypes.INTEGER,
        special_type: null,
        description: "Number of times the customer has been past due over 90 days.",
        help_text: "Number of times the customer has been past due over 90 days.",
        default_value: null,
        placeholder_text: 'Enter number of times past due over 90 days...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 39,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 34,
        is_sortable: true,
        sort_order: 31,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 4
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Primary Contact's Name
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Primary Contact's Name",
        identifier: "primary_name",
        field_type: FieldTypes.NAME,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The name of the Primary Contact. Used for full-text search.",
        help_text: "The name of this Subscription's Primary Contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 41,
        is_required: false,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 36,
        is_sortable: true,
        sort_order: 33,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 5
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Primary Contact's Phone
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Primary Contact's Phone",
        identifier: "primary_phone",
        field_type: FieldTypes.PHONE,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The phone number of the Primary Contact. Used for full-text search.",
        help_text: "The phone number of the Primary Contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 42,
        is_required: false,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 37,
        is_sortable: true,
        sort_order: 34,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 5
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Primary Contact's Email
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Primary Contact's Email",
        identifier: "primary_email",
        field_type: FieldTypes.EMAIL,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The email address of the Primary Contact. Used for full-text search.",
        help_text: "The email address of the Primary Contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 43,
        is_required: false,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 35,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 5
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Primary Property's Address
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Primary Property's Address",
        identifier: "primary_address",
        field_type: FieldTypes.ADDRESS,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The address of the Primary Property. Used for full-text search.",
        help_text: "The address of the Primary Property.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 44,
        is_required: false,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 38,
        is_sortable: true,
        sort_order: 36,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 5
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Billing Contact's Name
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Billing Contact's Name",
        identifier: "billing_name",
        field_type: FieldTypes.NAME,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The name of the Billing Contact. Used for full-text search.",
        help_text: "The name of the Billing Contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 45,
        is_required: false,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 39,
        is_sortable: true,
        sort_order: 37,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 5
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Billing Contact's Phone Number
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Billing Contact's Phone",
        identifier: "billing_phone",
        field_type: FieldTypes.PHONE,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The phone number of the Billing Contact. Used for full-text search.",
        help_text: "The phone number of the Billing Contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 46,
        is_required: false,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 40,
        is_sortable: true,
        sort_order: 38,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 5
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Billing Contact's Email Address
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Billing Contact's Email",
        identifier: "billing_email",
        field_type: FieldTypes.EMAIL,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The email address of the Billing Contact. Used for full-text search.",
        help_text: "The email address of the Billing Contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 47,
        is_required: false,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 39,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 5
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Billing Property's Address
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Billing Property's Address",
        identifier: "billing_address",
        field_type: FieldTypes.ADDRESS,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The address of the Billing Property. Used for full-text search.",
        help_text: "The address of the Billing Property.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 48,
        is_required: false,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 41,
        is_sortable: true,
        sort_order: 40,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 5
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Service Contact's Name
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Service Contact's Name",
        identifier: "service_name",
        field_type: FieldTypes.NAME,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The name of the Service Contact. Used for full-text search.",
        help_text: "The name of the Service Contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 49,
        is_required: false,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 42,
        is_sortable: true,
        sort_order: 41,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 5
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Service Contact's Phone Number
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Service Contact's Phone",
        identifier: "service_phone",
        field_type: FieldTypes.PHONE,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The phone number of the Service Contact. Used for full-text search.",
        help_text: "The phone number of the Service Contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 50,
        is_required: false,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 43,
        is_sortable: true,
        sort_order: 42,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 5
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Service Contact's Email Address
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Service Contact's Email",
        identifier: "service_email",
        field_type: FieldTypes.EMAIL,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The email address of the Service Contact. Used for full-text search.",
        help_text: "The email address of the Service Contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 51,
        is_required: false,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 43,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 5
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Service Property's Address
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Service Property's Address",
        identifier: "service_address",
        field_type: FieldTypes.ADDRESS,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The address of the Service Property. Used for full-text search.",
        help_text: "The address of the Service Property.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 52,
        is_required: false,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 44,
        is_sortable: true,
        sort_order: 44,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 5
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Is Trialing
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Is Trialing",
        identifier: "is_trialing",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If True, represents that the subscription in the trial period.",
        help_text: "If True, represents that this subscription in the trial period.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 53,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 45,
        is_sortable: true,
        sort_order: 45,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Subscription Checks",
            identifier: "subscription_checks",
            order: 5
        },
        checkbox_labels: { true: "Active", false: "Not Active" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Taxable
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Is Taxable",
        identifier: "is_taxable",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Subscription is taxable and uses the tax rates data.",
        help_text: "If true, this Subscription is taxable and uses the tax rates data.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 54,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 46,
        is_sortable: true,
        sort_order: 46,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Subscription Checks",
            identifier: "subscription_checks",
            order: 5
        },
        checkbox_labels: { true: "Attempted", false: "Not Attempted" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Tax Applied After Discount
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Is Tax Applied After Discount",
        identifier: "is_tax_applied_after_discount",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, the tax rates are applied after discounts are applied to the subtotal.",
        help_text: "If true, the tax rates are applied after discounts are applied to the subtotal.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 55,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 47,
        is_sortable: true,
        sort_order: 47,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Subscription Checks",
            identifier: "subscription_checks",
            order: 5
        },
        checkbox_labels: { true: "Paid", false: "Not Paid" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Active
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Is Active",
        identifier: "is_active",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if this Subscription is currently active; able for users to use it.",
        help_text: "Represents if this Subscription is currently active; able for users to use it.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: true
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 56,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 48,
        is_sortable: true,
        sort_order: 48,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Subscription Checks",
            identifier: "subscription_checks",
            order: 5
        },
        checkbox_labels: { true: "Active", false: "Not Active" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is On Hold
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Is On Hold",
        identifier: "is_on_hold",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this subscription is on hold and all invoices should be temporarily stopped.",
        help_text: "If true, this subscription is on hold and all invoices should be temporarily stopped.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 57,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 49,
        is_sortable: true,
        sort_order: 49,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Subscription Checks",
            identifier: "subscription_checks",
            order: 5
        },
        checkbox_labels: { true: "On Hold", false: "Not On Hold" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Archived
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Is Archived",
        identifier: "is_archived",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Subscription is archived and not shown in normal use; only able to recover in the trash.",
        help_text: "If true, this Subscription is archived and not shown in normal use; only able to recover in the trash.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Archived", false: "Not Archived" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Deleted
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Is Deleted",
        identifier: "is_deleted",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Subscription is deleted and non-recoverable after 180 days.",
        help_text: "If true, this Subscription is deleted and non-recoverable after 180 days.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Deleted", false: "Not Deleted" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Draft
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Is Draft",
        identifier: "is_draft",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Subscription is a draft Subscription; Saved details, but not ready for release yet.",
        help_text: "If true, this Subscription is a draft Subscription; Saved details, but not ready for release yet.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Draft", false: "Public" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Template
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Is Template",
        identifier: "is_template",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Subscription is a template for creating future Subscriptions faster.",
        help_text: "A template is used for creating future Subscriptions faster.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Template", false: "Not Template" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Live
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Is Live",
        identifier: "is_live",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Subscription is live for the organization. Not a record in testing mode.",
        help_text: "If true, this Subscription is live for the organization. Not a record in testing mode.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: true
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Live Data", false: "Test Data" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Currency
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Currency",
        identifier: "currency",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The preferred currency for a Subscription.",
        help_text: "The preferred currency for a Subscription.",
        default_value: { data_type: 'text', data: 'usd' },
        placeholder_text: "Select a currency...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 58,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 50,
        is_sortable: true,
        sort_order: 50,
        sort_method: SortingMethods.LINKED_ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 7
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.currencies",
            object_identifier: "static",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Language
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Language",
        identifier: "language",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The preferred language for a Subscription.",
        help_text: "The preferred language for a Subscription.",
        default_value: { data_type: 'text', data: 'en' },
        placeholder_text: "Select a language...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 59,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 51,
        is_sortable: true,
        sort_order: 51,
        sort_method: SortingMethods.LINKED_ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 7
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.languages",
            object_identifier: "static",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Time Zone
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Time Zone",
        identifier: "time_zone",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The Time Zone that the Subscription is based in.",
        help_text: "What time zone this subscription is based in.",
        default_value: { data_type: 'text', data: 'America/Los_Angeles' },
        placeholder_text: "Select a time zone...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 60,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 52,
        is_sortable: true,
        sort_order: 52,
        sort_method: SortingMethods.LINKED_ALPHANUMERIC,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 7
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.time_zones",
            object_identifier: "static",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Country
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Country",
        identifier: "country",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The Country that the Subscription is based in.",
        help_text: "What time zone this subscription is based in.",
        default_value: { data_type: 'text', data: 'America/Los_Angeles' },
        placeholder_text: "Select a time zone...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 61,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 53,
        is_sortable: true,
        sort_order: 53,
        sort_method: SortingMethods.LINKED_ALPHANUMERIC,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 7
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.countries",
            object_identifier: "static",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Domain
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Domain",
        identifier: "domain",
        field_type: FieldTypes.SHORT_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "Represents the platform/domain the Subscription was created on. Can be created by ProHelper or other platforms through integrations.",
        help_text: "Represents the platform/domain the Subscription was created on. Can be created by ProHelper or other platforms through integrations.",
        default_value: null,
        placeholder_text: "Enter a Domain...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 62,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 7
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Popup Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Popup Alert",
        identifier: "popup_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Shows an important message as a banner or dialog when viewing this record in detail.",
        help_text: "An important alert message shown when someone views an subscription.",
        default_value: null,
        placeholder_text: "Enter a popup message to show to subscriptions...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 63,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 54,
        is_sortable: true,
        sort_order: 54,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Subscription Alerts",
            identifier: "subscription_alerts",
            order: 8
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // On Hold Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "On Hold Alert",
        identifier: "on_hold_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Shows an alert message why the Subscription is on hold.",
        help_text: "Shows an alert message why this Subscription is on hold.",
        default_value: null,
        placeholder_text: "Enter an completed alert message...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 64,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: true,
        filter_order: 55,
        is_sortable: true,
        sort_order: 55,
        sort_method: SortingMethods.NESTED_FIELD,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Subscription Alerts",
            identifier: "subscription_alerts",
            order: 8
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Canceled Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Canceled Alert",
        identifier: "canceled_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Shows the canceled alert message as a banner or dialog when viewing the Subscription record in detail.",
        help_text: "An alert message detailing why this Subscription is canceled.",
        default_value: null,
        placeholder_text: "Enter an canceled alert message...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 65,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: true,
        filter_order: 56,
        is_sortable: true,
        sort_order: 56,
        sort_method: SortingMethods.NESTED_FIELD,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Subscription Alerts",
            identifier: "subscription_alerts",
            order: 8
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Created At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Created At",
        identifier: "created_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the date/time the Subscription was created. System generated timestamp.",
        help_text: "The date/time when the Subscription was created.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 66,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 57,
        is_sortable: true,
        sort_order: 57,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 7
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Created By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Created By",
        identifier: "created_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the User who created the Subscription. System generated.",
        help_text: "The User who created an Subscription.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 67,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 58,
        is_sortable: true,
        sort_order: 58,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 7
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Updated At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Last Updated At",
        identifier: "last_updated_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the date/time when the Subscription was last updated. System generated timestamp.",
        help_text: "The date/time when the Subscription was last updated.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 68,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 59,
        is_sortable: true,
        sort_order: 59,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 7
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Updated By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Last Updated By",
        identifier: "last_updated_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the User who last updated the Subscription. System generated.",
        help_text: "The User who last updated the Subscription.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 69,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 59,
        is_sortable: true,
        sort_order: 59,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 7
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Viewed At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Last Viewed At",
        identifier: "last_viewed_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the date/time the Subscription was last viewed. System generated timestamp.",
        help_text: "The date/time when the Subscription was last viewed.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 70,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 61,
        is_sortable: true,
        sort_order: 61,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 7
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Viewed By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Last Viewed By",
        identifier: "last_viewed_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the User who last viewed the Subscription. System generated.",
        help_text: "The User who last viewed the Subscription.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 71,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 62,
        is_sortable: true,
        sort_order: 62,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 7
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Activity At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Last Activity At",
        identifier: "last_activity_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represent the last timestamp when a related item to this subscription was created, updated, or deleted.",
        help_text: "Represent the last timestamp when a related item to this subscription was created, updated, or deleted.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 72,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 63,
        is_sortable: true,
        sort_order: 63,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 7
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Activity By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Last Activity By",
        identifier: "last_activity_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represent the last user who created, updated, or deleted a related item to this subscription.",
        help_text: "Represent the last user who created, updated, or deleted a related item to this subscription.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 73,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 64,
        is_sortable: true,
        sort_order: 64,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 7
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Archived Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Archived Alert",
        identifier: "archived_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Shows an alert why the Subscription was archived when someone views an archived record.",
        help_text: "Shows an alert why the Subscription was archived when someone views an archived record.",
        default_value: null,
        placeholder_text: "Enter a reason for this Subscription's archival...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Archived At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Archived At",
        identifier: "archived_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the timestamp the User archived this Subscription. System generated timestamp.",
        help_text: "Represents the timestamp the User archived this Subscription. System generated timestamp.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Archived By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Archived By",
        identifier: "archived_by",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the User who archived this Subscription. System generated timestamp.",
        help_text: "Represents the User who archived this Subscription. System generated timestamp.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Suggested Item Action
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Suggested Item Action",
        identifier: "suggested_action",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The first shown item action for this Subscription.",
        help_text: "The first shown item action for this Subscription.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: {
            type: LookupRelationshipTypes.INDEPENDENT,
            object_identifier: "actions",
            filters: [
                {
                    fields: ["actions.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
                {
                    fields: ["actions.object_identifier"],
                    field_types: ["id"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'Subscriptions',
                            input: 'subscriptions',
                            data: 'subscriptions',
                            data_type: 'text',
                            status: 'valid'
                        }
                    ]
                }
            ],
            display_field: "label",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Sync Token
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Sync Token",
        identifier: "sync_token",
        field_type: FieldTypes.INTEGER,
        data_type: DatabaseTypes.INTEGER,
        special_type: null,
        description: "The current sync token for this Subscription; Only the most current sync token can be used to write data to avoid conflits.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Metadata
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Metadata",
        identifier: "metadata",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Some extra metadata that can be added to a Subscription.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Payment Settings
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Payment Settings",
        identifier: "payment_settings",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Represents some payment settings for the Subscription.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Notifications
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Notifications",
        identifier: "notifications",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Represents some notifcations for the Subscription.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Custom Fields
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Custom Fields",
        identifier: "custom_fields",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Custom fields for the Subscription.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Settings
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'subscriptions',
        sync_token: 1,
        label: "Settings",
        identifier: "settings",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Represents some settings for the Subscription.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
];

export default SubscriptionFieldsData;