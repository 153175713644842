
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';

// Import Custom Modules
import * as SortingMethods from '../../sorting/SortingMethods';
import * as SortingDirectionIdentifiers from '../../sorting/SortDataTypeIdentifiers';


const ItemListViewsData = [
    // All Items
    {
        object: null,
        object_identifier: 'items',
        public_id: uniqid('view_'),
        sync_token: 1,
        type: "list_view",
        identifier: identifier('All Items').toLowerCase(),
        name: 'All Items',
        description: 'All items with the company.',
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        sort_field: "items.label",
        sql_sort_field: "items.label",
        sort_direction: SortingDirectionIdentifiers.ASCENDING,
        sort_join: null,
        sort_method: SortingMethods.ALPHANUMERIC,
        filter_by_viewer: false,
        filters: [ ],
        search_value: "",
        visible_fields: [
            {
                label: 'Label',
                identifier: 'items.label',
                display_fields: ['items.label'],
                display_format: null,
                width: 220,
                is_locked: true
            },
            {
                label: 'Type',
                identifier: 'items.type',
                display_fields: ['items.type', 'object_types.label'],
                display_format: null,
                width: 180,
                is_locked: false
            },
            {
                label: 'Description',
                identifier: 'items.description',
                display_fields: ['items.description'],
                display_format: null,
                width: 180,
                is_locked: false
            },
            {
                label: 'Last Sold At',
                identifier: 'items.last_sold_at',
                display_fields: ['items.last_sold_at'],
                display_format: 'MM/DD/YYYY',
                width: 180,
                is_locked: false
            },
        ],
        hidden_fields: [
            "items.id",
            "items.item_id",
            "items.sync_token",
            "items.company",
            "items.suggested_action",
            "items.currency"
        ],
        batch_actions: [
            "items.batch_edit",
            "items.batch_archive"
        ],
        item_actions: [
            "items.edit",
            "items.archive"
        ],
        more_actions: [ ],
        pagination_amount: 25
    },
    
];

export default ItemListViewsData;