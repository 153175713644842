
// Import Custom Modules
import { combineReducers } from 'redux';
import ThemeReducer from './ThemeReducer';
import LayoutReducer from './LayoutReducer';
import PageListViewListReducer from './PageListViewListReducer';
import PageListViewTableReducer from './PageListViewTableReducer';

// Combine Reducers and export as the default
export default combineReducers({
    theme: ThemeReducer,
    layout: LayoutReducer,
    tables: PageListViewTableReducer,
    lists: PageListViewListReducer,
});