

//
// A file containing various UI breakpoints for different device groups to determine the best UI choice given the total screen width size.
// This file is created to maintain a centeral name for MDVT cell types around the program.
// 
//

// General Device Breakpoints
export const DESKTOP_BREAKPOINT = 1280;
export const TABLET_BREAKPOINT = 1020;
export const MOBILE_BREAKPOINT = 700;

export const SMALL_MAX_WIDTH = 650;
export const SMALL_MAX_HEIGHT = 650;
export const MEDIUM_MAX_WIDTH = 1100;
export const MEDIUM_MAX_HEIGHT = 1200;
export const LARGE_MAX_WIDTH = 2000;
export const LARGE_MAX_HEIGHT = 1100;

export const DESKTOP_MAX_BREAKPOINT = 2100;
export const DESKTOP_WIDE_BREAKPOINT = 1500;
export const MOBILE_PERPENDICULAR_BREAKPOINT = 500;
export const MOBILE_WIDE = 400;