
// Import NPM Modules
import _ from 'lodash';

// Import Custom Modules
import * as actionTypes from '../../action_types/customizations/PageLayoutsActionTypes';


const PageLayoutsReducer = (state = { }, action) => {

    switch (action.type) {

        // Handles setting all the object's Page Layouts data at once.
        case actionTypes.SET_ALL_PAGE_LAYOUTS:
            // Create a copy of the current redux state
            let newPageLayoutsState = {...state};

            // Convert the action.payload.page_layouts from an array to a Page Layouts map.
            if (_.isArray(action.payload.page_layouts)) {
                // Go through all the Page Layouts received from the customization query and order them in this redux reducers state (state -> object -> page_layout)
                action.payload.page_layouts.forEach((page_layout) => {
                    if (page_layout.type === "object_page_layout") { // This page layout is an "object_page_layout" type, so it has an object or object_identifier
                        // If no JSON has been created for all object page layouts, then create a new empty JSON structure.
                        if (!_.isObject(newPageLayoutsState.object)) {
                            newPageLayoutsState.object = { };
                        }
                        // If no JSON has been created for this object in object page layouts mapping, then create a new JSON structure.
                        if (!_.isObject(newPageLayoutsState.object[page_layout.object_identifier])) {
                            newPageLayoutsState.object[page_layout.object_identifier] = { };
                        }
                        // Add Page layout to nested mapping
                        newPageLayoutsState.object[page_layout.object_identifier][page_layout.identifier] = page_layout;
                    } else { // This page layout is an "app_page_layout" type, so it doesnt have an object or object_identifier
                        // If no JSON has been created for all app page layouts, then create a new empty JSON structure.
                        if (!_.isObject(newPageLayoutsState.app)) {
                            newPageLayoutsState.app = { };
                        }
                        // If no JSON has been created for this page in app page layouts mapping, then create a new JSON structure.
                        if (!_.isObject(newPageLayoutsState.app[page_layout.screen_identifier])) {
                            newPageLayoutsState.app[page_layout.screen_identifier] = { };
                        }
                        // Add Page layout to nested mapping
                        newPageLayoutsState.app[page_layout.screen_identifier][page_layout.identifier] = page_layout;
                    }
                });
            }

            // Return the new action state
            return newPageLayoutsState;
        
        // Removes all the page layouts in this reducer and replaces it with blank JSON objects
        case actionTypes.REMOVE_ALL_PAGE_LAYOUTS:

            // Create a copy of the current redux state
            let newRemovedState = { ...state };

            for (let key in newRemovedState) {
                newRemovedState[key] = { };
            }

            // Return the new page layout state
            return newRemovedState;


        // Removes all the page layouts in this reducer and replaces it with blank JSON objects
        case actionTypes.UPDATE_PAGE_LAYOUT:
            // Create a copy of the current redux state
            let newUpdatedState = { ...state };
            const old_page_layout = action.payload.old_page_layout;
            const updated_page_layout = action.payload.new_page_layout;
            const screen_type = updated_page_layout.type === 'app_page_layout' ? 'app' : 'object';

            if (typeof updated_page_layout === 'object') {

                if (updated_page_layout.type === 'app_page_layout') {
                    if (!_.isEmpty(newUpdatedState.app)) {
                        if (!_.isEmpty(newUpdatedState.app[updated_page_layout.screen_identifier])) {
                            if (!_.isEmpty(newUpdatedState.app[updated_page_layout.screen_identifier])) {
                                // If old page layout exists, delete it (in case identifier has changed)
                                if (!_.isEmpty(newUpdatedState.app[updated_page_layout.screen_identifier][old_page_layout.identifier])) {
                                    delete newUpdatedState.app[updated_page_layout.screen_identifier][old_page_layout.identifier];
                                }
                                // Add new updated page layout back (fresh insert)
                                newUpdatedState.app[updated_page_layout.screen_identifier][updated_page_layout.identifier] = updated_page_layout;
                            }
                        }
                    }
                } else {
                    if (!_.isEmpty(newUpdatedState.object)) {
                        if (!_.isEmpty(newUpdatedState.object[updated_page_layout.object_identifier])) {
                            // If old page layout exists, delete it (in case identifier has changed)
                            if (!_.isEmpty(newUpdatedState.object[updated_page_layout.object_identifier][old_page_layout.identifier])) {
                                delete newUpdatedState.object[updated_page_layout.object_identifier][old_page_layout.identifier];
                            }
                            // Add new updated page layout back (fresh insert)
                            newUpdatedState.object[updated_page_layout.object_identifier][updated_page_layout.identifier] = updated_page_layout;
                        }
                    }
                }


                // if (!_.isEmpty(newUpdatedState[screen_type])) {
                //     if (!_.isEmpty(newUpdatedState[screen_type][updated_page_layout.screen_identifier])) {
                //         // If old page layout exists, delete it (in case identifier has changed)
                //         if (!_.isEmpty(newUpdatedState[screen_type][updated_page_layout.screen_identifier][old_page_layout.identifier])) {
                //             delete newUpdatedState[screen_type][updated_page_layout.screen_identifier][old_page_layout.identifier];
                //         }
                //         // Add new updated page layout back (fresh insert)
                //         newUpdatedState[screen_type][updated_page_layout.screen_identifier][updated_page_layout.identifier] = updated_page_layout;
                //     }
                // }
            }

            // Return the new page layout state
            return newUpdatedState;

        // Do nothing for the default besides return existing state
        default:
            return state;
    }
};

export default PageLayoutsReducer;
