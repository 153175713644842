
// This constants file is a representation of how data for actions will be recieved by the ProHelper database once linked.

// Schedule
import VisitActionsData from './actions/VisitActionsData';

// Work
import RequestActionsData from './actions/RequestActionsData';
import EstimateActionsData from './actions/EstimateActionsData';
import TaskActionsData from './actions/TaskActionsData';
import JobActionsData from './actions/JobActionsData';
import RouteActionsData from './actions/RouteActionsData';

// Billing
import InvoiceActionsData from './actions/InvoiceActionsData';
import SubscriptionActionsData from './actions/SubscriptionActionsData';
import PaymentActionsData from './actions/PaymentActionsData';
import ItemActionsData from './actions/ItemActionsData';

// Clients
import AccountActionsData from './actions/AccountActionsData';
import ContactActionsData from './actions/ContactActionsData';
import PropertyActionsData from './actions/PropertyActionsData';

// Audit
import LedgerActionsData from './actions/LedgerActionsData';
import ReportActionsData from './actions/ReportActionsData';

// Team
import UserActionsData from './actions/UserActionsData';

// Security
import RoleActionsData from './actions/RoleActionsData';
import PermissionSetActionsData from './actions/PermissionSetActionsData';

// Other
import LineItemActionsData from './actions/LineItemActionsData';
import NoteActionsData from './actions/NoteActionsData';

// Add (concact) all the individual object action arrays into one big array
const ActionsData = [].concat(
    RequestActionsData,
    EstimateActionsData,
    TaskActionsData,
    JobActionsData,
    RouteActionsData,
    AccountActionsData, 
    ContactActionsData, 
    PropertyActionsData, 
    LedgerActionsData,
    ReportActionsData,
    UserActionsData,
    RoleActionsData,
    PermissionSetActionsData,
    VisitActionsData,
    InvoiceActionsData,
    SubscriptionActionsData,
    PaymentActionsData,
    ItemActionsData,
    LineItemActionsData,
    NoteActionsData
);

// Export 1 main actions array concatenation 
export default ActionsData;



