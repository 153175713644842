// This constants file is a representation of how data for ledger fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as FieldTypes from '../../types/FieldTypes';
import * as DatabaseTypes from '../../types/DatabaseTypes';
import * as SpecialTypes from '../../types/SpecialTypes';
import * as FilterOperatorTypeIdenifiers from '../../filtering/operators/FilterOperatorTypeIdentifiers';
import * as LookupRelationshipTypes from '../../types/LookupRelationshipTypes';
import * as SortingMethods from '../../sorting/SortingMethods';

const LedgerFieldsData = [
    // Database ID
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Database ID",
        identifier: "id",
        field_type: FieldTypes.ID,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The ID of this Ledger in the ProHelper Database.",
        help_text: "The ID of this Ledger in the ProHelper Database.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Company
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Company",
        identifier: "company",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The organization in ProHelper that this Ledger is apart of.",
        help_text: "The organization for this Ledger in ProHelper.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Subject
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Subject",
        identifier: "subject",
        field_type: FieldTypes.LONG_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: SpecialTypes.MAIN_LABEL,
        description: "The primary subject/label for a Ledger.",
        help_text: "The primary subject/label of this Ledger.",
        default_value: null,
        placeholder_text: 'Enter a Ledger subject/title...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 1,
        is_required: true,
        is_editable: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 1,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Description
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Description",
        identifier: "description",
        field_type: FieldTypes.LONG_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "Represents a detailed description of what's included in a Ledger.",
        help_text: "Represents a detailed description of what's included in this Ledger.",
        default_value: null,
        placeholder_text: 'Add a Ledger description...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 2,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: 2,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Category
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Category",
        identifier: "category",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The classification of an Ledger.",
        help_text: "The classification of this Ledger.",
        default_value: null,
        placeholder_text: 'Select a Ledger Category...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 3,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 1,
        is_sortable: true,
        sort_order: 3,
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "ledgers.categories",
            object_identifier: "ledgers",
            type: FieldTypes.TEXT_DROPDOWN
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Type
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Type",
        identifier: "type",
        field_type: FieldTypes.TYPE_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: SpecialTypes.MAIN_TYPE,
        description: "Represents the type of a Ledger.",
        help_text: "A custom type for this Ledger.",
        default_value: null,
        placeholder_text: 'Select a Ledger Type...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 4,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 3,
        is_sortable: true,
        sort_order: 4,
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Sub Type
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Sub Type",
        identifier: "sub_type",
        field_type: FieldTypes.TYPE_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the sub type of a Ledger.",
        help_text: "The custom sub type for this Ledger.",
        default_value: null,
        placeholder_text: 'Select a Ledger Sub Type...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 4,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 3,
        is_sortable: true,
        sort_order: 4,
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        is_multi: false,
        is_async: true,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Balance
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Balance",
        identifier: "balance",
        field_type: FieldTypes.BALANCE,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents this Ledger's current balance based on its transactions history.",
        help_text: "This Ledger's current balance according to ProHelper.",
        default_value: { data_type: DatabaseTypes.BIGINT,  data: "0" },
        placeholder_text: "Enter a balance adjustment...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 6,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 4,
        is_sortable: true,
        sort_order: 6,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: true,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Balance with Sub Ledgers
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Balance with Sub Ledgers",
        identifier: "balance_with_sub_ledgers",
        field_type: FieldTypes.BALANCE,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents a Ledger's current balance including its sub ledgers based on all the combinded transactions history.",
        help_text: "This Ledger's current balance with sub ledgers included according to ProHelper.",
        default_value: { data_type: DatabaseTypes.BIGINT,  data: "0" },
        placeholder_text: "Enter a balance adjustment...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 6,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 4,
        is_sortable: true,
        sort_order: 6,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: true,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Accounting Balance
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Accounting Balance",
        identifier: "accounting_balance",
        field_type: FieldTypes.BALANCE,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The current balance for the Ledger in linked Accounting system like QBO.",
        help_text: "The current balance for this Ledger in linked Accounting system like QBO.",
        default_value: { data_type: DatabaseTypes.BIGINT,  data: "0" },
        placeholder_text:null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 8,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 6,
        is_sortable: true,
        sort_order: 8,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: true,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Bank Balance
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Bank Balance",
        identifier: "bank_balance",
        field_type: FieldTypes.BALANCE,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The current balance for the Ledger in linked Bank like Wells Fargo or Chase.",
        help_text: "The current balance for this Ledger in linked Bank like Wells Fargo or Chase.",
        default_value: { data_type: DatabaseTypes.BIGINT,  data: "0" },
        placeholder_text:null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 9,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 7,
        is_sortable: true,
        sort_order: 9,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: true,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Ledger ID
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Ledger ID",
        identifier: "ledger_id",
        field_type: FieldTypes.PUBLIC_ID,
        data_type: DatabaseTypes.TEXT,
        special_type: SpecialTypes.PUBLIC_ID,
        description: "The unique ID for a Ledger shown to publically to ledgers. Ledger Prefix is 'ledger_'.",
        help_text: "The unique ID for a Ledger shown to publically to ledgers.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 10,
        is_required: true,
        is_editable: false,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Ledger Number
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Ledger Number",
        identifier: "ledger_number",
        field_type: FieldTypes.INTEGER,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "A sequentially generated integer representing the number of Ledgers this organization has created.",
        help_text: "A sequentially generated integer representing the number of Ledgers this organization has created.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 11,
        is_required: true,
        is_editable: false,
        is_searchable: true,
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 10,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: 'A',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Fully Qualified Name
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Fully Qualified Name",
        identifier: "fully_qualified_name",
        field_type: FieldTypes.LONG_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The fully qualified name of a Ledger, used by integrations like QBO.",
        help_text: "The fully qualified name of this Ledger, used by integrations like QBO.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 1,
        is_required: true,
        is_editable: false,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 1,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Monetary
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Is Monetary",
        identifier: "is_monetary",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this is a ledger that tracks with a currency amount, not an inventory count.",
        help_text: "If true, this ledger tracks with a currency amount, not an inventory count.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: true
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 13,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 8,
        is_sortable: true,
        sort_order: 11,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Ledger Checks",
            identifier: "ledger_checks",
            order: 5
        },
        checkbox_labels: { true: "Is Monetary", false: "Is Inventory" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Active
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Is Active",
        identifier: "is_active",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if this Ledger is currently active; able for users to use it.",
        help_text: "Represents if this Ledger is currently active; able for users to use it.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: true
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 14,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 9,
        is_sortable: true,
        sort_order: 12,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Ledger Checks",
            identifier: "ledger_checks",
            order: 5
        },
        checkbox_labels: { true: "Active", false: "Not Active" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Archived
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Is Archived",
        identifier: "is_archived",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Ledger is archived and not shown in normal use; only able to recover in the trash.",
        help_text: "If true, this Ledger is archived and not shown in normal use; only able to recover in the trash.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Archived", false: "Not Archived" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Deleted
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Is Deleted",
        identifier: "is_deleted",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Ledger is deleted and non-recoverable after 180 days.",
        help_text: "If true, this Ledger is deleted and non-recoverable after 180 days.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Deleted", false: "Not Deleted" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Locked
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Is Locked",
        identifier: "is_locked",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, an Ledger is locked permanently and it's data cannot be edited or deleted even by admin users.",
        help_text: "If true, an Ledger is locked permanently and it's data cannot be edited or deleted even by admin users.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 77,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 65,
        is_sortable: true,
        sort_order: 68,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Ledger Checks",
            identifier: "ledger_checks",
            order: 5
        },
        checkbox_labels: { true: "Locked", false: "Not Locked" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Draft
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Is Draft",
        identifier: "is_draft",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Ledger is a draft Ledger; Saved details, but not ready for release yet.",
        help_text: "If true, this Ledger is a draft Ledger; Saved details, but not ready for release yet.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Draft", false: "Public" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Template
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Is Template",
        identifier: "is_template",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Ledger is a template for creating future Ledgers faster.",
        help_text: "A template is used for creating future Ledgers faster.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Template", false: "Not Template" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Live
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Is Live",
        identifier: "is_live",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Ledger is live for the organization. Not a record in testing mode.",
        help_text: "If true, this Ledger is live for the organization. Not a record in testing mode.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: true
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Live Data", false: "Test Data" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Sub Ledger
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Is Sub Ledger",
        identifier: "is_sub_ledger",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Ledger is a sub Ledger and as a parent Ledger associated with it. If true, the parent Ledger should be filled in.",
        help_text: "Represents whether an Ledger is a child Ledger to another Ledger in the system.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 15,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 10,
        is_sortable: true,
        sort_order: 13,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Ledger Checks",
            identifier: "ledger_checks",
            order: 5
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Sub Ledger", false: "Not Sub Ledger" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Level
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Level",
        identifier: "level",
        field_type: FieldTypes.INTEGER,
        data_type: DatabaseTypes.INTEGER,
        special_type: null,
        description: "Represents the depth in the overall Ledger structure. (Parent Ledger, Sub Ledgers) (Max Level: 3)",
        help_text: "The depth of the Ledger in the overall parent-child Ledger structure.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 16,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 11,
        is_sortable: true,
        sort_order: 14,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Parent Ledger
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Parent Ledger",
        identifier: "parent",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "If this Ledger is a sub-Ledger, then this represents a link to the parent Ledger.",
        help_text: "The parent Ledger of this sub-Ledger.",
        default_value: null,
        placeholder_text: "Select a parent Ledger...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 17,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 12,
        is_sortable: true,
        sort_order: 15,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "ledgers",
            filters: [
                {
                    fields: ["ledgers.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "subject",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Popup Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Popup Alert",
        identifier: "popup_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Shows an important message as a banner or dialog when viewing this record in detail.",
        help_text: "An important alert message shown when someone views an ledger.",
        default_value: null,
        placeholder_text: "Enter a popup message to show to ledgers...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 18,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 13,
        is_sortable: true,
        sort_order: 16,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Ledger Alerts",
            identifier: "ledger_alerts",
            order: 7
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Created At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Created At",
        identifier: "created_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the date/time the Ledger was created. System generated timestamp.",
        help_text: "The date/time when the Ledger was created.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 19,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 14,
        is_sortable: true,
        sort_order: 17,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Created By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Created By",
        identifier: "created_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the User who created the Ledger. System generated.",
        help_text: "The User who created an Ledger.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 20,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 15,
        is_sortable: true,
        sort_order: 18,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Updated At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Last Updated At",
        identifier: "last_updated_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the date/time when the Ledger was last updated. System generated timestamp.",
        help_text: "The date/time when the Ledger was last updated.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 21,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 16,
        is_sortable: true,
        sort_order: 19,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Updated By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Last Updated By",
        identifier: "last_updated_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the User who last updated the Ledger. System generated.",
        help_text: "The User who last updated the Ledger.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 22,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 17,
        is_sortable: true,
        sort_order: 20,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Transaction At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Last Transaction At",
        identifier: "last_transaction_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the date and time a user last performed an transaction related to a Ledger.",
        help_text: "Represents the date and time a user last performed an transaction related to this Ledger.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 23,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 18,
        is_sortable: true,
        sort_order: 21,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_createable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Archived At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Archived At",
        identifier: "archived_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the timestamp the User archived this Ledger. System generated timestamp.",
        help_text: "Represents the timestamp the User archived this Ledger. System generated timestamp.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Archived By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Archived By",
        identifier: "archived_by",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the User who archived this Ledger. System generated timestamp.",
        help_text: "Represents the User who archived this Ledger. System generated timestamp.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Currency
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Currency",
        identifier: "currency",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The preferred currency for a Ledger.",
        help_text: "The preferred currency for a Ledger.",
        default_value: { data_type: 'text', data: 'usd' },
        placeholder_text: "Select a currency...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 24,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 19,
        is_sortable: true,
        sort_order: 22,
        sort_method: SortingMethods.LINKED_ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.currencies",
            object_identifier: "static",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Language
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Language",
        identifier: "language",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The preferred language for a Ledger.",
        help_text: "The preferred language for a Ledger.",
        default_value: { data_type: 'text', data: 'en' },
        placeholder_text: "Select a language...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 26,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 21,
        is_sortable: true,
        sort_order: 24,
        sort_method: SortingMethods.LINKED_ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.languages",
            object_identifier: "static",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Time Zone
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Time Zone",
        identifier: "time_zone",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The Time Zone that the Ledger is based in.",
        help_text: "What time zone this ledger is based in.",
        default_value: { data_type: 'text', data: 'America/Los_Angeles' },
        placeholder_text: "Select a time zone...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 25,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 19,
        is_sortable: true,
        sort_order: 22,
        sort_method: SortingMethods.LINKED_ALPHANUMERIC,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.time_zones",
            object_identifier: "static",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Country
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Country",
        identifier: "country",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The Country that the Ledger is based in.",
        help_text: "What time zone this ledger is based in.",
        default_value: { data_type: 'text', data: 'America/Los_Angeles' },
        placeholder_text: "Select a time zone...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 27,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 22,
        is_sortable: true,
        sort_order: 25,
        sort_method: SortingMethods.LINKED_ALPHANUMERIC,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.countries",
            object_identifier: "static",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Suggested Item Action
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Suggested Item Action",
        identifier: "suggested_action",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The first shown item action for this Ledger.",
        help_text: "The first shown item action for this Ledger.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: {
            type: LookupRelationshipTypes.INDEPENDENT,
            object_identifier: "actions",
            filters: [
                {
                    fields: ["actions.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
                {
                    fields: ["actions.object_identifier"],
                    field_types: ["id"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'Ledgers',
                            input: 'ledgers',
                            data: 'ledgers',
                            data_type: 'text',
                            status: 'valid'
                        }
                    ]
                }
            ],
            display_field: "label",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Sync Token
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Sync Token",
        identifier: "sync_token",
        field_type: FieldTypes.INTEGER,
        data_type: DatabaseTypes.INTEGER,
        special_type: null,
        description: "The current sync token for this Ledger; Only the most current sync token can be used to write data to avoid conflits.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    }, 
    // Metadata
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Metadata",
        identifier: "metadata",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Some extra metadata that can be added to a Ledger.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Custom Fields
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Custom Fields",
        identifier: "custom_fields",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Custom fields for the Ledger.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Settings
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'ledgers',
        sync_token: 1,
        label: "Settings",
        identifier: "settings",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Represents some settings for the Ledger.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_createable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
];

export default LedgerFieldsData;