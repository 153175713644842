// Import NPM Modules
import React from 'react';
import useTheme from '@mui/material/styles/useTheme';
import { styled } from '@mui/material/styles';
import PulseLoader from 'react-spinners/PulseLoader';

// Create styled-component css
const Container = styled('div')`
    width: 100%;
    height: fit-content;
    padding: 40px 0px;
    display: flex;
    flex: 1;
    justify-content: center;
`;


const ContainerPulseLoader = (props) => {
    // Get theme from themeContent
    const theme = useTheme();
    
    return (
        <Container>
            <PulseLoader
                size={24}
                margin={10}
                color={theme.palette.primary.main}
            />
        </Container>
    );
};

export default ContainerPulseLoader;