
//
// A file containing all the different use types for actions.
// This file is created to maintain a centeral name for action use types around the program.
// 
//

// Item Actions
export const NEW = 'new';
export const EDIT = 'edit';
export const CONVERT = 'convert';
export const EDIT_FIELD = 'edit_field';
export const EDIT_POPUP = 'edit_popup';
export const DUPLICATE = 'duplicate';
export const ARCHIVE = 'archive';
export const DELETE = 'delete';
export const START = 'start';


// Batch Actions
export const BATCH_EDIT = 'batch_edit';
export const BATCH_EMAIL = 'batch_email';
export const BATCH_PRINT = 'batch_print';
export const BATCH_EDIT_FIELD = 'batch_edit_field';
export const BATCH_EDIT_POPUP = 'batch_edit_popup';
export const BATCH_MARK_SENT = 'batch_mark_sent';
export const BATCH_MARK_PAID = 'batch_mark_paid';
export const BATCH_ARCHIVE = 'batch_archive';


// More Actions
export const COGNITO_ACTION = 'cognito_action';
export const CALL_PHONE = 'call_phone';
export const EMAIL = 'EMAIL';
export const PRINT = 'print';
export const PROHELPER_LINK = 'prohelper_link';
export const MAPS_LINK = 'maps_link';
export const EDIT_RECURRENCE = 'edit_recurrence';
export const COLLECT_SIGNATURE = 'collect_signature';
export const MARK_SENT = 'mark_sent';
export const COMPLETE = 'complete';
export const CLOSE = 'close';
export const APPROVE = 'approve';
export const DENY = 'deny';
export const MARK_PAID = 'mark_paid';
export const EDIT_PAYMENT_METHOD = 'edit_payment_method';
export const EDIT_LINE_ITEMS = 'edit_line_items';
export const ADJUST_BALANCE = 'adjust_balance';
export const RUN_REPORT = 'run_report';