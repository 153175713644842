
// Import NPM Modules
import _ from 'lodash';
import uniqid from 'uniqid';


// Import Custom Modules
import ActionsData from '../../constants/customizations/ActionsData';
import NavigationItemsData from '../../constants/customizations/NavigationItemsData';
import ObjectsData from '../../constants/customizations/ObjectsData';
import ObjectTypesData from '../../constants/customizations/ObjectTypesData';
import ObjectFieldsData from '../../constants/customizations/ObjectFieldsData';
import DropdownSetsData from '../../constants/customizations/DropdownSetsData';
import ListViewsData from '../../constants/customizations/ListViewsData';
import PageLayoutsData from '../../constants/customizations/PageLayoutsData';
import * as FieldTypes from '../../constants/types/FieldTypes'

/**
 * ~~ Class Description ~~
 *  A class containing functions that are related to the customizations used around the program.
 *  Customizations include objects, object types, object fields, actionDatas, dropdown sets, list views, page layouts, and more.
 */
export default class CustomizationsHelper {

    /**
     * 
     * @param {*} auth A new authentication object to get dynamic information from
     * 
     * @returns {Object} A dynamic customizations query data result object
     */
    GetCustomizationsQueryData(auth) {

        // Create result object to return in this callback
        let result = null;

        // Check for a valid auth object
        if (_.isObject(auth)) {

            // Create a full customizations object with starting with static information from copied arrays
            let dynamicResultData = {
                actions: [...ActionsData],
                navigation_items: [...NavigationItemsData],
                objects: [...ObjectsData],
                object_types: [...ObjectTypesData],
                object_fields: [...ObjectFieldsData],
                dropdown_sets: [...DropdownSetsData],
                list_views: [...ListViewsData],
                page_layouts: [...PageLayoutsData]
            }

            // 1. Update the Objects data
            dynamicResultData.objects = this.UpdateObjectsData(auth, dynamicResultData.objects);

            // 2. Update Object Types data
            dynamicResultData.object_types = this.UpdateObjectTypesData(auth, dynamicResultData.objects, dynamicResultData.object_types);

            // 3. Update Dropdown Sets data
            dynamicResultData.dropdown_sets = this.UpdateDropdownSetsData(auth, dynamicResultData.objects, dynamicResultData.dropdown_sets);

            // 4. Update Navigation Items data
            dynamicResultData.navigation_items = this.UpdateNavigationItemsData(auth, dynamicResultData.navigation_items);

            // 5. Update Actions data
            dynamicResultData.actions = this.UpdateActionsData(auth, dynamicResultData.objects, dynamicResultData.actions);

            // 6. Update Object Fields data
            dynamicResultData.object_fields = this.UpdateObjectFieldsData(auth, dynamicResultData.objects, dynamicResultData.object_fields, dynamicResultData.dropdown_sets);

            // 7. Update List Views data
            dynamicResultData.list_views = this.UpdateListViewsData(auth, dynamicResultData.objects, dynamicResultData.object_types, dynamicResultData.dropdown_sets, dynamicResultData.list_views);

            // 8. Update Page Layouts data
            dynamicResultData.page_layouts = this.UpdatePageLayoutsData(auth, dynamicResultData.objects, dynamicResultData.object_types, dynamicResultData.dropdown_sets, dynamicResultData.list_views, dynamicResultData.page_layouts);

            // Set the returned result with a pointer to the dynamic result data object
            result = dynamicResultData;
        }

        // Return the customizations result object in the callback (can be null)
        return result;
    }


    ConvertCustomizationsQueryDataToReduxStoreFormat(CustomizationsData) {
        if (_.isEmpty(CustomizationsData)) { return null; }

        let newCustomizationsObj = { };

        // Update Actions Data
        if (!_.isEmpty(CustomizationsData.actions)) {
            // Init nested actions data store
            let newActionsState = {};
            // Go through all the action received from the customization query and order them in this redux reducers state (state -> object -> action_type -> action)
            for (let i = 0; i < CustomizationsData.actions.length; i++) {
                // Reference current action in array
                const currentAction = CustomizationsData.actions[i];
                // 'item_action' -> 'item_actions' & 'batch_action' -> 'batch_actions'
                const plural_actions_group = currentAction.action_type + 's';
                // If this currentAction belongs to an object then store the currentAction inside the parent object container
                if (!_.isNull(currentAction.object) && !_.isNull(currentAction.object_identifier)) {
                    // This action has a valid object and object_identifier and is a real object
                    // If no JSON has been created for this object, then create a new JSON structure to hold this new object's actions.
                    if (_.isEmpty(newActionsState[currentAction.object_identifier])) {
                        newActionsState[currentAction.object_identifier] = {
                            item_actions: {},
                            batch_actions: {}
                        };
                    }
                    // If no JSON has been created for this object's action type, then create a new JSON structure to hold this new object's action type actions.
                    if (_.isEmpty(newActionsState[currentAction.object_identifier][plural_actions_group])) {
                        newActionsState[currentAction.object_identifier][plural_actions_group] = {};
                    }
                    newActionsState[currentAction.object_identifier][plural_actions_group][currentAction.identifier] = currentAction;
                } else {
                    // If this currentAction does not have a valid object, object_identifier 
                    // Store this currentAction under global actions, create if it does not exist already
                    if (!_.isObject(newActionsState.global)) {
                        newActionsState.global = {
                            item_actions: {},
                            batch_actions: {}
                        };
                    }
                    // If no nested JSON has been created for the global plural action type group, then create a new JSON object.
                    if (!_.isEmpty(newActionsState.global[plural_actions_group])) {
                        newActionsState.global[plural_actions_group] = {};
                    }
                    // Check for an action type and switch between the correct action object to store this action.
                    newActionsState.global[plural_actions_group][currentAction.identifier] = currentAction;
                }
            }
            // Add new nested Actions data into new customizations object
            newCustomizationsObj.actions = newActionsState;
        }

        // Update Navigation Items Data
        if (!_.isEmpty(CustomizationsData.navigation_items)) {
            // Init nested Navigation Items data store
            let newNavigationItemsState = {};
            for (let i = 0; i < CustomizationsData.navigation_items.length; i++) {
                // Reference current Navigation Item in array
                const current_navigation_item = CustomizationsData.navigation_items[i];
                // Add to navigation data store by navigation identifier as key for obj mapping
                newNavigationItemsState[current_navigation_item.identifier] = current_navigation_item;
            }
            // Add new nested Navigation Items data into new customizations object
            newCustomizationsObj.navigation_items = newNavigationItemsState;
        }

        // Update Objects Data
        if (!_.isEmpty(CustomizationsData.objects)) {
            // Init nested Objects data store
            let newObjectsState = {};
            for (let i = 0; i < CustomizationsData.objects.length; i++) {
                // Reference current Object in array
                const current_object = CustomizationsData.objects[i];
                // Add to Object data store by Object identifier as key for obj mapping
                newObjectsState[current_object.identifier] = current_object;
            }
            // Add new nested Objects data into new customizations object
            newCustomizationsObj.objects = newObjectsState;
        }

        // Update Object Types Data
        if (!_.isEmpty(CustomizationsData.object_types)) {
            // Init nested Object Types data store
            let newObjectTypesState = {};
            // Go through all the object types received from the customization query and order them in this redux reducers state (state -> object -> object_type)
            for (let i = 0; i < CustomizationsData.object_types.length; i++) {
                // Reference current Object Type
                const current_object_type = CustomizationsData.object_types[i];
                // If this current Object Type belongs to an object then store the current_object_type inside the parent object container
                if (!_.isNull(current_object_type.object) && !_.isNull(current_object_type.object_identifier)) {
                    // If no JSON has been created for this object, then create a new JSON structure to hold this new object's types.
                    if (_.isEmpty(newObjectTypesState[current_object_type.object_identifier])) {
                        newObjectTypesState[current_object_type.object_identifier] = {};
                    }
                    // Add Object Type with identifier as key in obj mapping under nested container by parent object 
                    newObjectTypesState[current_object_type.object_identifier][current_object_type.identifier] = current_object_type;
                }
            }
            // Add new nested Object Types data into new customizations object
            newCustomizationsObj.object_types = newObjectTypesState;
        }

        // Update Object Fields Data
        if (!_.isEmpty(CustomizationsData.object_fields)) {
            // Init nested Object Fields data store
            let newObjectFieldsState = {};
            // Go through all the object fields received from the customization query and order them in this redux reducers state (state -> object -> object_field)
            for (let i = 0; i < CustomizationsData.object_fields.length; i++) {
                // Reference current Object Type
                const current_object_field = CustomizationsData.object_fields[i];
                // If this current Object Type belongs to an object then store the current_object_type inside the parent object container
                if (!_.isNull(current_object_field.object) && !_.isNull(current_object_field.object_identifier)) {
                    // If no JSON has been created for this object, then create a new JSON structure to hold this new object's fields.
                    if (_.isEmpty(newObjectFieldsState[current_object_field.object_identifier])) {
                        newObjectFieldsState[current_object_field.object_identifier] = {};
                    }
                    // Add Object Type with identifier as key in obj mapping under nested container by parent object 
                    newObjectFieldsState[current_object_field.object_identifier][current_object_field.identifier] = current_object_field;
                }
            }
            // Add new nested Object Fields data into new customizations object
            newCustomizationsObj.object_fields = newObjectFieldsState;
        }

        // Update Dropdown Sets Data
        if (!_.isEmpty(CustomizationsData.dropdown_sets)) {
            // Init nested Dropdown Sets data store
            let newDropdownSetsState = {};
            // Go through all the Dropdown Sets received from the customization query and order them in this redux reducers state (state -> object -> dropdown_set)
            for (let i = 0; i < CustomizationsData.dropdown_sets.length; i++) {
                // Get the current Dropdown Set using the index position
                const current_dropdown_set = CustomizationsData.dropdown_sets[i];
                // If the current Dropdown Set belongs to an object, then store it inside the parent object container
                if (current_dropdown_set.object_identifier) {
                    // If no JSON has been created for this object, then create a new JSON structure to hold this new object's dropdown sets.
                    if (_.isEmpty(newDropdownSetsState[current_dropdown_set.object_identifier])) {
                        newDropdownSetsState[current_dropdown_set.object_identifier] = {};
                    }
                    // Add Dropdown Set with identifier as key in obj mapping under nested container by parent object 
                    newDropdownSetsState[current_dropdown_set.object_identifier][current_dropdown_set.identifier] = current_dropdown_set;
                }
            }
            // Add new nested Dropdown Set data into new customizations object
            newCustomizationsObj.dropdown_sets = newDropdownSetsState;

        }

        // Update List Views Data
        if (!_.isEmpty(CustomizationsData.list_views)) {
            // Init nested List Views data store
            let newListViewsState = {};
            // Go through all the List Views received from the customization query and order them in this redux reducers state (state -> object -> list view)
            for (let i = 0; i < CustomizationsData.list_views.length; i++) {
                // Reference current List View
                const current_list_view = CustomizationsData.list_views[i];
                // If this current List View belongs to an object, then store the current List View inside the parent object container
                if (!_.isNull(current_list_view.object) && !_.isNull(current_list_view.object_identifier)) {
                    // If no JSON has been created for this object, then create a new JSON structure to hold this new object's list views.
                    if (_.isEmpty(newListViewsState[current_list_view.object_identifier])) {
                        newListViewsState[current_list_view.object_identifier] = { };
                    }
                    // Add List View with identifier as key in obj mapping under nested container by parent object 
                    newListViewsState[current_list_view.object_identifier][current_list_view.identifier] = current_list_view;
                }
            }
            // Add new nested List Views data into new customizations object
            newCustomizationsObj.list_views = newListViewsState;
        }

        // Update Page Layouts Data
        if (!_.isEmpty(CustomizationsData.page_layouts)) {
            // Init nested List Views data store
            let newPageLayoutsState = {};
            // Go through all the object types received from the customization query and order them in this redux reducers state (state -> object -> page layout)
            for (let i = 0; i < CustomizationsData.page_layouts.length; i++) {
                // Reference current Page Layout
                const current_page_layout = CustomizationsData.page_layouts[i];
                // If this current Page Layout belongs to an object, then store the current Page Layout inside the parent object container
                if (!_.isNull(current_page_layout.object) && !_.isNull(current_page_layout.object_identifier)) {
                    // If no JSON has been created for this object, then create a new JSON structure to hold this new object's page layouts.
                    if (_.isEmpty(newPageLayoutsState[current_page_layout.object_identifier])) {
                        newPageLayoutsState[current_page_layout.object_identifier] = {};
                    }
                    // Add Page Layout with identifier as key in obj mapping under nested container by parent object 
                    newPageLayoutsState[current_page_layout.object_identifier][current_page_layout.identifier] = current_page_layout;
                }
            }
            // Add new nested Page Layouts data into new customizations object
            newCustomizationsObj.page_layouts = newPageLayoutsState;
        }

        return newCustomizationsObj;
    }


    // Helper Functions


    /**
     * 
     * @param {Object} auth An authentication object
     * @param {Object[]} objectsData An array of static customization's objects data
     * 
     * @returns {Object[]} An updated array of objects data with dynamic information added
     */
     UpdateObjectsData(auth, objectsData) {

        // Start with an empty result, return if no valid inputs
        let result = null;

        // If there is a valid auth & objects data, then we can continue
        if (_.isObject(auth) && _.isArray(objectsData)) {

            // Create a new customization data object remapping the arrays of each 
            const dynamicObjectsData = objectsData.map((objectData) => {
                // Add unique fake database ID
                objectData.id = uniqid("db_");

                // Add Real Company ID
                if (auth.company.id) {
                    objectData.company = auth.company.id;
                }

                // Check for valid user id
                if (auth.user.id) {
                    // Add Created Information
                    objectData.created_by = auth.user.id;
                    objectData.created_at = new Date();

                    // Add Last Updated Information
                    objectData.last_updated_by = auth.user.id;
                    objectData.last_updated_at = new Date();
                }

                // Return modified object data back into dynamic object data's list
                return objectData;
            });
            // Make the result reference the newly updated dynamic objects data
            result = dynamicObjectsData;
        }
        // Return the result
        return result;
    }

    /**
     * 
     * @param {Object} auth An authentication object
     * @param {Object[]} objectsData An array of static customization's objects data
     * @param {Object[]} objectTypesData An array of static customization's object types data
     * 
     * @returns {Object[]} An updated array of object types data with dynamic information added
     */
     UpdateObjectTypesData(auth, objectsData, objectTypesData) {

        // Start with an empty result, return if no valid inputs
        let result = null;

        // If there is a valid auth & objects data, then we can continue
        if (_.isObject(auth) && _.isArray(objectsData) && _.isArray(objectTypesData)) {

            // Create a map of the objects array to use that information faster later on
            let dynamicObjectsMap = { };

            for (let i=0; i < objectsData.length; i++) {
                dynamicObjectsMap[objectsData[i].identifier] = objectsData[i];
            }


            // Create a new customization data object remapping the arrays of each 
            const dynamicObjectTypesData = objectTypesData.map((objectTypeData) => {
                // Add unique fake database ID
                objectTypeData.id = uniqid("db_");

                // Add Real Company ID
                if (auth.company.id) {
                    objectTypeData.company = auth.company.id;
                }

                // Check if the object type has a valid object identifier
                if (!_.isEmpty(objectTypeData.object_identifier)) {
                    // Check if there is a valid object map for this object identifier given by the object type data
                    if (!_.isEmpty(dynamicObjectsMap[objectTypeData.object_identifier])) {
                        // Update the object type data's object id information
                        objectTypeData.object = dynamicObjectsMap[objectTypeData.object_identifier].id;
                    }
                }

                // Check for valid user id
                if (auth.user.id) {
                    // Add Created Information
                    objectTypeData.created_by = auth.user.id;
                    objectTypeData.created_at = new Date();

                    // Add Last Updated Information
                    objectTypeData.last_updated_by = auth.user.id;
                    objectTypeData.last_updated_at = new Date();
                }

                // Return modified object data back into dynamic object data's list
                return objectTypeData;
            });
            // Make the result reference the newly updated dynamic objects data
            result = dynamicObjectTypesData;
        }
        // Return the result
        return result;
    }


    /**
     * 
     * @param {Object} auth An authentication object
     * @param {Object[]} objectsData An array of static customization's objects data
     * @param {Object[]} objectFieldsData An array of static customization's object fields data
     * @param {Object[]} dropdownSetsData An array of static customization's object fields data
     * 
     * @returns {Object[]} An updated array of object fields data with dynamic information added
     */
     UpdateObjectFieldsData(auth, objectsData, objectFieldsData, dropdownSetsData) {

        // Start with an empty result, return if no valid inputs
        let result = null;

        // If there is a valid auth & objects data, then we can continue
        if (_.isObject(auth) && _.isArray(objectsData) && _.isArray(objectFieldsData)) {

            // Create a map of the objects array to use that information faster later on
            let dynamicObjectsMap = { };
            let dynamicDropdownSetsMap = { };

            for (let i=0; i < objectsData.length; i++) {
                dynamicObjectsMap[objectsData[i].identifier] = objectsData[i];
            }

            for (let i=0; i < dropdownSetsData.length; i++) {
                if (_.isEmpty(dynamicDropdownSetsMap[dropdownSetsData[i].object_identifier])) {
                    dynamicDropdownSetsMap[dropdownSetsData[i].object_identifier] = {};
                }
                dynamicDropdownSetsMap[dropdownSetsData[i].object_identifier][dropdownSetsData[i].identifier] = dropdownSetsData[i];
            }


            // Create a new customization data object remapping the arrays of each 
            const dynamicObjectFieldsData = objectFieldsData.map((objectFieldData) => {
                // Add unique fake database ID
                objectFieldData.id = uniqid("db_");

                // Add Real Company ID
                if (auth.company.id) {
                    objectFieldData.company = auth.company.id;
                }

                // Check if the object field has a valid object identifier
                if (!_.isEmpty(objectFieldData.object_identifier)) {
                    // Check if there is a valid object map for this object identifier given by the object field data
                    if (!_.isEmpty(dynamicObjectsMap[objectFieldData.object_identifier])) {
                        // Update the object field data's object id information
                        objectFieldData.object = dynamicObjectsMap[objectFieldData.object_identifier].id;
                    }
                }

                // Update dropdown set information if dropdown field type
                if (objectFieldData.field_type === FieldTypes.CUSTOM_DROPDOWN || objectFieldData.field_type === FieldTypes.TEXT_DROPDOWN ) {
                    if (!_.isEmpty(objectFieldData.dropdown_set_options)) {
                        if (!_.isEmpty(dynamicDropdownSetsMap[objectFieldData.dropdown_set_options.object_identifier])) {
                            if (!_.isEmpty(dynamicDropdownSetsMap[objectFieldData.dropdown_set_options.object_identifier][objectFieldData.dropdown_set_options.identifier])) {
                                let currentDropdownSet = dynamicDropdownSetsMap[objectFieldData.dropdown_set_options.object_identifier][objectFieldData.dropdown_set_options.identifier];
                                // Update object_field data's dropdown set id with the matched dropdown set from the customizations data
                                objectFieldData.dropdown_set = currentDropdownSet.identifier;
                            }
                        }
                    }
                }

                // Check for valid user id
                if (auth.user.id) {
                    // Add Created Information
                    objectFieldData.created_by = auth.user.id;
                    objectFieldData.created_at = new Date();

                    // Add Last Updated Information
                    objectFieldData.last_updated_by = auth.user.id;
                    objectFieldData.last_updated_at = new Date();
                }

                // Return modified object data back into dynamic object data's list
                return objectFieldData;
            });
            // Make the result reference the newly updated dynamic objects data
            result = dynamicObjectFieldsData;
        }
        // Return the result
        return result;
    }


    /**
     * 
     * @param {Object} auth An authentication object
     * @param {Object[]} objectsData An array of static customization's objects data
     * @param {Object[]} actionsData An array of static customization's action data
     * 
     * @returns {Object[]} An updated array of actions data with dynamic information added
     */
     UpdateActionsData(auth, objectsData, actionsData) {

        // Start with an empty result, return if no valid inputs
        let result = null;

        // If there is a valid auth & objects data, then we can continue
        if (_.isObject(auth) && _.isArray(objectsData) && _.isArray(actionsData)) {

            // Create a map of the objects array to use that information faster later on
            let dynamicObjectsMap = { };

            for (let i=0; i < objectsData.length; i++) {
                dynamicObjectsMap[objectsData[i].identifier] = objectsData[i];
            }

            // Create a new customization data object remapping the arrays of each 
            const dynamicActionsData = actionsData.map((actionData) => {
                // Add unique fake database ID
                actionData.id = uniqid("db_");

                // Add Real Company ID
                if (auth.company.id) {
                    actionData.company = auth.company.id;
                }

                // Check if the action has a valid object identifier
                if (!_.isEmpty(actionData.object_identifier)) {
                    // Check if there is a valid object map for this object identifier given by the action data
                    if (!_.isEmpty(dynamicObjectsMap[actionData.object_identifier])) {
                        // Update the action data's object id information
                        actionData.object = dynamicObjectsMap[actionData.object_identifier].id;
                    }
                }

                // Check for valid user id
                if (auth.user.id) {
                    // Add Created Information
                    actionData.created_by = auth.user.id;
                    actionData.created_at = new Date();

                    // Add Last Updated Information
                    actionData.last_updated_by = auth.user.id;
                    actionData.last_updated_at = new Date();
                }
                // Return modified action data back into dynamic action data's list
                return actionData;
            });
            // Make the result reference the newly updated dynamic actions data
            result = dynamicActionsData;
        }
        // Return the result
        return result;
    }


    /**
     * 
     * @param {Object} auth An authentication object
     * @param {Object[]} navigationItemsData An array of static customization's navigation items data
     * 
     * @returns {Object[]} An updated array of navigation items data with dynamic information added
     */
     UpdateNavigationItemsData(auth, navigationItemsData) {
        // Start with an empty result, return if no valid inputs
        let result = null;

        // If there is a valid auth & objects data, then we can continue
        if (_.isObject(auth) && _.isArray(navigationItemsData)) {
            // Create a new customization data object remapping the arrays of each 
            const dynamicNavigationItemsData = navigationItemsData.map((navigationItemData) => {
                // Add unique fake database ID
                navigationItemData.id = uniqid("db_");

                // Add Real Company ID
                if (auth.company.id) {
                    navigationItemData.company = auth.company.id;
                }

                // Check for valid user id
                if (auth.user.id) {
                    // Add Created Information
                    navigationItemData.created_by = auth.user.id;
                    navigationItemData.created_at = new Date();

                    // Add Last Updated Information
                    navigationItemData.last_updated_by = auth.user.id;
                    navigationItemData.last_updated_at = new Date();
                }

                // Return modified navigation item data back into dynamic navigation items data's list
                return navigationItemData;
            });
            // Make the result reference the newly updated dynamic navigation items data
            result = dynamicNavigationItemsData;
        }
        // Return the result
        return result;
    }

    /**
     * 
     * @param {Object} auth An authentication object
     * @param {Object[]} objectsData An array of static customization's objects data
     * @param {Object[]} dropdownSetsData An array of static customization's dropdown sets data
     * 
     * @returns {Object[]} An updated array of dropdown sets data with dynamic information added
     */
     UpdateDropdownSetsData(auth, objectsData, dropdownSetsData) {
        // Start with an empty result, return if no valid inputs
        let result = null;

        // If there is a valid auth & objects data, then we can continue
        if (_.isObject(auth) && _.isArray(objectsData)  && _.isArray(dropdownSetsData)) {
            // Create a map of the objects array to use that information faster later on
            let dynamicObjectsMap = { };

            for (let i=0; i < objectsData.length; i++) {
                dynamicObjectsMap[objectsData[i].identifier] = objectsData[i];
            }

            let mutatableDropdownSetsData = [...dropdownSetsData];

            // Create a new customization data object remapping the arrays of each 
            const dynamicDropdownSetsData = mutatableDropdownSetsData.map((dropdownSetItemData) => {

                let newDropdownSetItemData = {...dropdownSetItemData};

                // Add unique fake database ID
                newDropdownSetItemData.id = uniqid("db_");
                // Add Real Company ID
                if (auth.company.id) {
                    newDropdownSetItemData.company = auth.company.id;
                }

                // Check if the dropdown set has a valid object identifier
                if (!_.isEmpty(newDropdownSetItemData.object_identifier)) {
                    // Check if there is a valid object map for this object identifier given by the dropdown set data
                    if (!_.isEmpty(dynamicObjectsMap[newDropdownSetItemData.object_identifier])) {
                        // Update the dropdown set data's object id information
                        newDropdownSetItemData.object = dynamicObjectsMap[newDropdownSetItemData.object_identifier].id;
                    }
                }

                // Check for valid user id
                if (auth.user.id) {
                    // Add Created Information
                    newDropdownSetItemData.created_by = auth.user.id;
                    newDropdownSetItemData.created_at = new Date();
                    // Add Last Updated Information
                    newDropdownSetItemData.last_updated_by = auth.user.id;
                    newDropdownSetItemData.last_updated_at = new Date();
                }

                // If the dropdowns array is not empty, loop through each dropdown and update dynamic information
                if (!_.isEmpty(newDropdownSetItemData.dropdowns)) {
                    let dropdownsObjMap = {};
                    // Add dynamic information to dropdowns
                    let mutableDropdownSetItemDataDropdowns = [...newDropdownSetItemData.dropdowns];
                    mutableDropdownSetItemDataDropdowns.forEach((dropdownData, index) => {
                        // Add unique fake database ID
                        dropdownData.id = uniqid("db_");
                        // Add Real Company ID
                        if (auth.company.id) {
                            dropdownData.company = auth.company.id;
                        }
                        // Add parent dropdown set id
                        dropdownData.dropdown_set = newDropdownSetItemData.identifier;

                        // Check if the dropdown has a valid object identifier
                        if (!_.isEmpty(dropdownData.object_identifier)) {
                            // Check if there is a valid object map for this object identifier given by the dropdown data
                            if (!_.isEmpty(dynamicObjectsMap[dropdownData.object_identifier])) {
                                // Update the dropdown data's object id information
                                dropdownData.object = dynamicObjectsMap[dropdownData.object_identifier].id;
                            }
                        }
                        // Check for valid user id
                        if (auth.user.id) {
                            // Add Created Information
                            dropdownData.created_by = auth.user.id;
                            dropdownData.created_at = new Date();
                            // Add Last Updated Information
                            dropdownData.last_updated_by = auth.user.id;
                            dropdownData.last_updated_at = new Date();
                        }
                        // Set updated dropdown in object mapping
                        dropdownsObjMap[`${dropdownData.identifier}`] = dropdownData;
                    });
                    // Change dropdowns array to dropdown object map
                    newDropdownSetItemData.dropdowns = dropdownsObjMap; 
                }
                // Return modified navigation item data back into dynamic dropdown sets data's list
                return newDropdownSetItemData;
            });
            // Make the result reference the newly updated dynamic dropdown sets data
            result = dynamicDropdownSetsData;
        }
        // Return the result
        return result;
    }


    /**
     * 
     * @param {Object} auth An authentication object
     * @param {Object[]} objectsData An array of static customization's Objects data
     * @param {Object[]} objectTypesData An array of static customization's Object Types data
     * @param {Object[]} dropdownSetsData An array of static customization's Dropdown Sets data
     * @param {Object[]} list_views_data An array of static customization's List Views data
     * 
     * @returns {Object[]} An updated array of list views data with dynamic information added
     */
     UpdateListViewsData(auth, objectsData, objectTypesData, dropdownSetsData, list_views_data) {

        // Start with an empty result, return if no valid inputs
        let result = null;

        // If there is a valid auth & objects data, then we can continue
        if (_.isObject(auth) && _.isArray(objectsData) && _.isArray(list_views_data)) {

            // Create a map of the objects array to use that information faster later on
            let dynamicObjectsMap = { };

            // Create a map of the object types array to use that information faster later on
            let dynamicObjectTypesMap = { };

            // Create a map of the dropdown sets array to use that information faster later on
            let dynamicDropdownSetsMap = { };

            // Loop through all the objects data and convert the array to an object map
            // each key is the object identifier and value is the object data
            for (let i=0; i < objectsData.length; i++) {
                dynamicObjectsMap[objectsData[i].identifier] = objectsData[i];
            }
            
            // Loop through all the object types data and convert the array to an object map
            // each key is the object identifier and value is another object which is an object map with the types data for each object.
            for (let i=0; i < objectTypesData.length; i++) {

                if (!_.isObject(dynamicObjectTypesMap[objectTypesData[i].object_identifier])) {
                    dynamicObjectTypesMap[objectTypesData[i].object_identifier] = { };
                }

                dynamicObjectTypesMap[objectTypesData[i].object_identifier][objectTypesData[i].identifier] = objectTypesData[i];
            }

            // Loop through all the object types data and convert the array to an object map
            // each key is the object identifier and value is another object which is an object map with the types data for each object.
            for (let i=0; i < dropdownSetsData.length; i++) {

                if (!_.isObject(dynamicDropdownSetsMap[dropdownSetsData[i].object_identifier])) {
                    dynamicDropdownSetsMap[dropdownSetsData[i].object_identifier] = { };
                }

                dynamicDropdownSetsMap[dropdownSetsData[i].object_identifier][dropdownSetsData[i].identifier] = dropdownSetsData[i];
            }

            // Create a new customization data object remapping the arrays of each 
            const dynamicListViewsData = list_views_data.map((listViewData) => {

                // Add unique fake database ID
                listViewData.id = uniqid("db_");

                // Add Real Company ID
                if (auth.company.id) {
                    listViewData.company = auth.company.id;
                }

                // Check if the list view has a valid object identifier
                if (!_.isEmpty(listViewData.object_identifier)) {
                    // Check if there is a valid object map for this object identifier given by the list view data
                    if (!_.isEmpty(dynamicObjectsMap[listViewData.object_identifier])) {
                        // Update the list view data's object id information
                        listViewData.object = dynamicObjectsMap[listViewData.object_identifier].id;
                    }
                }

                // Check for valid user id
                if (auth.user.id) {
                    // Add Created Information
                    listViewData.created_by = auth.user.id;
                    listViewData.created_at = new Date();

                    // Add Last Updated Information
                    listViewData.last_updated_by = auth.user.id;
                    listViewData.last_updated_at = new Date();
                }


                // If the filters array is not empty, loop through each filter and update dynamic information
                if (!_.isEmpty(listViewData.filters)) {
                    for (let i = 0; i < listViewData.filters.length; i++) {

                        // Reference the currenct filter item data
                        let filterData = listViewData.filters[i];
                        // If the filters array is not empty, loop through each filter and update dynamic information
                        if (!_.isEmpty(filterData.values)) {
                            for (let j = 0; j < filterData.values.length; j++) {
                                // Reference the currenct filter value data
                                let filterValueData = filterData.values[j];
                                if (filterValueData.type === "value" && !_.isEmpty(filterData.field_types)) {
                                    switch (filterData.field_types[0]) {
                                        case ("type_dropdown"):
                                            // Check to make sure the is a valid object identifier and input in the filter data and there is first an object map in the dynamic object types map.
                                            if (!_.isEmpty(dynamicObjectTypesMap[listViewData.object_identifier]) && !_.isEmpty(filterValueData.input)) {
                                                // Check to make sure the is a valid object type data in the nested object map.
                                                if (!_.isEmpty(dynamicObjectTypesMap[listViewData.object_identifier][filterValueData.input])) {
                                                    // If all true, enter the correct filter data information with the object type data
                                                    const typeDropdownItem = dynamicObjectTypesMap[listViewData.object_identifier][filterValueData.input];
                                                    filterValueData.data = typeDropdownItem.id;
                                                    filterValueData.label = typeDropdownItem.label;
                                                }
                                            }
                                            break;
        
                                        case ("custom_dropdown"):
                                            // Check to make sure the is a valid object identifier and input in the filter data and there is first an object map in the dynamic object types map.
                                            if (!_.isEmpty(dynamicDropdownSetsMap[listViewData.object_identifier]) && !_.isEmpty(filterValueData.input)) {
                                                // Check to make sure the is a valid object type data in the nested object map.
                                                if (!_.isEmpty(dynamicDropdownSetsMap[listViewData.object_identifier][filterValueData.input])) {
                                                    // If all true, enter the correct filter data information with the object type data
                                                    const customDropdownItem = dynamicDropdownSetsMap[listViewData.object_identifier][filterValueData.input];
                                                    filterValueData.data = customDropdownItem.id;
                                                    filterValueData.label = customDropdownItem.label;
                                                }
                                            }
                                            break;
        
                                        default: 
                                            break;
                                    }
                                }
                            }
                        }
                    }
                }
                
                // Return a modified list view data object back into dynamic list view data's list
                return listViewData;
            });
            // Make the result reference the newly updated dynamic list views data
            result = dynamicListViewsData;
        }
        // Return the result
        return result;
    }


    /**
     * 
     * @param {Object} auth An authentication object
     * @param {Object[]} objectsData An array of static customization's Objects data
     * @param {Object[]} objectTypesData An array of static customization's Object Types data
     * @param {Object[]} dropdownSetsData An array of static customization's Dropdown Sets data
     * @param {Object[]} list_views_data An array of static customization's List Views data
     * @param {Object[]} page_layouts_data An array of static customization's Page Layouts data
     * 
     * @returns {Object[]} An updated array of list views data with dynamic information added
     */
     UpdatePageLayoutsData(auth, objectsData, objectTypesData, dropdownSetsData, list_views_data, page_layouts_data) {

        // Start with an empty result, return if no valid inputs
        let result = null;

        // If there is a valid auth & objects data, then we can continue
        if (_.isObject(auth) && _.isArray(objectsData) && _.isArray(dropdownSetsData) && _.isArray(list_views_data) && _.isArray(page_layouts_data)) {

            // Convert the previous arrays to objects to access that information faster later on
            let dynamicObjectsMap = { };
            let dynamicObjectTypesMap = { };
            let dynamicDropdownSetsMap = { };
            let dynamicListViewsMap = { };

            // Convert customization's objects array [value] to an object {key:value}.
            for (let i=0; i < objectsData.length; i++) {
                dynamicObjectsMap[objectsData[i].identifier] = objectsData[i];
            }
            // Convert customization's object_types array [value] to an object {key:value}.
            for (let i=0; i < objectTypesData.length; i++) {
                if (!_.isObject(dynamicObjectTypesMap[objectTypesData[i].object_identifier])) {
                    dynamicObjectTypesMap[objectTypesData[i].object_identifier] = { };
                }
                dynamicObjectTypesMap[objectTypesData[i].object_identifier][objectTypesData[i].identifier] = objectTypesData[i];
            }

            // Convert customization's dropdown sets array [value] to an object {key:value}.
            for (let i=0; i < dropdownSetsData.length; i++) {
                if (!_.isObject(dynamicDropdownSetsMap[dropdownSetsData[i].object_identifier])) {
                    dynamicDropdownSetsMap[dropdownSetsData[i].object_identifier] = { };
                }
                dynamicDropdownSetsMap[dropdownSetsData[i].object_identifier][dropdownSetsData[i].identifier] = dropdownSetsData[i];
            }

            // Convert customization's objects array [value] to an object {key:value}.
            for (let i=0; i < list_views_data.length; i++) {
                // Reference current list view
                const currentListView = list_views_data[i];
                // Check for valid list view object_identifier & identifier
                if (!_.isEmpty(currentListView.object_identifier) && !_.isEmpty(currentListView.identifier)) {
                    // If the newly created object does not have sub object already for the parent object_identifier then create one before adding child
                    if (!_.isObject(dynamicListViewsMap[currentListView.object_identifier])) {
                        dynamicListViewsMap[currentListView.object_identifier] = { };
                    }
                    // Add current list view to parent object_identifier sub object mapping
                    dynamicListViewsMap[currentListView.object_identifier][currentListView.identifier] = currentListView;
                }
            }

            // Create a new customization data object remapping the arrays of each 
            const dynamicPageLayoutsData = page_layouts_data.map((pageLayoutData) => {

                // Add unique fake database ID
                pageLayoutData.id = uniqid("db_");

                // Add Real Company ID
                if (auth.company) {
                    pageLayoutData.company = auth.company.id ? auth.company.id : null;
                }

                // Check if the list view has a valid object identifier
                if (!_.isEmpty(pageLayoutData.object_identifier)) {
                    // Check if there is a valid object map for this object identifier given by the list view data
                    if (!_.isEmpty(dynamicObjectsMap[pageLayoutData.object_identifier])) {
                        // Update the list view data's object id information
                        pageLayoutData.object = dynamicObjectsMap[pageLayoutData.object_identifier].id;
                    }
                }

                // Check for valid user id
                if (auth.user) {
                    // Add Created Information
                    pageLayoutData.created_by = auth.user.id ? auth.user.id : null;
                    pageLayoutData.created_at = new Date();
                    // Add Last Updated Information
                    pageLayoutData.last_updated_by = auth.user.id ? auth.user.id : null;
                    pageLayoutData.last_updated_at = new Date();
                    // Add Last Viewed Information
                    pageLayoutData.last_viewed_by = auth.user.id ? auth.user.id : null;
                    pageLayoutData.last_viewed_at = new Date();
                }


                // Return a modified list view data object back into dynamic list view data's list
                return pageLayoutData;
            });
            // Make the result reference the newly updated dynamic page layouts data
            result = dynamicPageLayoutsData;
        }
        
        // Return the result
        return result;
    }

};