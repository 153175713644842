// Import NPM Modules
import _ from 'lodash';

// Import Custom Modules
import * as actionTypes from '../../action_types/screens/CustomizePageLayoutActionTypes';

const initialState = {
    page_layouts_structure: {},
    page_layout_selected_group: {},
    page_layout_groups: [],
    page_layout_selected_sub_group: {},
    page_layout_sub_groups: {},
    current_page_layout: null,
    shown_page_layout: null,
    available_page_layouts: [],
    page_layout_sections: [],
    all_actions: [],
    available_actions: [],
    all_more_actions: [],
    available_more_actions: [],
    selected_actions: [],
    selected_more_actions: [],
    available_components: [],
    selected_display_type: 'desktop',
    available_roles: [],
    selected_role: null,
    available_users: [],
    selected_user: null,
    selected_item: null,
    tabs: [],
    current_tab: null,
    is_changed: false,
    dropdown_menu: {
        anchor: null,
        type: '',
        list: []
    }
};

const CustomizePageLayoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CUSTOMIZE_PAGE_LAYOUT_STATE:
            return !_.isEmpty(action.payload) ? action.payload : state;

        case actionTypes.SET_CUSTOMIZE_PAGE_LAYOUT_STARTING_STATE:
            return !_.isEmpty(action.payload) ? {
                ...state,
                current_page_layout: action.payload.page_layout,
                shown_page_layout: action.payload.page_layout
            } : state;

        default:
            return state;
    }
};

export default CustomizePageLayoutReducer;