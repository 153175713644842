
const PrecreatedLedgersData = [
    // Checking
    {
        subject: "Checking",
        identifier: "checking",
        description: 'The primary bank checking account.',
        category: 'asset',
        type: 'bank',
        sub_type: 'checking',
        is_sub_ledger: false,
        parent: null
    },
    // Saving
    {
        subject: "Savings",
        identifier: "savings",
        description: 'The primary bank savings account.',
        category: 'asset',
        type: 'bank',
        sub_type: 'savings',
        is_sub_ledger: false,
        parent: null
    },
    // Accounts Receivable
    {
        subject: "Accounts Receivable",
        identifier: "accounts_receivable",
        description: 'Money owed to a company by its clients or customers for products or services that have been delivered but not yet paid for.',
        category: 'asset',
        type: 'accounts_receivable',
        sub_type: 'accounts_receivable',
        is_sub_ledger: false,
        parent: null
    },
    // Inventory Asset
    {
        subject: "Inventory Asset",
        identifier: "inventory_asset",
        description: 'Inventory products held for sale.',
        category: 'asset',
        type: 'other_current_assets',
        sub_type: 'inventory',
        is_sub_ledger: false,
        parent: null
    },
    // Prepaid Expense
    {
        subject: "Prepaid Expense",
        identifier: "prepaid_expenses",
        description: 'Expenses paid in advance but not yet incurred.',
        category: 'asset',
        type: 'other_current_assets',
        sub_type: 'prepaid_expenses',
        is_sub_ledger: false,
        parent: null
    },
    // Uncategorized Asset
    {
        subject: "Uncategorized Asset",
        identifier: "uncategorized_asset",
        description: 'Assets that have not yet been assigned to specific categories or ledgers.',
        category: 'asset',
        type: 'other_current_assets',
        sub_type: 'other_current_assets',
        is_sub_ledger: false,
        parent: null
    },
    // Undeposited Funds
    {
        subject: "Undeposited Funds",
        identifier: "undeposited_funds",
        description: 'Funds yet to be deposited into bank accounts.',
        category: 'asset',
        type: 'other_current_assets',
        sub_type: 'undeposited_funds',
        is_sub_ledger: false,
        parent: null
    },
    // Truck
    {
        subject: "Truck",
        identifier: "truck",
        description: 'The asset value of the company owned truck.',
        category: 'asset',
        type: 'fixed_asset',
        sub_type: 'vehicles',
        is_sub_ledger: false,
        parent: null
    },
    // Truck - Depreciation
    {
        subject: "Depreciation",
        identifier: "truck_depreciation",
        description: "The truck's depreciation value.",
        category: 'asset',
        type: 'fixed_asset',
        sub_type: 'accumulated_depreciation',
        is_sub_ledger: true,
        parent: "truck"
    },
    // Truck - Orginal Cost
    {
        subject: "Orginal Cost",
        identifier: "truck_orginal_cost",
        description: 'The orginal value of the truck at time of purchase.',
        category: 'asset',
        type: 'fixed_asset',
        sub_type: 'vehicles',
        is_sub_ledger: true,
        parent: "truck"
    },
    // Accounts Payable
    {
        subject: "Accounts Payable",
        identifier: "accounts_payable",
        description: 'The amount of money owed by a business to suppliers or vendors for goods or services received but not yet paid for.',
        category: 'liability',
        type: 'accounts_payable',
        sub_type: 'accounts_payable',
        is_sub_ledger: false,
        parent: null
    },
    // Chase Credit Card
    {
        subject: "Chase Credit Card",
        identifier: "chase_credit_card",
        description: 'Chase credit card account.',
        category: 'liability',
        type: 'credit_card',
        sub_type: 'credit_card',
        is_sub_ledger: false,
        parent: null
    },
    // Mastercard Credit Card
    {
        subject: "Mastercard Credit Card",
        identifier: "mastercard_credit_card",
        description: 'Mastercard credit card account.',
        category: 'liability',
        type: 'credit_card',
        sub_type: 'credit_card',
        is_sub_ledger: false,
        parent: null
    },
    // California Dept. of Revenue Payable
    {
        subject: "California Dept. of Revenue Payable",
        identifier: "california_dept_of_revenue_payable",
        description: 'Transactions related to outstanding payments and liabilities owed to the California Department of Revenue.',
        category: 'liability',
        type: 'other_current_liability',
        sub_type: 'sales_tax_payable',
        is_sub_ledger: false,
        parent: null
    },
    // California Office of Tax Appeals Payable
    {
        subject: "California Office of Tax Appeals Payable",
        identifier: "california_office_of_tax_appeals_payable",
        description: 'Transactions related to outstanding payments and liabilities owed to the California Office of Tax Appeals.',
        category: 'liability',
        type: 'other_current_liability',
        sub_type: 'sales_tax_payable',
        is_sub_ledger: false,
        parent: null
    },
    // Board of Equalization Payable
    {
        subject: "Board of Equalization Payable",
        identifier: "board_of_equalization_payable",
        description: 'Transactions related to outstanding payments and liabilities owed to the California Board of Equalization.',
        category: 'liability',
        type: 'other_current_liability',
        sub_type: 'sales_tax_payable',
        is_sub_ledger: false,
        parent: null
    },
    // Loan Payable
    {
        subject: "Loan Payable",
        identifier: "loan_payable",
        description: 'Outstanding balance, interest, and repayment schedule of loans.',
        category: 'liability',
        type: 'other_current_liability',
        sub_type: '',
        is_sub_ledger: false,
        parent: null
    },
    // Out of Scope Agency Payable
    {
        subject: "Out of Scope Agency Payable",
        identifier: "out_of_scope_agency_payable",
        description: "Payments owed to external agencies or entities for services or expenses not within the typical scope of an organization's operations.",
        category: 'liability',
        type: 'other_current_liability',
        sub_type: 'sales_tax_payable',
        is_sub_ledger: false,
        parent: null
    },
    // Opening Balance Equity
    {
        subject: "Opening Balance Equity",
        identifier: "opening_balance_equity",
        description: "The initial investment or owner's equity when starting a business or transitioning accounting systems.",
        category: 'equity',
        type: 'equity',
        sub_type: 'opening_balance_equity',
        is_sub_ledger: false,
        parent: null
    },
    // Retained Earnings
    {
        subject: "Retained Earnings",
        identifier: "retained_earnings",
        description: "The portion of a company's profits that have been retained and reinvested into the business.",
        category: 'equity',
        type: 'equity',
        sub_type: 'retained_earnings',
        is_sub_ledger: false,
        parent: null
    },
    // Billable Expense Income
    {
        subject: "Billable Expense Income",
        identifier: "billable_expense_income",
        description: 'The income generated by billing clients or customers for reimbursable expenses incurred on their behalf.',
        category: 'revenue',
        type: 'income',
        sub_type: 'service_fee_income',
        is_sub_ledger: false,
        parent: null
    },
    // Design Income
    {
        subject: "Design Income",
        identifier: "design_income",
        description: 'The revenue generated from design-related services or products.',
        category: 'revenue',
        type: 'income',
        sub_type: 'other_primary_income',
        is_sub_ledger: false,
        parent: null
    },
    // Discounts given
    {
        subject: "Discounts given",
        identifier: "discounts_given",
        description: 'The value of discounts offered to customers or clients as part of sales transactions.',
        category: 'revenue',
        type: 'income',
        sub_type: 'discount_refunds_given',
        is_sub_ledger: false,
        parent: null
    },
    // Fees Billed
    {
        subject: "Fees Billed",
        identifier: "fees_billed",
        description: 'The charges/fees invoiced to clients or customers for services rendered.',
        category: 'revenue',
        type: 'income',
        sub_type: 'service_fee_income',
        is_sub_ledger: false,
        parent: null
    },
    // Pool Cleaning Services
    {
        subject: "Pool Cleaning Services",
        identifier: "pool_cleaning_services",
        description: 'Income related to pool maintenance and cleaning services.',
        category: 'revenue',
        type: 'income',
        sub_type: 'other_primary_income',
        is_sub_ledger: false,
        parent: null
    },
    // Install/Repair Services
    {
        subject: "Install/Repair Services",
        identifier: "install_repair_services",
        description: 'Income related to pool installation and repair services.',
        category: 'revenue',
        type: 'income',
        sub_type: 'other_primary_income',
        is_sub_ledger: false,
        parent: null
    },
    // Construction Services
    {
        subject: "Construction Services",
        identifier: "construction_services",
        description: 'Income related to pool construction services.',
        category: 'revenue',
        type: 'income',
        sub_type: 'other_primary_income',
        is_sub_ledger: false,
        parent: null
    },
    // Other Income
    {
        subject: "Other Income",
        identifier: "other_income",
        description: 'Any other ways of income that is not categorized.',
        category: 'revenue',
        type: 'income',
        sub_type: 'other_primary_income',
        is_sub_ledger: false,
        parent: null
    },
    // Refunds-Allowances
    {
        subject: "Refunds-Allowances",
        identifier: "refunds_allowances",
        description: 'Reimbursements and allowances granted to customers or clients.',
        category: 'revenue',
        type: 'income',
        sub_type: 'discount_refunds_given',
        is_sub_ledger: false,
        parent: null
    },
    // Sales of Product Income
    {
        subject: "Sales of Product Income",
        identifier: "sales_of_product_income",
        description: 'Revenue generated from the sale of tangible goods.',
        category: 'revenue',
        type: 'income',
        sub_type: 'other_primary_income',
        is_sub_ledger: false,
        parent: null
    },
    // Services
    {
        subject: "Services",
        identifier: "services",
        description: 'Revenue of services provided by the company.',
        category: 'revenue',
        type: 'income',
        sub_type: 'other_primary_income',
        is_sub_ledger: false,
        parent: null
    },
    // Shipping Income
    {
        subject: "Shipping Income",
        identifier: "shipping_income",
        description: 'Revenue generated by shipping.',
        category: 'revenue',
        type: 'income',
        sub_type: 'other_primary_income',
        is_sub_ledger: false,
        parent: null
    },
    // Unapplied Cash Payment Income
    {
        subject: "Unapplied Cash Payment Income",
        identifier: "unapplied_cash_payment_income",
        description: 'Income received but not yet allocated to specific transactions.',
        category: 'revenue',
        type: 'income',
        sub_type: 'unapplied_cash_payment_income',
        is_sub_ledger: false,
        parent: null
    },
    // Uncategorized Income
    {
        subject: "Uncategorized Income",
        identifier: "uncategorized_income",
        description: 'Income that has not yet been assigned to specific categories or accounts.',
        category: 'revenue',
        type: 'income',
        sub_type: 'other_income',
        is_sub_ledger: false,
        parent: null
    },
    // Cost of Goods Sold
    {
        subject: "Cost of Goods Sold",
        identifier: "cost_of_goods_sold",
        description: 'Direct expenses associated with producing or purchasing the goods that a business sells.',
        category: 'expense',
        type: 'cost_of_goods_sold',
        sub_type: '',
        is_sub_ledger: false,
        parent: null
    },
    // Inventory Shrinkage
    {
        subject: "Inventory Shrinkage",
        identifier: "inventory_shrinkage",
        description: "Reduction in a business's inventory levels due to factors like theft, spoilage, or damage, helping to account for and minimize losses in the value of stock.",
        category: 'expense',
        type: 'cost_of_goods_sold',
        sub_type: '',
        is_sub_ledger: false,
        parent: null
    },
    // Advertising
    {
        subject: "Advertising",
        identifier: "advertising",
        description: 'Expenses related to promotional and marketing efforts.',
        category: 'expense',
        type: 'expense',
        sub_type: 'advertising',
        is_sub_ledger: false,
        parent: null
    },
    // Automobile
    {
        subject: "Automobile",
        identifier: "automobile",
        description: "Expenses and revenues associated with an organization's vehicle ownership, including purchases, maintenance, fuel, and depreciation.",
        category: 'expense',
        type: 'expense',
        sub_type: 'auto',
        is_sub_ledger: false,
        parent: null
    },
    // Automobile - Fuel
    {
        subject: "Fuel",
        identifier: "automobile_fuel",
        description: 'Fuel related expenses for a vehicle.',
        category: 'expense',
        type: 'expense',
        sub_type: 'auto',
        is_sub_ledger: true,
        parent: 'automobile'
    },
    // Automobile - Maintenance
    {
        subject: "Maintenance",
        identifier: "automobile_maintenance",
        description: 'Maintenence related expenses for a vehicle.',
        category: 'expense',
        type: 'expense',
        sub_type: 'auto',
        is_sub_ledger: true,
        parent: 'automobile'
    },
    // Bank Charges
    {
        subject: "Bank Charges",
        identifier: "bank_charges",
        description: 'Expenses incurred as a result of banking services and transactions.',
        category: 'expense',
        type: 'expense',
        sub_type: 'bank_charges',
        is_sub_ledger: false,
        parent: null
    },
    // Commissions & Fees
    {
        subject: "Commissions & Fees",
        identifier: "commisions_fees",
        description: 'Expenses from owed commissions and fees.',
        category: 'expense',
        type: 'expense',
        sub_type: 'commisions_fees',
        is_sub_ledger: false,
        parent: null
    },
    // Disposal Fees
    {
        subject: "Disposal Fees",
        identifier: "disposal_fees",
        description: 'Costs associated with the disposal of assets or waste materials.',
        category: 'expense',
        type: 'other_expense',
        sub_type: 'other_miscellaneous_expense',
        is_sub_ledger: false,
        parent: null
    },
    // Dues & Subscriptions
    {
        subject: "Dues & Subscriptions",
        identifier: "dues_and_subscriptions",
        description: 'Expenses and income associated with fees, subscriptions, and other recurring charges.',
        category: 'expense',
        type: 'other_expense',
        sub_type: 'dues_and_subscriptions',
        is_sub_ledger: false,
        parent: null
    },
];

export default PrecreatedLedgersData;