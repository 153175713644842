// Import Custom Modules
import _ from 'lodash';

// Import Custom Modules
import * as actionTypes from '../../action_types/screens/ScreenActionTypes';

const STARTING_DATA = {
    object_list_view_screen: {
        object: null,
        object_identifier: '',
        screen_identifier: '',
        raw_list_view: null,
        shown_list_view: null,
        all_list_views: [],
        is_loaded: false,
        is_edit_record_dialog_open: false,
        is_edit_field_dialog_open: false,
        is_new_record_dialog_open: false,
        is_archive_dialog_open: false,
        is_customize_field_dialog_open: false,
        is_edit_visible_fields_dialog_open: false,
        is_edit_item_actions_dialog_open: false,
        is_import_customizations_dialog_open: false,
        is_filter_open: false,
        selected_items: {},
        pagination: {
            current_page: 1,
            last_page: 1,
            pagination_amount: 25,
            total_amount: 0,
        }
    },
    object_page_layout_screen: {
        object: null,
        object_identifier: '',
        screen_identifier: '',
        raw_page_layout: null,
        shown_page_layout: null,
        all_page_layouts: [],
        is_loaded: false,
        is_edit_record_dialog_open: false,
        is_edit_field_dialog_open: false,
        is_new_record_dialog_open: false,
        is_archive_dialog_open: false,
        is_popup_dialog_open: false,
        popup_alert: null,
        is_import_customizations_dialog_open: false,
        header: {
            is_search_open: false,
            is_editable: false,
            is_archivable: false,
            search_value: '',
            item_actions: [],
            more_actions: [],
            display_label: ''
        }
    },
    app_page_layout_screen: {
        route: '',
        screen_identifier: '',
        raw_page_layout: null,
        shown_page_layout: null,
        all_page_layouts: [],
        is_loaded: false,
        is_import_customizations_dialog_open: false,
        header: {
            is_search_open: false,
            search_value: '',
            item_actions: [],
            more_actions: []
        }
    }
}


const ObjectScreensReducer = (state = {...STARTING_DATA}, action) => {

    switch (action.type) {

        case actionTypes.SET_LIST_VIEW_SCREEN:

            return {
                ...state,
                object_list_view_screen: {
                    ...state.object_list_view_screen,
                    object: _.isObject(action.payload.object) ? action.payload.object : state.object_list_view_screen.object,
                    object_identifier: _.isString(action.payload.object_identifier) ? action.payload.object_identifier : state.object_list_view_screen.object_identifier,
                    raw_list_view: _.isObject(action.payload.raw_list_view) ? action.payload.raw_list_view : state.object_list_view_screen.raw_list_view,
                    shown_list_view: _.isObject(action.payload.shown_list_view) ? action.payload.shown_list_view : state.object_list_view_screen.shown_list_view,
                    all_list_views: _.isArray(action.payload.all_list_views) ? action.payload.all_list_views : state.object_list_view_screen.all_list_views,
                    is_loaded: _.isBoolean(action.payload.is_loaded) ? action.payload.is_loaded : state.object_list_view_screen.is_loaded,
                    is_edit_record_dialog_open: _.isBoolean(action.payload.is_edit_record_dialog_open) ? action.payload.is_edit_record_dialog_open : state.object_list_view_screen.is_edit_record_dialog_open,
                    is_edit_field_dialog_open: _.isBoolean(action.payload.is_edit_field_dialog_open) ? action.payload.is_edit_field_dialog_open : state.object_list_view_screen.is_edit_field_dialog_open,
                    is_new_record_dialog_open: _.isBoolean(action.payload.is_new_record_dialog_open) ? action.payload.is_new_record_dialog_open : state.object_list_view_screen.is_new_record_dialog_open,
                    is_archive_dialog_open: _.isBoolean(action.payload.is_archive_dialog_open) ? action.payload.is_archive_dialog_open : state.object_list_view_screen.is_archive_dialog_open,
                    is_customize_field_dialog_open: _.isBoolean(action.payload.is_customize_field_dialog_open) ? action.payload.is_customize_field_dialog_open : state.object_list_view_screen.is_customize_field_dialog_open,
                    is_import_customizations_dialog_open: _.isBoolean(action.payload.is_import_customizations_dialog_open) ? action.payload.is_import_customizations_dialog_open : state.object_list_view_screen.is_import_customizations_dialog_open,
                    is_import_data_dialog_open: _.isBoolean(action.payload.is_import_data_dialog_open) ? action.payload.is_import_data_dialog_open : state.object_list_view_screen.is_import_data_dialog_open,
                    is_filter_open: _.isBoolean(action.payload.is_filter_open) ? action.payload.is_filter_open : state.object_list_view_screen.is_filter_open,
                    selected_items: _.isObject(action.payload.selected_items) ? action.payload.selected_items : state.object_list_view_screen.selected_items,
                    pagination: !_.isEmpty(action.payload.pagination) ? {
                        current_page: action.payload.pagination.current_page ? action.payload.pagination.current_page : state.object_list_view_screen.pagination.current_page,
                        last_page: action.payload.pagination.last_page ? action.payload.pagination.last_page : state.object_list_view_screen.pagination.last_page,
                        pagination_amount: action.payload.pagination.pagination_amount ? action.payload.pagination.pagination_amount : state.object_list_view_screen.pagination.pagination_amount,
                        total_amount: action.payload.pagination.total_amount ? action.payload.pagination.total_amount : state.object_list_view_screen.pagination.total_amount,
                    } : state.object_list_view_screen.pagination
                }
            };

        case actionTypes.SET_OBJECT_PAGE_LAYOUT_SCREEN:
            return {
                ...state,
                object_page_layout_screen: {
                    ...state.object_page_layout_screen,
                    object: _.isObject(action.payload.object) ? action.payload.object : state.object_page_layout_screen.object,
                    object_identifier: _.isString(action.payload.object_identifier) ? action.payload.object_identifier : state.object_page_layout_screen.object_identifier,
                    raw_page_layout: _.isObject(action.payload.raw_page_layout) ? action.payload.raw_page_layout : state.object_page_layout_screen.raw_page_layout,
                    shown_page_layout: _.isObject(action.payload.shown_page_layout) ? action.payload.shown_page_layout : state.object_page_layout_screen.shown_page_layout,
                    all_page_layouts: _.isArray(action.payload.all_page_layouts) ? action.payload.all_page_layouts : state.object_page_layout_screen.all_page_layouts,
                    is_loaded: _.isBoolean(action.payload.is_loaded) ? action.payload.is_loaded : state.object_page_layout_screen.is_loaded,
                    is_edit_record_dialog_open: _.isBoolean(action.payload.is_edit_record_dialog_open) ? action.payload.is_edit_record_dialog_open : state.object_page_layout_screen.is_edit_record_dialog_open,
                    is_edit_field_dialog_open: _.isBoolean(action.payload.is_edit_field_dialog_open) ? action.payload.is_edit_field_dialog_open : state.object_page_layout_screen.is_edit_field_dialog_open,
                    is_new_record_dialog_open: _.isBoolean(action.payload.is_new_record_dialog_open) ? action.payload.is_new_record_dialog_open : state.object_page_layout_screen.is_new_record_dialog_open,
                    is_archive_dialog_open: _.isBoolean(action.payload.is_archive_dialog_open) ? action.payload.is_archive_dialog_open : state.object_page_layout_screen.is_archive_dialog_open,
                    is_popup_dialog_open: _.isBoolean(action.payload.is_popup_dialog_open) ? action.payload.is_popup_dialog_open : state.object_page_layout_screen.is_popup_dialog_open,
                    popup_alert: _.isObject(action.payload.popup_alert) ? action.payload.popup_alert : state.object_page_layout_screen.popup_alert,
                    is_import_customizations_dialog_open: _.isBoolean(action.payload.is_import_customizations_dialog_open) ? action.payload.is_import_customizations_dialog_open : state.object_page_layout_screen.is_import_customizations_dialog_open,
                    header: !_.isEmpty(action.payload.header) ? {
                        is_editable: _.isBoolean(action.payload.header.is_editable) ? action.payload.header.is_editable : state.object_page_layout_screen.header.is_editable,
                        is_archivable: _.isBoolean(action.payload.header.is_archivable) ? action.payload.header.is_archivable : state.object_page_layout_screen.header.is_archivable,
                        is_search_open: _.isBoolean(action.payload.header.is_search_open) ? action.payload.header.is_search_open : state.object_page_layout_screen.header.is_search_open,
                        search_value: _.isString(action.payload.header.search_value) ? action.payload.header.search_value : state.object_page_layout_screen.header.search_value,
                        item_actions: _.isArray(action.payload.header.item_actions) ? action.payload.header.item_actions : state.object_page_layout_screen.header.item_actions,
                        more_actions: _.isArray(action.payload.header.more_actions) ? action.payload.header.more_actions : state.object_page_layout_screen.header.more_actions,
                        display_label: _.isString(action.payload.header.display_label) ? action.payload.header.display_label : state.object_page_layout_screen.header.display_label,
                    } : state.object_page_layout_screen.header
                }
            };

        case actionTypes.SET_APP_PAGE_LAYOUT_SCREEN:

            return {
                ...state,
                app_page_layout_screen: {
                    ...state.app_page_layout_screen,
                    route: _.isString(action.payload.route) ? action.payload.route : state.app_page_layout_screen.route,
                    screen_identifier: _.isString(action.payload.screen_identifier) ? action.payload.screen_identifier : state.app_page_layout_screen.screen_identifier,
                    raw_page_layout: _.isObject(action.payload.raw_page_layout) ? action.payload.raw_page_layout : state.app_page_layout_screen.raw_page_layout,
                    shown_page_layout: _.isObject(action.payload.shown_page_layout) ? action.payload.shown_page_layout : state.app_page_layout_screen.shown_page_layout,
                    all_page_layouts: _.isArray(action.payload.all_page_layouts) ? action.payload.all_page_layouts : state.app_page_layout_screen.all_page_layouts,
                    is_loaded: _.isBoolean(action.payload.is_loaded) ? action.payload.is_loaded : state.app_page_layout_screen.is_loaded,
                    is_import_customizations_dialog_open: _.isBoolean(action.payload.is_import_customizations_dialog_open) ? action.payload.is_import_customizations_dialog_open : state.app_page_layout_screen.is_import_customizations_dialog_open,
                    header: !_.isEmpty(action.payload.header) ? {
                        is_search_open: _.isBoolean(action.payload.header.is_search_open) ? action.payload.header.is_search_open : state.app_page_layout_screen.header.is_search_open,
                        search_value: _.isString(action.payload.header.search_value) ? action.payload.header.search_value : state.app_page_layout_screen.header.search_value,
                        item_actions: _.isArray(action.payload.header.item_actions) ? action.payload.header.item_actions : state.app_page_layout_screen.header.item_actions,
                        more_actions: _.isArray(action.payload.header.more_actions) ? action.payload.header.more_actions : state.app_page_layout_screen.header.more_actions,
                    } : state.app_page_layout_screen.header
                }
            };

        case actionTypes.REMOVE_APP_PAGE_LAYOUT_SCREEN:

            return {
                ...state,
                app_page_layout_screen: {
                    route: '',
                    screen_identifier: '',
                    raw_page_layout: null,
                    shown_page_layout: null,
                    all_page_layouts: [],
                    is_loaded: false,
                    header: {
                        is_search_open: false,
                        search_value: '',
                        item_actions: [],
                        more_actions: []
                    }
                }
            };

        case actionTypes.SET_OBJECT_LIST_VIEW_AND_PAGE_LAYOUT_SCREEN:

            return {
                ...state,
                object_list_view_screen: {
                    ...state.object_list_view_screen,
                    object: _.isObject(action.payload.object) ? action.payload.object : state.object_list_view_screen.object,
                    object_identifier: _.isString(action.payload.object_identifier) ? action.payload.object_identifier : state.object_list_view_screen.object_identifier,
                    raw_list_view: _.isObject(action.payload.object_list_view_screen.raw_list_view) ? action.payload.object_list_view_screen.raw_list_view : state.object_list_view_screen.raw_list_view,
                    shown_list_view: _.isObject(action.payload.object_list_view_screen.shown_list_view) ? action.payload.object_list_view_screen.shown_list_view : state.object_list_view_screen.shown_list_view,
                    all_list_views: _.isArray(action.payload.object_list_view_screen.all_list_views) ? action.payload.object_list_view_screen.all_list_views : state.object_list_view_screen.all_list_views,
                    is_loaded: _.isBoolean(action.payload.object_list_view_screen.is_loaded) ? action.payload.object_list_view_screen.is_loaded : state.object_list_view_screen.is_loaded,
                    is_edit_record_dialog_open: _.isBoolean(action.payload.object_list_view_screen.is_edit_record_dialog_open) ? action.payload.object_list_view_screen.is_edit_record_dialog_open : state.object_list_view_screen.is_edit_record_dialog_open,
                    is_edit_field_dialog_open: _.isBoolean(action.payload.object_list_view_screen.is_edit_field_dialog_open) ? action.payload.object_list_view_screen.is_edit_field_dialog_open : state.object_list_view_screen.is_edit_field_dialog_open,
                    is_new_record_dialog_open: _.isBoolean(action.payload.object_list_view_screen.is_new_record_dialog_open) ? action.payload.object_list_view_screen.is_new_record_dialog_open : state.object_list_view_screen.is_new_record_dialog_open,
                    is_archive_dialog_open: _.isBoolean(action.payload.object_list_view_screen.is_archive_dialog_open) ? action.payload.object_list_view_screen.is_archive_dialog_open : state.object_list_view_screen.is_archive_dialog_open,
                    is_customize_field_dialog_open: _.isBoolean(action.payload.object_list_view_screen.is_customize_field_dialog_open) ? action.payload.object_list_view_screen.is_customize_field_dialog_open : state.object_list_view_screen.is_customize_field_dialog_open,
                    is_filter_open: _.isBoolean(action.payload.object_list_view_screen.is_filter_open) ? action.payload.object_list_view_screen.is_filter_open : state.object_list_view_screen.is_filter_open,
                    selected_items: _.isObject(action.payload.object_list_view_screen.selected_items) ? action.payload.object_list_view_screen.selected_items : state.object_list_view_screen.selected_items,
                    pagination: !_.isEmpty(action.payload.object_list_view_screen.pagination) ? {
                        current_page: action.payload.object_list_view_screen.pagination.current_page ? action.payload.object_list_view_screen.pagination.current_page : state.object_list_view_screen.pagination.current_page,
                        last_page: action.payload.object_list_view_screen.pagination.last_page ? action.payload.object_list_view_screen.pagination.last_page : state.object_list_view_screen.pagination.last_page,
                        pagination_amount: action.payload.object_list_view_screen.pagination.pagination_amount ? action.payload.object_list_view_screen.pagination.pagination_amount : state.object_list_view_screen.pagination.pagination_amount,
                        total_amount: action.payload.object_list_view_screen.pagination.total_amount ? action.payload.object_list_view_screen.pagination.total_amount : state.object_list_view_screen.pagination.total_amount,
                    } : state.object_list_view_screen.pagination
                },
                object_page_layout_screen: {
                    ...state.object_page_layout_screen,
                    object: _.isObject(action.payload.object) ? action.payload.object : state.object_page_layout_screen.object,
                    object_identifier: _.isString(action.payload.object_identifier) ? action.payload.object_identifier : state.object_page_layout_screen.object_identifier,
                    raw_page_layout: _.isObject(action.payload.object_page_layout_screen.raw_page_layout) ? action.payload.object_page_layout_screen.raw_page_layout : state.object_page_layout_screen.raw_page_layout,
                    shown_page_layout: _.isObject(action.payload.object_page_layout_screen.shown_page_layout) ? action.payload.object_page_layout_screen.shown_page_layout : state.object_page_layout_screen.shown_page_layout,
                    all_page_layouts: _.isArray(action.payload.object_page_layout_screen.all_page_layouts) ? action.payload.object_page_layout_screen.all_page_layouts : state.object_page_layout_screen.all_page_layouts,
                    is_loaded: _.isBoolean(action.payload.object_page_layout_screen.is_loaded) ? action.payload.object_page_layout_screen.is_loaded : state.object_page_layout_screen.is_loaded,
                    is_edit_record_dialog_open: _.isBoolean(action.payload.is_edit_record_dialog_open) ? action.payload.is_edit_record_dialog_open : state.object_page_layout_screen.is_edit_record_dialog_open,
                    is_edit_field_dialog_open: _.isBoolean(action.payload.is_edit_field_dialog_open) ? action.payload.is_edit_field_dialog_open : state.object_page_layout_screen.is_edit_field_dialog_open,
                    is_new_record_dialog_open: _.isBoolean(action.payload.object_page_layout_screen.is_new_record_dialog_open) ? action.payload.object_page_layout_screen.is_new_record_dialog_open : state.object_page_layout_screen.is_new_record_dialog_open,
                    is_archive_dialog_open: _.isBoolean(action.payload.object_page_layout_screen.is_archive_dialog_open) ? action.payload.object_page_layout_screen.is_archive_dialog_open : state.object_page_layout_screen.is_archive_dialog_open,
                    header: !_.isEmpty(action.payload.object_page_layout_screen.header) ? {
                        is_editable: _.isBoolean(action.payload.object_page_layout_screen.header.is_editable) ? action.payload.object_page_layout_screen.header.is_editable : state.object_page_layout_screen.header.is_editable,
                        is_archivable: _.isBoolean(action.payload.object_page_layout_screen.header.is_archivable) ? action.payload.object_page_layout_screen.header.is_archivable : state.object_page_layout_screen.header.is_archivable,
                        is_search_open: _.isBoolean(action.payload.object_page_layout_screen.header.is_search_open) ? action.payload.object_page_layout_screen.header.is_search_open : state.object_page_layout_screen.header.is_search_open,
                        search_value: _.isString(action.payload.object_page_layout_screen.header.search_value) ? action.payload.object_page_layout_screen.header.search_value : state.object_page_layout_screen.header.search_value,
                        item_actions: _.isArray(action.payload.object_page_layout_screen.header.item_actions) ? action.payload.object_page_layout_screen.header.item_actions : state.object_page_layout_screen.header.item_actions,
                        more_actions: _.isArray(action.payload.object_page_layout_screen.header.more_actions) ? action.payload.object_page_layout_screen.header.more_actions : state.object_page_layout_screen.header.more_actions,
                        display_label: _.isString(action.payload.header.display_label) ? action.payload.header.display_label : state.object_page_layout_screen.header.display_label,
                    } : state.object_page_layout_screen.header
                }
            };

        default:
            return state;

    }
};


export default ObjectScreensReducer;
