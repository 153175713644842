    
const PrecreatedReportsData = [
    // Balance Sheet Comparison
    {
        subject: "Balance Sheet Comparison",
        identifier: "balance_sheet_comparison",
        description: "A side-by-side analysis of an organization's financial position, highlighting changes in assets, liabilities, and equity.",
        category: 'audit',
        type: 'ledgers',
        sub_type: '',
    },
    // Balance Sheet Detail
    {
        subject: "Balance Sheet Detail",
        identifier: "balance_sheet_detail",
        description: "Breakdown of an organization's assets, liabilities, and equity, providing a granular view of its financial position.",
        category: 'audit',
        type: 'ledgers',
        sub_type: '',
    },
    // Balance Sheet Summary
    {
        subject: "Balance Sheet Summary",
        identifier: "balance_sheet_summary",
        description: "Overview of an organization's financial position, focusing on key totals for assets, liabilities, and equity.",
        category: 'audit',
        type: 'ledgers',
        sub_type: '',
    },
    // Balance Sheet
    {
        subject: "Balance Sheet",
        identifier: "balance_sheet",
        description: "A snapshot of an organization's financial health at a specific point in time, detailing its assets, liabilities, and equity.",
        category: 'audit',
        type: 'ledgers',
        sub_type: '',
    },
    // Profit and Loss Detail
    {
        subject: "Profit and Loss Detail",
        identifier: "profit_loss_detail",
        description: "A comprehensive breakdown of an organization's revenue and expenses, offering a detailed view of its financial performance.",
        category: 'audit',
        type: 'ledgers',
        sub_type: '',
    },
    // Profit and Loss
    {
        subject: "Profit and Loss",
        identifier: "profit_loss",
        description: "Summarizes an organization's financial performance by detailing its revenue, expenses, and net profit or loss over a specific period, typically a month, quarter, or year.",
        category: 'audit',
        type: 'ledgers',
        sub_type: '',
    },
    // Profit and Loss by Customer Type
    {
        subject: "Profit and Loss by Customer Type",
        identifier: "profit_loss_by_customer_type",
        description: "Analyzes an organization's financial performance based on customer segments or types, helping identify which customer groups contribute the most to revenue and profitability.",
        category: 'audit',
        type: 'ledgers',
        sub_type: '',
    },
    // Profit and Loss by Month
    {
        subject: "Profit and Loss by Month",
        identifier: "profit_loss_by_month",
        description: "Displays an organization's financial performance on a month-to-month basis, revealing fluctuations in revenue and expenses throughout the year.",
        category: 'audit',
        type: 'ledgers',
        sub_type: '',
    },
    // Quarterly Profit and Loss Summary
    {
        subject: "Quarterly Profit and Loss Summary",
        identifier: "quarterly_profit_and_loss_summary",
        description: "A condensed overview of an organization's financial performance, focusing on key totals for revenue, expenses, and net profit for each quarter of the year.",
        category: 'audit',
        type: 'ledgers',
        sub_type: '',
    },
    // Profit and Loss as % of total income
    {
        subject: "Profit and Loss as % of total income",
        identifier: "profit_loss_as_percentage_of_total_income",
        description: "Presents the proportion of each expense category relative to the total income, offering insights into cost structures and profitability ratios.",
        category: 'audit',
        type: 'ledgers',
        sub_type: '',
    },
    // Profit and Loss Comparison
    {
        subject: "Profit and Loss Comparison",
        identifier: "profit_loss_comparison",
        description: 'Compares the financial performance of an organization between two or more periods, highlighting changes in revenue, expenses, and profitability to assess growth or decline.',
        category: 'audit',
        type: 'ledgers',
        sub_type: '',
    },
    // Statement of Cash Flows
    {
        subject: "Statement of Cash Flows",
        identifier: "statement_of_cash_flows",
        description: "A summary of how an organization's cash position has changed over a specific period, detailing cash inflows and outflows from operating, investing, and financing activities.",
        category: 'audit',
        type: 'ledgers',
        sub_type: '',
    },
    // Accounts receivable aging detail
    {
        subject: "Accounts receivable aging detail",
        identifier: "accounts_receivable_aging_detail",
        description: "Categorizes outstanding customer invoices by their age, helping to identify and manage overdue payments more effectively.",
        category: 'audit',
        type: 'ledgers',
        sub_type: '',
    },
    // Accounts receivable aging summary
    {
        subject: "Accounts receivable aging summary",
        identifier: "accounts_receivable_aging_summary",
        description: "Provides an overview of outstanding customer invoices categorized by their age, allowing businesses to quickly assess the overall state of their receivables and prioritize collection efforts.",
        category: 'audit',
        type: 'ledgers',
        sub_type: '',
    },
    // Total Sales
    {
        subject: "Total Sales",
        identifier: "total_sales",
        description: "Summarizes the overall sales revenue generated by a business over a specific period, providing a comprehensive view of its financial performance.",
        category: 'audit',
        type: 'ledgers',
        sub_type: '',
    },
    // Customer Balance Detail
    {
        subject: "Customer Balance Detail",
        identifier: "customer_balance_detail",
        description: "A comprehensive breakdown of the outstanding balances owed by individual customers, providing a detailed view of their accounts and payment histories.",
        category: 'clients',
        type: 'accounts',
        sub_type: 'customers',
    },
    // Customer balance summary
    {
        subject: "Customer Balance Summary",
        identifier: "customer_balance_summary",
        description: "Provides an overview of the outstanding balances owed by customers, summarizing the overall financial relationship between the business and its clientele.",
        category: 'clients',
        type: 'accounts',
        sub_type: 'customers',
    },
    // Invoice summary
    {
        subject: "Invoice Summary",
        identifier: "invoice_summary",
        description: "A condensed overview of issued invoices, summarizing key details such as total amounts, due dates, and payment status for efficient tracking of outstanding payments.",
        category: 'billing',
        type: 'invoices',
        sub_type: '',
    },
    // Open Invoices
    {
        subject: "Open Invoices",
        identifier: "open_invoices",
        description: "Lists all unpaid or partially paid invoices, helping businesses monitor outstanding payments and manage their accounts receivable effectively.",
        category: 'billing',
        type: 'invoices',
        sub_type: '',
    },
    // Customers List
    {
        subject: "Customers List",
        identifier: "customers_list",
        description: "A comprehensive roster of all customers or clients associated with a business, typically including key contact information and relevant details for customer management purposes.",
        category: 'clients',
        type: 'accounts',
        sub_type: '',
    },
    // New Customers
    {
        subject: "New Customers",
        identifier: "new_customers",
        description: "A list of customers who have recently started doing business with a company, helping to track and analyze customer acquisition efforts and growth.",
        category: 'clients',
        type: 'accounts',
        sub_type: '',
    },
    // Product/Service List
    {
        subject: "Product/Service List",
        identifier: "product_service_list",
        description: "A comprehensive catalog of all products or services offered by a business, often including key details such as names, descriptions, prices, and inventory levels for reference and management.",
        category: 'billing',
        type: 'items',
        sub_type: '',
    },
    // Product/Service Stats
    {
        subject: "Product/Service Stats",
        identifier: "product_service_stats",
        description: "Insights related to products/services, helping businesses analyze and improve their items performance.",
        category: 'billing',
        type: 'items',
        sub_type: '',
    },
    // Request Stats
    {
        subject: "Request Stats",
        identifier: "request_stats",
        description: "Insights related to requests made by customers or users, helping businesses analyze and improve their service or support performance.",
        category: 'work',
        type: 'requests',
        sub_type: '',
    },
    // Requests by Customer
    {
        subject: "Requests by Customer",
        identifier: "requests_by_customer",
        description: "Summarizes customer-specific requests or inquiries, helping businesses track and manage customer interactions and service requests efficiently.",
        category: 'work',
        type: 'requests',
        sub_type: '',
    },
    // Estimate Stats
    {
        subject: "Estimate Stats",
        identifier: "estimate_stats",
        description: "Analysis regarding estimates or quotations issued by a business, offering insights into the effectiveness and trends of pricing and sales strategies.",
        category: 'work',
        type: 'estimates',
        sub_type: '',
    },
    // Estimates by Customer
    {
        subject: "Estimates by Customer",
        identifier: "estimates_by_customer",
        description: "Summarizes all the estimates or quotations provided to individual customers, assisting businesses in tracking potential sales and customer engagement.",
        category: 'work',
        type: 'estimates',
        sub_type: '',
    },
    // Job Stats
    {
        subject: "Job Stats",
        identifier: "job_stats",
        description: "Analysis related to jobs or projects undertaken by a business, helping track performance metrics, project timelines, and resource utilization.",
        category: 'work',
        type: 'jobs',
        sub_type: '',
    },
    // Jobs by Customer
    {
        subject: "Jobs by Customer",
        identifier: "jobs_by_customer",
        description: "Provides a list of jobs or projects associated with specific customers, aiding businesses in tracking project progress, timelines, and costs for each customer relationship.",
        category: 'work',
        type: 'jobs',
        sub_type: '',
    },
    // New Customers
    {
        subject: "New Jobs",
        identifier: "new_jobs",
        description: "Summarizes newly initiated projects or tasks within a business, offering insights into workload management and project tracking.",
        category: 'work',
        type: 'jobs',
        sub_type: '',
    },
    // Route Stats
    {
        subject: "Route Stats",
        identifier: "route_stats",
        description: "Analysis related to routes or transportation logistics, aiding businesses in optimizing delivery or transportation operations and efficiency.",
        category: 'work',
        type: 'routes',
        sub_type: '',
    },
    // Invoice Stats
    {
        subject: "Invoice Stats",
        identifier: "invoice_stats",
        description: "Insights and analysis regarding invoicing activities, providing valuable data on billing trends, revenue generation, and payment tracking for a business.",
        category: 'billing',
        type: 'invoices',
        sub_type: '',
    },
    // Invoices by Customer
    {
        subject: "Invoices by Customer",
        identifier: "invoices_by_customer",
        description: "Summarizes all the invoices issued to individual customers, offering a comprehensive view of billing history and outstanding payments for effective customer account management.",
        category: 'billing',
        type: 'invoices',
        sub_type: '',
    },
    // Avg. Days Until Payment
    {
        subject: "Avg. Days Until Payment",
        identifier: "avg_days_until_payment",
        description: "The average number of days it takes for invoices to be paid, aiding businesses in understanding their cash flow cycle and optimizing payment collection strategies.",
        category: 'billing',
        type: 'payments',
        sub_type: '',
    },
    // Visit Stats
    {
        subject: "Visit Stats",
        identifier: "visit_stats",
        description: "Groups and analyzes the timing and duration of visits or interactions, offering insights into when and how long engagements or appointments typically occur for a business or organization.",
        category: 'schedule',
        type: 'visits',
        sub_type: '',
    },
    // Visits Type Breakdown
    {
        subject: "Visits Type Breakdown",
        identifier: "visits_type_breakdown",
        description: "Groups and analyzes different types of visits or interactions, providing insights into the distribution and nature of engagements or appointments for a business or organization.",
        category: 'schedule',
        type: 'visits',
        sub_type: '',
    },
    // Visits Time Breakdown
    {
        subject: "Visits Time Breakdown",
        identifier: "visits_time_breakdown",
        description: "Groups and analyzes the timing and duration of visits or interactions, offering insights into when and how long engagements or appointments typically occur for a business or organization.",
        category: 'schedule',
        type: 'visits',
        sub_type: '',
    },
    // Visits by Day
    {
        subject: "Visits by Day",
        identifier: "visits_by_day",
        description: "Presents a breakdown of visits or interactions based on the days of the week, helping businesses analyze and optimize their scheduling and resource allocation.",
        category: 'schedule',
        type: 'visits',
        sub_type: '',
    },
    // Visits by Employee
    {
        subject: "Visits by Employee",
        identifier: "visits_by_employee",
        description: "Provides a summary of visits or interactions attributed to specific employees, facilitating performance tracking and workload management within a business or organization.",
        category: 'schedule',
        type: 'visits',
        sub_type: '',
    },
];

export default PrecreatedReportsData;