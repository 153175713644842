
// Import NPM Modules
import _ from 'lodash';

// Import Custom Modules
import * as actionTypes from '../../action_types/customizations/ActionsActionTypes';

/**
 * Handles all the state and reducer handling for all customization about Actions. 
 * This includes Item Actions, Batch Actions, and More Actions.
 * 
 */
const actionsReducer = (state = { }, action) => {

    switch (action.type) {

        // Handles setting all the object's Actions data at once.
        case actionTypes.SET_ALL_ACTIONS:

            // Create a copy of the current redux state
            let newActionsState = {...state};

            // Convert the action.payload.actions from an array to a Actions map.
            if (_.isArray(action.payload.actions)) {

                // Go through all the action received from the customization query and order them in this redux reducers state (state -> object -> action)
                for (let i=0; i< action.payload.actions.length; i++) {

                    const currentAction = action.payload.actions[i];
                    const plural_actions_group = currentAction.action_type + 's';

                    // If this currentAction belongs to an object then store the currentAction inside the parent object container
                    if (!_.isNull(currentAction.object_identifier)) {
                        // This action has a valid object and object_identifier and is a real object

                        // If no JSON has been created for this object, then create a new JSON structure to hold this new object's actions.
                        if (!_.isObject(newActionsState[currentAction.object_identifier])) {
                            newActionsState[currentAction.object_identifier] = {
                                item_actions: {},
                                batch_actions: {}
                            };
                        }

                        // If no JSON has been created for this object's action type, then create a new JSON structure to hold this new object's action type actions.
                        if (!_.isObject(newActionsState[currentAction.object_identifier][plural_actions_group])) {
                            newActionsState[currentAction.object_identifier][plural_actions_group] = {};
                        }
                       
                        // Check for an action type and switch between the correct action object to store this action.
                        if (!_.isEmpty(currentAction.identifier)) {
                            newActionsState[currentAction.object_identifier][plural_actions_group][currentAction.identifier] = currentAction;
                        }

                    } else {
                        // If this currentAction does not have a valid object, object_identifier 
                        // Store this currentAction under global actions, create if it does not exist already
                        if (!_.isObject(newActionsState.global)) {
                            newActionsState.global = {
                                item_actions: {},
                                batch_actions: {}
                            };
                        }

                        // Check for an action type and switch between the correct action object to store this action.
                        newActionsState.global[currentAction.action_type][currentAction.identifier] = currentAction;

                    }
                }
                
            }

            // Return the new action state
            return newActionsState;
        
        // Removes all the actions in this reducer
        case actionTypes.REMOVE_ALL_ACTIONS:

            // Create a copy of the current redux state
            let newRemovedState = {...state};

            // Loop through each key in this reducer's state
            for (let key in newRemovedState) {
                // Delete each key's value 
                delete newRemovedState[key];
            }

            // Return the new action state
            return newRemovedState;
        

        // Default, do nothing and return state
        default:
            return state;
    }
};

export default actionsReducer;