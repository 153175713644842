
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';

// Import Custom Modules
import * as FilterOperatorTypeIdenifiers from '../../../constants/filtering/operators/FilterOperatorTypeIdentifiers';
import * as SortingMethods from '../../sorting/SortingMethods';
import * as SortingDirectionIdentifiers from '../../sorting/SortDataTypeIdentifiers';
import * as GraphQLQueryTypes from '../../types/GraphQLQueryTypes';
import * as GraphQLTypes from '../../types/GraphQLTypes';
import * as FieldTypes from '../../types/FieldTypes';
import * as DatabaseTypes from '../../types/DatabaseTypes';


const VisitListViewsData = [

    // All Visits
    {
        object: null,
        object_identifier: 'visits',
        public_id: uniqid('view_'),
        sync_token: 1,
        type: "list_view",
        identifier: identifier('All Visits').toLowerCase(),
        name: 'All Visits',
        description: 'All visits for the company.',
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        sort_field: "visits.subject",
        sql_sort_field: ["visits.subject"],
        sort_direction: SortingDirectionIdentifiers.ASCENDING,
        sort_join: null,
        sort_method: SortingMethods.ALPHANUMERIC,
        filter_by_viewer: false,
        api_request: {
            [GraphQLQueryTypes.GetVisits]: {
                inputs: {
                    company_id: GraphQLTypes.ID,
                    user_id: GraphQLTypes.ID,
                    list_view_id: GraphQLTypes.ID,
                    sort_by: GraphQLTypes.Array_String,
                    sort_direction: GraphQLTypes.String,
                    sort_join: GraphQLTypes.Array_String,
                    sort_method: GraphQLTypes.String,
                    sort_nulls_last: GraphQLTypes.Boolean,
                    filter_by_viewer: GraphQLTypes.Boolean,
                    filters: GraphQLTypes.Array_Filters
                },
                outputs: {
                    title: GraphQLTypes.String,
                    id: GraphQLTypes.ID,
                    type: {
                        id: GraphQLTypes.ID,
                        public_id: GraphQLTypes.ID,
                        label: GraphQLTypes.String,
                        identifier: GraphQLTypes.String
                    },
                    visit_id: GraphQLTypes.ID,
                    sync_token: GraphQLTypes.Int,
                    time_zone: {
                        id: GraphQLTypes.ID,
                        public_id: GraphQLTypes.ID,
                        label: GraphQLTypes.String,
                        identifier: GraphQLTypes.String,
                        value: GraphQLTypes.JSON
                    },
                    language: {
                        id: GraphQLTypes.ID,
                        public_id: GraphQLTypes.ID,
                        label: GraphQLTypes.String,
                        identifier: GraphQLTypes.String,
                        value: GraphQLTypes.JSON
                    },
                    company: GraphQLTypes.ID,
                    settings: GraphQLTypes.JSON,
                    custom_fields: GraphQLTypes.JSON,
                    metadata: GraphQLTypes.JSON,
                    suggested_action: {
                        id: GraphQLTypes.ID,
                        public_id: GraphQLTypes.ID,
                        label: GraphQLTypes.String,
                        short_label: GraphQLTypes.String,
                        identifier: GraphQLTypes.String,
                        icon: GraphQLTypes.String,
                        sort_order: GraphQLTypes.Int,
                        action_type: GraphQLTypes.String,
                        use_type: GraphQLTypes.String,
                        description: GraphQLTypes.String,
                        help_text: GraphQLTypes.String
                    }
                }
            }
        },
        filters: [ ],
        search_value: "",
        visible_fields: [
            {
                label: "Subject",
                identifier: 'visits.subject',
                display_fields: ['visits.subject'],
                display_format: null,
                width: 180,
                is_locked: true
            },
            {
                label: "Type",
                identifier: 'visits.type',
                display_fields: ['visits.type', 'object_types.label'],
                display_format: null,
                width: 120,
                is_locked: false
            },
            {
                label: "Status",
                identifier: 'visits.status',
                display_fields: ['visits.status', 'dropdowns.label'],
                display_format: null,
                width: 120,
                is_locked: false
            },
            {
                label: "Start Date",
                identifier: 'visits.start_date',
                display_fields: ['visits.start_date'],
                display_format: 'MMM D h:mm A',
                width: 160,
                is_locked: false
            },
            {
                label: "End Date",
                identifier: 'visits.end_date',
                display_fields: ['visits.end_date'],
                display_format: 'MMM D h:mm A',
                width: 160,
                is_locked: false
            },
            {
                label: "Duration",
                identifier: 'visits.duration',
                display_fields: ['visits.duration'],
                display_format: null,
                width: 120,
                is_locked: false
            },
            {
                label: "Show As",
                identifier: 'visits.show_as',
                display_fields: ['visits.show_as', 'dropdowns.label'],
                display_format: null,
                width: 120,
                is_locked: false
            }
        ],
        hidden_fields: [
            "visits.id",
            "visits.type",
            "visits.visit_id",
            "visits.sync_token",
            "visits.time_zone",
            "visits.language",
            "visits.company",
            "visits.settings",
            "visits.custom_fields",
            "visits.metadata",
            "visits.suggested_action"
        ],
        batch_actions: [
            "visits.batch_edit",
            "visits.batch_archive"
        ],
        item_actions: [
            "visits.edit",
            "visits.start",
            "visits.end",
            "visits.complete",
            "visits.close",
            "visits.convert_to_job",
            "visits.convert_to_invoice",
            "visits.archive"
        ],
        more_actions: [
            "visits.edit_recurrence",
            "visits.duplicate",
            "visits.delete"
        ],
        pagination_amount: 25
    },
];

export default VisitListViewsData;