
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';
import * as ROUTES from '../../../../navigation/routes';
import * as ScreenTypes from '../../../../types/ScreenTypes';
import * as ScreenIdentifiers from '../../../../screens/ScreenIdentifiers';
import * as ComponentTypes from '../../../../types/ProHelperComponentTypes';


const UserPageLayoutsData = [
    // Default
    {
        public_id: uniqid('page_'),
        sync_token: 1,
        identifier: identifier('Default').toLowerCase(),
        label: 'Default',
        description: 'View a User in detail.',
        status: 'published',
        revisions: 1,
        route: ROUTES.USER_DETAIL_PREFIX,
        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
        screen_identifier: ScreenIdentifiers.USER_PAGE_LAYOUT,
        template: null,
        object: null,
        object_identifier: 'users',
        object_type: null,
        group: {
            label: 'Team',
            identifier: 'team',
            order: 6
        },
        sub_group: {
            label: 'Users',
            identifier: 'users',
            order: 2
        },
        components: {
            component_1: {
                id: "component_1",
                label: 'Header',
                object_identifier: 'users',
                connection_type: 'parent',
                type: ComponentTypes.OBJECT_HEADER,
                header_type: 'action_bar',
                special_type: 'header',
                is_editable: true,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                    fields: [
                        {
                            label: "Name",
                            identifier: "users.name",
                            is_required: true,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Type",
                            identifier: "users.type",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Role",
                            identifier: "users.role",
                            is_required: true,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Phone",
                            identifier: "users.phone",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Email",
                            identifier: "users.email",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Manager",
                            identifier: "users.manager",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                    ],
                    first_visible_amount: {
                        mobile: 4,
                        tablet: 4,
                        desktop: 2
                    }
                }
            },
            component_2: {
                id: "component_2",
                label: 'Details',
                object_identifier: 'users',
                connection_type: 'parent',
                type: ComponentTypes.OBJECT_DETAILS,
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: true,
                metadata: {
                    sections: [
                        // General
                        {
                            label: "General Information",
                            id: 'general_info',
                            tab_order: 'left-right',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 4,
                                tablet: 2,
                                desktop: 2
                            },
                            fields: [
                                {
                                    label: "Name",
                                    identifier: "users.name",
                                    is_required: true,
                                    is_read_only: false,
                                    is_shown_if_empty: false
                                },
                                {
                                    label: "About",
                                    identifier: "users.about",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Department",
                                    identifier: "users.department",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Skills",
                                    identifier: "users.skills",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Tags",
                                    identifier: "users.tags",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Manager",
                                    identifier: "users.manager",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                            ],
                        },
                        // Employee
                        {
                            label: "Employee Information",
                            id: 'employee_info',
                            tab_order: 'top-bottom',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: false,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 4,
                                tablet: 2,
                                desktop: 2
                            },
                            fields: [
                                {
                                    label: "Online Status",
                                    identifier: "users.online_status",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Working Status",
                                    identifier: "users.working_status",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Role",
                                    identifier: "users.role",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Permission Sets",
                                    identifier: "users.permission_sets",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Communication Method",
                                    identifier: "users.communication_method",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Manager Notes",
                                    identifier: "users.manager_notes",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Work Address",
                                    identifier: "users.work_address",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Last Working Address",
                                    identifier: "users.last_working_address",
                                    is_required: false,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Last Working Address",
                                    identifier: "users.last_working_address",
                                    is_required: false,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Language",
                                    identifier: "users.language",
                                    is_required: true,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                            ],
                        },
                        // Billing Information
                        {
                            label: "Billing Information",
                            id: 'billing_info',
                            tab_order: 'left-right',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 4,
                                tablet: 2,
                                desktop: 2
                            },
                            fields: [
                                {
                                    label: "Payroll Method",
                                    identifier: "users.payroll_method",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Cost Rate",
                                    identifier: "users.cost_rate",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Billing Address",
                                    identifier: "users.billing_address",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Tax ID",
                                    identifier: "users.tax_id",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Is Taxable",
                                    identifier: "users.is_taxable",
                                    is_required: true,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Is Time Billable",
                                    identifier: "users.is_time_billable",
                                    is_required: true,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                            ],
                        },
                        // System Information
                        {
                            label: "System Information",
                            id: 'system_info',
                            tab_order: 'left-right',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: false,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 4,
                                tablet: 2,
                                desktop: 2
                            },
                            fields: [
                                {
                                    label: "Popup Alert",
                                    identifier: "users.popup_alert",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Last Login",
                                    identifier: "users.last_login",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Last Activity At",
                                    identifier: "users.last_activity_at",
                                    is_required: false,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Start Date",
                                    identifier: "users.start_date",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "User ID",
                                    identifier: "users.user_id",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Is Employee",
                                    identifier: "users.is_employee",
                                    is_required: true,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Is Driver",
                                    identifier: "users.is_driver",
                                    is_required: true,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                            ],
                        },
                    ]
                }
            },
            component_3: {
                id: "component_3",
                label: 'Related',
                object_identifier: 'users',
                connection_type: 'parent',
                type: ComponentTypes.RELATED,
                special_type: null,
                header_type: 'normal',
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {

                }
            },
            component_4: {
                id: "component_4",
                label: 'Permissions',
                object_identifier: 'users',
                connection_type: 'parent',
                type: ComponentTypes.PERMISSIONS,
                special_type: null,
                header_type: 'normal',
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: true,
                metadata: {

                }
            }
        },
        sections: [
            {
                id: uniqid('section_'),
                anchor_name: 'header',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'seperate',
                columns: {
                    mobile: [{ id: '1', flexSize: 1, components: ["component_1"] }],
                    tablet: [{ id: '1', flexSize: 1, components: ["component_1"] }],
                    desktop: [{ id: '1', flexSize: 1, components: ["component_1"] }]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'details',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'seperate',
                columns: {
                    mobile: [{ id: '2', flexSize: 1, components: ["component_2"] }],
                    tablet: [{ id: '2', flexSize: 1, components: ["component_2"] }],
                    desktop: [{ id: '2', flexSize: 1, components: ["component_2"] }]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'related_and_activities',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: null,
                template_identifier: null,
                shrink_method: 'tabs',
                columns: {
                    mobile: [{ id: '3', flexSize: 1, components: [["component_3", "component_4"]] }],
                    tablet: [{ id: '3', flexSize: 2, components: ["component_3"] }, { id: '4', flexSize: 1, components: ["component_4"] }],
                    desktop: [{ id: '3', flexSize: 2, components: ["component_3"] }, { id: '4', flexSize: 1, components: ["component_4"] }]
                }
            }
        ],
        item_actions: [
            "users.duplicate",
            "users.archive"
        ],
        more_actions: [ ],
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        published_at: new Date(),
        published_by: null,
        
    }
];

export default UserPageLayoutsData;