// This constants file is a representation of how data for object fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as ActionTypes from '../../types/ActionTypes';
import * as ActionUseTypes from '../../types/ActionUseTypes';

const AccountActionsData = [

    //
    // Item Actions
    //
    // New Account
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "New Account",
        short_label: "New",
        identifier: "new",
        icon: "sitemap",
        sort_order: 1,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.NEW,
        description: "Create a new Account.",
        help_text: "Create a new Account.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Edit Account
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Edit Account",
        short_label: "Edit",
        identifier: "edit",
        icon: "pen-to-square",
        sort_order: 2,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.EDIT,
        description: "Edit the Fields for an Account.",
        help_text: "Edit the Fields for an Account.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Adjust Balance
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Adjust Balance",
        short_label: "Adjust Balance",
        identifier: "adjust_balance",
        icon: "scale-balanced",
        sort_order: 3,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.ADJUST_BALANCE,
        description: "Adjust the balance of an Account by creating a Credit or Debit transaction with an amount and note, in order to change the total to an updated amount.",
        help_text: "Adjust the balance of an Account by creating a Credit or Debit transaction with an amount and note, in order to change the total to an updated amount.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Call Phone
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Call Phone",
        short_label: "Call",
        identifier: "call_phone",
        icon: "phone",
        sort_order: 4,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.CALL_PHONE,
        description: "Call the primary phone number for an account.",
        help_text: "Call the primary phone number for an account.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Send Email
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Send Email",
        short_label: "Email",
        identifier: "send_email",
        icon: "envelope",
        sort_order: 5,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.EMAIL,
        description: "Send an email to the primary email address for an account.",
        help_text: "Send an email to the primary email address for an account.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Send Mail
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Send Mail",
        short_label: "Mail",
        identifier: "send_mail",
        icon: "mailbox",
        sort_order: 6,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.EMAIL,
        description: "Create a printable letter to be mailed to an Account.",
        help_text: "Create a printable letter to be mailed to an Account.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // View Contact
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "View Contact",
        short_label: "View Contact",
        identifier: "view_contact",
        icon: "address-book",
        sort_order: 7,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.PROHELPER_LINK,
        description: "View the primary contact linked for the account.",
        help_text: "View the primary contact linked for the account.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // View Property
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "View Property",
        short_label: "View Property",
        identifier: "view_property",
        icon: "house-building",
        sort_order: 8,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.PROHELPER_LINK,
        description: "View the primary property linked for the account.",
        help_text: "View the primary property linked for the account.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // View in Maps
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "View in Maps",
        short_label: "View in Maps",
        identifier: "view_in_maps",
        icon: "map-location",
        sort_order: 9,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.PROHELPER_LINK,
        description: "Links to the default mapping software or website to get directions to the primary address/property.",
        help_text: "Links to the default mapping software or website to get directions to the primary address/property.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Duplicate Account
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Duplicate Account",
        short_label: "Duplicate",
        identifier: "duplicate_account",
        icon: "copy",
        sort_order: 10,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.PROHELPER_LINK,
        description: "Copy and duplicate the contents of this account to a new one.",
        help_text: "Copy and duplicate the contents of this account to a new one.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Archive Account
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Archive Account",
        short_label: "Archive",
        identifier: "archive",
        icon: "box-archive",
        sort_order: 11,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.ARCHIVE,
        description: "Archive an account to be hidden from normal users in the program.",
        help_text: "Archive an account, hidden but not deleted.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Delete Account
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Delete Account",
        short_label: "Delete",
        identifier: "delete",
        icon: "trash",
        sort_order: 12,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.DELETE,
        description: "Permantently deletes an Account. Not retrievable after this point.",
        help_text: "Permantently deletes an Account, Not viewable or retrievable by users after.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },

    //
    // Batch Actions
    //


    // Batch Edit
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Batch Edit",
        short_label: "Batch Edit",
        identifier: "batch_edit",
        icon: "pen-to-square",
        sort_order: 1,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_EDIT,
        description: "Edit the Fields for multiple selected Accounts at once.",
        help_text: "Edit the Fields for multiple selected Accounts at once.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Batch Email
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Batch Email",
        short_label: "Batch Email",
        identifier: "batch_email",
        icon: "envelope",
        sort_order: 2,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_EMAIL,
        description: "Send an email to all selected accounts' primary emails.",
        help_text: "Send an email to all selected accounts' primary emails.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Batch Mail
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Batch Mail",
        short_label: "Batch Mail",
        identifier: "batch_mail",
        icon: "mailbox",
        sort_order: 2,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_PRINT,
        description: "Send an email to all selected accounts' primary emails.",
        help_text: "Send an email to all selected accounts' primary emails.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Batch Archive
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Batch Archive",
        short_label: "Batch Archive",
        identifier: "batch_archive",
        icon: 'box-archive',
        sort_order: 4,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_ARCHIVE,
        description: "Archive multiple selected Accounts to be hidden from normal users in the program.",
        help_text: "Archive multiple selected Accounts to be hidden from normal users in the program.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },

];


export default AccountActionsData;