
// Import NPM Modules
import _ from 'lodash';

// User Info Reducer
import * as actionTypes from '../../action_types/customizations/ObjectFieldsActionTypes';

// Empty initial state
// const initialState = {
//     'accounts': {}
// };

const objectFieldsReducer = (state = { }, action) => {

    switch (action.type) {

        // Set All the Object's Object Fields at once
        case actionTypes.SET_ALL_OBJECT_FIELDS:

            // Create a copy of the current redux state
            let newObjectFieldsState = {...state};

            // Convert the action.payload.object_fields from an array to a object fields map.
            if (_.isArray(action.payload.object_fields)) {

                // Go through all the object fields received from the customization query and order them in this redux reducers state (state -> object -> object_fields)
                for (let i=0; i< action.payload.object_fields.length; i++) {

                    let currentObjectFields = action.payload.object_fields[i];

                    // If the current object field belongs to an object then store it inside the parent object container
                    if (!_.isNull(currentObjectFields.object) && !_.isNull(currentObjectFields.object_identifier)) {
                        // This object field belongs to an object and should be placed in the appropriate object container

                        // If no JSON has been created for this object, then create a new JSON structure to hold this new object's fields.
                        if (!_.isObject(newObjectFieldsState[currentObjectFields.object_identifier])) {
                            newObjectFieldsState[currentObjectFields.object_identifier] = { };
                        }

                        // Check if this object field has a valid identifier
                        if (!_.isEmpty(currentObjectFields.identifier)) {
                            // Store this object field in the parent object container 
                            newObjectFieldsState[currentObjectFields.object_identifier][currentObjectFields.identifier] = currentObjectFields;
                        }
                    }
                }

            }

            return newObjectFieldsState;

        // Removes all the object fields in this reducer 
        case actionTypes.REMOVE_ALL_OBJECT_FIELDS:

            // Create a copy of the current redux state
            let newRemovedState = {...state};
            
            // Loop through each key in this reducer's state
            for (let key in newRemovedState) {
                // Delete each key's value 
                delete newRemovedState[key];
            }

            // Return the new action state
            return newRemovedState;
        

        // Default, do nothing and return state
        default:
            return state;
    }
};

export default objectFieldsReducer;