// Sign Up Reducer
import * as actionTypes from '../../action_types/screens/SignUpActionTypes';

const initialState =
{
    name: "",
    email: "",
    companyName: "",
    password: "",
    isSignUpSubmitted: false,
    verificationCode: "",
    error: null
};

const signUpReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_NAME:
            return {
                ...state,
                name: action.payload.name
            }
        case actionTypes.SET_EMAIL:
            return {
                ...state,
                email: action.payload.email
            }
        case actionTypes.SET_COMPANY_NAME:
            return {
                ...state,
                companyName: action.payload.companyName
            }
        case actionTypes.SET_PASSWORD:
            return {
                ...state,
                password: action.payload.password
            }
        case actionTypes.SET_VERIFICATION_CODE:
                return {
                    ...state,
                    verificationCode: action.payload.verificationCode
                }
        case actionTypes.SUBMIT_SIGN_UP:
                return {
                    ...state,
                    isSignUpSubmitted: action.payload.isSignUpSubmitted
                }
        case actionTypes.SET_ERROR:
            return {
                ...state,
                error: action.payload.error
            }
        case actionTypes.CLEAR_SIGN_UP_INFO:
            return {
                name: "",
                email: "",
                companyName: "",
                password: "",
                verificationCode: "",
                isSignUpSubmitted: false,
                error: null
            }
        default:
            return state
    }
};

export default signUpReducer;