
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';

// Import Custom Modules
import * as SortingMethods from '../../sorting/SortingMethods';
import * as SortingDirectionIdentifiers from '../../sorting/SortDataTypeIdentifiers';


const EstimateListViewsData = [
    // All Estimates
    {
        object: null,
        object_identifier: 'estimates',
        public_id: uniqid('view_'),
        sync_token: 1,
        type: "list_view",
        identifier: identifier('All Estimates').toLowerCase(),
        name: 'All Estimates',
        description: 'All estimates with the company.',
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        sort_field: "estimates.status",
        sql_sort_field: ["estimates.status", "dropdowns.sort_order"],
        sort_direction: SortingDirectionIdentifiers.ASCENDING,
        sort_join: ["estimates.status", "dropdowns.id"],
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        filter_by_viewer: false,
        filters: [ ],
        search_value: "",
        visible_fields: [
            {
                label: 'Subject',
                identifier: 'estimates.subject',
                display_fields: ['estimates.subject'],
                display_format: null,
                width: 260,
                is_locked: true
            },
            {
                label: 'Type',
                identifier: 'estimates.type',
                display_fields: ['estimates.type', 'object_type.label'],
                display_format: null,
                width: 140,
                is_locked: false
            },
            {
                label: 'Account',
                field: 'estimates.account',
                display_fields: ['estimates.account', 'accounts.name', 'name.display'],
                display_format: null,
                width: 180,
                is_locked: false
            },
            {
                label: 'Description',
                identifier: 'estimates.description',
                display_fields: ['estimates.description'],
                display_format: null,
                width: 340,
                is_locked: false
            },
            {
                label: 'Status',
                identifier: 'estimates.status',
                display_fields: ['estimates.status', 'dropdown.label'],
                display_format: null,
                width: 140,
                is_locked: false
            },
            {
                label: 'Priority',
                identifier: 'estimates.priority',
                display_fields: ['estimates.priority', 'dropdown.label'],
                display_format: null,
                width: 140,
            is_locked: false
            },
            {
                label: 'Created At',
                identifier: 'estimates.created_at',
                display_fields: ['estimates.created_at'],
                display_format: 'MMM Do, YYYY h:mm A',
                width: 220,
                is_locked: false
            },
        ],
        hidden_fields: [
            "estimates.id",
            "estimates.type",
            "estimates.estimate_id",
            "estimates.sync_token",
            "estimates.company",
            "estimates.suggested_action",
            "estimates.time_zone",
            "estimates.currency",
            "estimates.language"
        ],
        batch_actions: [
            "estimates.batch_edit",
            "estimates.batch_email",
            "estimates.batch_print",
            "estimates.batch_archive"
        ],
        item_actions: [
            "estimates.edit",
            "estimates.email",
            "estimates.print",
            "estimates.mark_sent",
            "estimates.mark_approved",
            "estimates.mark_denied",
            "estimates.convert_to_job",
            "estimates.convert_to_task",
            "estimates.convert_to_invoice",
            "estimates.complete",
            "estimates.close",
            "estimates.archive"
        ],
        more_actions: [
            "estimates.duplicate",
            "estimates.delete"
        ],
        pagination_amount: 25
    },
    
];

export default EstimateListViewsData;