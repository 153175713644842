

//
// A file containing all the different sorting methods for object fields.
// This file is created to maintain a centeral name for sorting methods around the program.
// 
//
// Active Sorting Methods
export const ALPHANUMERIC = 'alphanumeric';
export const NUMERIC = 'numeric';
export const BOOLEAN = 'boolean';
export const CHRONOLOGICAL = 'chronological';
export const CUSTOM_SORT_ORDER = 'custom_sort_order';

// Not true sorting, but sub-fields can be sortable
export const NESTED_FIELD = 'nested_field';
export const LOOKUP_RELATIONSHIP = 'lookup_relationship';
export const DROPDOWN = 'dropdown';


// Want to delete
export const FULL_NAME = 'full_name';
export const LINKED_SORT_ORDER = 'linked_sort_order';
export const LINKED_ALPHANUMERIC = 'linked_alphanumeric';
export const LINKED_FIELD = 'linked_field';