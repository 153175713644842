
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';

// Import Custom Modules
import * as SortingMethods from '../../sorting/SortingMethods';
import * as SortingDirectionIdentifiers from '../../sorting/SortDataTypeIdentifiers';


const ReportListViewsData = [
    // All Reports
    {
        object: null,
        object_identifier: 'reports',
        public_id: uniqid('view_'),
        sync_token: 1,
        type: "list_view",
        identifier: identifier('All Reports').toLowerCase(),
        name: 'All Reports',
        description: 'All reports with the company.',
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        sort_field: "reports.category",
        sql_sort_field: [
            "reports.category",
            "dropdowns.label"
        ],
        sort_direction: SortingDirectionIdentifiers.ASCENDING,
        sort_join: null,
        sort_method: SortingMethods.ALPHANUMERIC,
        filter_by_viewer: false,
        filters: [ ],
        search_value: "",
        visible_fields: [
            {
                label: 'Subject',
                identifier: 'reports.subject',
                display_fields: ['reports.subject'],
                display_format: null,
                width: 260,
                is_locked: true
            },
            {
                label: 'Category',
                identifier: 'reports.category',
                display_fields: ['reports.category', 'dropdown.label'],
                display_format: null,
                width: 140,
                is_locked: false
            },
            {
                label: 'Type',
                identifier: 'reports.type',
                display_fields: ['reports.type', 'object_types.label'],
                display_format: null,
                width: 140,
                is_locked: false
            },
            {
                label: 'Description',
                identifier: 'reports.description',
                display_fields: ['reports.description'],
                display_format: null,
                width: 360,
                is_locked: false
            },
            {
                label: 'Last Used At',
                identifier: 'reports.last_used_at',
                display_fields: ['reports.last_used_at'],
                display_format: null,
                width: 340,
                is_locked: false
            },
        ],
        hidden_fields: [
            "reports.id",
            "reports.company",
            "reports.report_id",
            "reports.sync_token",
            "reports.category",
            "reports.type",
            "reports.sub_type",
            "reports.suggested_action",
            "reports.time_zone",
            "reports.language"
        ],
        batch_actions: [
            "reports.batch_edit",
            "reports.batch_archive"
        ],
        item_actions: [
            "reports.edit",
            "reports.run",
            "reports.archive"
        ],
        more_actions: [
            "reports.duplicate",
            "reports.delete"
        ],
        pagination_amount: 50
    },
    
];

export default ReportListViewsData;