
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';

// Import Custom Modules
import * as SortingMethods from '../../sorting/SortingMethods';
import * as SortingDirectionIdentifiers from '../../sorting/SortDataTypeIdentifiers';


const SubscriptionListViewsData = [
    // All Subscriptions
    {
        object: null,
        object_identifier: 'subscriptions',
        public_id: uniqid('view_'),
        sync_token: 1,
        type: "list_view",
        identifier: identifier('All Subscriptions').toLowerCase(),
        name: 'All Subscriptions',
        description: 'All subscriptions with the company.',
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        sort_field: "subscriptions.status",
        sql_sort_field: ["subscriptions.status", "dropdowns.sort_order"],
        sort_direction: SortingDirectionIdentifiers.ASCENDING,
        sort_join: ["subscriptions.status", "dropdowns.id"],
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        filter_by_viewer: false,
        filters: [ ],
        search_value: "",
        visible_fields: [
            {
                label: 'Subject',
                identifier: 'subscriptions.subject',
                display_fields: ['subscriptions.subject'],
                display_format: null,
                width: 260,
                is_locked: true
            },
            {
                label: 'Status',
                identifier: 'subscriptions.status',
                display_fields: ['subscriptions.status', 'dropdown.label'],
                display_format: null,
                width: 140,
                is_locked: false
            },
            {
                label: 'Account',
                identifier: 'subscriptions.account',
                display_fields: ['subscriptions.account', 'accounts.name', 'name.display'],
                display_format: null,
                width: 180,
                is_locked: false
            },
            {
                label: 'Description',
                identifier: 'subscriptions.description',
                display_fields: ['subscriptions.description'],
                display_format: null,
                width: 340,
                is_locked: false
            },
            {
                label: 'Payment Method',
                identifier: 'subscriptions.payment_method',
                display_fields: ['subscriptions.payment_method', 'dropdown.label'],
                display_format: null,
                width: 140,
                is_locked: false
            },
            {
                label: 'Created At',
                identifier: 'subscriptions.created_at',
                display_fields: ['subscriptions.created_at'],
                display_format: null,
                width: 220,
                is_locked: false
            },
        ],
        hidden_fields: [
            "subscriptions.id",
            "subscriptions.status",
            "subscriptions.subscription_id",
            "subscriptions.sync_token",
            "subscriptions.company",
            "subscriptions.suggested_action",
            "subscriptions.time_zone",
            "subscriptions.currency",
            "subscriptions.language"
        ],
        batch_actions: [
            "subscriptions.batch_edit",
            "subscriptions.batch_archive"
        ],
        item_actions: [
            "subscriptions.edit",
            "subscriptions.edit_payment_method",
            "subscriptions.edit_line_items",
            "subscriptions.edit_recurrence",
            "subscriptions.archive"
        ],
        more_actions: [
            "subscriptions.duplicate",
            "subscriptions.delete"
        ],
        pagination_amount: 25
    },
    
];

export default SubscriptionListViewsData;