
// This constants file is a representation of how data for object types will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';


const JobObjectTypesData = [

    // ----------------------------------------
    // Job Object Types
    // ----------------------------------------

    // Service
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "jobs",
        sync_token: 1,
        label: "Service",
        identifier: "service",
        description: "A service job.",
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Repair
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "jobs",
        sync_token: 1,
        label: "Repair",
        identifier: "repair",
        description: "A repair job.",
        sort_order: 2,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Installation
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "jobs",
        sync_token: 1,
        label: "Installation",
        identifier: "installation",
        description: "An installation job",
        sort_order: 3,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "jobs",
        sync_token: 1,
        label: "Other",
        identifier: "other",
        description: "A job that doesn't fit in a category.",
        sort_order: 4,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
];

export default JobObjectTypesData;