
// Import NPM Modules
import _ from 'lodash';

// Objects Reducer
import * as actionTypes from '../../action_types/customizations/NavigationItemsActionTypes';

// Empty initial state
const initialState = { };

const navigationItemsReducer = (state = initialState, action) => {

    switch (action.type) {


        case actionTypes.SET_ALL_NAVIGATION_ITEMS:

            // Create a copy of the current redux state
            let newObjectsState = {...state};

            if (_.isArray(action.payload.navigation_items)) {
                action.payload.navigation_items.forEach((navigation_item) => {
                    newObjectsState[navigation_item.identifier] = navigation_item;
                })
            }

            return newObjectsState;

        case actionTypes.REMOVE_ALL_NAVIGATION_ITEMS:

            // Return an empty object's JSON object.
            return {};

        default:
            return state;
    }
};

export default navigationItemsReducer;