
// This constants file is a representation of how data for object types will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';


const InvoiceObjectTypesData = [

    // ----------------------------------------
    // Invoice Object Types
    // ----------------------------------------

    // Service
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "invoices",
        sync_token: 1,
        label: "Service",
        identifier: "service",
        description: "A service invoice.",
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Repair
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "invoices",
        sync_token: 1,
        label: "Repair",
        identifier: "repair",
        description: "A repair invoice.",
        sort_order: 2,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Installation
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "invoices",
        sync_token: 1,
        label: "Installation",
        identifier: "installation",
        description: "An installation invoice",
        sort_order: 3,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Replacement
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "invoices",
        sync_token: 1,
        label: "Replacement",
        identifier: "replacement",
        description: "An replacement invoice",
        sort_order: 4,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Construction
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "invoices",
        sync_token: 1,
        label: "Construction",
        identifier: "construction",
        description: "An construction invoice",
        sort_order: 5,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Maintenance
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "invoices",
        sync_token: 1,
        label: "Maintenance",
        identifier: "maintenance",
        description: "An maintenance invoice",
        sort_order: 6,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "invoices",
        sync_token: 1,
        label: "Other",
        identifier: "other",
        description: "A invoice that doesn't fit in a category.",
        sort_order: 7,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
];

export default InvoiceObjectTypesData;