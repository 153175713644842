
// This constants file is a representation of how data for object types will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';


const PropertyObjectTypesData = [

    // ----------------------------------------
    // Property Object Types
    // ----------------------------------------
    // Residential
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "properties",
        sync_token: 1,
        label: "Residential",
        identifier: "residential",
        description: "A residential property that a person lives at.",
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Commerical
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "properties",
        sync_token: 1,
        label: "Commerical",
        identifier: "commerical",
        description: "A commerical location that a person works at.",
        sort_order: 2,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Mailbox
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "properties",
        sync_token: 1,
        label: "Mailbox",
        identifier: "mailbox",
        description: "A postal mailbox where a person receives mail, but doesn't live there.",
        sort_order: 3,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "properties",
        sync_token: 1,
        label: "Other",
        identifier: "other",
        description: "A property that is different and not categorized yet.",
        sort_order: 4,
        is_active: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },

];

export default PropertyObjectTypesData;