
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';

// Import Custom Modules
import * as SortingMethods from '../../sorting/SortingMethods';
import * as SortingDirectionIdentifiers from '../../sorting/SortDataTypeIdentifiers';


const InvoiceListViewsData = [
    // All Invoices
    {
        object: null,
        object_identifier: 'invoices',
        public_id: uniqid('view_'),
        sync_token: 1,
        type: "list_view",
        identifier: identifier('All Invoices').toLowerCase(),
        name: 'All Invoices',
        description: 'All invoices with the company.',
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        sort_field: "invoices.status",
        sql_sort_field: ["invoices.status", "dropdowns.sort_order"],
        sort_direction: SortingDirectionIdentifiers.ASCENDING,
        sort_join: ["invoices.status", "dropdowns.id"],
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        filter_by_viewer: false,
        filters: [ ],
        search_value: "",
        visible_fields: [
            {
                label: 'Subject',
                identifier: 'invoices.subject',
                display_fields: ['invoices.subject'],
                display_format: null,
                width: 260,
                is_locked: true
            },
            {
                label: 'Type',
                identifier: 'invoices.type',
                display_fields: ['invoices.type','dropdown.label'],
                display_format: null,
                width: 140,
                is_locked: false
            },
            {
                label: 'Account',
                identifier: 'invoices.account',
                display_fields: ['invoices.account', 'account.name','name.display'],
                display_format: null,
                width: 180,
                is_locked: false
            },
            {
                label: 'Description',
                identifier: 'invoices.description',
                display_fields: ['invoices.description'],
                display_format: null,
                width: 340,
                is_locked: false
            },
            {
                label: 'Status',
                identifier: 'invoices.status',
                display_fields: ['invoices.status','dropdown.label'],
                display_format: null,
                width: 140,
                is_locked: false
            },
            {
                label: 'Created At',
                identifier: 'invoices.created_at',
                display_fields: ['invoices.created_at'],
                display_format: null,
                width: 220,
                is_locked: false
            },
        ],
        hidden_fields: [
            "invoices.id",
            "invoices.type",
            "invoices.invoice_id",
            "invoices.sync_token",
            "invoices.company",
            "invoices.suggested_action",
            "invoices.time_zone",
            "invoices.currency",
            "invoices.language"
        ],
        batch_actions: [
            "invoices.batch_edit",
            "invoices.batch_email",
            "invoices.batch_print",
            "invoices.batch_archive"
        ],
        item_actions: [
            "invoices.edit",
            "invoices.email",
            "invoices.print",
            "invoices.mark_sent",
            "invoices.mark_paid",
            "invoices.archive"
        ],
        more_actions: [
            "invoices.duplicate",
            "invoices.delete"
        ],
        pagination_amount: 25
    },
    
];

export default InvoiceListViewsData;