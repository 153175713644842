
// Import Custom Modules
import { combineReducers } from 'redux';
import FakeDBDataReducer from './FakeDBDataReducer';
import ListViewDataReducer from './ListViewDataReducer';
import PageLayoutDataReducer from './PageLayoutDataReducer';
import DialogDataReducer from './DialogDataReducer';


// Combine Reducers and export as the default
export default combineReducers({
    fake_db: FakeDBDataReducer,
    list_views_data: ListViewDataReducer,
    page_layouts_data: PageLayoutDataReducer,
    dialogs_data: DialogDataReducer
});