// This constats file is a representation of how data for object fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as ActionTypes from '../../types/ActionTypes';
import * as ActionUseTypes from '../../types/ActionUseTypes';

const TaskActionsData = [
    //
    // Item Actions
    //
    // New Task
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'tasks',
        sync_token: 1,
        label: "New Task",
        short_label: "New",
        identifier: "new",
        icon: "list-check",
        sort_order: 1,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.NEW,
        description: "Create a new Task.",
        help_text: "Create a new Task.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Edit Task
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'tasks',
        sync_token: 1,
        label: "Edit Task",
        short_label: "Edit",
        identifier: "edit",
        icon: "pen-to-square",
        sort_order: 2,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.EDIT,
        description: "Edit the Fields for a Task.",
        help_text: "Edit the Fields for a Task.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Duplicate Task
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'tasks',
        sync_token: 1,
        label: "Duplicate Task",
        short_label: "Duplicate",
        identifier: "duplicate",
        icon: "copy",
        sort_order: 3,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.DUPLICATE,
        description: "Copy and duplicate the contents of a Task to a new one.",
        help_text: "Copy and duplicate the contents of this Task to a new one.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Mark Completed
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'tasks',
        sync_token: 1,
        label: "Mark Completed",
        short_label: "Complete",
        identifier: "mark_completed",
        icon: "square-check",
        sort_order: 4,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.COMPLETE,
        description: "Mark a Task as completed.",
        help_text: "Mark a Task as completed.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Mark Closed
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'tasks',
        sync_token: 1,
        label: "Mark Closed",
        short_label: "Close",
        identifier: "mark_closed",
        icon: "square-minus",
        sort_order: 5,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.CLOSE,
        description: "Mark a Task as closed.",
        help_text: "Mark this Task as closed.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Convert to Estimate
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'tasks',
        sync_token: 1,
        label: "Convert to Estimate",
        short_label: "Convert",
        identifier: "convert_to_estimate",
        icon: "envelope-open-dollar",
        sort_order: 6,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.CONVERT,
        description: "Convert the details of the Task to a new Estimate.",
        help_text: "Convert the details of this Task to a new Estimate.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Convert to Job
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'tasks',
        sync_token: 1,
        label: "Convert to Job",
        short_label: "Convert",
        identifier: "convert_to_job",
        icon: "briefcase",
        sort_order: 7,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.CONVERT,
        description: "Convert the details of the Task to a new Job.",
        help_text: "Convert the details of this Task to a new Job.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Convert to Invoice
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'tasks',
        sync_token: 1,
        label: "Convert to Invoice",
        short_label: "Convert",
        identifier: "convert_to_invoice",
        icon: "file-invoice-dollar",
        sort_order: 8,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.CONVERT,
        description: "Convert the details of the Task to a new Invoice.",
        help_text: "Convert the details of this Task to a new Invoice.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Archive Task
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'tasks',
        sync_token: 1,
        label: "Archive Task",
        short_label: "Archive",
        identifier: "archive",
        icon: "box-archive",
        sort_order: 9,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.ARCHIVE,
        description: "Archive a task to be hidden from normal tasks in the program.",
        help_text: "Archive a task, hidden but not deleted.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Delete Task
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'tasks',
        sync_token: 1,
        label: "Delete Task",
        short_label: "Delete",
        identifier: "delete",
        icon: "trash",
        sort_order: 10,
        action_type: ActionTypes.ITEM_ACTION,
        use_type: ActionUseTypes.DELETE,
        description: "Permantently deletes a Task. Not retrievable after this point.",
        help_text: "Permantently deletes this Task. Not retrievable after this point.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },


    //
    // Batch Actions
    //

    // Batch Edit
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'tasks',
        sync_token: 1,
        label: "Batch Edit",
        short_label: "Batch Edit",
        identifier: "batch_edit",
        icon: "pen-to-square",
        sort_order: 1,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_EDIT,
        description: "Edit the Fields for multiple selected Tasks at once.",
        help_text: "Edit the Fields for multiple selected Tasks at once.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Batch Archive
    {
        public_id: uniqid('action_'),
        object: null,
        object_identifier: 'tasks',
        sync_token: 1,
        label: "Batch Archive",
        short_label: "Batch Archive",
        identifier: "batch_archive",
        icon: 'box-archive',
        sort_order: 2,
        action_type: ActionTypes.BATCH_ACTION,
        use_type: ActionUseTypes.BATCH_ARCHIVE,
        description: "Archive multiple selected Tasks to be hidden from normal tasks in the program.",
        help_text: "Archive multiple selected Tasks to be hidden from normal tasks in the program.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_conditional: false,
        conditional_requirements: null,
        permission_requirements: null,
        next_actions: null,
        is_custom: false,
        group: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },

];


export default TaskActionsData;