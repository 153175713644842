
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';
import * as ROUTES from '../../../../navigation/routes';
import * as ScreenTypes from '../../../../types/ScreenTypes';
import * as ScreenIdentifiers from '../../../../screens/ScreenIdentifiers';


const BillingPageLayoutsData = [
    {
        public_id: uniqid('page_'),
        sync_token: 1,
        identifier: identifier('Default').toLowerCase(),
        label: 'Default',
        description: 'The default view of the Billing screen.',
        status: 'published',
        revisions: 1,
        route: ROUTES.BILLING,
        type: ScreenTypes.APP_PAGE_LAYOUT,
        screen_identifier: ScreenIdentifiers.BILLING_OVERVIEW,
        template: null,
        object: null,
        object_identifier: null,
        object_type: null,
        group: {
            label: 'Billing',
            identifier: 'billing',
            order: 5
        },
        sub_group: {
            label: 'Billing Overview',
            identifier: 'billing_overview',
            order: 1
        },
        components: {
            component_1: {
                id: "component_1",
                label: 'Recommended Actions',
                object_identifier: null,
                connection_type: 'user',
                type: 'recommended_actions',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                                        
                }
            },
            component_2: {
                id: "component_2",
                label: 'Past-Due Invoices',
                object_identifier: null,
                connection_type: 'none',
                type: 'report',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                                        
                }
            },
            component_3: {
                id: "component_3",
                label: 'Invoice Stats',
                object_identifier: null,
                connection_type: 'none',
                type: 'report',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                                        
                }
            },
            component_4: {
                id: "component_4",
                label: 'Product/Service Stats',
                object_identifier: null,
                connection_type: 'none',
                type: 'report',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                                        
                }
            },
            component_5: {
                id: "component_5",
                label: 'Product/Service Stats',
                object_identifier: null,
                connection_type: 'none',
                type: 'report',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                                        
                }
            },
            component_6: {
                id: "component_6",
                label: 'Payment Methods',
                object_identifier: null,
                connection_type: '',
                type: 'report',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                                        
                }
            },
            component_7: {
                id: "component_7",
                label: 'Invoices',
                object_identifier: null,
                connection_type: 'none',
                type: 'view',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {
                    
                }
            },
            component_8: {
                id: "component_8",
                label: 'Subscriptions',
                object_identifier: null,
                connection_type: 'none',
                type: 'view',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {
                    
                }
            },
            component_9: {
                id: "component_9",
                label: 'Payments',
                object_identifier: null,
                connection_type: 'none',
                type: 'view',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {
                    
                }
            },
            component_10: {
                id: "component_10",
                label: 'Items',
                object_identifier: null,
                connection_type: 'none',
                type: 'view',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {
                    
                }
            }
        },
        sections: [
            {
                id: uniqid('section_'),
                anchor_name: 'recommended_actions_report',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'tabs',
                columns: {
                    mobile: [ { id: '1', flexSize: 1, components: [['component_1', 'component_2']] } ],
                    tablet: [ { id: '1', flexSize: 1, components: ['component_1'] }, { id: '2', flexSize: 1, components: ['component_2'] }],
                    desktop: [ { id: '1', flexSize: 1, components: ['component_1'] }, { id: '2', flexSize: 1, components: ['component_2'] }]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'reports',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'seperate',
                columns: {
                    mobile: [ { id: '3', flexSize: 1, components: [['component_3', 'component_4', 'component_5', 'component_6']] } ],
                    tablet: [ { id: '3', flexSize: 1, components: [['component_3']] }, { id: '4', flexSize: 1, components: [['component_4']] }, { id: '5', flexSize: 1, components: [['component_5']] }, { id: '6', flexSize: 1, components: [['component_6']] }],
                    desktop: [ { id: '3', flexSize: 1, components: [['component_3']] }, { id: '4', flexSize: 1, components: [['component_4']] }, { id: '5', flexSize: 1, components: [['component_5']] }, { id: '6', flexSize: 1, components: [['component_6']] }]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'views',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'tabs',
                columns: {
                    mobile: [{id: '7', flexSize: 1, components: [["component_7", "component_8", "component_9", "component_10"]]}],
                    tablet: [{id: '7', flexSize: 1, components: [["component_7", "component_8", "component_9", "component_10"]]}],
                    desktop: [{id: '7', flexSize: 1, components: [["component_7", "component_8", "component_9", "component_10"]]}]
                }
            },
        ],
        item_actions: [
            "invoices.new",
            "subscriptions.new",
            "payments.new",
            "items.new"
        ],
        more_actions: [ ],
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        published_at: new Date(),
        published_by: null,
        
    },
];

export default BillingPageLayoutsData;