
// This constants file is a representation of how data for List Views will be recieved by the ProHelper database once linked.

// Schedule
import VisitListViewsData from './list_views/VisitListViewsData';

// Work
import RequestListViewsData from './list_views/RequestListViewsData';
import EstimateListViewsData from './list_views/EstimateListViewsData';
import TaskListViewsData from './list_views/TaskListViewsData';
import JobListViewsData from './list_views/JobListViewsData';
import RouteListViewsData from './list_views/RouteListViewsData';

// Clients
import AccountListViewsData from './list_views/AccountListViewsData';
import ContactListViewsData from './list_views/ContactListViewsData';
import PropertyListViewsData from './list_views/PropertyListViewsData';

// Audit
import LedgerListViewsData from './list_views/LedgerListViewsData';
import ReportListViewsData from './list_views/ReportListViewsData';

// Biling 
import InvoiceListViewsData from './list_views/InvoiceListViewsData';
import PaymentListViewsData from './list_views/PaymentListViewsData';
import ItemListViewsData from './list_views/ItemListViewsData';
import SubscriptionListViewsData from './list_views/SubscriptionListViewsData';

// Team
import UserListViewsData from './list_views/UserListViewsData';

// Security
import RoleListViewsData from './list_views/RoleListViewsData';
import PermissionSetListViewsData from './list_views/PermissionSetListViewsData';



// Add (concact) all the individual list view arrays into one big array
const ListViewsData = [].concat(
    VisitListViewsData,
    RequestListViewsData,
    EstimateListViewsData,
    TaskListViewsData,
    JobListViewsData,
    RouteListViewsData,
    AccountListViewsData, 
    ContactListViewsData, 
    PropertyListViewsData, 
    LedgerListViewsData,
    ReportListViewsData,
    InvoiceListViewsData,
    PaymentListViewsData,
    SubscriptionListViewsData,
    ItemListViewsData,
    UserListViewsData,
    RoleListViewsData,
    PermissionSetListViewsData
);

export default ListViewsData;