//
// A file containing all the different Component types for various Object and App Page Layout Screens.
// This file is created to maintain a centeral name for Component types around the ProHelper program.
// 
//

export const OBJECT_HEADER = 'object_header';
export const OBJECT_DETAILS = 'object_details';
export const RELATED = 'related';
export const ACTIVITY = 'activity';
export const TIMELINE = 'timeline';
export const RELATED_RECORD = 'related_record';
export const PERMISSIONS = 'permissions';
export const LINE_ITEMS = 'line_items';
export const TRANSACTIONS_LIST = 'transactions_list';
export const SCHEDULE = 'schedule';
export const SCHEDULE_ITEM = 'schedule_item';
export const TEAM = 'team';
export const ASSIGNMENTS = 'assignments';
export const REPORT = 'report';
export const MAP = 'map';
export const RECOMMENDED_ACTIONS = 'recommended_actions';

export const VIEW = 'view';
export const NOTES_ATTACHMENTS = 'notes_attachments';