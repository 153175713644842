

// This constants file is a representation of how data for object fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as FieldTypes from '../../types/FieldTypes';
import * as DatabaseTypes from '../../types/DatabaseTypes';

const EstimateDropdownSetsData = [
    // Estimate - Statuses
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'estimates',
        sync_token: 1,
        label: "Estimate Statuses",
        identifier: 'estimates.statuses',
        dropdown_plural_label: 'Statuses',
        dropdown_singular_label: "Status",
        description: "A set of all the different statuses for a Estimate.",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: false,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // New
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "New",
                identifier: "new",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "new"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Scheduled
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "Scheduled",
                identifier: "scheduled",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "scheduled"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // In Progress
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "In Progress",
                identifier: "in_progress",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "in_progress"
                },
                color: null,
                sort_order: 3,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // On Hold
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "On Hold",
                identifier: "on_hold",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "on_hold"
                },
                color: null,
                sort_order: 4,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Completed
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "Completed",
                identifier: "completed",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "completed"
                },
                color: null,
                sort_order: 5,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Converted
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "Converted",
                identifier: "converted",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "converted"
                },
                color: null,
                sort_order: 6,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Cancelled
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "Cancelled",
                identifier: "cancelled",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "cancelled"
                },
                color: null,
                sort_order: 7,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            }
        ]
    },
    // Estimates - Tags
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'estimates',
        sync_token: 1,
        label: "Estimate Tags",
        identifier: 'estimates.tags',
        description: "A set of all the different tags for a Estimate.",
        dropdown_plural_label: 'Tags',
        dropdown_singular_label: "Tag",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "Special",
                identifier: "special",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "special"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "Internal",
                identifier: "internal",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "internal"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            }
        ]
    },
    // Estimates - Send Methods
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'estimates',
        sync_token: 1,
        label: "Send Methods",
        identifier: 'estimates.send_methods',
        description: "A set of all the different ways to send an Estimate to a customer.",
        dropdown_plural_label: 'Send Methods',
        dropdown_singular_label: "Send Method",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "Email",
                identifier: "mail",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "mail"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "Mail",
                identifier: "mail",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "mail"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            }
        ]
    },
    // Estimates - Results
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'estimates',
        sync_token: 1,
        label: "Results",
        identifier: 'estimates.results',
        description: "A set of all the different results/outcomes for an Estimate given by a customer.",
        dropdown_plural_label: 'Results',
        dropdown_singular_label: "Result",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Pending
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "Pending",
                identifier: "pending",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "pending"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // On Hold
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "On Hold",
                identifier: "on_hold",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "on_hold"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Accepted
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "Accepted",
                identifier: "accepted",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "accepted"
                },
                color: null,
                sort_order: 3,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Denied
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "Denied",
                identifier: "denied",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "denied"
                },
                color: null,
                sort_order: 4,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Expired
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "Expired",
                identifier: "expired",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "expired"
                },
                color: null,
                sort_order: 5,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Closed
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "Closed",
                identifier: "closed",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "closed"
                },
                color: null,
                sort_order: 7,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    },
    // Estimates - Result Reasons
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'estimates',
        sync_token: 1,
        label: "Results",
        identifier: 'estimates.result_reasons',
        description: "A set of all the different reasonings for the Estimate result/outcome given by a customer.",
        dropdown_plural_label: 'Result Reasons',
        dropdown_singular_label: "Result Reason",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Price
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "Price",
                identifier: "price",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "price"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Scope Alignment
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "Scope Alignment",
                identifier: "scope_alignment",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "scope_alignment"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Competition
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "Competition",
                identifier: "competition",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "competition"
                },
                color: null,
                sort_order: 3,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Changed Requirements
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "Changed Requirements",
                identifier: "changed_requirements",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "changed_requirements"
                },
                color: null,
                sort_order: 4,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Fees
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "Fees",
                identifier: "fees",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "fees"
                },
                color: null,
                sort_order: 5,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Payment Methods
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "Payment Methods",
                identifier: "payment_methods",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "payment_methods"
                },
                color: null,
                sort_order: 7,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Timeframes
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "Timeframes",
                identifier: "timeframes",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "timeframes"
                },
                color: null,
                sort_order: 8,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Terms and Conditions
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "Terms and Conditions",
                identifier: "terms_and_conditions",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "terms_and_conditions"
                },
                color: null,
                sort_order: 9,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Value
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "Value",
                identifier: "value",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "value"
                },
                color: null,
                sort_order: 9,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Communication
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "Communication",
                identifier: "communication",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "communication"
                },
                color: null,
                sort_order: 10,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Other
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "estimates",
                sync_token: 1,
                label: "Other",
                identifier: "other",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "other"
                },
                color: null,
                sort_order: 11,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    },
]

export default EstimateDropdownSetsData;