
// Import NPM Modules
import _ from 'lodash';

// Import Custom Modules
import * as actionTypes from '../../action_types/program/PermissionsActionTypes';

// Empty inital state
const initialState =
{
    role: { },
    permission_sets: [ ],
    all_permissions: {
        platform_permissions: { },
        admin_permissions: { },
        user_permissions: { },
        object_permissions: { },
        object_type_permissions: { },
        object_field_permissions: { },
        navigation_item_permissions: { },
        list_view_permissions: { }
    }
};


// This Reducer handles all ProHelper Permissions data ( )
const permissionsReducer = (state = initialState, action) => {

    switch (action.type) {

        // Set Permissions takes in a payload which is an object optionally consisting of (  )
        case actionTypes.SET_PERMISSIONS:

            // Create a copy of the current redux state
            let newPermissionsState = { ...state };

            // Check if the payload's role object & permission sets array are not empty
            if (!_.isEmpty(action.payload.role) && !_.isEmpty(action.payload.permission_sets) && !_.isEmpty(action.payload.all_permissions)) {

                // Set the user's role in this reducers state
                newPermissionsState.role = {...action.payload.role};

                // Set the user's permission set in this reducers state
                newPermissionsState.permission_sets = [...action.payload.permission_sets];

                // Set the user's permission set in this reducers state
                newPermissionsState.all_permissions = {...action.payload.all_permissions};

            }

            // Update this reducer's state with the newly updated permissions state
            return newPermissionsState;

        default:
            // Return untouched state
            return state;
    }


};

export default permissionsReducer;