const Currencies = [
    {
        label: "United States Dollar",
        short_label: "USD",
        value: 'usd',
        metadata: {
            precision: 2,
            seperator: ',',
            decimal: '.',
            pattern: '!#',
            negativePattern: '-!#',
            symbol: "$",
            countries: ['us']
        }
    },
    {
        label: "Mexican Peso",
        short_label: "MXP",
        value: 'mxp',
        metadata: {
            precision: 2,
            seperator: ',',
            decimal: '.',
            pattern: '!#',
            negative_pattern: '-!#',
            symbol: "$",
            countries: ['MX']
        }
    },
    {
        label: "Canadian Dollar",
        short_label: "CAD",
        value: 'cad',
        metadata: {
            precision: 2,
            seperator: ',',
            decimal: '.',
            pattern: '!#',
            negative_pattern: '-!#',
            symbol: "$",
            countries: ['CA']
        }
    }
];

export default Currencies;