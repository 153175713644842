

// Import Custom Modules
import * as actionTypes from '../../action_types/ui/PageListViewListActionTypes';
import * as ObjectIdentifiers from '../../../constants/static_data/ObjectIdentifiers';

const STARTING_LIST_DATA = {
    totalContentSize: {
        width: 0,
        height: 0
    },
    rows: [],
    itemActions: [],
    selectedRowIds: {},
    expandedRowIds: {},
}

const initialState =
{
    [ObjectIdentifiers.EVENTS]: {...STARTING_LIST_DATA},
    [ObjectIdentifiers.VISITS]: {...STARTING_LIST_DATA},
    [ObjectIdentifiers.TASKS]: {...STARTING_LIST_DATA},
    [ObjectIdentifiers.REQUESTS]: {...STARTING_LIST_DATA},
    [ObjectIdentifiers.ESTIMATES]: {...STARTING_LIST_DATA},
    [ObjectIdentifiers.JOBS]: {...STARTING_LIST_DATA},
    [ObjectIdentifiers.ROUTES]: {...STARTING_LIST_DATA},
    [ObjectIdentifiers.ACCOUNTS]: {...STARTING_LIST_DATA},
    [ObjectIdentifiers.CONTACTS]: {...STARTING_LIST_DATA},
    [ObjectIdentifiers.PROPERTIES]: {...STARTING_LIST_DATA},
    [ObjectIdentifiers.INVOICES]: {...STARTING_LIST_DATA},
    [ObjectIdentifiers.PAYMENTS]: {...STARTING_LIST_DATA},
    [ObjectIdentifiers.SUBSCRIPTIONS]: {...STARTING_LIST_DATA},
    [ObjectIdentifiers.ITEMS]: {...STARTING_LIST_DATA},
    [ObjectIdentifiers.LEDGERS]: {...STARTING_LIST_DATA},
    [ObjectIdentifiers.REPORTS]: {...STARTING_LIST_DATA},
    [ObjectIdentifiers.USERS]: {...STARTING_LIST_DATA},
    [ObjectIdentifiers.ROLES]: {...STARTING_LIST_DATA},
    [ObjectIdentifiers.PERMISSION_SETS]: {...STARTING_LIST_DATA},
};


const PageListViewListReducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SET_PAGE_LIST_VIEW_LIST_STATE:

            return {
                ...state,
                [action.payload.object.identifier]: action.payload.state
            };

        default:
            return state;

    }
};


export default PageListViewListReducer;
