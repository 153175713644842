
// This constants file is a representation of how data for object fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import moment from 'moment-timezone';
import * as FieldTypes from '../../types/FieldTypes';
import * as DatabaseTypes from '../../types/DatabaseTypes';
import Currencies from '../../static_data/Currencies';
import Countries from '../../static_data/Countries';
import Languages from '../../static_data/Languages';

const createTimeZonesObjList = (timezone_identifiers) => {
    return timezone_identifiers.map((timezone_identifier) => {
        return {
            label: timezone_identifier,
            value: timezone_identifier
        }
    });
}

const convertToDropdownArray = (staticObjectsArray) => {
    return staticObjectsArray.map((staticObj) => {
        return {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "static",
                sync_token: 1,
                label: staticObj.label ? staticObj.label : '',
                short_label: staticObj.short_label ? staticObj.short_label : staticObj.label,
                identifier: staticObj.value.toLowerCase(),
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: staticObj.value.toLowerCase()
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                is_locked: false,
                is_default: true,
                is_custom: false,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: staticObj.metadata ? staticObj.metadata : null,
                dropdown_settings: {}
            }
    })
}

const StaticDropdownSetsData = [
    // Static - Countries
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: "static",
        sync_token: 1,
        label: "Countries",
        identifier: 'static.countries',
        dropdown_plural_label: 'Countries',
        dropdown_singular_label: "Country",
        description: "A set of all the countries that are usable in the ProHelper platform.",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: true,
        is_live: true,
        is_editable: false,
        is_editable_inline: false,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: convertToDropdownArray(Countries)
    },
    // Static - Languages
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: "static",
        sync_token: 1,
        label: "Languages",
        identifier: 'static.languages',
        dropdown_plural_label: 'Languages',
        dropdown_singular_label: "Language",
        description: "A set of all the languages that are usable in the ProHelper platform.",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: true,
        is_live: true,
        is_editable: false,
        is_editable_inline: false,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: convertToDropdownArray(Languages)
    },
    // Static - Time Zones
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: "static",
        sync_token: 1,
        label: "Time Zones",
        identifier: 'static.time_zones',
        dropdown_plural_label: 'Time Zones',
        dropdown_singular_label: "Time Zone",
        description: "A set of all the timezones used by ProHelper, Moment Timezones, & PostgreSQL Timezones.",
        field_type: FieldTypes.TEXT_DROPDOWN,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: true,
        is_live: true,
        is_editable: false,
        is_editable_inline: false,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: convertToDropdownArray(createTimeZonesObjList(moment.tz.names()))
    },
    // Static - Currencies
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: "static",
        sync_token: 1,
        label: "Currencies",
        identifier: 'static.currencies',
        dropdown_plural_label: 'Currencies',
        dropdown_singular_label: "Currency",
        description: "A set of all the currencies used by ProHelper, Stripe & Quickbooks.",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: true,
        is_live: true,
        is_editable: false,
        is_editable_inline: false,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: convertToDropdownArray(Currencies)
    },
    // Static - Event Visibilities
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: "static",
        sync_token: 1,
        label: "Currencies",
        identifier: 'static.currencies',
        dropdown_plural_label: 'Currencies',
        dropdown_singular_label: "Currency",
        description: "A set of all the currencies used by ProHelper, Stripe & Quickbooks.",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: true,
        is_live: true,
        is_editable: false,
        is_editable_inline: false,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: convertToDropdownArray(Currencies)
    },
    // Static - Visibility
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'static',
        sync_token: 1,
        label: "Event Visibilities",
        identifier: 'static.event_visibilities',
        description: "A set of all the different options for visibility of an Event.",
        dropdown_plural_label: 'Visiblities',
        dropdown_singular_label: "Visibility",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "static",
                sync_token: 1,
                label: "Public",
                identifier: "public",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "public"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "static",
                sync_token: 1,
                label: "Private",
                identifier: "private",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "private"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    },
    // Statis - Show As
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'static',
        sync_token: 1,
        label: "Event Show As Options",
        identifier: 'static.event_show_as_options',
        description: "A set of all the different options for how to show an Event as busy or not.",
        dropdown_plural_label: 'Show As',
        dropdown_singular_label: "Show As",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "static",
                sync_token: 1,
                label: "Busy",
                identifier: "busy",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "busy"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "static",
                sync_token: 1,
                label: "Out of Office",
                identifier: "out_of_office",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "out_of_office"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "static",
                sync_token: 1,
                label: "Free",
                identifier: "free",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "free"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            }
        ]
    },
    // Static - Date Input Formats
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: "global",
        sync_token: 1,
        label: "Date Input Formats",
        identifier: 'static.date_input_formats',
        dropdown_plural_label: 'Date Input Formats',
        dropdown_singular_label: "Date Input Format",
        description: "A set of all the different possible data formats/display configurations (YYYY/MM/DD) to used by the company when inputting date data.",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "MM-DD-YYYY",
                identifier: "MM-DD-YYYY",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "MM-DD-YYYY"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                is_locked: false,
                is_default: true,
                is_custom: false,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "YYYY-MM-DD",
                identifier: "YYYY-MM-DD",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "YYYY-MM-DD"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "DD-MM-YYYY",
                identifier: "DD-MM-YYYY",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "DD-MM-YYYY"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    },
    // Static - Date Viewing Formats
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: "global",
        sync_token: 1,
        label: "Date Viewing Formats",
        identifier: 'static.date_viewing_formats',
        dropdown_plural_label: 'Date Viewing Formats',
        dropdown_singular_label: "Date Viewing Format",
        description: "A set of all the different possible data formats/display configurations (YYYY/MM/DD) to used by the company when displaying date data.",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "MMM Do, YYYY",
                identifier: "MMM Do, YYYY",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "MMM Do, YYYY"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                is_locked: false,
                is_default: true,
                is_custom: false,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "M-D-YYYY",
                identifier: "M-D-YYYY",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "M-D-YYYY"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                is_locked: false,
                is_default: true,
                is_custom: false,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "YYYY-M-D",
                identifier: "YYYY-M-D",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "YYYY-M-D"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                is_locked: false,
                is_default: true,
                is_custom: false,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "D-M-YYYY",
                identifier: "D-M-YYYY",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "D-M-YYYY"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                is_locked: false,
                is_default: true,
                is_custom: false,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "MM-DD-YYYY",
                identifier: "MM-DD-YYYY",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "MM-DD-YYYY"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                is_locked: false,
                is_default: true,
                is_custom: false,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "YYYY-MM-DD",
                identifier: "YYYY-MM-DD",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "YYYY-MM-DD"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                is_locked: false,
                is_default: true,
                is_custom: false,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "DD-MM-YYYY",
                identifier: "DD-MM-YYYY",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "DD-MM-YYYY"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                is_locked: false,
                is_default: true,
                is_custom: false,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    },
    // Static - Online Statuses
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'static',
        sync_token: 1,
        label: "Online Statuses",
        identifier: 'static.online_statuses',
        description: "A set of all the different online statuses for a user.",
        dropdown_plural_label: 'Online Statuses',
        dropdown_singular_label: "Online Status",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: true,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "static",
                sync_token: 1,
                label: "Online",
                identifier: "online",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "online"
                },
                color: "#2D9639",
                sort_order: 1,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "static",
                sync_token: 1,
                label: "Away",
                identifier: "away",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "away"
                },
                color: "rgba(105, 114, 130, 1)",
                sort_order: 2,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "static",
                sync_token: 1,
                label: "Offline",
                identifier: "offline",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "offline"
                },
                color: "#DA3A3B",
                sort_order: 3,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    },
    // Static - Working Statuses
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'static',
        sync_token: 1,
        label: "Working Statuses",
        identifier: 'static.working_statuses',
        description: "A set of all the different working statuses for a user.",
        dropdown_plural_label: 'Working Statuses',
        dropdown_singular_label: "Working Status",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "static",
                sync_token: 1,
                label: "Off-Clock",
                identifier: "off-clock",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "off-clock"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "static",
                sync_token: 1,
                label: "Clocked-In",
                identifier: "clocked-in",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "clocked-in"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "static",
                sync_token: 1,
                label: "On-Break",
                identifier: "on-break",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "on-break"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "static",
                sync_token: 1,
                label: "Working",
                identifier: "working",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "working"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "static",
                sync_token: 1,
                label: "Not Set",
                identifier: "not-set",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "not-set"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            }
        ]
    },
    // Static - Device Types
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'static',
        sync_token: 1,
        label: "Device Types",
        identifier: 'static.device_types',
        description: "A set of all the different device viewport types.",
        dropdown_plural_label: 'Device Types',
        dropdown_singular_label: "Device Type",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "static",
                sync_token: 1,
                label: "Mobile",
                identifier: "mobile",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "mobile"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "static",
                sync_token: 1,
                label: "Tablet",
                identifier: "tablet",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "tablet"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "static",
                sync_token: 1,
                label: "Desktop",
                identifier: "desktop",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "desktop"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            }
        ]
    },

]

export default StaticDropdownSetsData;