
// This constants file is a representation of how data for Report object types will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';


const ReportObjectTypesData = [

    // ----------------------------------------
    // Report Object Types
    // ----------------------------------------

    // Administrative
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Administrative",
        identifier: "administrative",
        description: "An adiministrative type.",
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'admin',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Schedule
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Schedule",
        identifier: "schedule",
        description: "An schedule type.",
        sort_order: 2,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'schedule',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Events
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Events",
        identifier: "events",
        description: "An events type.",
        sort_order: 3,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'schedule',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Visits
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Visits",
        identifier: "visits",
        description: "An visits type.",
        sort_order: 4,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'schedule',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Work
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Work",
        identifier: "work",
        description: "An work type.",
        sort_order: 5,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'work',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Requests
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Requests",
        identifier: "requests",
        description: "An requests type.",
        sort_order: 6,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'work',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Estimates
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Estimates",
        identifier: "estimates",
        description: "An estimates type.",
        sort_order: 7,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'work',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Tasks
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Tasks",
        identifier: "tasks",
        description: "An tasks type.",
        sort_order: 8,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'work',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Jobs
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Jobs",
        identifier: "jobs",
        description: "An jobs type.",
        sort_order: 9,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'work',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Routes
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Routes",
        identifier: "routes",
        description: "An routes type.",
        sort_order: 10,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'work',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Clients
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Clients",
        identifier: "clients",
        description: "An clients type.",
        sort_order: 11,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'clients',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Accounts
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Accounts",
        identifier: "accounts",
        description: "An accounts type.",
        sort_order: 12,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'clients',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Contacts
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Contacts",
        identifier: "contacts",
        description: "An contacts type.",
        sort_order: 13,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'clients',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Properties
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Properties",
        identifier: "properties",
        description: "An properties type.",
        sort_order: 14,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'clients',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Billing
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Billing",
        identifier: "billing",
        description: "An billing type.",
        sort_order: 15,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'billing',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Invoices
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Invoices",
        identifier: "invoices",
        description: "An invoices type.",
        sort_order: 16,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'billing',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Subscription
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Subscription",
        identifier: "subscription",
        description: "An subscription type.",
        sort_order: 17,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'billing',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Payments
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Payments",
        identifier: "payments",
        description: "An payments type.",
        sort_order: 18,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'billing',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Items
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Items",
        identifier: "items",
        description: "An items type.",
        sort_order: 19,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'billing',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Audit
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Audit",
        identifier: "audit",
        description: "An audit type.",
        sort_order: 20,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'audit',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Ledgers
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Ledgers",
        identifier: "ledgers",
        description: "An ledgers type.",
        sort_order: 21,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'audit',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Team
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Team",
        identifier: "team",
        description: "An team type.",
        sort_order: 22,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'team',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Users
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Users",
        identifier: "users",
        description: "An users type.",
        sort_order: 23,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'team',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Security
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Security",
        identifier: "security",
        description: "An security type.",
        sort_order: 24,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'security',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Roles
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Roles",
        identifier: "roles",
        description: "An roles type.",
        sort_order: 25,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'security',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Permission Sets
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Permission Sets",
        identifier: "permission_sets",
        description: "An permission sets type.",
        sort_order: 26,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'security',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // General
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "General",
        identifier: "general",
        description: "An general type.",
        sort_order: 27,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'general',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // File & Content
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "File & Content",
        identifier: "file_content",
        description: "An file & content type.",
        sort_order: 28,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'file_content',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Campaigns
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Campaigns",
        identifier: "campaigns",
        description: "An campaigns type.",
        sort_order: 29,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'campaigns',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Personal
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Personal",
        identifier: "personal",
        description: "An personal type.",
        sort_order: 30,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'personal',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "reports",
        sync_token: 1,
        label: "Other",
        identifier: "other",
        description: "An other type.",
        sort_order: 31,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        is_sub_type: false,
        parent: null,
        category: 'other',
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    
];

export default ReportObjectTypesData;