
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';
import * as ROUTES from '../../../../navigation/routes';
import * as ScreenTypes from '../../../../types/ScreenTypes';
import * as ScreenIdentifiers from '../../../../screens/ScreenIdentifiers';


const AuditPageLayoutsData = [
    {
        public_id: uniqid('page_'),
        sync_token: 1,
        identifier: identifier('Default').toLowerCase(),
        label: 'Default',
        description: 'The default view of the Audit screen.',
        status: 'published',
        revisions: 1,
        route: ROUTES.AUDIT,
        type: ScreenTypes.APP_PAGE_LAYOUT,
        screen_identifier: ScreenIdentifiers.AUDIT_OVERVIEW,
        template: null,
        object: null,
        object_identifier: null,
        object_type: null,
        group: {
            label: "Audit",
            identifier: "audit",
            order: 6
        },
        sub_group: {
            label: "Audit Overview",
            identifier: "overview",
            order: 1
        },
        components: {
            component_1: {
                id: "component_1",
                label: 'Balance Sheet',
                object_identifier: null,
                connection_type: 'none',
                type: 'report',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {

                }
            },
            component_2: {
                id: "component_2",
                label: 'Cash Flow',
                object_identifier: null,
                connection_type: 'none',
                type: 'report',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {

                }
            },
            component_3: {
                id: "component_3",
                label: 'Profits and Loss',
                object_identifier: null,
                connection_type: 'none',
                type: 'report',
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {

                }
            },
            component_3: {
                id: "component_3",
                label: 'New Customers',
                object_identifier: null,
                connection_type: null,
                type: 'report',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {

                }
            },
            component_4: {
                id: "component_4",
                label: 'New Jobs',
                object_identifier: null,
                connection_type: null,
                type: 'report',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {

                }
            },
            component_5: {
                id: "component_5",
                label: 'New Subscriptions',
                object_identifier: null,
                connection_type: null,
                type: 'report',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {

                }
            },
            component_6: {
                id: "component_6",
                label: 'Total Revenue',
                object_identifier: null,
                connection_type: null,
                type: 'report',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {

                }
            },
            component_7: {
                id: "component_7",
                label: 'Profit Margin',
                object_identifier: null,
                connection_type: null,
                type: 'report',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {

                }
            },
            component_8: {
                id: "component_8",
                label: 'Revenue Breakdown',
                object_identifier: null,
                connection_type: null,
                type: 'report',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {

                }
            },
            component_9: {
                id: "component_9",
                label: 'Outstanding Balances',
                object_identifier: null,
                connection_type: null,
                type: 'report',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {

                }
            },
            component_10: {
                id: "component_10",
                label: 'Projected Revenue',
                object_identifier: null,
                connection_type: null,
                type: 'report',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {

                }
            },
            component_11: {
                id: "component_11",
                label: 'Avg. Days until Payment',
                object_identifier: null,
                connection_type: null,
                type: 'report',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {

                }
            },
            component_12: {
                id: "component_12",
                label: 'Items Breakdown',
                object_identifier: null,
                connection_type: null,
                type: 'report',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {

                }
            },
            component_13: {
                id: "component_13",
                label: 'Avg. Daily Employee Items',
                object_identifier: null,
                connection_type: null,
                type: 'report',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {

                }
            },
            component_14: {
                id: "component_14",
                label: 'Avg. Employee Work Time',
                object_identifier: null,
                connection_type: null,
                type: 'report',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {

                }
            },
            component_15: {
                id: "component_15",
                label: 'Customers by City',
                object_identifier: null,
                connection_type: null,
                type: 'report',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {

                }
            },
            component_16: {
                id: "component_16",
                label: 'Payment Method Breakdown',
                object_identifier: null,
                connection_type: null,
                type: 'report',
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: false,
                metadata: {

                }
            }
        },
        sections: [
            {
                id: uniqid('section_'),
                anchor_name: 'company_reports',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'tabs',
                columns: {
                    mobile: [{ id: '1', flexSize: 1, components: [["component_1", "component_2", "component_3"]] }],
                    tablet: [{ id: '1', flexSize: 1, components: ["component_1"] }, { id: '2', flexSize: 1, components: ["component_2"] }, { id: '3', flexSize: 1, components: ["component_3"] }],
                    desktop: [{ id: '1', flexSize: 1, components: ["component_1"] }, { id: '2', flexSize: 1, components: ["component_2"] }, { id: '3', flexSize: 1, components: ["component_3"] }]
                }
                
            },
            {
                id: uniqid('section_'),
                anchor_name: 'business_overview',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'seperate',
                columns: {
                    mobile: [{ id: '3', flexSize: 1, components: [["component_3", "component_4", "component_5", "component_6", "component_7", "component_8"]] }],
                    tablet: [{ id: '3', flexSize: 1, components: [["component_3", "component_4", "component_5"]] }, { id: '4', flexSize: 1, components: [["component_6", "component_7", "component_8"]] }],
                    desktop: [{ id: '3', flexSize: 1, components: [["component_3", "component_4", "component_5"]] }, { id: '4', flexSize: 1, components: [["component_6", "component_7", "component_8"]] }]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'growth_and_billing_area',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'seperate',
                columns: {
                    mobile: [{ id: '5', flexSize: 1, components: [["component_9", "component_10", "component_11", "component_12"]] }],
                    tablet: [{ id: '5', flexSize: 1, components: [["component_9", "component_10"]] }, { id: '6', flexSize: 1, components: [["component_11", "component_12"]] }],
                    desktop: [{ id: '5', flexSize: 1, components: ["component_9"] }, { id: '6', flexSize: 1, components: ["component_10"] }, { id: '7', flexSize: 1, components: ["component_11"] }, { id: '8', flexSize: 1, components: ["component_12"] }]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'employee_customer_area',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'seperate',
                columns: {
                    mobile: [{ id: '9', flexSize: 1, components: [["component_13", "component_14", "component_15", "component_16"]] }],
                    tablet: [{ id: '9', flexSize: 1, components: [["component_13", "component_14"]] }, { id: '6', flexSize: 1, components: [["component_15", "component_16", "component_17"]] }],
                    desktop: [{ id: '9', flexSize: 1, components: [["component_13", "component_14"]] }, { id: '6', flexSize: 1, components: [["component_15", "component_16", "component_17"]] }]
                }
            },
        ],
        item_actions: [ ],
        more_actions: [ ],
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        published_at: new Date(),
        published_by: null,
        
    },
];

export default AuditPageLayoutsData;